import { useContext } from 'react';
import { store } from 'components/Store';
import normalize from 'components/Dashboard/utilities/normalizeString';
import enforceUpdateStoredFilters from './enforceUpdateStoredFilters';
import FiltersData from './FiltersData';
import detectURImalformed from 'components/Dashboard/helpers/detectURImalformed';
import LocalStorage from 'utils/LocalStorage';
import { createSectionName } from 'components/Dashboard/helpers/controlActiveFilters';
const dayjs = require('dayjs');

const useCompileInitialFilters = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { useLocalStorageForReference, team, keywordsLists } = state;

  const compileInitialFilters = (mainSection) => {
    let storedFilters = LocalStorage.getItem('contentFilters');
    let initialObject = JSON.parse(JSON.stringify(FiltersData()));
    const sectionName = createSectionName();

    let referenceFilters =
      !enforceUpdateStoredFilters(mainSection) &&
      storedFilters &&
      JSON.parse(storedFilters)[mainSection] &&
      useLocalStorageForReference
        ? JSON.parse(storedFilters)[mainSection]
        : initialObject;
    let storedUnreadFilter = LocalStorage.getItem('seeUnreadFilter');

    //OPTIONS FROM THE OBJECT
    let sortFilter = referenceFilters.find((item) => item.type === 'sort');
    let searchFilter = referenceFilters
      .filter((filter) => (sectionName ? filter.pages.includes(sectionName) : true))
      .find((item) => item.type === 'search');
    let unreadFilter = referenceFilters.find((item) => item.name === 'See unread only');
    let dateFilter = referenceFilters.filter((item) => item.type === 'date');
    let addedOnFilter = referenceFilters.find((item) => item.type === 'addedOn');
    let toggleFilter = referenceFilters.find((option) => option.type === 'toggle' && option.name !== 'See unread only');
    let notUseLocalstorageFilters = referenceFilters.filter((item) => item.notUseFromLocalStorage);
    let parliamentaryFilters = referenceFilters.find(
      (option) => option.type === 'parliamentaryRecordMultipleHierarchy'
    );

    //UNREAD FILTER FROM LOCALSTORAGE
    if (storedUnreadFilter === 'true' && !!unreadFilter) {
      unreadFilter.value = true;
    }

    //SET DEFAULT OPTION TO THE SORTFILTER FROM THE LOCALSTORAGE
    if (!sortFilter?.optionSelected) {
      sortFilter.optionSelected = 'Most relevant';
    }

    //GET FILTERS FROM HASH
    let hash = window.location.hash;
    let hashParts = hash !== '' ? hash.split('#')[1].split('~') : [];
    //SORT FILTER
    if (hashParts[0] && hashParts[0].startsWith('most')) {
      sortFilter.options.forEach((item) => {
        if (normalize(item.name) === hashParts[0]) {
          sortFilter.optionSelected = item.name;
        }
      });
      hashParts.slice(0, 1);
    }

    //SOME FILTERS DON'T NEED TO USE LOCALSTORAGE (CALENDAR FILTER, CONSULTATIONS DATE, CONTACTSTAGS)
    notUseLocalstorageFilters.forEach((item) => {
      let initialItem = initialObject.find(
        (option) => option.name === item.name && option.searchState === item.searchState && option.type === item.type
      );
      if (initialItem) {
        let referenceFiltersIndex = referenceFilters.findIndex(
          (option) => option.name === item.name && option.searchState === item.searchState && option.type === item.type
        );
        referenceFilters[referenceFiltersIndex] = initialItem;
      }
    });

    //HASH HAS PRIORITY
    hashParts.forEach((item) => {
      //SEARCH FILTER
      if (item.startsWith('search-within')) {
        searchFilter.value = detectURImalformed(item.split(':')[1]);
      }

      //UNREAD FILTER
      if (item === 'unread-only') {
        unreadFilter.value = true;
      }
      //DATE FILTER
      dateFilter.forEach((dateFilterItem) => {
        dateFilterItem.options.forEach((dateOption) => {
          let { hash, name, showCalendar, filterProperty } = dateOption;
          let hashParts = item.split('-');
          let dateName =
            item.includes('after') || item.includes('before') || item.includes('between')
              ? hashParts.splice(0, 1)[0]
              : item;
          if (normalize(hash ? hash : name) === dateName) {
            dateFilterItem.optionSelected = name;
            if (showCalendar) {
              let dates = hashParts.join('-').split('and');
              let filterToApply;
              if (dates.length > 1) {
                filterToApply = [
                  {
                    field: 'dateTime',
                    value: `${dayjs(dates[0].slice(0, -1)).startOf('day').format().split('+')[0]}`,
                    operator: 'DATE_GTE',
                  },
                  {
                    field: 'dateTime',
                    value: `${
                      dayjs(dates[1].substring(1, dates[1].length)).startOf('day').add(1, 'day').format().split('+')[0]
                    }`,
                    operator: 'DATE_LT',
                  },
                ];
              } else {
                filterToApply = [
                  {
                    field: 'dateTime',
                    value: `${dayjs(dates[0]).startOf('day').format().split('+')[0]}`,
                    operator: filterProperty,
                  },
                ];
              }
              dateOption.dates = dates.join(',');
              dateOption.filter = filterToApply;
            }
          }
        });
      });

      // //DATE FILTER
      addedOnFilter?.options.forEach((dateOption) => {
        let { hash, name, showCalendar, filterProperty } = dateOption;
        let prefixValue = detectURImalformed(item.split(':')[1]);
        let hashParts = prefixValue.split('-');
        let dateName =
          prefixValue.includes('after') || prefixValue.includes('before') || prefixValue.includes('between')
            ? hashParts.splice(0, 1)[0]
            : prefixValue;
        if (normalize(hash ? hash : name) === dateName) {
          addedOnFilter.optionSelected = name;
          if (showCalendar) {
            let dates = hashParts.join('-').split('and');
            let filterToApply;
            if (dates.length > 1) {
              filterToApply = [
                {
                  field: 'dateTime',
                  value: `${dayjs(dates[0].slice(0, -1)).startOf('day').format().split('+')[0]}`,
                  operator: 'DATE_GTE',
                },
                {
                  field: 'dateTime',
                  value: `${
                    dayjs(dates[1].substring(1, dates[1].length)).startOf('day').add(1, 'day').format().split('+')[0]
                  }`,
                  operator: 'DATE_LT',
                },
              ];
            } else {
              filterToApply = [
                {
                  field: 'dateTime',
                  value: `${dayjs(dates[0]).startOf('day').format().split('+')[0]}`,
                  operator: filterProperty,
                },
              ];
            }
            dateOption.dates = dates.join(',');
            dateOption.filter = filterToApply;
          }
        }
      });

      // //TOGGLE FILTER
      if (item.includes('exclude')) {
        toggleFilter.value = true;
      }

      // //PARLIAMENTARY RECORD FILTERS
      parliamentaryFilters.options.forEach((option) => {
        let { options, houseFilter, selectedOptions, houseFilterSelectedOptions } = option;

        options.forEach((suboption) => {
          let itemToCompare = suboption.hash ?? normalize(suboption.name);
          if (itemToCompare === item) {
            option.selected = true;
            if (!selectedOptions.includes(suboption.name)) {
              selectedOptions.push(suboption.name);
            }
          }
        });

        if (houseFilter) {
          houseFilter.options.forEach((houseOption) => {
            let itemToCompare = houseOption.hash ?? normalize(houseOption.name);
            if (itemToCompare === item) {
              option.selected = true;
              if (!houseFilterSelectedOptions.includes(houseOption.name)) {
                houseFilterSelectedOptions.push(houseOption.name);
              }
            }
          });
        }
      });
      // //COMMON FILTERS
      referenceFilters.forEach((filter) => {
        let options = filter.options;
        let isPrefix = item.includes(':');
        let prefixProperty = item.split(':')[0];
        let prefixValue = isPrefix ? detectURImalformed(item.split(':')[1]) : '';
        if (options) {
          options.forEach((option) => {
            let filterHash = option.hash ?? normalize(option.name);

            if ((isPrefix ? prefixValue : item) === filterHash) {
              option.selected = true;
              filter.optionSelected = option.name;
            }

            //LEGISLATION CASE
            if (option.suboptions) {
              option.suboptions.forEach((suboption) => {
                let suboptionHash = suboption.hash ?? normalize(suboption.name);
                if (item === suboptionHash) {
                  option.optionSelected = suboption.name;
                }
              });
            }
          });

          if (isPrefix && filter.type === 'topicPickerWithSentiment') {
            const list = keywordsLists?.find((list) => list?.id === parseInt(prefixValue));
            const listOption = options?.find((item) => item?.id === list?.id);
            if (!!list && !listOption) {
              options.push({ ...list, selected: true });
            }
          }

          if (
            isPrefix &&
            prefixProperty === filter.prefix &&
            (filter.type === 'membership' || filter.filterName === 'tags')
          ) {
            let userId;
            if (filter.subtype === 'ContactAddedByUser') {
              const userTitle = detectURImalformed(item.split(':')[1]);
              const userEmail = userTitle
                .split(' ')
                .pop()
                .substring(1, userTitle.split(' ').pop().length - 1);
              userId = team.allMembers.find((user) => user.emailAddress === userEmail)?.id;
            }

            let option = {
              filterValue: filter.subtype === 'ContactAddedByUser' ? `${userId}` : prefixValue,
              name: prefixValue,
              selected: true,
            };
            let filterOption = options?.find((filterOption) => filterOption.name === option.name);
            if (!filterOption) {
              options.push(option);
            }
          }
        }
      });
    });
    return referenceFilters;
  };

  return { compileInitialFilters };
};

export default useCompileInitialFilters;
