import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';

const SkeletonEmailAlertsPage = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='pt-4 pt-lg-5 settings-content-component email-alerts-page'>
        <div className='settings-box' ref={containerRef} style={{ height: `${heightContainer}px` }}>
          <div className='padding-topic-component section-title py-4 row mx-0 justify-content-between align-items-center'>
            <div className='col-10'>
              <Skeleton height={24} width={`30%`} />
            </div>
            <div className='col-6 text-right'>
              <Skeleton height={28} width={`50%`} />
            </div>
          </div>
          <div className='px-lg-2 px-xl-4 pt-5'>
            <div className='padding-topic-component'>
              <Skeleton height={23} width={`30%`} />
            </div>
            {Array(3)
              .fill()
              .map((item, index) => (
                <div
                  className={`alert-list-main-container section-title padding-topic-component `}
                  key={`index-${index}`}
                >
                  <div className='px-lg-2 px-xl-4 pb-5'>
                    <div
                      className={`row mx-0 justify-content-between align-items-center email-alerts-main-box-container`}
                    >
                      <div className='col-lg-9 px-0'>
                        <Skeleton height={22} width={`60%`} />
                      </div>
                      <div className='pause-edit-alert d-flex flex-row-reverse flex-sm-row mt-3 mt-sm-0'>
                        <Skeleton height={24} width={`120px`} style={{ borderRadius: '20px' }} />
                        <div className='ml-3'>
                          <Skeleton height={24} width={`130px`} style={{ borderRadius: '20px' }} />
                        </div>
                      </div>
                    </div>

                    <div className={`overflow-hidden transition-element`}>
                      <Skeleton height={18} width={`50%`} />
                      <Skeleton height={18} width={`80%`} />
                      <Skeleton height={18} width={`70%`} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <hr />
          <div className='padding-topic-component py-4'>
            <div className='px-lg-2 px-xl-4'>
              <div className='mt-5 mb-4'>
                <Skeleton height={22} width={`50%`} />
              </div>
              {Array(3)
                .fill()
                .map((item, index) => (
                  <div className='row mx-0 mb-3' key={`index-${index}`}>
                    <div className='col-lg-6 px-0'>
                      <Skeleton height={19} width={`80%`} />
                    </div>
                    <div className='col-lg-10 px-0'>
                      <div className='row mx-0'>
                        <div className='col-lg-4 px-0'>
                          <button
                            className={`general-button mx-4  toggle-component pricing-toggle toggle-inactive toggle-skeleton`}
                          />
                        </div>
                        <div className='col-lg-12'>
                          <div>
                            <Skeleton height={19} width={`80%`} />
                          </div>
                          <div className='d-flex'>
                            <div>
                              <Skeleton height={19} width={`80%`} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonEmailAlertsPage;
