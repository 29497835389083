import { useContext } from 'react';
import { store } from 'components/Store';
import { createAnalyticsState, createDataOptionsMapping } from 'components/Dashboard/Analytics/createAnalyticsState';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import dayjs from 'dayjs';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import getAppSite from 'utils/getAppSite';

export const useCreateReportsWidgets = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, team, topicForReport, activePlan } = state;
  const { teamName } = team;
  const { organisationName } = activePlan;

  const topicParam = getUrlParam('topic-id');
  const { reportData } = props ?? {};

  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();

  const topicParameter = !!topicParam
    ? keywordsLists.find((topic) => parseInt(topicParam) === topic.id)
    : keywordsLists.filter((item) => item.id !== null)?.[0];
  const topicSaved = reportData?.keywordListId
    ? keywordsLists.find((topic) => topic.id === reportData?.keywordListId)
    : null;
  const topic = topicForReport ?? topicSaved ?? topicParameter;

  const graphProperties = {
    component: 'Graph',
    maxRowSpan: 6,
    state: {
      topic: topic,
      analyticsState: createAnalyticsState(),
      commentary: null,
      displayGrid: true,
      allowFrontbenchers: false,
    },
  };
  const reportsWidgets = [
    {
      definition: { minColumnSpan: 2, minRowSpan: 2, defaultRowSpan: 2, defaultColumnSpan: 2 },
      data: {
        name: 'Your commentary',
        icon: 'consultation',
        component: 'Commentary',
        state: {
          value: '',
          widgetName: `Commentary ${teamName ? `from ${newAgencyPlan ? organisationName : teamName}` : ''}`,
        },
      },
    },
    {
      definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 6, defaultColumnSpan: 2 },
      data: {
        name: 'Latest news',
        icon: 'key-update',
        component: 'LatestNews',
        state: { topic: topic, commentary: null, showImages: true },
      },
    },
    {
      definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 4 },
      data: {
        name: 'A specific update',
        icon: 'briefing-material',
        component: 'SpecificUpdate',
        state: { specificUpdate: null, topic: topic, commentary: null, highlightUpdate: true },
      },
    },
    {
      definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 6, defaultColumnSpan: 2 },
      data: {
        name: 'Relevant policymakers',
        icon: 'team',
        component: 'MostInterestedStakeholders',
        state: {
          topic: topic,
          showRelevantContributions: true,
          showRelevantPositions: false,
          showRelevantMemberships: false,
          commentary: null,
          dates: {
            name: 'Last 12 months',
            filter: [dayjs().subtract(1, 'year').startOf('day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
          },
        },
      },
    },
    {
      definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 2 },
      data: { name: 'Mentions', icon: 'statistics', ...graphProperties },
    },
    {
      definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 2 },
      data: { name: 'Interest by region', icon: 'location', ...graphProperties },
      hidden: getAppSite() === 'usa',
    },
    {
      definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 2 },
      data: {
        name: 'Sentiment snapshot',
        icon: 'snapshot',
        ...graphProperties,
        state: { ...graphProperties.state, activeSections: ['Positive', 'Negative', 'Neutral', 'Mixed'] },
      },
    },
    {
      definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 2 },
      data: { name: 'Sentiment changes', icon: 'changes', ...graphProperties },
    },
    /* {
      definition: {minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4,  defaultColumnSpan: 2}, 
      data: {name: "Top MPs and peers", icon: "graph", ...graphProperties}
    } */
  ];

  const createWidgets = ({ layout, report }) => {
    const dateToReference = report?.updateTo;
    if (layout) {
      return reportsWidgets
        .filter((item) => !item.hidden)
        .map((item, index) => {
          let newId = index;
          // eslint-disable-next-line
          while (layout.find((widget) => widget.id === newId)) {
            newId += '0';
          }
          let newItem = {
            ...item,
            id: newId,
            title: item.data.name,
            data: {
              ...item.data,
              state: { ...item.data.state, widgetName: item?.data?.state?.widgetName ?? item.data.name },
            },
          };
          newItem = modifyGraphDates({ item: newItem, dateToReference });
          return newItem;
        });
    }
  };

  const modifyGraphDates = ({ item, dateToReference }) => {
    let newItem = { ...item };
    if (!!dateToReference) {
      let graphItem = newItem.data.component === 'Graph';
      let stakeholderItem = newItem?.data?.component === 'MostInterestedStakeholders';

      let filterName = graphItem
        ? newItem?.data?.state?.analyticsState?.activeDateInterval?.name
        : newItem?.data?.state?.dates?.name ?? 'Last 12 months';
      let firstDateParent = createDataOptionsMapping({ date: dateToReference })?.find(
        (item) => item.name === filterName
      );
      let dates = [firstDateParent?.filter?.[0], dayjs(dateToReference).startOf('day').format('YYYY-MM-DD')];
      if (filterName !== 'Custom') {
        if (graphItem) {
          newItem = {
            ...newItem,
            data: {
              ...newItem.data,
              state: {
                ...newItem.data.state,
                analyticsState: {
                  ...newItem.data.state.analyticsState,
                  activeDateInterval: {
                    ...newItem.data.state.analyticsState.activeDateInterval,
                    filter: dates,
                  },
                },
              },
            },
          };
        }

        if (stakeholderItem) {
          newItem = {
            ...newItem,
            data: {
              ...newItem.data,
              state: {
                ...newItem.data.state,
                dates: {
                  name: filterName,
                  filter: dates,
                },
              },
            },
          };
        }
      }
    }
    return newItem;
  };

  const createReportName = ({ type }) => {
    const timeFrameReport = !!type && type !== 'mentions' && type !== 'commentary';
    let reportName = agencyUser
      ? `${timeFrameReport && !topic?.prospective ? `${uppercaseFirstLetter(type)} political report` : 'Political report'} for ${topic?.name}`
      : `${topic?.name}${timeFrameReport ? ` - ${type}` : ''} political report`;
    return reportName;
  };

  return { reportsWidgets, createWidgets, topic, createReportName, modifyGraphDates };
};
