import React from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer.js';
import getAppSite from 'utils/getAppSite';
const WaitingAnimationScreen = (props) => {
  const { waitingMessage, section } = props;
  const [heightContainer, containerRef] = useHeightContainer();

  const updatingCondition = section?.hasNotBeenModified === false;

  return (
    <div ref={containerRef} className='pt-2' style={{ height: `${heightContainer}px` }}>
      <div className='settings-box p-lg-5 py-4 px-3 h-100 d-flex flex-column justify-content-center'>
        <div>
          <div className='text-center pb-5 mx-auto waiting-screen'>
            {waitingMessage ? (
              <>
                <p className='mb-2'>
                  <strong>{`PolicyMogul is identifying ${waitingMessage}`}</strong>
                </p>
                <p>This typically takes 20 - 30 seconds, but may take longer</p>
              </>
            ) : (
              <>
                <p className='mb-2'>
                  <strong>
                    PolicyMogul is {updatingCondition ? 'updating' : 'creating'} your personali
                    {getAppSite() === 'usa' ? 'z' : 's'}ed feed
                  </strong>
                </p>
                <p>This typically takes 20 - 30 seconds, but may take longer</p>
              </>
            )}
          </div>
          <div className='waiting-bar mx-auto'>
            <div className='waiting-bar-indicator' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingAnimationScreen;
