import { Tooltip as ReactTooltip } from 'react-tooltip';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
const SettingsTooltips = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <ReactTooltip
        id={'settings-tooltip'}
        effect={'solid'}
        type={'info'}
        className={'pricing-feature-tooltip feed-tooltip'}
        arrowColor={'transparent'}
        place={'right'}
        backgroundColor={'#00122B'}
        multiline={true}
        globalEventOff={isMobile ? 'click' : undefined}
      />
      {/* Necessary to allow some items that needs to work on mobile*/}
      <ReactTooltip
        id={'setting-tooltip-all'}
        effect={'solid'}
        type={'info'}
        className={'pricing-feature-tooltip feed-tooltip'}
        backgroundColor={'#00122B'}
        textColor={'#fff'}
        multiline={true}
        arrowColor={'transparent'}
        place={'right'}
      />
    </>
  );
};

export default SettingsTooltips;
