import { useEffect, useRef } from 'react';
import Loading from 'components/Login/Loading';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useNavigate } from 'react-router-dom';
import createNotification from 'components/Settings/Utilities/CreateNotification';

const OauthCallback = () => {
  const initialCall = useRef();
  const { generalApiCall } = useGeneralApiCall();
  const codeParam = getUrlParam('code');
  const stateParam = getUrlParam('state');
  const navigate = useNavigate();

  initialCall.current = async () => {
    try {
      const pathname = '/api/slack/process-slack-authorization';
      const method = 'post';
      const requestProperties = {
        code: codeParam,
        state: stateParam,
      };
      const results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        requestProperties,
        returnCompleteRequest: true,
      });
      if (results?.status === 200) {
        navigate('/settings/alerts/create?alertType=Slack');
      }
    } catch (error) {
      createNotification('danger', `An error ocurred.`);
    }
  };

  useEffect(() => {
    if (!!stateParam && !!codeParam) {
      initialCall.current();
    } else {
      createNotification('danger', `An error ocurred.`);
    }
  }, []);

  return <Loading />;
};

export default OauthCallback;
