import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { store } from 'components/Store';
import ParliamentarianNameAutocomplete from 'components/Login/RegistrationVerification/ParliamentarianNameAutocomplete';
import axios from 'axios';
import getAppSite from 'utils/getAppSite';

const SecondStepParlamentarian = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { userProfile } = state;
  const parliamentarianType = decodeURIComponent(getUrlParam('user-type'));
  const [parliamentarianInfo, setParliamentarianInfo] = useState('');
  const modifyValue = (property, value) => {
    setParliamentarianInfo(value);
  };
  const navigate = useNavigate();
  const { generalApiCall } = useGeneralApiCall();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const registerUser = async (e) => {
    let el = e.target;
    el.disabled = true;
    let addTopics = await addTopicForParliamentarian();
    if (addTopics && addTopics.status === 200) {
      let userCall = await updateUser();
      if (!userCall) {
        return navigate('/create-your-profile/1');
      }
      return navigate('/create-your-profile/3');
    }
    e.disabled = false;
  };

  const addTopicForParliamentarian = async (e) => {
    let pathname = `/api/registration/add-topics-for-parlimentarian-or-staff`;
    let requestProperties = {
      parliamentarianId: parliamentarianInfo.id,
      userType: parliamentarianType,
    };
    let method = 'post';
    let results = await generalApiCall({
      pathname,
      requestProperties,
      method,
      returnCompleteRequest: true,
      needsAuthentication: true,
    });
    return results;
  };

  const updateUser = async (e) => {
    let pathname = `/api/user/update`;
    const { userID, firstName, lastName, fullName, organisationLogo, jobTitle, organisation } = userProfile;
    let requestProperties = {
      userType: parliamentarianType,
      userID,
      firstName,
      lastName,
      fullName,
      organisationLogo,
      organisation,
      jobTitle,
    };
    if (parliamentarianType === 'Parliamentary or political staff') {
      requestProperties = {
        ...requestProperties,
        organisation: parliamentarianInfo.label,
      };
    } else {
      let arrayName = parliamentarianInfo.label.split(' ');
      let firstName = arrayName[0];
      let lastName = arrayName.splice(1).join(' ');
      requestProperties = {
        ...requestProperties,
        firstName: firstName,
        lastName: lastName,
        fullName: parliamentarianInfo.label,
      };
    }
    let method = 'post';
    let updateCall = await generalApiCall({ pathname, requestProperties, method, needsAuthentication: true });
    return updateCall;
  };

  const inputSearchSuggestion = useRef();

  const focusInput = useRef();
  focusInput.current = () => {
    setTimeout(() => {
      inputSearchSuggestion.current.focus();
    }, 300);
  };

  useEffect(() => {
    focusInput.current();
    return () => {
      source.cancel('Parliamentarian registration cancelled by the user');
    };
  }, []);

  return (
    <>
      <h2 className='mb-2'>
        {parliamentarianType && parliamentarianType === 'Parliamentary or political staff'
          ? `Which ${getAppSite() === 'usa' ? 'policymaker' : 'parliamentarian'} do you work for?`
          : 'Please confirm your name'}{' '}
      </h2>

      <div className='form-group my-4'>
        <ParliamentarianNameAutocomplete
          ref={inputSearchSuggestion}
          property={'parliamentarian'}
          modifyValue={modifyValue}
          itemValue={parliamentarianInfo}
        />
      </div>

      <button
        className='btn btn-general action-button px-4 py-1 my-4'
        disabled={!parliamentarianInfo.id}
        onClick={(e) => {
          registerUser(e);
        }}
      >
        Complete signup
      </button>
    </>
  );
};

export default SecondStepParlamentarian;
