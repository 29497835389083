import GraphItem from 'components/Dashboard/Analytics/AnalyticsDashboardGraphItem';
import DropdownComponent from 'components/Settings/WebsiteFeeds/DropdownComponent';
import { DateFilterButton } from 'components/Dashboard/Analytics/ui/MentionsByTopic/FiltersBar';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { ContributionsToIncludeSelect } from 'components/Dashboard/Analytics/ui/OverallSentiment';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import TopicPicker from 'components/Common/TopicPicker';
import { createDataOptionsMapping, mentionsGraphNations } from 'components/Dashboard/Analytics/createAnalyticsState';
import dayjs from 'dayjs';

const ReportsAnalyticsItem = (props) => {
  const { chartType, item, widgetData, setWidgetData, boardContainer, previewMode, report } = props;
  const baseMaxTickLimit = 5;
  const defaultColumn = 2;
  const { analyticsState, topic, activeSections, displayGrid, commentary, allowFrontbenchers } = item?.data?.state;
  const mainRef = useRef();
  const imageRef = useRef();
  const [image, setImage] = useState(null);
  const [map, setMap] = useState(null);
  const [isMapResizing, setIsMapResizing] = useState(true);
  const [secondImage, setSecondImage] = useState(null);

  const parent = mainRef.current?.parentElement?.parentElement?.parentElement?.parentElement;
  const prevSibling = mainRef.current?.parentElement?.previousElementSibling;
  const previewInterestByRegion = previewMode && chartType === 'Interest by region' && !isMapResizing;
  const { width: mapWidth, height: mapHeight } = parent?.getBoundingClientRect() ?? {};

  useEffect(() => {
    if (previewInterestByRegion) {
      createRegionPreloader();
      //AE: Time of map resizing transition
      if (map) {
        setTimeout(() => {
          let element = mainRef.current?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
          html2canvas(element, {
            allowTaint: true,
            useCORS: true, //important to avoid CORS related errors
            width: element.clientWidth - 2, //
            height: element.clientHeight - 2,
            onclone: (doc) => {
              let svg = doc.querySelector('svg.leaflet-zoom-animated');
              const matrixValues = svg.style.transform.match(/matrix.*\((.+)\)/)?.[1]?.split(', ');
              svg.style.transform = `none`;
              svg.style.left = `${matrixValues?.[4]}px`;
              svg.style.top = `${matrixValues?.[5]}px`;
              svg.style.filter = 'brightness(0.75)';
            },
          })
            .then((canvas) => {
              return canvas.toDataURL('image/png', 1.0);
            })
            .then((img) => {
              setImage(img);
            });
        }, 500);
      }
    } else {
      setImage(null);
      setSecondImage(null);
    }
  }, [previewMode, map, isMapResizing]);

  useEffect(() => {
    if (chartType === 'Interest by region' && isMapResizing) {
      createRegionPreloader();
    } else {
      if (!previewMode) {
        setTimeout(() => removeRegionPreloader(), 200);
      }
    }
  }, [isMapResizing]);

  useEffect(() => {
    if (!!image && imageRef.current) {
      domtoimage
        .toPng(imageRef.current, {
          width: imageRef.current?.clientWidth,
          height: imageRef.current?.clientHeight,
        })
        .then(function (dataUrl) {
          setSecondImage(dataUrl);
          let element = mainRef.current?.parentElement?.previousElementSibling;
          let commentary = mainRef.current?.previousElementSibling;
          if (element) {
            element.style.display = 'none';
          }
          if (commentary) {
            commentary.style.display = 'none';
          }
          removeRegionPreloader();
        });
    }
  }, [image, imageRef.current]);

  const createRegionPreloader = () => {
    const element = document.getElementById('region-preview-preloader');
    if (!element) {
      const { top: parentTop, left: parentLeft } = boardContainer.current?.getBoundingClientRect();
      const selector = mainRef.current?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
      const { top, left, height, width } = selector?.getBoundingClientRect() ?? {};
      const newElement = document.createElement('div');
      const elementClasses = [
        'position-absolute',
        'z-max',
        'flex-centered',
        'justify-content-center',
        'bg-main-white',
        'rounded',
      ];
      newElement.setAttribute('id', 'region-preview-preloader');
      newElement.classList.add(...elementClasses);
      newElement.style.top = `${top - parentTop + 1}px`;
      newElement.style.left = `${left - parentLeft + 1}px`;
      newElement.style.width = `${width - 2}px`;
      newElement.style.height = `${height - 2}px`;
      newElement.innerHTML = `<img src="${process.env.REACT_APP_CDNURL}/images/loader.gif" alt="Loading" />`;
      boardContainer.current.append(newElement);
    }
  };

  const removeRegionPreloader = () => {
    const element = document.getElementById('region-preview-preloader');
    if (element) {
      element.remove();
    }
  };

  const [commentaryHeight, setCommentaryHeight] = useState(0);
  useEffect(() => {
    let previousElement = mainRef?.current?.previousElementSibling;
    if (previousElement) {
      setTimeout(() => {
        setCommentaryHeight(previousElement?.offsetHeight + 24);
      }, 10);
    }
  }, [mainRef?.current, mainRef.current?.offsetHeight, commentary]);

  const heightValue = () => {
    let heightValue = parent?.getBoundingClientRect()?.height - (prevSibling?.getBoundingClientRect()?.height + 24);
    if (previewInterestByRegion) {
      return '100%';
    } else if (!!commentary) {
      if (previewMode) {
        return isNaN(heightValue) ? '100%' : heightValue - commentaryHeight;
      } else {
        return `calc(100% - ${commentaryHeight}px)`;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <div
        ref={mainRef}
        className={`pt-2 reports-analytics-item ${previewInterestByRegion || !!commentary ? '' : 'h-100'}`}
        style={!!heightValue() ? { height: heightValue() } : null}
      >
        <div className={!!image ? 'd-none h-100' : 'h-100'} style={!!image ? { maxWidth: '100%' } : null}>
          <GraphItem
            analyticsState={analyticsState}
            topic={topic}
            chartType={chartType}
            reportsProps={{
              widgetData,
              setWidgetData,
              activeSections,
              boardPosition: { w: item?.columnSpan, h: item?.rowSpan },
              regionHeight: 'calc(100% - 1px)',
              totalHeight: !previewMode,
              heightGraph:
                heightValue() ??
                mainRef?.current?.parentElement?.parentElement?.parentElement?.getBoundingClientRect()?.height - 74,
              maxTicksLimit:
                item.columnSpan !== defaultColumn
                  ? baseMaxTickLimit + (item.columnSpan - defaultColumn) * 2
                  : baseMaxTickLimit,
              paddingXLabel: 28,
              sentimentChangesLeftPadding: 30,
              map,
              displayGrid,
              setMap,
              allowFrontbenchers,
              setIsMapResizing,
              reportUpdateTo: report?.updateTo,
            }}
          />
        </div>
        {image && !secondImage && (
          <div
            className='border rounded report-region-preview-map'
            style={{
              maxWidth: `${mapWidth}px`,
              maxHeight: `${mapHeight}px`,
              width: `${mapWidth}px`,
              height: `${mapHeight}px`,
            }}
            ref={imageRef}
          >
            <div style={{ filter: 'brightness(1.15)' }}>
              <img alt={`map-chart`} src={image} style={{ maxWidth: '100%' }} />
            </div>
          </div>
        )}
        {secondImage && (
          <img
            className='report-region-preview-map border rounded'
            style={{
              maxWidth: `${mapWidth}px`,
              maxHeight: `${mapHeight}px`,
              width: `${mapWidth}px`,
              height: `${mapHeight}px`,
            }}
            alt='final map'
            src={secondImage}
          />
        )}
      </div>
    </>
  );
};

const ReportsAnalyticsSettings = (props) => {
  const globalState = useContext(store);
  const { state: appState } = globalState;
  const { keywordsLists } = appState;

  const { state, setState, chartType, report } = props;
  const { analyticsState, topic, displayGrid, allowFrontbenchers } = state;
  const { selectedCountriesInterestByRegion } = analyticsState;

  const typeOfChartOptions = {
    'Interest by region': {
      default: 'Heat map',
      options: ['Heat map', 'Bubble chart map'],
      stateOption: 'typeOfChartInterestByRegion',
    },
    'Sentiment changes': {
      default: 'Line',
      options: ['Line', 'Bar'],
      stateOption: 'typeOfChartSentimentChanges',
    },
    Mentions: {
      options: ['Line', 'Bar'],
      stateOption: 'typeOfChart',
    },
  };
  const chartTypeMap = typeOfChartOptions[chartType];

  const views = ['Month', 'Week', 'Day'];

  const modifyState = ({ property, newValue }) => {
    let dateToReference = report?.updateTo;
    let newState = property === 'CompleteState' ? newValue : { ...analyticsState, [property]: newValue };
    if (property === 'activeDateInterval' && !!dateToReference) {
      let finalValue = { ...newValue };
      let firstDateParent = createDataOptionsMapping({ date: dateToReference })?.find(
        (item) => item.name === finalValue?.name
      );
      let dates = [firstDateParent?.filter?.[0], dayjs(dateToReference).startOf('day').format('YYYY-MM-DD')];
      finalValue = {
        ...finalValue,
        filter: dates,
      };
      newState = { ...analyticsState, [property]: finalValue };
    }
    setState({ ...state, analyticsState: newState });
  };

  const { transformTopicOrClientWord, agencyUser } = useTopicOrClientWord();

  return (
    <div className='reports-graph-settings'>
      {!agencyUser && (
        <div className='my-4 reports-topick-picker'>
          <label className='mb-2'> {transformTopicOrClientWord({ uppercase: true })} </label>
          <TopicPicker
            keywordsLists={keywordsLists.filter((item) => item.id !== null)}
            showTopicCases={true}
            searchFunction={({ topic }) => {
              setState({ ...state, topic });
            }}
            defaultTopic={topic}
            fixedPosition
            placeholder={`Select a topic`}
            showTopicSelected={true}
            showActions={false}
          />
        </div>
      )}

      {chartTypeMap && (
        <div className={`my-4`}>
          <label>Type of chart</label>
          <div className='mt-2 keywords-website-settings'>
            <DropdownComponent
              mainItem={() => (
                <span className='hidden-lines hidden-one-line w-100'>{analyticsState?.[chartTypeMap.stateOption]}</span>
              )}
            >
              {chartTypeMap.options.map((item, index) => (
                <p
                  key={`${item}-${index}`}
                  className={`mb-0 hour-item position-relative`}
                  onClick={() => {
                    modifyState({ property: chartTypeMap.stateOption, newValue: item });
                  }}
                >
                  {item}

                  {analyticsState?.[chartTypeMap.stateOption] === item && (
                    <span className='icon-tick topic-selected-mark paragraph-p4' />
                  )}
                </p>
              ))}
            </DropdownComponent>
          </div>
        </div>
      )}

      {chartType === 'Sentiment snapshot' && (
        <div className='mt-4 pt-1'>
          <label className='mb-2'>Contributions to include</label>
          <div className='pt-2'>
            <ContributionsToIncludeSelect
              activeSections={state?.activeSections}
              setActiveSections={(newSections) => setState({ ...state, activeSections: newSections })}
            />
          </div>
        </div>
      )}

      <div className='row my-4'>
        <div
          className={`col-lg-${chartType === 'Sentiment snapshot' || chartType === 'Interest by region' ? '16' : '8'}`}
        >
          <label className='mb-2'>Timeframe</label>
          <DateFilterButton
            analyticsState={analyticsState}
            modifyFiltersBar={modifyState}
            customTopValue={35}
            customLeftValue={0}
          />
        </div>
        {chartType !== 'Interest by region' && chartType !== 'Sentiment snapshot' && (
          <div className='col-lg-8'>
            <label className='mb-2'>View</label>
            <div className='keywords-website-settings'>
              <DropdownComponent
                fixedDropdown={true}
                mainItem={() => (
                  <span className='hidden-lines hidden-one-line w-100'>{analyticsState.currentView}</span>
                )}
              >
                {views.map((view, index) => (
                  <p
                    key={`${view}-${index}`}
                    className={`mb-0 flex-centered hour-item position-relative`}
                    onClick={() => {
                      modifyState({ property: 'currentView', newValue: view });
                    }}
                  >
                    {view}
                    {analyticsState.currentView === view && (
                      <span className='icon-tick topic-selected-mark paragraph-p4' />
                    )}
                  </p>
                ))}
              </DropdownComponent>
            </div>
          </div>
        )}
      </div>

      {chartType === 'Mentions' && (
        <div className={`d-flex justify-content-between align-items-center py-4 mt-4 border-top border-bottom`}>
          <p className='font-weight-bold mb-0'>Show gridlines</p>
          <button
            className={`general-button filter-toggle filter-toogle-${displayGrid ? 'active' : 'inactive'}`}
            onClick={() => {
              setState({ ...state, displayGrid: !displayGrid });
            }}
          >
            <span className='toggle-item' />
          </button>
        </div>
      )}

      {chartType === 'Interest by region' && (
        <>
          <div className={`d-flex justify-content-between align-items-center py-4`}>
            <p className='font-weight-bold'>Include contributions from frontbenchers</p>
            <button
              className={`general-button filter-toggle filter-toogle-${allowFrontbenchers ? 'active' : 'inactive'}`}
              onClick={() => {
                setState({ ...state, allowFrontbenchers: !allowFrontbenchers });
              }}
            >
              <span className='toggle-item' />
            </button>
          </div>

          <div className={`d-flex justify-content-between align-items-center py-4 border-top`}>
            <p className='font-weight-bold'>Include all stakeholders</p>
            <button
              className={`general-button filter-toggle filter-toogle-${selectedCountriesInterestByRegion?.length === 3 ? 'active' : 'inactive'}`}
              onClick={() => {
                modifyState({
                  property: 'selectedCountriesInterestByRegion',
                  newValue:
                    selectedCountriesInterestByRegion?.length === 1 ? mentionsGraphNations : [mentionsGraphNations[0]],
                });
              }}
            >
              <span className='toggle-item' />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export { ReportsAnalyticsSettings };
export default ReportsAnalyticsItem;
