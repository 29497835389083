import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';

const useUpdateSummaries = () => {
  const { generalApiCall } = useGeneralApiCall();

  const updateSummaries = async ({ objectId, contentType, summary, keywordListId }) => {
    let method = 'post';
    let pathname = keywordListId
      ? `/api/content-summary/edit-tailored-summary-for-client/${keywordListId}`
      : '/api/content-summary/edit-tailored-summary';
    let requestProperties = {
      objectId,
      contentType,
      summary,
    };
    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    if (result) {
      return result;
    }
  };

  return {
    updateSummaries,
  };
};

export default useUpdateSummaries;
