import React, { useEffect, useState, useContext, useRef } from 'react';
import { store } from 'components/Store.js';
import SettingsSidebar from './settings-sidebar';
import Account from './Account/index';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from 'components/Login/Loading';
import Billing from './Billing';
import Team from './Team';
import SettingsHeader from './settings-header';
import Topics from './Topics';
import WeebsiteFeedsPage from './WebsiteFeeds';
import EmailDomainsPage from './EmailDomains';
import TeamContent from './TeamContent';
import EmailAlerts from './EmailAlerts';
import EmailAddresses from './EmailAddressesList/index.js';
import axios from 'axios';
import getActiveSubscriptions from './Billing/getActiveSubscriptions.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import SlackIntegration from './SlackIntegration/index.js';
import getCurrentUser from './Account/getCurrentUser.js';
import SettingsTooltips from './settings-tooltips.js';
import { Navigate, Route, Routes } from 'react-router-dom';
import Clients from './Clients/index.js';
import { useResetClientNavigation } from 'components/Dashboard/Navigation/ClientNavigation.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';

const Settings = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { search, activeSubscriptions } = state;
  const { subscriptionStatus, enableCrm } = activeSubscriptions;
  const [headerSizes, setHeaderSizes] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessToken } = useGetAccessToken();
  const { resetClientState } = useResetClientNavigation();

  const settingsPagesApiCall = useRef();
  settingsPagesApiCall.current = async (source) => {
    try {
      //AE: Enforce to load keywordlists, teams and currentteam when navigating betgit aween settings and main app.
      dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: false });
      resetClientState();
      const includeScheduledSubscription = true;
      let activeSubscriptions = await getActiveSubscriptions(getAccessToken, source, includeScheduledSubscription);
      let currentUser = await getCurrentUser(getAccessToken, source);

      if (!!activeSubscriptions) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: activeSubscriptions });
      }
      if (!!currentUser) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: currentUser });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    settingsPagesApiCall.current(source);
    //AE: THIS IS NECCESSARY TO AVOID DUPLICATE CALLS WHEN YOU CLICK ON A SETTINGS PAGE AND GO BACK TO THE MAIN APP
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'search',
      value: {
        ...search,
        searchState: false,
      },
    });

    return () => {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'scrollTopicPosition', value: null });
      source.cancel('Settings cancel by the user');
    };
  }, []);

  const { transformTopicOrClientWord, newAgencyPlan } = useTopicOrClientWord();

  return (
    <div className='container-fluid px-0 settings-container'>
      <SettingsHeader headerSizes={headerSizes} setHeaderSizes={setHeaderSizes} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className='row mx-auto main-content-wrapper main-content-settings'>
          <div className='side-left-element'>
            <SettingsSidebar headerSizes={headerSizes} />
          </div>
          <div className='px-0 main-content pr-xl-5 px-md-4 px-3'>
            <Routes>
              <Route path={`account`} element={<Account />} />
              <Route path='alerts' element={<EmailAlerts />} />
              <Route path='alerts/create' element={<EmailAlerts />} />
              <Route path='/integration-with-slack' element={<SlackIntegration />} />

              <Route
                path={`topics`}
                element={newAgencyPlan ? <Navigate replace to={'/settings/clients'} /> : <Topics />}
              />
              <Route path={`topics/:list`} element={<Topics />} />
              <Route path={`clients`} element={<ClientsPage />} />
              <Route path={`clients/:list`} element={<Topics />} />
              <Route path={`embeddables`} element={<WeebsiteFeedsPage />} />
              <Route
                path={`email-domains`}
                element={
                  !enableCrm ? (
                    <Navigate
                      replace
                      to={`/settings/${transformTopicOrClientWord({
                        plural: true,
                      })}`}
                    />
                  ) : (
                    <EmailDomainsPage />
                  )
                }
              ></Route>
              <Route
                path={`sender-email-addresses`}
                element={
                  !enableCrm ? (
                    <Navigate
                      replace
                      to={`/settings/${transformTopicOrClientWord({
                        plural: true,
                      })}`}
                    />
                  ) : (
                    <EmailAddresses />
                  )
                }
              ></Route>
              <Route
                path={`billing`}
                element={
                  subscriptionStatus === 'InTrial' || subscriptionStatus === 'TrialEnded' ? (
                    <Navigate replace to={`/pricing`} />
                  ) : (
                    <Billing />
                  )
                }
              ></Route>

              <Route path={`team`} element={<Team />} />
              <Route path={`my-teams-content`} element={<TeamContent />} />
              <Route element={<NoMatchPage />} />
            </Routes>
          </div>
        </div>
      )}
      <SettingsTooltips />
    </div>
  );
};

const ClientsPage = () => {
  const { newAgencyPlan } = useTopicOrClientWord();
  return <>{newAgencyPlan ? <Clients /> : <Topics />}</>;
};

const NoMatchPage = () => {
  return <h3 className='px-5'>404 - Not found</h3>;
};

export default withAuthenticationRequired(Settings, {
  onRedirecting: () => <Loading />,
});
