const getAppSite = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'PolicyMogulUSA':
      return 'usa';
    default:
      return 'global';
  }
};

const useSiteSectionsNames = () => {
  const names = [
    {
      global: 'Parliamentary record',
      usa: 'Congressional record',
    },
    {
      global: 'Spoken parliamentary contributions',
      usa: 'Congressional record contributions',
    },
    { global: 'Key updates', usa: 'News' },
  ];

  const getName = ({ property }) => {
    const item = names.find((item) => item?.global === property);
    return item?.[getAppSite()] ?? property;
  };

  return { getName };
};

export { useSiteSectionsNames };
export default getAppSite;
