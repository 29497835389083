import customApiCall from 'components/Dashboard/apiCalls/customApiCall';
import axios from 'axios';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';
import { store } from 'components/Store';
import { useContext } from 'react';

function useEmailDomainFunctions() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { listDomainsVerified } = state;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let requestHeaders = {
    cancelToken: source.token,
  };

  const { getAccessToken } = useGetAccessToken();
  const { generalApiCall } = useGeneralApiCall();

  const addDomainSenderRequest = async ({
    emailDomainId,
    userName,
    displayName,
    successMessage,
    isAdminPage,
    teamId,
  }) => {
    let method = 'post';
    let pathname = isAdminPage ? '/api/admin/add-sender-email-address' : `/api/email-domain/add-sender-email-address`;
    let token = await getAccessToken();
    requestHeaders.headers = {
      Authorization: `Bearer ${token}`,
    };
    let requestProperties = {
      emailDomainId,
      userName,
      displayName,
    };

    if (!!teamId) {
      requestProperties = {
        ...requestProperties,
        teamId: teamId,
      };
    }
    const result = await customApiCall({
      method,
      pathname,
      requestHeaders,
      requestProperties,
      successMessage: successMessage ?? 'Email address registered',
    });

    return result;
  };

  const addEmailDomainListRequest = async ({ domainName, isAdminPage, teamId }) => {
    let method = 'post';
    let pathname = isAdminPage ? '/api/admin/register-email-domain' : '/api/email-domain/register-email-domain';
    let requestProperties = {
      DomainName: domainName,
    };

    if (teamId) {
      requestProperties = {
        ...requestProperties,
        TeamId: parseInt(teamId),
      };
    }

    return await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      requestProperties,
    });
  };

  const getEmailDomains = async ({ organisationId, isAdminPage = false, source, getAllTeams = false }) => {
    let method = 'get';
    let pathname = isAdminPage
      ? `/api/admin/get-all-domains/organisation/${organisationId}`
      : `/api/email-domain/get-all-domains${getAllTeams ? '-all-teams' : ''}`;
    let emailDomains = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    return emailDomains;
  };

  const syncEmailDomain = async ({ id, isAdminPage, source, teamId }) => {
    let method = 'post';
    let pathname = isAdminPage
      ? '/api/admin/sync-email-domains'
      : `/api/email-domain/sync-email-domains${teamId ? `?team-id=${teamId}` : ''}`;
    let requestProperties = isAdminPage
      ? {
          organisationId: parseInt(id),
        }
      : null;
    return await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
  };

  const getEmailAddressesCall = async (source, getAllTeams) => {
    let method = 'get';
    let pathname = `/api/email-domain/get-all-sender-email-addresses${getAllTeams ? `-all-teams` : ''}`;
    let emailAddresses = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    return emailAddresses;
  };

  const deleteEmailAddressRequest = async ({ activeItem, loadInitialInformation }) => {
    let method = 'delete';
    let pathname = `/api/email-domain/sender-email-address/${activeItem.id}`;
    let token = await getAccessToken();
    requestHeaders.headers = {
      Authorization: `Bearer ${token}`,
    };
    let requestProperties = {};
    var successMessage = 'Email address deleted successfully';
    var result = await customApiCall({
      method,
      pathname,
      requestHeaders,
      requestProperties,
      successMessage,
    });
    if (result) {
      await loadInitialInformation();
      return result;
    }
  };

  const deleteEmailDomainRequest = async ({ emailDomainId, isAdminPage, teamId, loadInitialInformation }) => {
    let method = 'post';
    let pathname = isAdminPage ? '/api/admin/delete-email-domain' : '/api/email-domain/delete-email-domain';
    let token = await getAccessToken();
    requestHeaders.headers = {
      Authorization: `Bearer ${token}`,
    };
    let requestProperties = isAdminPage
      ? {
          EmailDomainId: parseInt(emailDomainId),
          TeamId: parseInt(teamId),
        }
      : {
          EmailDomainId: parseInt(emailDomainId),
        };
    var successMessage = 'Domain deleted successfully';
    var result = await customApiCall({
      method,
      pathname,
      requestHeaders,
      requestProperties,
      successMessage,
    });
    if (result) {
      loadInitialInformation(teamId);
      return result;
    }
  };

  const resetEmailDomain = async ({ domainName, teamId, loadInitialInformation, isAdminPage }) => {
    let method = 'post';
    let pathname = isAdminPage
      ? '/api/admin/restart-email-domain-verification'
      : '/api/email-domain/restart-email-domain-verification';
    let token = await getAccessToken();
    requestHeaders.headers = {
      Authorization: `Bearer ${token}`,
    };
    let requestProperties = {
      domainName,
      teamId,
    };
    let result = await customApiCall({
      method,
      pathname,
      requestHeaders,
      requestProperties,
    });
    if (result) {
      if (loadInitialInformation) {
        loadInitialInformation(teamId);
      }
      return result;
    }
  };

  const needsDomainVerification =
    //no verified domains
    (((!listDomainsVerified.verifiedDomains.length || !listDomainsVerified.allDomains.length) &&
      !listDomainsVerified.verifiedEmailAddresses.length) ||
      //no verif ied addresses
      !listDomainsVerified.verifiedEmailAddresses.length) &&
    !listDomainsVerified.domainsProccessing;

  return {
    addDomainSenderRequest,
    addEmailDomainListRequest,
    getEmailDomains,
    syncEmailDomain,
    getEmailAddressesCall,
    deleteEmailAddressRequest,
    deleteEmailDomainRequest,
    resetEmailDomain,
    needsDomainVerification,
  };
}

export default useEmailDomainFunctions;
