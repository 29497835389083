import React, { useContext } from 'react';
import preventLink from './prevenLink.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { store } from 'components/Store.js';
import { formatEmbargoedDate } from 'components/Dashboard/helpers/formatGeneralDate.js';

const MatchingListItem = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { keywordsLists, search } = state;
  const { list } = search ?? {};

  const { item, calendarTags } = props;
  const { name, id, embargoed, embargoedUntil, teamName, topics } = item;

  const navigate = useNavigate();
  const location = useLocation();

  const tagSelected = item?.id === list?.id;

  return embargoed ? (
    <p className='position-relative explanation-container under-embargo-list-item'>
      <span className='explanation-tag tag-keyword-list'>
        <span className='icon-clock'></span>
        Embargoed {!!embargoedUntil ? `until ${formatEmbargoedDate(embargoedUntil)}` : ''}
      </span>
    </p>
  ) : (
    <>
      <p className='position-relative explanation-container'>
        <span
          className={`explanation-tag tag-keyword-list ${tagSelected ? 'tag-keyword-list-current-topic' : ''}`}
          onClick={(e) => {
            if (!calendarTags && !tagSelected) {
              preventLink(e);
              let queryParams = new URLSearchParams(location.search);
              queryParams.set('topic-id', id);
              let list = keywordsLists.find((item) => item.id === id);
              dispatch({
                type: 'MODIFY_SECTION',
                parameter: 'search',
                value: {
                  ...search,
                  list,
                },
              });
              navigate({ search: queryParams.toString() });
            }
          }}
          data-tooltip-content={`${calendarTags || tagSelected ? '' : 'Click to see all updates for '}${!tagSelected || calendarTags ? `${topics ? `${topics?.join(', ')}` : name}` : ''}`}
          data-tooltip-id={'tags-tooltip'}
        >
          <span className='icon-topics'></span>
          <span className='explanation-tag-list-name'>{teamName ?? name}</span>
        </span>
      </p>
    </>
  );
};

export default MatchingListItem;
