import { useContext, useState } from 'react';
import { createSectionName } from 'components/Dashboard/helpers/controlActiveFilters';
import { store } from 'components/Store';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';

const useFeedbackItemCalls = () => {
  const { generalApiCall } = useGeneralApiCall();

  const addFeedback = async ({ objectId, ratingReason, comments, modifyKeywordListsBasedOnFeedback = false }) => {
    const pathname = '/api/feed/add-feedback';
    const method = 'post';
    let requestProperties = {
      objectId,
      ratingScored: 'Negative',
      modifyKeywordListsBasedOnFeedback,
    };
    if (ratingReason) {
      requestProperties = {
        ...requestProperties,
        ratingReason,
      };
    }
    if (comments) {
      requestProperties = {
        ...requestProperties,
        comments,
      };
    }
    const results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: true,
      returnCompleteRequest: true,
      returnError: true,
    });
    return results;
  };

  const removeFeedback = async ({ objectId }) => {
    const pathname = `/api/feed/remove-feedback?objectId=${objectId}`;
    const method = 'delete';
    return await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      returnCompleteRequest: true,
    });
  };

  return { addFeedback, removeFeedback };
};

const ThumbsDown = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { contentResults, activeResults } = state;
  const { item, index, nameSection, show, contentPage, updateFeedback, embedFeed, thick, keywordsFromLists } = props;

  const isMobile = useIsMobile(420);
  const sectionName = nameSection ?? createSectionName();
  const section = contentResults[activeResults][sectionName];
  const { feedItemFlags } = section ?? {};
  const feedItem = feedItemFlags?.[index];
  const { explanations } = feedItem ?? {};
  const { ratingScored } = feedItem ?? {};
  const alreadyMarked = props?.alreadyMarked ?? ratingScored === 'Negative';

  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  const { addFeedback } = useFeedbackItemCalls();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useAuth0();
  const topicParam = getUrlParam('topic-id');
  const isTopicANumber = topicParam ? Number.isInteger(parseInt(topicParam)) : false;

  return (
    <>
      {isAuthenticated && (inboxSection || (show && isTopicANumber)) && !embedFeed && (
        <>
          <button
            className={`button-thumbs-down ${
              alreadyMarked ? 'button-thumbs-down-default' : ''
            } general-button circle-button flex-centered justify-content-center ml-1`}
            data-tooltip-content={!alreadyMarked ? `Mark as irrelevant` : ''}
            data-tooltip-id='general-tooltip'
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!alreadyMarked) {
                const { objectID, parentObjectID } = item;
                try {
                  setIsLoading(true);
                  await addFeedback({ objectId: parentObjectID ?? objectID });
                  let newSection = { ...section };
                  if (index !== undefined && index >= 0) {
                    newSection.feedItemFlags[index] = {
                      ...newSection.feedItemFlags?.[index],
                      ratingScored: 'Negative',
                    };
                  }
                  if (contentPage) {
                    if (updateFeedback) {
                      //AE: CASE FOR INFLUENCE ITEMS
                      updateFeedback({ feedback: 'Negative' });
                    } else {
                      if (feedItemFlags?.length > 0 && newSection?.hits?.length > 0 && item?.objectID) {
                        let newFeedItemFlags = feedItemFlags;
                        let newFeedItemIndex = newSection.hits.findIndex((hit) => hit.objectID === item.objectID);
                        if (newFeedItemIndex !== -1) {
                          newFeedItemFlags[newFeedItemIndex] = {
                            ...newFeedItemFlags[newFeedItemIndex],
                            ratingScored: 'Negative',
                          };
                          newSection.feedItemFlags = newFeedItemFlags;
                        }
                      }

                      let parameter = sectionName === 'LibraryMaterial' ? 'content' : 'new';
                      newSection[parameter] = {
                        ...newSection[parameter],
                        feedback: { ratingScored: 'Negative' },
                      };
                    }
                  }
                  let newContentResults = {
                    ...contentResults,
                    [activeResults]: {
                      [sectionName]: newSection,
                    },
                  };
                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'contentResults',
                    value: newContentResults,
                  });

                  const searchTermMatches = contentPage
                    ? keywordsFromLists
                    : explanations?.reduce((accumulator, currentValue) => {
                        let terms = currentValue?.searchTermMatches;
                        terms?.forEach((item) => {
                          if (!accumulator.includes(item?.searchTerm)) {
                            accumulator.push(item?.searchTerm);
                          }
                        });
                        return accumulator;
                      }, []);

                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'feedbackItemPopup',
                    value: {
                      isHandPicked: feedItem?.isHandPicked,
                      ratingScored: 'Negative',
                      isOpen: true,
                      item,
                      index,
                      keywords: searchTermMatches,
                      contentPage,
                      updateFeedback,
                      firstTime: true,
                    },
                  });
                  setIsLoading(false);
                } catch (error) {
                  setIsLoading(false);
                }
              }
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <i className='main-subtle-text fas fa-spinner fa-spin d-inline-block' />
            ) : (
              <span
                className={`paragraph-p1 d-inline-block icon-thumbs-down${alreadyMarked ? '-complete' : ''}`}
                style={
                  thick && !isMobile && !alreadyMarked ? { fontWeight: '700', color: 'rgba(33,37,41,0.6)' } : undefined
                }
              />
            )}
          </button>
        </>
      )}
    </>
  );
};

export { useFeedbackItemCalls };
export default ThumbsDown;
