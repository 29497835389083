import React, { useContext, useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { components } from 'react-select';
import Select from 'react-select';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { store } from 'components/Store';
import { colourStyles } from './SelectFilterUtils';
import { createTagFilterOption } from 'components/Dashboard/Filters/FiltersData/InfluenceContactsFilters';
import CustomHighlighter from 'components/Dashboard/utilities/CustomHighlighter';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      {props?.selectProps?.inputValue?.length === 0 && (
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
      )}
      {React.Children.map(children, (child, i) =>
        child && child.type !== Placeholder ? <div key={i}>{child}</div> : null
      )}
    </ValueContainer>
  );
};

const TagsFilter = ({ disabled, filter, selectOption, isExpanded, menuPosition = '' }) => {
  const { type, options } = filter;
  const [inputValue, setinputValue] = useState('');

  const globalState = useContext(store);
  const { state } = globalState;
  const { contactsTags } = state;

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        selectRef.current.focus();
      }, 100);
    }
  }, [isExpanded]);

  const selectRef = useRef(null);

  const checkIfOptionSelected = (value) => {
    return !!options.filter((option) => option.name === value && option.selected).length;
  };

  const handleChange = (_, action, x) => {
    if (action.option) {
      const lastSelected = {
        name: action.option.name,
        selected: true,
        filterValue: action.option.value,
      };
      const currentOptionIndex = options.findIndex((item) => item.name === action.option.name);
      let valueToState = [];
      if (currentOptionIndex !== -1) {
        options[currentOptionIndex] = lastSelected;
        valueToState = options;
      } else {
        valueToState = [lastSelected, ...options];
      }
      //remove previous value if exists
      if (checkIfOptionSelected(action.option.value)) {
        valueToState = valueToState.filter((option) => !(option.name === action.option.value));
      }
      valueToState = valueToState.filter((option) => !(option.name === action.option.value && !option.selected));
      selectOption({ filter, newValue: valueToState });
    }
  };

  const Option = (props) => {
    const input = props.selectProps.inputValue;
    const value = props.data.name;
    const { isSelected, isFocused } = props;

    return (
      <div
        className={`email-component-select-option  ${
          checkIfOptionSelected(value) ? 'email-component-select-option-selected ' : ''
        }`}
        style={{
          backgroundColor: isSelected ? '#ebf7fb' : isFocused ? 'rgba(0, 148, 204, 0.04)' : 'transparent',
        }}
      >
        <components.Option {...props}>
          <div className={`checkbox-item filter-item-${normalize(value)}`}>
            <input id={normalize(value)} onChange={() => {}} type={`checkbox`} checked={checkIfOptionSelected(value)} />
            <label htmlFor={normalize(value)}>
              <CustomHighlighter highlightClassName='highlight-text' searchWord={input} textToHighlight={value} />
            </label>
          </div>
        </components.Option>
      </div>
    );
  };

  const onInputChange = (inputValue, event) => {
    if (event.action === 'input-change') {
      setinputValue(inputValue);
    }
  };

  return (
    <div className={`box-rounded mb-4 tags-filter-container`}>
      <div className='filter-select-container'>
        <span className='main-search-icon' />
        <Select
          ref={selectRef}
          options={[...createTagFilterOption(contactsTags, type === 'TagIsNot')]}
          components={{
            Option,
            MultiValueContainer: () => null,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            ValueContainer: CustomValueContainer,
          }}
          isMulti
          openMenuOnFocus={true}
          noOptionsMessage={() => {
            return <>{'No tags found'}</>;
          }}
          styles={colourStyles}
          placeholder={'Search tags'}
          onChange={handleChange}
          isDisabled={disabled}
          openMenuOnClick={true}
          hideSelectedOptions={false}
          classNamePrefix={'react-custom-select-contacts'}
          onInputChange={onInputChange}
          inputValue={inputValue}
          closeMenuOnSelect={false}
          menuPosition={menuPosition}
        />
      </div>
    </div>
  );
};

export default TagsFilter;
