import { net_api_url, app_url } from 'components/Store.js';
import axios from 'axios';
const callBillingPortal = async (token, location, source, plan) => {
  try {
    let response = await axios.post(
      `${net_api_url}/api/payment/create-billing-portal-session`,
      {
        returnUrl: `${app_url}${location.pathname}${location.search}`,
        plan: plan,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source?.token,
      }
    );
    let responseData = response.data;
    window.open(responseData.url);
  } catch (error) {
    console.error(error);
  }
};
export default callBillingPortal;
