import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from 'components/Store';
import MentionsByTopic from './ui/MentionsByTopic';
import useCallMentionsByTopic from './useCallMentionsByTopic';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import normalize from 'components/Dashboard/utilities/normalizeString';
import useModifyAnalyticsState from './useModifyAnalyticsState';
import useUpdateTitleAnalytics from './useUpdateTitleAnalytics';
import dayjs from 'dayjs';
import axios from 'axios';
import { useChartDataOptionSelected } from './ui/MentionsByTopic/ChartDataTable';

const compileData = (data, analyticsState) => {
  let datasetsInit = JSON.parse(JSON.stringify(data.value));
  let datasets = [];
  Object.keys(datasetsInit).forEach((dataset) => {
    datasets.push(datasetsInit[dataset]);
  });
  let compiledData = datasets.flat().reduce((acc, item) => {
    let dateIndex = acc.findIndex((point) => point.date === item.date);
    if (dateIndex >= 0) {
      let { value } = acc[dateIndex];
      let newValue = item.value + value;
      acc[dateIndex].value = newValue;
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  let filteredCompiledData = compiledData.filter((item) => {
    if (analyticsState.activeDateInterval.name === 'Custom') {
      if (analyticsState.currentView !== 'Day') {
        return (
          new Date(item.date) >=
            new Date(
              dayjs(analyticsState.activeDateInterval.filter[0]).startOf(analyticsState.currentView.toLowerCase())
            ) &&
          new Date(item.date) <=
            new Date(dayjs(analyticsState.activeDateInterval.filter[1]).endOf(analyticsState.currentView.toLowerCase()))
        );
      } else {
        return (
          new Date(item.date) >= new Date(analyticsState.activeDateInterval.filter[0]) &&
          new Date(item.date) <= new Date(analyticsState.activeDateInterval.filter[1])
        );
      }
    } else {
      return true;
    }
  });

  return {
    raw: filteredCompiledData,
    value: filteredCompiledData.map((item) => item.value),
  };
};

const MentionsByTopicContainer = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { analyticsState, keywordsLists } = state;
  const { selectedCountries } = analyticsState;

  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');
  const noTopicParameter = topicParameter === 'none';

  const { customTopic, teamId, embedWidget } = props;

  const [isLoading, setIsLoading] = useState(!noTopicParameter);
  const [data, setData] = useState({ labels: [], value: [] });
  const [rawData, setRawData] = useState(null);

  const isLoadedFirstTime = useRef(false);
  let setSelectedCountries = (val) => {
    modifyState({ property: 'selectedCountries', newValue: val });
  };
  const { callMentionsByTopic, callGetGraphData } = useCallMentionsByTopic({ chartType: 'Mentions', teamId });
  const location = useLocation();

  const { modifyState } = useModifyAnalyticsState();
  const { updateTitleAnalytics } = useUpdateTitleAnalytics();
  const { chartOptionName } = useChartDataOptionSelected();

  const functionToUpdateData = useRef();
  functionToUpdateData.current = async (source) => {
    try {
      if (!noTopicParameter || searchParameter) {
        setIsLoading(true);
        let topic =
          customTopic ??
          (topicParameter
            ? keywordsListsToUse.find((item) => item.id === parseInt(customTopic?.id ?? topicParameter))
            : keywordsListsToUse[0]);
        let chartData = await callMentionsByTopic({
          chartDataOption: chartOptionName,
          topic,
          freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
          source,
        });
        if (chartData) {
          setData({ ...chartData.data, chartOptionName });
          if (isLoadedFirstTime.current) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'changeOfGraphFilters', value: true });
          }
          isLoadedFirstTime.current = true;
          if (chartData.rawData) {
            setRawData(chartData.rawData);
          }
          setIsLoading(false);
          let hash = `#~${normalize(chartOptionName)}`;
          updateTitleAnalytics(hash);
        }
      }
      isLoadedFirstTime.current = true;
    } catch (error) {}
  };

  const resetOnPathnameChange = useRef();
  resetOnPathnameChange.current = () => {
    isLoadedFirstTime.current = false;
  };

  useEffect(() => {
    resetOnPathnameChange.current();
  }, [location.pathname]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (location.hash !== '') {
      functionToUpdateData.current(source);
    }
    return () => {
      source.cancel('Mentions cancelled by the user');
    };
  }, [topicParameter, customTopic, searchParameter, location.hash, location.pathname]);

  return (
    <MentionsByTopic
      isLoading={isLoading}
      isLoadedFirstTime={isLoadedFirstTime}
      data={data}
      setData={setData}
      callGetGraphData={callGetGraphData}
      analyticsState={analyticsState}
      modifyState={modifyState}
      rawData={rawData}
      selectedCountries={selectedCountries}
      setSelectedCountries={setSelectedCountries}
      embedWidget={embedWidget}
    />
  );
};

export { compileData };
export default MentionsByTopicContainer;
