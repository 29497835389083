import axios from 'axios';
import ContentItemsPanel from './ui/ContentItemsPanel';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import useRightPanelIndividualCall from './useRightPanelIndividualCall';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const SentimentRightPanel = (props) => {
  const { contentState, analyticsState, setRightPanelState } = props;
  const { chartDataOptionSelected } = analyticsState;
  const { text, rawData, dataKey, date, sentiment, noResults } = contentState;

  const totalCell = dataKey === 'totalCell';
  const chartOptionName = chartDataOptionSelected;
  const contributionGraph = chartOptionName === 'Contribution type' || chartOptionName === 'Overall';

  const sections = ['Positive', 'Negative', 'Neutral', 'Mixed'];
  let resultsSections = ['Spoken parliamentary contributions', 'Written questions', 'EDMs', 'Committee contributions'];

  if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
    resultsSections = [...resultsSections, 'Tweets', 'Retweets'];
  }

  const createSectionValues = () => {
    const values = [];
    if (!noResults) {
      if (totalCell) {
        sections.forEach((section) => {
          let number = 0;
          Object.keys(rawData[section.toLowerCase()]).forEach((key) => {
            let point = rawData[section.toLowerCase()][key]?.find((point) => point.date === date)?.value;
            if (point) {
              number += point;
            }
          });
          values.push({ name: section, value: number });
        });
      } else {
        sections.forEach((section) => {
          let number = 0;
          let point = rawData[section.toLowerCase()]?.[dataKey]?.find((point) => point.date === date)?.value;
          if (point) {
            number += point;
          }
          values.push({ name: section, value: number });
        });
      }
    }
    return values.filter((item) => item.value > 0);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const searchParameter = getUrlParam('search');

  const sectionsValues = createSectionValues();
  const [activeSection, setActiveSection] = useState(sentiment ?? sectionsValues[0]?.name);
  const [contentActiveSection, setContentActiveSection] = useState();
  const [isLoading, setIsLoading] = useState(!noResults);
  const [results, setResults] = useState({ hits: [] });
  const sectionResults = useRef([]);

  const callContentItems = useRef();
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const isLoadedComponent = useRef(false);

  const { individualApiCall } = useRightPanelIndividualCall({ analyticsState, requestSource: source, contentState });

  callContentItems.current = async ({ sentiment = activeSection, source }) => {
    try {
      if (noResults) {
        setResults({ hits: [] });
        setIsLoading(false);
      } else {
        isLoadedComponent.current = false;
        sectionResults.current = [];
        setIsLoading(true);
        if (contributionGraph && !totalCell) {
          let results = await individualApiCall({
            type: dataKey,
            sentiment,
            freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
            source,
          });
          if (results) {
            setContentActiveSection(dataKey);
            setResults({ ...results, thereIsMoreContent: results.hits?.length === pageSize });
            setIsLoading(false);
          }
        } else {
          let values = [];
          for (let i = 0; i < resultsSections.length; i++) {
            let type = resultsSections[i];
            let results = await individualApiCall({
              type,
              sentiment,
              freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
              source,
            });
            if (results) {
              values.push({
                name: type,
                value: results?.totalHits,
                results: { ...results, thereIsMoreContent: results.hits?.length === pageSize },
              });
            }
          }
          let finalValues = values.filter((item) => item.value > 0);
          sectionResults.current = finalValues;
          setContentActiveSection(finalValues[0]?.name);
          setResults(finalValues[0]?.results);
          setIsLoading(false);
        }
      }
      isLoadedComponent.current = true;
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callContentItems.current({ source });
    return () => {
      source.cancel('Content items panel cancelled by the user');
    };
  }, [activeSection]);

  useEffect(() => {
    if (isLoadedComponent.current) {
      let valueToCompare = sentiment ?? sectionsValues[0]?.name;
      if (valueToCompare !== activeSection) {
        setActiveSection(valueToCompare);
      } else {
        callContentItems.current({});
      }
    }
  }, [contentState]);

  useEffect(() => {
    if (isLoadedComponent.current && !noResults) {
      let section = sectionResults.current.find((item) => item.name === contentActiveSection);
      if (section?.results) {
        setIsLoading(true);
        setResults(section?.results);
        setTimeout(() => setIsLoading(false), 300);
      }
    }
  }, [contentActiveSection]);

  const callResults = async ({ pageNumber }) => {
    try {
      let callResults = await individualApiCall({
        type: contentActiveSection,
        sentiment: activeSection,
        pageNumber,
        freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
      });
      if (callResults) {
        let hits = results.hits;
        let highlights = results.highlights;

        hits = hits.concat(callResults.hits);
        highlights = highlights.concat(callResults.highlights);

        setResults({ ...callResults, hits, highlights, thereIsMoreContent: callResults.hits?.length === pageSize });
      }
    } catch (error) {}
  };

  const load_more_results = async () => {
    let { thereIsMoreContent, pageNumber } = results;
    if (thereIsMoreContent) {
      await callResults({ pageNumber: pageNumber + 1 });
    }
  };
  const handleScroll = (scrollValues) => {
    const { scrollTop, clientHeight, scrollHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      load_more_results();
    }
  };

  return (
    <ContentItemsPanel
      handleScroll={handleScroll}
      setRightPanelState={setRightPanelState}
      text={text}
      sectionsValues={sectionsValues}
      isLoading={isLoading}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      results={results}
      sentimentPage={true}
      sentimentProperties={{ sectionResults: sectionResults.current, setContentActiveSection, contentActiveSection }}
    />
  );
};

export default SentimentRightPanel;
