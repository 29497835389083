import { useContext, useEffect, useState, useRef } from 'react';
import { store } from 'components/Store';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import normalize from 'components/Dashboard/utilities/normalizeString';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const DropdownTagFilter = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contactsTags } = state;

  const { filter, allowedUsers, selectOption } = props;
  const { options } = filter ?? {};

  const [tagOptions, setTagOptions] = useState(contactsTags);
  const [searchValue, setSearchValue] = useState('');
  const searchTimeout = useRef();
  const { newAgencyPlan } = useTopicOrClientWord();

  useEffect(() => {
    setTagOptions(contactsTags);
  }, [contactsTags]);

  const changeFilter = ({ item }) => {
    const { label, value } = item;
    let newValue = [...options];
    if (allowedUsers) {
      const index = newValue?.findIndex((filter) => filter?.name === label && filter?.filterValue === value);
      if (index >= 0) {
        newValue.splice(index, 1);
      } else {
        newValue.push({
          name: label,
          filterValue: value,
          selected: true,
        });
      }
      selectOption({ filter, newValue });
    }
  };

  const onBlurFunction = (e) => {
    const value = e.target?.value;
    if (value !== '') {
      const filteredOptions = contactsTags?.filter((tag) =>
        tag.label.toLowerCase()?.includes(value?.trim()?.toLowerCase())
      );
      setTagOptions(filteredOptions);
    } else {
      setTagOptions(contactsTags);
    }
  };

  const getHighlightedText = (text) => {
    if (!searchValue) return text;
    const regex = new RegExp(`(${searchValue})`, 'gi');
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <span className='font-weight-bold' key={index}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  return (
    <>
      {contactsTags?.length > 0 ? (
        <>
          <div className='px-3 pt-3 pb-2'>
            <div className='rounded border-general border-grey flex-centered py-1 specific-x-padding-12 '>
              <span className='icon-search main-light-text mr-2' />
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target?.value)}
                className='input-empty'
                placeholder='Search tags'
                onKeyUp={(e) => {
                  if (searchTimeout.current) clearTimeout(searchTimeout.current);
                  searchTimeout.current = setTimeout(() => onBlurFunction(e), 300);
                }}
              />
            </div>
          </div>
          <CustomScrollbar
            className={`simple-scrollbar`}
            maximalThumbYSize={100}
            autoHeight
            autoHeightMin='100%'
            autoHeightMax={`225px`}
          >
            <div className='px-3'>
              {tagOptions?.map((item, index) => {
                const { label, value } = item;
                const selectedIndex = options?.findIndex(
                  (filter) => filter?.name === label && filter?.filterValue === value
                );
                return (
                  <div key={`filter-item-${normalize(label)}`} className={`filter-item-container filter-item-checkbox`}>
                    <div className={`checkbox-item  `}>
                      <input
                        id={`${normalize(label)}`}
                        onChange={() => {
                          changeFilter({ item });
                        }}
                        type={`checkbox`}
                        checked={selectedIndex >= 0}
                      />
                      <label htmlFor={`${normalize(label)}`}>{getHighlightedText(label)}</label>
                    </div>
                  </div>
                );
              })}
              {tagOptions?.length === 0 && searchValue !== '' && (
                <p className='pt-2 pb-3 text-center main-light-text mb-0'>No tags found</p>
              )}
            </div>
          </CustomScrollbar>
        </>
      ) : (
        <p className='py-3 px-4 mb-0 main-light-text'>
          You haven't added tags to any stakeholders{newAgencyPlan ? ' for this client' : ''}.
        </p>
      )}
    </>
  );
};

export default DropdownTagFilter;
