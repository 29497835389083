import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
const GeneralTooltips = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <ReactTooltip
        effect={'solid'}
        id={'tags-tooltip'}
        className={'tags-tooltip'}
        backgroundColor={'#00122B'}
        textColor={'#fff'}
        arrowColor={'transparent'}
        place={'top'}
        offset={5}
        delayShow={300}
        globalEventOff={isMobile ? 'click' : undefined}
      />
      <ReactTooltip
        effect={'solid'}
        id={'general-tooltip'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
        globalEventOff={isMobile ? 'click' : undefined}
      />
      <ReactTooltip
        effect={'solid'}
        id={'tooltip-mobile'}
        className={'header-tooltip'}
        scrollHide={true}
        arrowColor={'transparent'}
        place={'top'}
      />
      <ReactTooltip
        effect={'solid'}
        id={'clickeable-tooltip'}
        className={'contributions-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
        clickable={true}
        globalEventOff={'click'}
      />
    </>
  );
};

export default GeneralTooltips;
