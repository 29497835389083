import ModalComponent from 'components/Common/Modal';
import { useNavigate } from 'react-router-dom';

const ContentPopup = (props) => {
  const { registerUserFunction } = props;
  const navigate = useNavigate();
  return (
    <div className='py-4'>
      <div className='section-title pb-4 pt-2 px-lg-5 px-4'>
        <h3 className={`topic-title mb-0`}>Your team already has an account</h3>
      </div>
      <div className='px-lg-5 px-4'>
        <p className='mt-3'>
          It looks like your team already has a PolicyMogul account. Your account Owner or Admins can invite you from
          within PolicyMogul. Are you sure you want to create a new account?
        </p>
        <div className='justify-content-end d-flex py-3'>
          <button
            className='general-button action-button px-4 py-2'
            onClick={() => {
              navigate('/');
            }}
          >
            Cancel
          </button>
          <button
            className='general-button simple-link-button  ml-4'
            onClick={(e) => {
              registerUserFunction(e);
            }}
          >
            Continue anyway
          </button>
        </div>
      </div>
    </div>
  );
};

const ParliamentarianHasTeamPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent maxWidth={650} isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default ParliamentarianHasTeamPopup;
