import React, { useContext, useEffect, useRef, useState } from 'react';
import DashboardSidebar from './SidebarElements';
import SkeletonKeyUpdates from './KeyUpdates/SkeletonKeyUpdates';
import { useMainSectionParameter } from './helpers/useMainSectionParameter';
import StakeHolders from './StakeHolders';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from 'components/Store';
import { createFiltersProperties, sectionName } from './helpers/controlActiveFilters';
import useCompileFiltersFunction from './Filters/useCompileFilters';
import FiltersData from './Filters/FiltersData';
import useDetectActiveFilters from './SubNav/useDetectActiveFilters';
import useIsMobile from './utilities/useIsMobile';
import ListControl from './ListControl';
import { Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom';
import OutsideFilters from './Filters/ui/OutsideFilters';
import ConfigData from 'config/ConfigData';

const ListRoutes = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeResults, search, activePlan } = state;
  const { searchState, list } = search;
  const params = useParams();
  const { searchResultsSection } = useMainSectionParameter();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { detectActiveFilters } = useDetectActiveFilters();
  const { isAuthenticated } = useAuth0();

  const changesDashboard = useRef();
  changesDashboard.current = () => {
    setIsLoading(true);
    let activePlanItems = Object.keys(activePlan);
    if ((activePlanItems.length > 0 && searchState) || !isAuthenticated) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    changesDashboard.current();
  }, [activeResults, activePlan, searchState, isAuthenticated]);

  const filterProperties = createFiltersProperties();
  const { inboxSection } = useMainSectionParameter();
  const { activeResultsConditional } = useCompileFiltersFunction();

  const createFiltersCondition = () => {
    const filters = FiltersData().filter(
      (filter) =>
        filter.pages.includes(sectionName()) &&
        activeResultsConditional(filter, FiltersData()) &&
        !filter.hidden &&
        !filter.excludeFromPopup
    );
    if (inboxSection || (searchResultsSection && location.pathname.includes('upcoming-events'))) {
      return detectActiveFilters() ? true : list !== 'noListsCreated' && filters.length > 0;
    } else {
      return filters.length > 0 || searchResultsSection;
    }
  };

  const referenceFiltersConditional = createFiltersCondition();
  const isMobile = useIsMobile();

  return (
    <div className={`row mx-auto main-content-wrapper`}>
      <div className={`side-left-element d-none d-lg-block`}>
        <DashboardSidebar />
      </div>
      <div className={`main-content pr-xl-5 px-md-4 px-3`}>
        {isLoading && (
          <>
            <SkeletonKeyUpdates />
          </>
        )}
        {!isLoading && (
          <div className='content-lists-container'>
            <div className='row mx-0'>
              <div
                className={`flex-grow-1 ${referenceFiltersConditional ? 'reference-main-content' : ''}`}
                style={{ flex: 1 }}
              >
                <Routes>
                  <Route path='stakeholders' element={<StakeHolders />} />
                  <Route
                    path={`tweets`}
                    element={
                      process.env.REACT_APP_ENABLE_TWITTER === 'false' ? (
                        <Navigate
                          to={`/${params['mainSection']}/${ConfigData?.monitorInboxInitialPage ?? 'key-updates'}`}
                        />
                      ) : (
                        <ListControl />
                      )
                    }
                  />
                  <Route path=':page/*' element={<ListControl />} />
                </Routes>
              </div>
              {referenceFiltersConditional && !isMobile && (
                <div className='ml-4 outside-filters-container'>
                  <OutsideFilters section={filterProperties?.section} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListRoutes;
