import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useContext } from 'react';
import { store } from 'components/Store';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';

const useCallRelatedStakeholders = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, net_api_url } = state;
  const { list } = search;
  const numberOfStakeHoldersToShow = parseInt(process.env.REACT_APP_RELATED_STAKEHOLDERS_TO_SHOW);

  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  let callRelatedStakeholders = async ({ stakeholderFilters, pageSize, source }) => {
    let token = isAuthenticated ? await getAccessToken() : '';
    let url = `${net_api_url}/api/search/query`;
    let requestBody = {
      query: '',
      type: 'stakeholder',
      pageSize: pageSize ?? numberOfStakeHoldersToShow,
      filters: stakeholderFilters,
      pageNumber: 1,
    };

    let requestOptions = {
      cancelToken: source.token,
    };

    if (isAuthenticated) {
      requestOptions.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    if (list === null) {
      requestBody.query = '';
    }

    return await axios.post(url, requestBody, requestOptions);
  };

  return { callRelatedStakeholders };
};

export default useCallRelatedStakeholders;
