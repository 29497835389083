import React, { useContext } from 'react';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { formatGeneralDate } from 'components/Dashboard/helpers/formatGeneralDate';
import { partyColours } from 'components/Dashboard/Analytics/useCallMostRelevantStakeholders';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { MentionsChartLabels } from './htmlLegendPlugin';
import { useLocation } from 'react-router-dom';
import pattern from 'patternomaly';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import dayjs from 'dayjs';
import { createDate, useAllowRightPanel } from './ChartDataTable';
import { store } from 'components/Store';
import getAppSite from 'utils/getAppSite';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  zoomPlugin,
  Filler,
  Legend
);

ChartJS.defaults.font.family = 'Lato';

const colours = {
  'Contribution type': {
    retweets: '0, 28, 53',
    tweets: '0, 148, 204',
    'spoken-parliamentary-contributions': '242, 168, 5',
    'written-questions': '163, 228, 86',
    edms: '196, 44, 61',
  },
  Region: {
    'west-midlands': '129, 175, 63',
    london: '0, 0, 128',
    'south-east': '196, 44, 61',
    'yorkshire-&-the-humber': '',
    'east-midlands': '153, 204, 255',
    'north-west': '153, 204, 0',
    'south-west': '63, 132, 40',
    east: '238, 197, 67',
    scotland: '77, 77, 77',
    'north-east': '93, 156, 222',
    wales: '153, 204, 255',
    'northern-ireland': '0, 28, 53',
  },
  'Parliamentary position': {
    backbenchers: '196, 44, 61',
    'shadow-government': '0, 28, 53',
    government: '0, 148, 204',
    cabinet: '163, 228, 86',
    'parliamentary-private-secretary': '77, 77, 77',
    'shadow-cabinet': '238, 197, 67',
    mss: '93, 156, 222',
    msps: '63, 132, 40',
  },
  Party: partyColours,
};

const MentionsChart = React.forwardRef((props, ref) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsRateLimitReached } = state;

  const {
    data,
    analyticsState,
    setActiveSection,
    activeSection,
    compiledData,
    scrollRefs,
    setDisableSelectSection,
    emptyMessage,
    customGraph,
    selectedCategories,
    setSelectedCategories,
    setRightPanelState,
    rawData,
    chartDataKeys,
    labelDates,
    openDropdownState,
    scrollBarPosition,
    totalHeight,
    embedWidget,
  } = props;
  const { chartDataSection, scrollBarContainer } = scrollRefs ?? {};
  const { currentView, typeOfChart, chartDataOptionSelected, activeDateInterval } = analyticsState;
  const location = useLocation();
  const createNumberOfTicks = () => {
    if (isMobile) {
      return currentView === 'Month' ? 4 : 3;
    } else {
      return 10;
    }
  };

  const stakeholderContributions = location.pathname.includes('/analytics/mentions');
  //AE: This option now comes from the value to avoid the jump effect when updating the analytics state which creates a re-rendering of the component.
  const chartOptionName = props.chartOptionName ?? chartDataOptionSelected;
  const { delayShowPanelTitle } = useAllowRightPanel();

  const labels = data.labels;
  const isMobile = useIsMobile();
  let options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: typeOfChart === 'Line' ? 'nearest' : 'point',
        backgroundColor: 'rgb(244, 239, 237)',
        borderColor: 'rgba(0, 18, 43, 0.3)',
        borderWidth: 1,
        titleColor: 'rgb(0, 18, 43)',
        bodyColor: 'rgb(0, 18, 43)',
        bodyFont: { weight: 'bold' },
        padding: 12,
        titleFont: { size: 14 },
        displayColors: false,
        footerColor: 'rgb(0, 18, 43)',
        footerMarginTop: 2,
        callbacks: {
          title: function (context) {
            let label = context?.[0]?.label || '';
            return formatGeneralDate(label, false, currentView === 'Month');
          },
          label: function (context) {
            return parseInt(context.formattedValue) > 0 && context.dataset.label !== 'Total contributions'
              ? `${context.dataset.label} : ${thousandCommas(context.formattedValue)}`
              : '';
          },
          footer: function (context) {
            return `${`Total ${stakeholderContributions ? 'mentions' : ''}: ${compiledData[context[0].dataIndex] > 0 ? thousandCommas(compiledData[context[0].dataIndex]) : 0}`}`;
          },
          labelTextColor: function (context) {
            const { dataset } = context;
            const { label, backgroundColor } = dataset;
            const changeDataColours = () => {
              switch (label) {
                case 'SNP':
                  return 'rgb(238, 197, 67)';
                default:
                  return typeof backgroundColor === 'string' ? backgroundColor.replace('0.1', '1') : backgroundColor[0];
              }
            };
            return changeDataColours();
          },
        },
        compiledData: compiledData,
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          maxTicksLimit: createNumberOfTicks(),
          callback: function (value) {
            let labelOfTick = this.getLabelForValue(value);
            return formatGeneralDate(labelOfTick, false, currentView === 'Month');
          },
        },
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
      y: {
        grace: '1%',
        beginAtZero: true,
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    onHover: (e) => {
      if (!isMobile) {
        let points = ref.current.getElementsAtEventForMode(e, 'nearest', { intersect: false }, true);
        if (points.length) {
          const firstPoint = points[0];
          if (setActiveSection) {
            setActiveSection(firstPoint.index);
          }
        }
      }
    },
    onClick: (e) => {
      let points = ref.current.getElementsAtEventForMode(e, 'nearest', { intersect: false }, true);
      if (scrollBarContainer?.current && chartDataSection?.current && !embedWidget) {
        if (points.length) {
          const firstPoint = points[0];
          setActiveSection(firstPoint.index);
          if (setDisableSelectSection) {
            setDisableSelectSection(true);
          }
          const value = firstPoint?.element?.$context?.raw;
          const position = firstPoint?.element?.$context?.dataIndex;

          if (value > 0 && !!setRightPanelState && chartOptionName === 'Overall') {
            setRightPanelState({
              open: true,
              date: labelDates[position],
              rawData,
              text: `${thousandCommas(value)} ${`mention${value === 1 ? '' : 's'}`} by ${getAppSite() === 'usa' ? 'policymakers' : 'parliamentarians'} ${createDate(
                currentView,
                labelDates[position]
              )}`,
              dataKey: 'totalCell',
              chartDataKeys,
              noResults: value === 0,
            });
            delayShowPanelTitle();
          } else {
            scrollBarContainer.current.scrollTop(chartDataSection.current.offsetTop);
          }
        }
        setTimeout(() => {
          if (setDisableSelectSection) {
            setDisableSelectSection(false);
          }
        }, 1000);
      }
    },
  };

  if (typeOfChart === 'Bar') {
    options = {
      ...options,
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          stacked: true,
        },
        y: {
          ...options.scales.y,
          stacked: true,
        },
      },
    };
  }

  const borderWidth = () => {
    if (typeOfChart === 'Bar') {
      return 0;
    } else {
      return currentView === 'Day' ? 2 : 3;
    }
  };

  let datasets = [];

  const createBackgroundColor = (color) => {
    if (typeOfChart === 'Line') {
      return `rgb(${color}, 0.1)`;
    } else {
      return labels.map((item) => {
        const isDiagonalBackground = (label) => {
          if (activeDateInterval.name === 'Custom') {
            return (
              new Date(dayjs.tz(label, 'Europe/London')).getTime() <
                new Date(dayjs(activeDateInterval.filter[0])).getTime() ||
              new Date(label).getTime() >=
                new Date(dayjs(activeDateInterval.filter[1]).startOf(currentView.toLowerCase())).getTime()
            );
          } else {
            return new Date(label).getTime() >= new Date(dayjs().startOf(currentView.toLowerCase())).getTime();
          }
        };
        return isDiagonalBackground(item)
          ? pattern.draw('diagonal-right-left', `rgb(${color})`, 'rgba(255,255,255,0.6)', 6)
          : `rgb(${color})`;
      });
    }
  };

  const dash = (ctx) => (ctx.p0DataIndex > labels.length - 3 ? [6, 6] : [borderWidth(), 0]);
  Object.keys(data.value).forEach((item) => {
    let value = data.value[item];
    let color = colours[chartOptionName]?.[normalize(item)] ?? '0, 28, 53';
    let dataset = {
      label: item,
      data: Array.isArray(value) ? value?.map((item) => item?.value) : value,
      borderWidth: borderWidth(),
      pointRadius: (ctx) => {
        return currentView === 'Day' ? (ctx.raw === 0 ? 0 : 2) : 3;
      },
      pointHoverBorderWidth: (ctx) => {
        return currentView === 'Day' ? (ctx.raw === 0 ? 0 : 2) : 3;
      },
      pointBackgroundColor: `rgb(${color})`,
      pointHoverBackgroundColor: `rgb(255,255,255)`,
      borderColor: `rgb(${color})`,
      fill: true,
      hoverBackgroundColor: createBackgroundColor(color),
      backgroundColor: createBackgroundColor(color),
      hidden: !!selectedCategories ? !selectedCategories.includes(item) : false,
    };
    if (typeOfChart === 'Line') {
      dataset = {
        ...dataset,
        segment: {
          borderDash: (ctx) => dash(ctx) || [borderWidth(), 0],
        },
      };
    }
    datasets.push(dataset);
  });

  let dataContent = {
    labels,
    datasets,
  };

  const overallOption = chartOptionName === 'Overall';

  return (
    <>
      {!emptyMessage && !!ref.current && !overallOption && (
        <div
          id={`legend-container${customGraph ? '-custom' : ''}`}
          data-html2canvas-ignore='true'
          className={`d-flex mb-${customGraph ? '4' : '5'} flex-wrap`}
        >
          <MentionsChartLabels
            data={datasets}
            key={`legend-container${customGraph ? '-custom' : ''}-${!!ref.current.legend ? '-with-legend' : ''}`}
            chart={ref.current}
            chartDataOptionSelected={chartOptionName}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            typeOfChart={typeOfChart}
            customGraph={customGraph}
            scrollBarPosition={scrollBarPosition}
            onClickFunction={() => {
              if (setActiveSection) {
                setActiveSection(activeSection - 1);
              }
            }}
            openDropdownState={openDropdownState}
          />
        </div>
      )}
      <div
        style={{ height: totalHeight ? '100%' : overallOption ? '320px' : '260px' }}
        className={`${emptyMessage ? 'flex-centered justify-content-center' : ''}`}
      >
        {emptyMessage ? (
          <p className='title-h4 px-4 px-lg-0 text-center main-light-text my-0'>
            {analyticsRateLimitReached ? 'Try again soon or sign in for unlimited searches' : 'No data available'}
          </p>
        ) : (
          <>
            {typeOfChart === 'Line' && <Line ref={ref} options={options} data={dataContent} />}
            {typeOfChart === 'Bar' && <Bar ref={ref} options={options} data={dataContent} />}
          </>
        )}
      </div>
    </>
  );
});

export default MentionsChart;
