import React, { useContext } from 'react';
import { store } from 'components/Store.js';
import { Link, useLocation } from 'react-router-dom';
import TagsLists from 'components/Dashboard/TagsList';
import getSearchWithinHash from 'components/Dashboard/utilities/getSearchWithinHash.js';
import { contentItemSearchQuery } from 'components/Dashboard/helpers/locationSearchDescriptor.js';
import Share from 'components/Dashboard/components/Share.js';
import useRememberPosition from 'components/Dashboard/hooks/useRememberPosition.js';
import changeLabelOfContent from 'components/Dashboard/ParliamentaryRecord/ChangeLabelOfContent.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import { useParams } from 'react-router-dom';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter.js';
import Sentiment from 'components/Dashboard/components/Sentiment.js';
import ThumbsDown from 'components/Common/ThumbsDown.js';
import FeedbackOnItem from 'components/Dashboard/FeedbackItem/FeedbackOnItem.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import normalize from 'components/Dashboard/utilities/normalizeString.js';
const dayjs = require('dayjs');

const CommitteePublicationItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const location = useLocation();

  const { contentResults, activeResults, search, referenceState, lastSeenMarkers, itemsViewed } = state;
  const { activeReference } = referenceState;
  const { list, activeSearch } = search;
  const { CommitteePublications } = contentResults[activeResults];
  const { currentId } = CommitteePublications ?? {};

  const {
    index,
    item,
    scrollBarRef,
    itemOnclick,
    embedShowTags,
    explanations,
    feedItemFlags: propsFeedItemFlags,
    array,
    numberOfContributions,
    itemFromWidget,
    notShowShare,
    collapsedHits,
    showHighlights,
    useContentRelatedForFeedback,
    nameSection,
    listView,
  } = props;
  const feedItemFlags = propsFeedItemFlags ?? CommitteePublications?.feedItemFlags ?? [];
  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  const { agencyUser } = useTopicOrClientWord();

  const itemClass = () => {
    switch (item.house) {
      case 'commons':
        return 'legislation-commons';
      case 'lords':
        return 'legislation-lords';
      default:
        return 'legislation-grey';
    }
  };

  const locationDescriptor = (path) => {
    let locationSearch = contentItemSearchQuery(location, list);
    if (getUrlParam('topic-id') === 'all' && inboxSection) {
      locationSearch = `?topic-id=${feedItemFlags?.[index]?.matchingKeywordLists[0]?.id}`;
    }
    return {
      pathname: path,
      search: `${locationSearch}`,
      hash: getSearchWithinHash() ? getSearchWithinHash(true) : '',
    };
  };

  const createMarkup = (body) => {
    return { __html: body };
  };

  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    list !== null &&
    feedItemFlags?.length > 0 &&
    !!lastSeenMarkers['committeePublicationFeedItemId'] &&
    lastSeenMarkers['committeePublicationFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition &&
    listView;
  const alreadyMarkedFeedback = feedItem?.ratingScored === 'Negative';

  const { rememberPosition } = useRememberPosition();

  const urlParameterCondition =
    getUrlParam('search') ||
    getUrlParam('or') ||
    (getUrlParam('topic-id') && getUrlParam('topic-id') !== 'none') ||
    location.pathname.includes('/stakeholders') ||
    location.pathname.startsWith('/embed') ||
    location.pathname.startsWith('/search-results') ||
    showHighlights;

  const highlightConditional = urlParameterCondition || item.contentType !== 'ParliamentaryContribution';
  const stakeholderPage = location.pathname.includes('/stakeholders');
  const searchStakeholderCondition =
    !stakeholderPage ||
    (stakeholderPage && (getUrlParam('search') || (getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all')));
  const finalNumberOfContributions = () => {
    return numberOfContributions;
  };
  const inboxMessage = () => {
    let numberOfTopics = feedItemFlags?.[index]?.matchingKeywordLists?.length;
    return inboxSection && list?.id === null
      ? `${agencyUser ? 'for' : 'from'} ${numberOfTopics} ${agencyUser ? 'client' : 'topic'}${numberOfTopics === 1 ? '' : 's'}`
      : '';
  };

  const createItemNumber = ({ sentiment, hits }) => {
    if (sentiment && hits) {
      let items = hits.filter((item) => item?.hit?.sentiment === sentiment);
      let number = items?.length;
      if (number > 0) {
        return `<p class="mt-2 mb-0 paragraph-p3"><span class="d-inline-block mr-2 sentiment-dot sentiment-dot-${sentiment.toLowerCase()}"></span>${number} ${sentiment.toLowerCase()} contribution${number === 1 ? '' : 's'}</p>`;
      }
      return '';
    }
    return '';
  };

  const compileCollapseHits = ({ hits }) => {
    if (hits) {
      return `<div class="pt-1 px-3 pb-3">${createItemNumber({ sentiment: 'Positive', hits })}${createItemNumber({ sentiment: 'Negative', hits })}${createItemNumber({ sentiment: 'Mixed', hits })}${createItemNumber({ sentiment: 'Neutral', hits })}</div>`;
    }
  };

  const contributionContentTypes = [
    'ScotlandCountrySpecificParliamentaryContribution',
    'ParliamentaryContribution',
    'WalesCountrySpecificParliamentaryContribution',
    'SeneddCommitteeTranscriptContribution',
  ];

  return (
    <>
      <Link
        to={locationDescriptor(`/committee-publication/${item.id}/${normalize(item.title)}`)}
        className={`content-item-link`}
        style={{ zIndex: array ? array.length - index : 1 }}
        onClick={(e) => {
          rememberPosition('CommitteePublications', scrollBarRef, index);
          if (itemOnclick) {
            itemOnclick(e);
          }
        }}
      >
        <li
          className={`my-2 ${currentId === item.id ? 'active' : ''} ${unseenConditional ? 'unseen-item' : ''} adjustment-unseen-dot-parliamentary`}
        >
          <div className='content-item'>
            {unseenConditional && (
              <>
                <span className='unseen-dot' />
              </>
            )}
            <div className='p-3 p-sm-4 parliamentary-item-padding'>
              <div className='d-flex align-items-top'>
                <div className='flex-grow-1 pr-1 pr-sm-0'>
                  <h4 className={`mb-2 content-item-title mb-1`}>{item.title}</h4>
                  <div className={`w-100`}>
                    <p className={`heading-date mb-0 ${highlightConditional ? '' : 'information-date'}`}>
                      {(item.house || item.contentType) && (
                        <>
                          <span
                            className={`${itemClass()}`}
                          >{`${item.house && item.house !== 'n/a' ? item.house.charAt(0).toUpperCase() : changeLabelOfContent(item.contentType)}${item.house && item.house !== 'n/a' ? item.house.slice(1) : ''}`}</span>
                          <span className='dot'></span>
                        </>
                      )}
                      {item.committee ? (
                        <>
                          {item.committee}
                          <span className='dot'></span>
                        </>
                      ) : (
                        ''
                      )}
                      {item.publicationType ? (
                        <>
                          {item.publicationType}
                          <span className='dot'></span>
                        </>
                      ) : (
                        ''
                      )}
                      <span className='date'>{dayjs(item.dateTime).format('DD MMMM YYYY')}</span>
                    </p>
                  </div>
                </div>
                {!notShowShare && (
                  <div className='flex-centered'>
                    <Sentiment item={item} />
                    <ThumbsDown
                      item={item}
                      index={index}
                      useContentRelatedForFeedback={useContentRelatedForFeedback}
                      nameSection={nameSection}
                    />
                    <Share item={item} listItem />
                  </div>
                )}
              </div>

              {highlightConditional && (
                <div className='row mx-0'>
                  <div className='legislation-title w-100 px-0'>
                    <p className='title-body mt-2 mb-0'>
                      {!!item.contributorName && (
                        <>
                          <strong>{item.contributorName}: </strong>
                        </>
                      )}
                      <span
                        dangerouslySetInnerHTML={createMarkup(
                          item.highlights ? item.highlights.highlightedBodySnippets[0] : item.body
                        )}
                      />
                    </p>
                    {((list !== null && activeSearch && !itemFromWidget) || embedShowTags) && (
                      <TagsLists
                        feedItemFlags={feedItemFlags}
                        explanations={explanations ?? CommitteePublications?.explanations}
                        index={index}
                        title={'Parliamentary record'}
                        section={'CommitteePublications'}
                      />
                    )}
                  </div>
                </div>
              )}
              {!!numberOfContributions &&
                contributionContentTypes?.indexOf(item?.contentType) >= 0 &&
                (activeReference || activeSearch || location.pathname.includes('/stakeholders/')) && (
                  <div className='pt-2'>
                    <p
                      className='mb-0 text-light-blue paragraph-p3 d-inline-block'
                      data-tooltip-html={collapsedHits ? compileCollapseHits({ hits: collapsedHits }) : ''}
                      data-tooltip-id={'clickeable-tooltip'}
                      data-tooltip-class-name={'contributions-tooltip sentiment-dot-tooltip'}
                    >
                      {finalNumberOfContributions()} {searchStakeholderCondition ? 'relevant' : ''} {'contribution'}
                      {finalNumberOfContributions() === 1 ? '' : 's'} {inboxMessage()}
                    </p>
                  </div>
                )}
            </div>
            {alreadyMarkedFeedback && <FeedbackOnItem item={item} index={index} />}
          </div>
        </li>
      </Link>
    </>
  );
};

export default CommitteePublicationItem;
