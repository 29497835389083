import useGeneralApiCall from './useGeneralApiCall';

const useCallTeamsContent = () => {
  const { generalApiCall } = useGeneralApiCall();
  const callTeamsContent = async (props) => {
    const { source, type, abbreviated, publishedOnly } = props;
    try {
      let method = 'get';
      let pathname = `/api/user-generated-content/get-team-user-generated-content${
        type && type !== 'anyType' ? `?contentType=${type}` : ''
      }${type && abbreviated ? `&abbreviated=true` : ''}${
        !type && abbreviated ? `?abbreviated=true` : ''
      }${abbreviated && publishedOnly ? `&publishedOnly=true` : ''}`;
      let responseUserContent = await generalApiCall({
        method,
        pathname,
        requestSource: source,
        needsAuthentication: true,
        notShowErrorMessage: true,
      });

      if (!!responseUserContent) {
        let results = { ...responseUserContent };
        const { myUserGeneratedContent, teamMemberUserGeneratedContent } = results;
        let totalItems = myUserGeneratedContent.concat(teamMemberUserGeneratedContent);
        //https://stackoverflow.com/questions/10123953/how-to-sort-an-object-array-by-date-property
        totalItems = totalItems.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return totalItems;
      }
    } catch (error) {}
  };

  return { callTeamsContent };
};
export default useCallTeamsContent;
