import React from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const SkeletonInfluenceContent = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div ref={containerRef}>
        <CustomScrollbar
          className={'simple-scrollbar'}
          maximalThumbYSize={100}
          style={{ height: `${heightContainer}px` }}
        >
          <div className='bg-white h-100' style={{ minHeight: `${heightContainer}px` }}>
            <div className='bg-grey mb-5 border-bottom'>
              <div className='influence-content-page main-content-wrapper px-3'>
                <div className='d-flex justify-content-between py-5 align-items-start'>
                  <div className='flex-grow-1'>
                    <Skeleton height={28} width={`60%`} />
                  </div>

                  <div className='d-flex'>
                    <div className='mr-3'>
                      <Skeleton height={28} width={`140px`} />
                    </div>
                    <Skeleton height={28} width={`140px`} />
                  </div>
                </div>

                <div className='d-lg-flex'>
                  <div className='mr-5'>
                    <Skeleton height={28} width={`130px`} />
                  </div>
                  <div className='mr-5'>
                    <Skeleton height={28} width={`130px`} />
                  </div>
                  <div className='mr-5'>
                    <Skeleton height={28} width={`130px`} />
                  </div>
                </div>
              </div>
            </div>

            <div className='influence-content-page main-content-wrapper px-3'>
              <div className='d-flex flex-column-reverse flex-lg-row mx-0 justify-content-between'>
                <div className='col-lg-10 pr-lg-5 pl-0 pb-5 pb-lg-0'>
                  {Array(5)
                    .fill()
                    .map((item, index) => (
                      <div className='mb-5' key={index}>
                        <div className='mb-2'>
                          <Skeleton height={22} width={`70%`} />
                        </div>
                        <div className='mb-2'>
                          <Skeleton height={19} width={`100%`} />
                        </div>
                        <div className='mb-2'>
                          <Skeleton height={19} width={`100%`} />
                        </div>
                        <div className='mb-2'>
                          <Skeleton height={19} width={`100%`} />
                        </div>
                      </div>
                    ))}
                </div>
                <div className='col-lg-4 p-0'>
                  <div className='details-information rounded border mb-4 mb-lg-0'>
                    <div className='py-2 px-3 border-bottom'>
                      <Skeleton height={24} width={`50%`} />
                    </div>
                    <div className='p-3'>
                      <div className='mb-3'>
                        <Skeleton height={22} width={`80%`} />
                      </div>
                      <div className='mb-3'>
                        <Skeleton height={20} width={`90%`} />
                      </div>
                      <div className='mb-3'>
                        <Skeleton height={20} width={`70%`} />
                      </div>
                      <div className='mb-3'>
                        <Skeleton height={20} width={`100%`} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomScrollbar>
      </div>
      {/* <div className="pt-5">
        <div className="box-rounded"                
          ref={containerRef}
          style={{
            minHeight: `${heightContainer}px`
          }}
        >
          <div className="influence-creation_section py-4">
            <div className="row mx-0 justify-content-between align-items-center">
              <div>
                <Skeleton height={24} width={`80%`} />
                <div className="d-lg-flex py-3 mt-2">
                  <div className="mr-5">
                    <Skeleton height={19} width={`100px`} />
                  </div>
                  <div className="mr-5">
                    <Skeleton height={19} width={`100px`} />
                  </div>
                  <div className="mr-5">
                    <Skeleton height={19} width={`100px`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 tabs-component">
            <button className={`general-button flex-grow-1 text-center`}
              style={{height: '40px'}}
            >
            </button>
          </div>
          <div className="influence-creation_section pt-5">
            <div className="overview-items">
            {Array(5)
              .fill()
              .map((item, index) => (
                <div className="mb-5" key={index}>
                  <div className="mb-2">
                    <Skeleton height={22} width={`70%`} />
                  </div>
                  <div className="mb-2">
                    <Skeleton height={19} width={`100%`} />
                  </div>
                  <div className="mb-2">
                    <Skeleton height={19} width={`100%`} />
                  </div>
                  <div className="mb-2">
                    <Skeleton height={19} width={`100%`} />
                  </div>
                </div>
              ))}
            </div>  
          </div> 
        </div>
      </div> */}
    </SkeletonTheme>
  );
};

export default SkeletonInfluenceContent;
