import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { net_api_url } from 'components/Store';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';

const useGetEmailStats = () => {
  const [stats, setStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessToken } = useGetAccessToken();
  const { isAuthenticated } = useAuth0();

  const params = useParams();
  let { crmEmailId } = params;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const crmEmailIdQuery = query.get('crmEmailId');
  if (crmEmailIdQuery) {
    crmEmailId = crmEmailIdQuery;
  }

  const asyncFn = async (source) => {
    try {
      if (crmEmailId) {
        setIsLoading(true);
        let token = isAuthenticated ? await getAccessToken() : '';
        let url = `${net_api_url}/api/crm-email/get-stats/${crmEmailId}`;
        let requestOptions = {
          cancelToken: source.token,
        };

        if (isAuthenticated) {
          requestOptions.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        let callResults = await Axios.get(url, requestOptions);
        let results = callResults.data;
        if (results) {
          setStats(results);
          setIsLoading(false);
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    asyncFn(source);
    return () => {
      source.cancel('Email statistics api call canceled by the user');
    };
  }, [crmEmailId]);
  return { stats, isLoading };
};
export default useGetEmailStats;
