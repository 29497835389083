import { useContext } from 'react';
import { store } from 'components/Store';
import { useLocation } from 'react-router-dom';

function useValidateContactsOversubscribed() {
  const location = useLocation();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contactsOversubscribed, shownInnitiallyContactsOversubscribed, activePlan } = state;
  const { subscriptionStatus } = activePlan;

  const setShowContactsOversubscribedModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showContactsOversubscribedModal',
      value,
    });
    if (location.pathname.includes('influence/directory') && value)
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'shownInnitiallyContactsOversubscribed',
        value: true,
      });
  };
  const setShowContactsOversubscribedConfirmModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showContactsOversubscribedConfirmModal',
      value,
    });
  };
  const setShowContactsOversubscribedInTrialModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showContactsOversubscribedInTrialModal',
      value,
    });
  };
  const setRequestedToAddContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'requestedToAddContacts',
      value,
    });
  };
  const trialUser = subscriptionStatus === 'InTrial';

  const validateContactsOversubscribed = (callback, type, requestedToAdd = 0) => {
    if (type === 'remove') return callback();
    if (!type) {
      if (contactsOversubscribed <= 0) {
        setShowContactsOversubscribedModal(true);
      } else {
        return callback();
      }
    } else {
      switch (type) {
        case 'send':
        case 'tag':
        case 'export':
        case 'send_email':
        case 'discard_email':
        case 'save_email':
        case 'note':
          if (contactsOversubscribed < 0) {
            setShowContactsOversubscribedModal(true);
          } else {
            return callback();
          }
          break;
        case 'add_custom_contact':
        case 'add_contact':
          if (contactsOversubscribed < 0) {
            if (trialUser) {
              setShowContactsOversubscribedInTrialModal(true);
            } else {
              setShowContactsOversubscribedModal(true);
            }
            return;
          }

          if (contactsOversubscribed - requestedToAdd < 0) {
            if (trialUser) {
              setShowContactsOversubscribedInTrialModal(true);
              return;
            } else {
              setRequestedToAddContacts(requestedToAdd - contactsOversubscribed);
              if (type === 'add_custom_contact') {
                setShowContactsOversubscribedConfirmModal(true);
                return;
              }
            }
          }
          return callback();
        default:
          return;
      }
    }
  };

  return {
    setShowContactsOversubscribedModal,
    validateContactsOversubscribed,
    shownInnitiallyContactsOversubscribed,
    contactsOversubscribed,
    setShowContactsOversubscribedConfirmModal,
    setRequestedToAddContacts,
    setShowContactsOversubscribedInTrialModal,
  };
}

export default useValidateContactsOversubscribed;
