import addHoverTextProps from 'components/Dashboard/helpers/addHoverTextProps';
import getAppSite from 'utils/getAppSite';

const disabledUSAProps = () => {
  return getAppSite() === 'usa'
    ? addHoverTextProps({
        disabled: true,
        message: 'Currently disabled for US site',
      })
    : {};
};

export default disabledUSAProps;
