import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { store } from 'components/Store';
import Axios from 'axios';
import useGetSectionFilters from 'components/Dashboard/Filters/useGetSectionFilters';
import useSendEmailToContacts from './sendEmailToContacts';
import ExportContactsButton from 'components/Dashboard/Influence/CrmContacts/ExportContactsButton';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import { useMemo } from 'react';
import useValidateContactsOversubscribed from 'components/Dashboard/utilities/useValidateContactsOversubscribed';
import InfluenceNotIncludedFeaturesPopup from 'components/Dashboard/Influence/CrmContacts/InfluenceNotIncludedFeaturesPopup';
import { useLocation, useNavigate, useParams } from 'react-router-dom/dist';
import RemoveTagsFromContacts from './RemoveTagsFromContacts';
import { AddContactsToGroupButton } from 'components/Dashboard/Influence/CrmGroups/AddContactsToGroup';
import RemoveContactsFromGroupModal from './RemoveContactsFromGroupModal';

const CrmContactsListBar = ({
  setSelectedCrmContacts,
  totalHits,
  setShowAddTagsModal,
  setShowRemoveContactsModal,
  setShowRemoveContactTagsModal,
  isLoading,
  resetSelectedContacts,
  setIsLoading,
}) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { listDomainsVerified, selectedCrmContacts, previousStoredSelectedCrmContacts, activePlan, group } = state;

  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const [expandedButton, setExpandedButton] = useState('');
  const [isShowRemoveContactsFromGroupModal, setIsShowRemoveContactsFromGroupModal] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { groupId } = params;

  const { enableCrm } = activePlan;
  const [showCrmPopup, setShowCrmPopup] = useState(false);

  const query = new URLSearchParams(location.search);
  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { filters } = sectionFilters;
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  const isSmallMobile = useIsMobile(1060);
  const onlyIconOnMobile = useIsMobile(677);

  useEffect(() => {
    resetSelectedContacts();
  }, [location.search]);

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;

  const isSelected = selectedIds.length > 0 || selectedAll || selectedAllVisible.length > 0;

  const selectAllContacts = () => {
    setSelectedCrmContacts({
      selectedAll: true,
      selectedIds: [],
      selectedAllVisible: [],
    });
  };

  useEffect(() => {
    if (!isSelected) setExpandedButton('');
  }, [isSelected]);

  // const writeNoteCondition = selectedIds.length === 1;
  const selectedQnttyDecreased = useMemo(
    () => previousStoredSelectedCrmContacts.selectedIds.length > selectedIds.length,
    [previousStoredSelectedCrmContacts, selectedIds]
  );

  const generateHelperText = () => {
    if (selectedIds.length > 0) {
      return (
        <p className='contacts-list-bar-helper-text'>
          <span
            key={selectedIds.length}
            className={`${selectedQnttyDecreased ? 'selected-qntty-decreased' : 'selected-qntty'} mr-2`}
          >
            {thousandCommas(selectedIds.length)}
          </span>
          <span className='selected-qntty'>selected</span>
        </p>
      );
    } else if (selectedAll) {
      return (
        <p className='contacts-list-bar-helper-text'>
          <span className='selected-qntty'>{thousandCommas(totalHits)} selected</span>
        </p>
      );
    } else if (selectedAllVisible.length > 0) {
      return (
        <p className='contacts-list-bar-helper-text'>
          <span className='selected-qntty'>{thousandCommas(selectedAllVisible.length)} selected.</span>
          {selectedAllVisible.length < totalHits && (
            <span className='contacts-list-bar-helper-text-link' onClick={selectAllContacts}>
              Select all contacts ({totalHits}).
            </span>
          )}
        </p>
      );
    } else {
      return (
        <p className='contacts-list-bar-helper-text'>
          <span>Select contacts to send emails, add tags and take other actions</span>
        </p>
      );
    }
  };

  const handleOnClick = (button, action) => {
    validateContactsOversubscribed(() => {
      if (onlyIconOnMobile && expandedButton !== button) {
        setExpandedButton(button);
      } else {
        setExpandedButton('');
        return action();
      }
    }, button);
  };

  const { sendEmailToContacts } = useSendEmailToContacts();
  const handleSendEmail = () => {
    let contactsToSendIds =
      selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds.length > 0 ? selectedIds : 'all';
    sendEmailToContacts({
      contactsToSendIds,
      query,
      requestSource: source,
      filters,
      setIsLoading: (val) => {
        setIsLoading(val);
        setIsLoadingSendEmail(val);
      },
    });
  };

  const unverifiedEmail =
    !listDomainsVerified.verifiedDomains.length && !listDomainsVerified.verifiedEmailAddresses.length;

  return (
    <>
      <div className='crm-contacts-list-bar'>
        <div className='contacts-list-bar-helper-text-wrapper'>{generateHelperText()}</div>
        {!!isSelected && (
          <div
            className={`contacts-list-bar-action-container ${expandedButton !== '' ? 'action-container-expanded' : ''}`}
          >
            {!groupId && <AddContactsToGroupButton />}
            <button
              className={`contacts-list-bar-action-container-btn  ${expandedButton === 'send' ? 'action-container-btn-expanded' : ''}`}
              disabled={isLoading}
              onClick={() => {
                if (enableCrm) {
                  handleOnClick('send', () =>
                    unverifiedEmail
                      ? navigate(`/influence/emails`, {
                          state: { setUpDomain: true },
                          replace: true,
                        })
                      : handleSendEmail()
                  );
                } else {
                  setShowCrmPopup(true);
                }
              }}
              data-tooltip-content={unverifiedEmail ? 'Configure an email domain in order to send emails' : undefined}
              data-tooltip-id={unverifiedEmail ? 'general-tooltip' : undefined}
              data-tooltip-class-name={unverifiedEmail ? 'header-tooltip pricing-tooltip-description' : undefined}
              data-tooltip-place='bottom'
            >
              {isLoadingSendEmail ? (
                <i className='fas fa-spinner fa-spin' style={{ marginRight: '2px' }}></i>
              ) : (
                <span className={`icon-email ${isLoading ? 'icon-email-disabled' : ''}`} />
              )}
              {onlyIconOnMobile && expandedButton !== 'send' ? '' : 'Create email'}
              {unverifiedEmail && !onlyIconOnMobile && <span className='icon-info text-red'></span>}
            </button>
            <button
              className={`contacts-list-bar-action-container-btn ${
                expandedButton === 'tag' ? 'action-container-btn-expanded' : ''
              }`}
              onClick={() =>
                handleOnClick('tag', () =>
                  setShowAddTagsModal({
                    show: true,
                    preselectedContact: undefined,
                  })
                )
              }
              disabled={isLoading}
            >
              <span className={`icon-tag ${isLoading ? 'icon-tag-disabled' : ''}`} />
              {onlyIconOnMobile && expandedButton !== 'tag' ? '' : 'Add tag'}
            </button>
            <div onClick={() => handleOnClick('export', () => {})}>
              <ExportContactsButton
                onlyIcon={onlyIconOnMobile && expandedButton !== 'export'}
                contactListBar
                isLoading={isLoading}
              />
            </div>
            {groupId && group?.type === 'Static' && (
              <button
                className='contacts-list-bar-action-container-btn remove-contact-from-group-button'
                onClick={() => {
                  setIsShowRemoveContactsFromGroupModal(true);
                }}
              >
                <span className={`icon-remove-stakeholder`} />
                Remove from group
              </button>
            )}
            <RemoveTagsFromContacts
              expandedButton={expandedButton}
              isLoading={isLoading}
              onlyIconOnMobile={onlyIconOnMobile}
              isSmallMobile={isSmallMobile}
              handleOnClick={handleOnClick}
              setShowRemoveContactsModal={setShowRemoveContactsModal}
              setShowRemoveContactTagsModal={setShowRemoveContactTagsModal}
              setShowChampionsStatusModal={() => {
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'showChangeChampionStatusModal',
                  value: true,
                });
              }}
            />
          </div>
        )}
      </div>
      <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={setShowCrmPopup} showPopup={showCrmPopup} />
      <RemoveContactsFromGroupModal
        isOpen={isShowRemoveContactsFromGroupModal}
        setIsOpen={setIsShowRemoveContactsFromGroupModal}
      />
    </>
  );
};

export default CrmContactsListBar;
