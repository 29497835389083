import React, { useEffect, useRef, useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const useUploadLogoFunction = (props) => {
  const { logo } = props;
  const { generalApiCall } = useGeneralApiCall();
  const uploadLogoFile = async ({ e, setTemporaryLogo }) => {
    try {
      const reader = new FileReader();
      const files = e.target.files;
      const file = files[0];
      reader.addEventListener(
        'load',
        () => {
          if (setTemporaryLogo) {
            setTemporaryLogo(reader.result);
          }
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
        const pathname = `/api/user-upload/upload`;
        const method = 'post';
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i]);
        }
        return await generalApiCall({ pathname, method, requestProperties: formData, needsAuthentication: true });
      }
    } catch (error) {}
  };

  const logoUrl = `${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(logo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`)}&mime=${encodeURIComponent('image/png')}&cache=true`;

  return { uploadLogoFile, logoUrl };
};

const ReportLogo = (props) => {
  const { logo, setLogo, previewMode, defaultTopic, notShowWebsite } = props;
  const [temporaryLogo, setTemporaryLogo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const typingWebsite = useRef(null);

  const { generalApiCall } = useGeneralApiCall();
  const { agencyUser } = useTopicOrClientWord();

  const { uploadLogoFile, logoUrl } = useUploadLogoFunction({ logo });

  const uploadLogo = async (e) => {
    try {
      setLoadingUpload(true);
      let result = await uploadLogoFile({ e, setTemporaryLogo });
      if (!!result) {
        if (defaultTopic?.id && agencyUser) {
          await updateKeywordListLogo({ logoUrl: result[0] });
        }
        setLogo(result[0]);
        closeModal(false);
      }
    } catch (error) {
      createNotification('danger', 'An error ocurred.');
    } finally {
      setLoadingUpload(false);
    }
  };

  const closeModal = (value) => {
    setTemporaryLogo(null);
    setWebsiteUrl('');
    setIsOpen(value);
  };

  const updateKeywordListLogo = async ({ logoUrl }) => {
    const pathname = `/api/keyword-list/update-keyword-list-logo`;
    const method = 'post';
    const requestProperties = {
      id: defaultTopic?.id,
      logoUrl: logoUrl,
    };
    await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
  };

  const onKeyDownWebsiteUrl = (e) => {
    let value = e.target.value;
    if (typingWebsite.current) clearTimeout(typingWebsite.current);
    typingWebsite.current = setTimeout(() => {
      if (value !== '') {
        let logo = new Image();
        let url = `https://logo.clearbit.com/${value}?size=180`;
        logo.src = url;
        logo.onload = () => {
          setLogo(`https://logo.clearbit.com/${value}?size=180`);
          setLoadingUpload(false);
        };
        logo.onerror = () => {
          setLoadingUpload(false);
        };
      } else {
        setLoadingUpload(false);
      }
    }, 500);
  };

  return (
    <>
      <div className='position-relative report-logo-container'>
        {!previewMode && (
          <div
            className='report-logo-edit-preview rounded flex-centered justify-content-center pointer'
            onClick={() => setIsOpen(true)}
          >
            <button
              className='bg-main-white border report-logo-edit circle-button general-button'
              tabIndex={notShowWebsite ? -1 : 0}
            >
              <span className='icon-edit-bold' />
            </button>
          </div>
        )}
        <img className='reports-logo rounded' src={logoUrl} alt='Organisation logo' />
      </div>
      <ModalComponent isOpen={isOpen} setIsOpen={closeModal} maxWidth={420}>
        <h3 className='title-h5-m-bold section-title py-3 px-3 px-lg-4 mb-0'>Upload a logo</h3>

        <div className='py-4'>
          <div className='report-change-logo-container mx-auto'>
            <div className='mb-4'>
              <img className='img-fluid w-100' src={temporaryLogo ?? logoUrl} alt='organisation logo' />
            </div>

            <label
              id={'inputFileLabel'}
              className={`pointer w-100 mb-0 d-block action-button py-2 text-center ${loadingUpload ? 'disabled-button' : ''}`}
              htmlFor='inputFile'
            >
              Upload a logo
            </label>
            <input
              disabled={loadingUpload}
              id='inputFile'
              accept='image/png, image/gif, image/jpeg'
              type='file'
              hidden
              onChange={(e) => {
                uploadLogo(e);
              }}
            />
            {!!logo && (
              <div>
                <button
                  className={`general-button button-danger-text w-100 d-block py-2 rounded-button`}
                  disabled={loadingUpload}
                  onClick={(e) => {
                    setLogo(null);
                  }}
                >
                  Remove logo
                </button>
              </div>
            )}
          </div>
          {!notShowWebsite && (
            <div className={`form-group mt-4 border-top px-lg-4 px-3 pt-4`}>
              <label className='font-weight-bold' htmlFor='organisationName'>
                Or use website URL{' '}
                <span
                  className='icon-info paragraph-p2 ml-2 main-light-text d-inline-block-centered'
                  style={{ marginTop: '-4px' }}
                  data-tooltip-html='For most websites, PolicyMogul can<br/> automatically identify the logo from the URL'
                  data-tooltip-id='modal-tooltip'
                />
              </label>
              <WebsiteUrl
                onKeyDownWebsiteUrl={onKeyDownWebsiteUrl}
                websiteUrl={websiteUrl}
                setWebsiteUrl={setWebsiteUrl}
              />
            </div>
          )}
        </div>
      </ModalComponent>
    </>
  );
};

const WebsiteUrl = (props) => {
  const { websiteUrl, setWebsiteUrl, onKeyDownWebsiteUrl } = props;
  const inputRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  }, []);

  return (
    <input
      ref={inputRef}
      value={websiteUrl}
      onChange={(e) => setWebsiteUrl(e.target.value)}
      className='main-input form-control'
      type='text'
      placeholder='E.g. google.com'
      onKeyDown={onKeyDownWebsiteUrl}
    />
  );
};

export { useUploadLogoFunction };
export default ReportLogo;
