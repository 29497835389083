import React from 'react';
import { useState } from 'react';
import ShareDomainInstructions from './ShareDomainInstructions';
import disabledUSAProps from 'components/Settings/Utilities/disabledUSAProps';
import getAppSite from 'utils/getAppSite';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';

function ShareInstructionsButton({ emailDomain }) {
  const [showShareInstructionsModal, setShowShareInstructionsModal] = useState(false);
  const showHideShareInstructions = () => {
    if (getAppSite() !== 'usa') {
      setShowShareInstructionsModal(!showShareInstructionsModal);
    }
  };
  const isSmallMobile = useIsMobile(500);
  return (
    <>
      <ShareDomainInstructions
        closeModal={showHideShareInstructions}
        emailDomainId={emailDomain.id}
        showModal={showShareInstructionsModal}
      />
      <div className='unauthenticated-section'>
        <p className='share-instructions' onClick={showHideShareInstructions} {...disabledUSAProps()}>
          Click here to share these instructions{isSmallMobile ? '' : ' with a colleague'}
        </p>
      </div>
    </>
  );
}

export default ShareInstructionsButton;
