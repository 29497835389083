import { useContext } from 'react';
import { useSuggestionFunctions } from 'components/WritingAssistant/WritingCreate';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { store } from 'components/Store';
import isJsonString from 'components/Dashboard/helpers/isJsonString';

const useCreateSummaryFromWritingAssistant = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { promptFromWritingAssistant } = state;

  const { getSuggestion } = useSuggestionFunctions();
  const { generalApiCall } = useGeneralApiCall();

  const convertToMarkdown = async ({ markdown, source = null }) => {
    const pathname = `/api/copy-suggestion/convert-mark-down-to-html`;
    const method = 'post';
    const requestProperties = {
      markdown: markdown,
    };
    return await generalApiCall({
      pathname,
      requestProperties,
      method,
      notShowErrorMessage: true,
      needsAuthentication: true,
      requestSource: source,
    });
  };

  const createMarkdownFromWritingAssistant = async ({ id }) => {
    let description;
    let descriptionContent;
    if (id) {
      const pathname = `/api/writing-assistant-generated-content/${id}`;
      const method = 'get';
      let results = await generalApiCall({ pathname, method, needsAuthentication: true });
      if (results) {
        descriptionContent = isJsonString(results?.body)
          ? JSON.parse(results?.body)?.choices?.[0]?.message?.content ?? JSON.parse(results?.body)?.choices?.[0]?.text
          : results?.body;
      }
    }

    if (promptFromWritingAssistant) {
      let content = await getSuggestion({ prompt: promptFromWritingAssistant });
      if (content) {
        descriptionContent = content?.choices?.[0]?.message?.content;
      }
    }

    if (descriptionContent) {
      let descriptionCall = await convertToMarkdown({ markdown: descriptionContent });
      description = descriptionCall?.html;
      return { description };
    }
  };
  return { createMarkdownFromWritingAssistant, convertToMarkdown };
};

export default useCreateSummaryFromWritingAssistant;
