import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from 'components/Store';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import InterestByRegion from './ui/InterestByRegion';
import useCallInterestByRegion from './useCallInterestByRegion';
import useUpdateTitleAnalytics from './useUpdateTitleAnalytics';
import useModifyAnalyticsState from './useModifyAnalyticsState';
import axios from 'axios';

const InterestByRegionContainer = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { analyticsState, keywordsLists } = state;
  const { selectedCountriesInterestByRegion, allowFrontbenchers } = analyticsState;
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');
  const noTopicParameter = topicParameter === 'none';

  const { customTopic, teamId, embedWidget } = props;

  const [isLoading, setIsLoading] = useState(!noTopicParameter);
  const [data, setData] = useState({
    labels: [],
    value: [],
    colours: [],
    rawData: {},
  });
  const [initialData, setInitialData] = useState({
    labels: [],
    value: [],
    colours: [],
    rawData: {},
  });
  const { modifyState } = useModifyAnalyticsState();

  let setAllowFrontbenchers = (val) => {
    modifyState({ property: 'allowFrontbenchers', newValue: val });
  };
  const mountedInterestByRegion = useRef(true);
  const isLoadedFirstTime = useRef(noTopicParameter);

  const { callInterestByRegion } = useCallInterestByRegion({
    allowFrontbenchers: allowFrontbenchers,
    teamId,
  });
  const { updateTitleAnalytics } = useUpdateTitleAnalytics();
  const location = useLocation();

  const functionToUpdateData = useRef();
  functionToUpdateData.current = async (source) => {
    if (!noTopicParameter || searchParameter) {
      setIsLoading(true);
      let topic =
        customTopic ??
        (topicParameter
          ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
          : keywordsListsToUse[0]);

      let results = await callInterestByRegion({
        topic,
        source,
        freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
      });
      if (!!results) {
        setData(results);
        setInitialData(results);
        updateTitleAnalytics();
        if (isLoadedFirstTime.current) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'changeOfGraphFilters', value: true });
        }
        isLoadedFirstTime.current = true;
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    functionToUpdateData.current(source);

    return () => {
      source.cancel('Interest by region cancelled by the user');
    };
  }, [
    location.pathname,
    topicParameter,
    customTopic,
    searchParameter,
    allowFrontbenchers,
    selectedCountriesInterestByRegion,
  ]);

  useEffect(() => {
    return () => {
      mountedInterestByRegion.current = false;
    };
  }, []);

  return (
    <InterestByRegion
      isLoading={isLoading}
      isLoadedFirstTime={isLoadedFirstTime}
      data={data}
      analyticsState={analyticsState}
      initialData={initialData}
      setData={setData}
      allowFrontbenchers={allowFrontbenchers}
      setAllowFrontbenchers={setAllowFrontbenchers}
      embedWidget={embedWidget}
    />
  );
};

export default InterestByRegionContainer;
