import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';

const StakeholderPageSkeleton = () => {
  const [heightContainer, containerRef] = useHeightContainer();

  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div ref={containerRef} style={{ height: `${heightContainer}px` }}>
        <div className='d-lg-flex align-items-start'>
          <div className='biography-general-container border rounded'>
            <div className={` box-top-rounded biography-container`}>
              <div className='d-flex align-items-center p-4'>
                <div className='text-center'>
                  <Skeleton width={130} height={130} circle={true} />
                </div>
                <div className='col-13'>
                  <Skeleton height={20} width={'80%'} />
                  <div className='my-1'>
                    <Skeleton height={20} width={'70%'} />
                  </div>
                  <div className='mt-3'>
                    <Skeleton height={24} width={'40%'} />
                  </div>
                </div>
              </div>

              <div className='flex-centered px-4'>
                <Skeleton height={30} width={'112px'} />
                <div className='px-3'>
                  <Skeleton height={30} width={'112px'} />
                </div>
                <div className='pr-3'>
                  <Skeleton height={30} width={'112px'} />
                </div>
                <Skeleton height={30} width={'112px'} />
              </div>

              <div className={`py-3 section-title px-4`}>
                {Array(3)
                  .fill()
                  .map((item, key) => (
                    <div className={`py-3 `} key={`item-text-${key}`}>
                      <div className='my-1'>
                        <Skeleton height={19} width={'50%'} />
                      </div>
                      <div className='my-1'>
                        <Skeleton height={19} width={'30%'} />
                      </div>
                    </div>
                  ))}
              </div>

              {Array(3)
                .fill()
                .map((item, key, array) => (
                  <div
                    className={` px-4 py-3 ${key === array.length - 1 ? 'rounded-bottom' : 'section-title'}`}
                    key={`item-infor-${key}`}
                  >
                    <div className='my-1'>
                      <Skeleton height={19} width={'70%'} />
                    </div>
                    <div className='my-1'>
                      <Skeleton height={19} width={'50%'} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className='flex-grow-1 pl-4'>
            <div className='box-rounded p-4'>
              <ActivitySkeleton />
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

const ActivitySkeleton = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='mt-2 mb-4'>
        <Skeleton height={24} width={'50%'} />
      </div>
      <ul className='policy-list px-0 items-list'>
        {Array(3)
          .fill()
          .map((item, index) => (
            <li className={`my-2`} key={index}>
              <div className='row mx-0 p-3 align-items-center'>
                <div className='mr-3'>
                  <Skeleton height={19} width={19} />
                </div>
                <div className='col-12 col-sm-13 pr-lg-5 pr-2 pl-0'>
                  <Skeleton height={19} width={`80%`} />
                  <Skeleton height={19} width={`30%`} />
                </div>
                <div className='col-4 col-sm-2 d-none d-md-block'>
                  <Skeleton height={13} width={`100%`} />
                  <div className='mx-auto d-flex flex-column align-items-center'>
                    <Skeleton height={13} width={`65px`} />
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
      <div className='my-2'>
        <Skeleton height={24} width={'50%'} />
      </div>
      <ul className='policy-list px-0 items-list'>
        {Array(3)
          .fill()
          .map((item, index) => (
            <li className={`my-2`} key={index}>
              <div className='row mx-0 p-3 align-items-center'>
                <div className='mr-3'>
                  <Skeleton height={19} width={19} />
                </div>
                <div className='col-12 col-sm-13 pr-lg-5 pr-2 pl-0'>
                  <Skeleton height={19} width={`80%`} />
                  <Skeleton height={19} width={`30%`} />
                </div>
                <div className='col-4 col-sm-2 d-none d-md-block'>
                  <Skeleton height={13} width={`100%`} />
                  <div className='mx-auto d-flex flex-column align-items-center'>
                    <Skeleton height={13} width={`65px`} />
                  </div>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </SkeletonTheme>
  );
};

export { ActivitySkeleton };
export default StakeholderPageSkeleton;
