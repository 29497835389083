import React, { useContext, useState } from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import AddEmailDomainModal from 'components/Settings/EmailDomains/ui/AddEmailDomainModal';
import DeleteEmailAddressModal from './DeleteEmailAddressModal';
import disabledUSAProps from 'components/Settings/Utilities/disabledUSAProps';
import getAppSite from 'utils/getAppSite';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { store } from 'components/Store';

const EmailAddressesList = ({
  emailAddresses,
  emailDomains,
  loadInitialInformation,
  team,
  isAdminPage,
  showHideEditEmailSignatureModal,
}) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { clients } = state;

  const [heightContainer, containerRef] = useHeightContainer();
  const [showAddEmailDomainModal, setShowAddEmailDomainModal] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [teamId, setTeamId] = useState();
  const [showDeleteEmailAddressModal, setShowDeleteEmailAddressModal] = useState(false);
  const showHideAddEmailDomainModal = () => {
    setShowAddEmailDomainModal(!showAddEmailDomainModal);
  };

  const { newAgencyPlan } = useTopicOrClientWord();

  const { myself } = team;
  const allowAddDomain = (myself?.role === 'Admin' || myself?.role === 'Owner') && getAppSite() !== 'usa';

  const showHideDeleteEmailAddressModal = () => {
    setShowDeleteEmailAddressModal(!showDeleteEmailAddressModal);
  };

  return (
    <>
      <AddEmailDomainModal
        loadInitialInformation={loadInitialInformation}
        closeModal={showHideAddEmailDomainModal}
        domainsList={newAgencyPlan ? emailDomains?.find((item) => item?.teamId === teamId)?.emailDomains : emailDomains}
        senderAddressesPage
        teamId={newAgencyPlan ? teamId : null}
        teamName={newAgencyPlan ? clients?.find((item) => item?.teamId === teamId)?.teamName : null}
        openState={{ isOpen: showAddEmailDomainModal, setIsOpen: setShowAddEmailDomainModal }}
      />
      <DeleteEmailAddressModal
        setShowPopup={setShowDeleteEmailAddressModal}
        showPopUp={showDeleteEmailAddressModal}
        loadInitialInformation={loadInitialInformation}
        activeItem={activeItem}
        domainsList={newAgencyPlan ? emailDomains?.find((item) => item?.teamId === teamId)?.emailDomains : emailDomains}
        team={team}
        teamId={newAgencyPlan ? teamId : null}
        isAdminPage={isAdminPage}
      />
      <div className='mt-lg-5 email-domains-container-list'>
        <div className={`email-domains-header ${!newAgencyPlan ? 'border-bottom' : ''}`}>
          <div className='d-flex  align-items-center'>
            <h3 className='email-domains-title'>Your team’s email addresses</h3>
          </div>
          {!newAgencyPlan && (
            <div className='align-items-right'>
              <button
                className={`add-sender-btn ${allowAddDomain ? '' : 'disabled'}`}
                onClick={allowAddDomain ? showHideAddEmailDomainModal : undefined}
                data-tooltip-content={
                  allowAddDomain
                    ? undefined
                    : `Only team owners and admins can register new domains and sender email addresses`
                }
                data-tooltip-id={'settings-tooltip'}
                data-tooltip-class-name={'add-domain-tooltip'}
                {...disabledUSAProps()}
              >
                Add a sender email address
              </button>
            </div>
          )}
        </div>

        <div ref={containerRef} />
        <CustomScrollbar
          className={'simple-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
        >
          {newAgencyPlan ? (
            <>
              {emailAddresses.map((item) => {
                return (
                  <EmailAddressAgencyPlan
                    key={`emails-${item?.teamId}`}
                    team={item}
                    showHideAddEmailDomainModal={showHideAddEmailDomainModal}
                    showHideDeleteEmailAddressModal={showHideDeleteEmailAddressModal}
                    setActiveItem={setActiveItem}
                    allowAddDomain={allowAddDomain}
                    setTeamId={setTeamId}
                    showHideEditEmailSignatureModal={showHideEditEmailSignatureModal}
                  />
                );
              })}
            </>
          ) : (
            <EmailAddressElement
              emailAddresses={emailAddresses}
              showHideDeleteEmailAddressModal={showHideDeleteEmailAddressModal}
              setActiveItem={setActiveItem}
              allowAddDomain={allowAddDomain}
              showHideEditEmailSignatureModal={showHideEditEmailSignatureModal}
              myself={myself}
            />
          )}
        </CustomScrollbar>
      </div>
    </>
  );
};

const EmailAddressElement = (props) => {
  const {
    emailAddresses,
    showHideDeleteEmailAddressModal,
    setActiveItem,
    allowAddDomain,
    teamIdState,
    showHideEditEmailSignatureModal,
    myself,
  } = props;
  const { emailAddress } = myself ?? {};
  const { setTeamId, teamId } = teamIdState ?? {};
  const { newAgencyPlan } = useTopicOrClientWord();

  const statusText = (item) => {
    return item.isVerified ? 'Authenticated' : 'Unauthenticated';
  };

  let editSignatureCondition = (item) => {
    return allowAddDomain || emailAddress === `${item.userName}@${item.crmDomainName}`;
  };
  let signatureColumnCondition = emailAddresses?.some(editSignatureCondition);
  return (
    <>
      {emailAddresses.length > 0 ? (
        <>
          <div className='email-addresses-body mt-4'>
            {/* <div className="email-addresses-default-select-container">
                <p className="default-select-title">
                  <span className="default-select-title-icon" />
                  Your default address
                </p>
                <EmailAddressesDropDown
                  senderAddresses={emailAddresses}
                  value={emailAddresses[0]}
                />
              </div> */}
            <div className='domain-record-container'>
              <table
                className='domains-table domains-table-addresses'
                style={{ gridTemplateColumns: `1fr 1fr ${signatureColumnCondition ? '1fr' : ''} auto auto` }}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    {signatureColumnCondition && <th className='pl-1'>Signature</th>}
                    <th className='domain-status-column'>Status</th>
                    <th className='domains-table-domain-cell'>
                      <div>Domain</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emailAddresses.map((item) => (
                    <tr key={item.id}>
                      <td>{item.displayName}</td>
                      <td className='table-cellemailAddress-container'>
                        <div>
                          {item.userName}@{item.crmDomainName}
                        </div>
                        <div
                          data-tooltip-content={
                            allowAddDomain
                              ? undefined
                              : `Only team owners and admins can delete domains and sender email addresses`
                          }
                          data-tooltip-id={'settings-tooltip'}
                          data-tooltip-class-name={'add-domain-tooltip'}
                          className='d-flex'
                          {...disabledUSAProps()}
                        >
                          <button
                            className='delete-domain-btn'
                            onClick={
                              allowAddDomain
                                ? () => {
                                    showHideDeleteEmailAddressModal();
                                    setActiveItem(item);
                                    if (newAgencyPlan) {
                                      setTeamId(teamId);
                                    }
                                  }
                                : undefined
                            }
                            disabled={!allowAddDomain}
                          />
                        </div>
                      </td>
                      {editSignatureCondition(item) ? (
                        <td>
                          <button
                            className='edit-cignature-button'
                            onClick={() => showHideEditEmailSignatureModal(item)}
                            disabled={getAppSite() === 'usa'}
                            {...disabledUSAProps()}
                          >
                            {item.signature ? (
                              <>
                                <span className='icon-edit-bold'></span> <span>Edit</span>
                              </>
                            ) : (
                              <>
                                <span className='icon-add'></span> <span>Create</span>
                              </>
                            )}
                          </button>
                        </td>
                      ) : signatureColumnCondition ? (
                        <td></td>
                      ) : null}
                      <td className='domain-status-column'>
                        <span className='domain-status-cell' data-value={statusText(item)}>
                          {statusText(item)}
                        </span>
                      </td>
                      <td>
                        <div>{item.crmDomainName}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className='admin-general-content widget-padding'>
          <div className='ml-5'>No Email Addresses Registered</div>
        </div>
      )}
    </>
  );
};
const EmailAddressAgencyPlan = (props) => {
  const {
    team,
    allowAddDomain,
    showHideDeleteEmailAddressModal,
    setTeamId,
    setActiveItem,
    showHideAddEmailDomainModal,
    showHideEditEmailSignatureModal,
  } = props;
  const { teamName, crmSenderEmailAddresses, teamId, myself } = team ?? {};
  return (
    <>
      <div className='padding-topic-component border-bottom border-top py-3 bg-salmon'>
        <div className='px-lg-2 px-xl-4'>
          <div className='flex-centered justify-content-between'>
            <h3 className='title-h4-bold email-based-title topics-item-digest-combining mb-0'>{teamName}</h3>
            <button
              className={`general-button simple-link-button pl-3 py-0`}
              onClick={() => {
                if (allowAddDomain) {
                  setTeamId(teamId);
                  showHideAddEmailDomainModal();
                }
              }}
              disabled={!allowAddDomain}
              data-tip={
                allowAddDomain
                  ? undefined
                  : `Only team owners and admins can register new domains and sender email addresses`
              }
              data-for={'settings-tooltip'}
              data-class={'add-domain-tooltip'}
              {...disabledUSAProps()}
            >
              + Add a sender email address
            </button>
          </div>
        </div>
      </div>
      <EmailAddressElement
        emailAddresses={crmSenderEmailAddresses}
        showHideDeleteEmailAddressModal={showHideDeleteEmailAddressModal}
        setActiveItem={setActiveItem}
        allowAddDomain={allowAddDomain}
        teamIdState={{ teamId, setTeamId }}
        showHideEditEmailSignatureModal={showHideEditEmailSignatureModal}
        myself={myself}
      />
    </>
  );
};

export default EmailAddressesList;
