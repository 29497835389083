import { useContext } from 'react';
import { store } from 'components/Store';

const useGetKeywordsListsByClient = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { clients } = state;

  const getKeywordListByClient = ({ client }) => {
    const item = clients?.find((item) => item?.teamId === client?.teamId);
    if (!!item) {
      const { myKeywordLists, teamMemberKeywordLists } = item ?? {};
      const myLists = myKeywordLists ?? [];
      const teamLists = teamMemberKeywordLists ?? [];
      let lists = [...myLists, ...teamLists];
      if (lists?.length > 1) {
        let masterTopic = {
          id: null,
          name: `All updates ${!client?.teamName.includes('@') ? ` for ${client?.teamName}` : ''}`,
          masterTopic: true,
          keywords: lists.flatMap((list) => list.keywords),
          andKeywords: lists.flatMap((list) => list.andKeywords),
          keywordExclusions: lists.flatMap((list) => list.keywordExclusions),
        };
        lists = [masterTopic, ...lists];
      }
      return lists;
    }
  };
  return { getKeywordListByClient };
};

export default useGetKeywordsListsByClient;
