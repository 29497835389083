import { useContext, useEffect } from 'react';
import MentionsByTopicContainer from 'components/Dashboard/Analytics/MentionsByTopicContainer';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useAnalyticsHashesFunctions from 'components/Dashboard/Analytics/useAnalyticsHashesFunctions';
import { store } from 'components/Store';
import OverallSentimentContainer from 'components/Dashboard/Analytics/OverallSentimentContainer';
import SentimentOverTimeContainer from 'components/Dashboard/Analytics/SentimentOverTimeContainer';
import InterestByRegionContainer from 'components/Dashboard/Analytics/InterestByRegionContainer';

const individualHashHelper = ({ field, chart, analyticsState }) => {
  if (field === 'activeDateInterval') {
    if (analyticsState[field].name === 'Custom') {
      return `between-${analyticsState.activeDateInterval.filter[0]}-and-${analyticsState.activeDateInterval.filter[1]}`;
    } else {
      return analyticsState[field].name;
    }
  } else if (field === 'typeOfChart' && chart === 'Sentiment changes') {
    return analyticsState['typeOfChartSentimentChanges'];
  } else if (field === 'chartDataOptionSelected' && chart === 'Tweets') {
    return analyticsState['chartDataOptionSelectedTweets'];
  } else if (field === 'chartDataOptionSelected' && chart === 'Sentiment changes') {
    return analyticsState['chartDataOptionSelectedSentimentChanges'];
  } else if (field === 'typeOfChart' && chart === 'Interest by region') {
    return analyticsState['typeOfChartInterestByRegion'];
  } else if (field === 'selectedCountries') {
    if (chart === 'Interest by region') {
      return analyticsState['selectedCountriesInterestByRegion'].map((item) => item.value).join('-');
    } else {
      return analyticsState[field].map((item) => item.value).join('-');
    }
  } else if (field === 'chartDataOptionSelected' && analyticsState[field] === 'Overall') {
    return 'none';
  } else if (field === 'allowFrontbenchers') {
    return `${analyticsState[field]}`;
  } else {
    return analyticsState[field];
  }
};

const EmbedAnalyticsIndividual = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsState } = state;

  const { chart, topic, teamId, setCurrentChart, singleGraph } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const { addAnalyticsFilterHashToUrl } = useAnalyticsHashesFunctions();
  useEffect(() => {
    addAnalyticsFilterHashToUrl({
      individualHashHelper,
      chart: chart,
      setHashes: true,
    });
  }, [analyticsState, chart]);

  return (
    <div className='dashboard-root-container' ref={containerRef} style={{ height: `${heightContainer}px` }}>
      {!singleGraph && (
        <div className='analytics-widget-spacing pt-2 analytics-embed-back-button z-extra-max'>
          <button
            className='text-light-blue general-button action-state-hover px-3 py-2 rounded-lg'
            style={{ marginLeft: '-1em' }}
            onClick={() => setCurrentChart(null)}
          >
            <span className='icon-short-arrow-left mr-2 paragraph-p5' /> Back
          </button>
        </div>
      )}
      <div className='flex-grow-1 analytics-widget-content-container'>
        {chart === 'Mentions' && <MentionsByTopicContainer customTopic={topic} teamId={teamId} embedWidget={true} />}
        {chart === 'Sentiment snapshot' && (
          <OverallSentimentContainer customTopic={topic} teamId={teamId} embedWidget={true} />
        )}
        {chart === 'Sentiment changes' && (
          <SentimentOverTimeContainer customTopic={topic} teamId={teamId} embedWidget={true} />
        )}
        {chart === 'Interest by region' && (
          <InterestByRegionContainer customTopic={topic} teamId={teamId} embedWidget={true} />
        )}
      </div>
    </div>
  );
};

export { individualHashHelper };
export default EmbedAnalyticsIndividual;
