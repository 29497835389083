import { useContext } from 'react';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import { store } from 'components/Store';
import { PricingPopUpData } from './PopUpCheckout';

const TotalPlanTable = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions } = state;
  const { isPaidFor, subscriptionStatus } = activeSubscriptions;
  const userSubscribed = subscriptionStatus === 'Subscribed' || subscriptionStatus === 'SubscribedOverLimit';

  const {
    keywordsCategories,
    defaultCategories,
    targetPlan,
    period,
    contactsSelected,
    defaultContacts,
    customTopicPrice,
    basePlanFlatPrice,
  } = props;
  const { agencyUser, topicOrClientWord, transformTopicOrClientWord } = useTopicOrClientWord();

  const periodCapitalize = uppercaseFirstLetter(period);
  const price = PricingPopUpData[targetPlan][`${agencyUser ? 'agencyPrice' : 'price'}${periodCapitalize}`];
  const localPlanPrice = period === 'annually' ? price * 12 : price;
  const finalPlanPrice = basePlanFlatPrice ?? localPlanPrice;

  const finalCategories = keywordsCategories - defaultCategories;
  const finalConctacts = contactsSelected - defaultContacts;

  const localPriceForCategories =
    keywordsCategories > defaultCategories
      ? finalCategories *
        PricingPopUpData['Mogul'][`${agencyUser ? 'agencyCategory' : 'category'}Price${periodCapitalize}`] *
        (period === 'annually' ? 12 : 1)
      : 0;

  const priceForCategories = customTopicPrice ? finalCategories * customTopicPrice : localPriceForCategories;

  const priceForContacts =
    !!defaultContacts && contactsSelected > defaultContacts
      ? (finalConctacts / 50) *
        PricingPopUpData['Mogul'][`contactPrice${periodCapitalize}`] *
        (period === 'annually' ? 12 : 1)
      : 0;

  const finalPrice = finalPlanPrice + priceForCategories + priceForContacts;
  const vatPrice = (finalPrice * 20) / 100;

  const changeDecimalValues = (value) => {
    return value % 1 === 0 ? value : value.toFixed(2);
  };

  return (
    <table className='table confirmation-pricing-table'>
      <tbody>
        <tr className='items-information'>
          <td>{targetPlan} plan</td>
          <td>£{thousandCommas(changeDecimalValues(finalPlanPrice))}</td>
        </tr>
        {targetPlan !== 'Enterprise' && (
          <>
            {keywordsCategories > defaultCategories && (
              <tr className='items-information'>
                <td>
                  {finalCategories} additional{' '}
                  {`${finalCategories === 1 ? topicOrClientWord : transformTopicOrClientWord({ plural: true })}`}
                </td>
                <td>£{thousandCommas(changeDecimalValues(priceForCategories))}</td>
              </tr>
            )}
            {contactsSelected > defaultContacts && (
              <tr className='items-information'>
                <td>{thousandCommas(finalConctacts)} additional contacts</td>
                <td>£{thousandCommas(changeDecimalValues(priceForContacts))}</td>
              </tr>
            )}
          </>
        )}
        <tr>
          <td>Subtotal</td>
          <td>£{thousandCommas(changeDecimalValues(finalPrice))}</td>
        </tr>
        <tr className='vat-information'>
          <td>VAT (20%)</td>
          <td>£{thousandCommas(changeDecimalValues(vatPrice))}</td>
        </tr>
        <tr>
          <td>
            <span>Total due {isPaidFor ? 'next invoice' : 'today'}</span>
          </td>
          <td>£{thousandCommas(changeDecimalValues(vatPrice + finalPrice))}</td>
        </tr>

        {userSubscribed && (
          <tr>
            <td className='pt-0 pr-4' colSpan={2}>
              <span className='main-light-text d-block font-weight-normal paragraph-p3  bg-light-blue-hovered rounded border py-2 px-3'>
                Upgrades to your current subscription will be charged today on a pro rata basis
              </span>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TotalPlanTable;
