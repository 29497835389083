import getUrlParam from 'components/Dashboard/utilities/getUrlParam';

export const filterFiltersOptionDependOnLocation = ({ item, filters }) => {
  const topicId = getUrlParam('topic-id');
  if (item?.options) {
    let checkKeywordList = (condition) => {
      return !condition.keywordLists || condition.keywordLists.some((keywordList) => keywordList === topicId);
    };
    let checkLocation = (condition) => {
      return (
        !condition.locations || condition.locations.some((location) => window.location.pathname.includes(location))
      );
    };

    let getFilterItem = (filterName) => {
      return filters.find((item) => item.name === filterName);
    };

    let checkOptionFilterSelected = (condition) => {
      return (
        !condition.optionFilterSelected ||
        condition.optionFilterSelected.every((nationCondition) => {
          let filter = getFilterItem(nationCondition.name);
          if (filter?.optionSelected === nationCondition?.selectedOption) {
            return nationCondition?.condition;
          } else {
            return true;
          }
        })
      );
    };

    let checkConditionMapping = {
      keywordLists: checkKeywordList,
      locations: checkLocation,
      optionFilterSelected: checkOptionFilterSelected,
    };
    let checkCondition = (condition) => {
      return Object.keys(condition).every((key) => checkConditionMapping[key](condition));
    };
    let optionsToReturn = item.options.map((option) => {
      if (!option.optionConditionToShow || option.optionConditionToShow.some(checkCondition)) {
        return option;
      } else {
        return { ...option, hidden: true };
      }
    });
    return {
      ...item,
      options: optionsToReturn,
    };
  } else {
    return item;
  }
};
