import React, { useContext, useEffect, useRef } from 'react';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import InboxBar from './InboxBar';
import SearchResultsBar from './SearchResultsBar';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { store } from 'components/Store';
import { useLocation } from 'react-router-dom';

const SubNav = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const location = useLocation();
  const { inboxSection, referenceSection, searchResultsSection } = useMainSectionParameter();
  const memoryMasterTopic = useRef();
  memoryMasterTopic.current = () => {
    if (location.pathname.includes('/monitor-inbox')) {
      let memory = getUrlParam('topic-id') === 'all';
      dispatch({ type: 'MODIFY_SECTION', parameter: 'memoryMasterTopic', value: memory });
    }
  };

  useEffect(() => {
    memoryMasterTopic.current();
  }, [location.search]);

  return (
    <>
      {inboxSection && <InboxBar />}
      {(searchResultsSection || referenceSection) && <SearchResultsBar />}
    </>
  );
};

export default SubNav;
