import { useRef, useState } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';

const MobileTopToggle = (props) => {
  const { activeTab, changeTopToggleOption } = props;
  const [showPricingOptions, setShowPricingOptions] = useState();
  const options = [
    {
      name: 'For companies and charities',
      option: 'companies',
    },
    {
      name: 'For agencies and consultants',
      option: 'agencies',
    },
    {
      name: 'For membership organisations',
      option: 'membership',
    },
  ];

  const hideOptions = () => {
    setShowPricingOptions(false);
  };

  const optionsElement = useRef(null);

  useOutsideElement(optionsElement, hideOptions);

  return (
    <div className='position-relative mt-4 d-inline-block mx-auto' ref={optionsElement}>
      <button
        className={`general-button action-state-hover toggle-pricing-button active  pricing-toggle-mobile`}
        onClick={() => {
          setShowPricingOptions(!showPricingOptions);
        }}
      >
        <span className={`dropdown-item-element ${showPricingOptions ? 'open' : ''} `}>
          {options.find((item) => item.option === activeTab).name}
        </span>
      </button>
      {showPricingOptions && (
        <div className='box filters-container pricing-options-mobile'>
          <div className='pb-2'>
            {options.map((item) => (
              <button
                className={`general-button frequency-item px-3 py-2 d-block text-left ${activeTab === item.option ? 'frequency-item-selected' : ''}`}
                onClick={(e) => {
                  changeTopToggleOption(e, item.option);
                  hideOptions();
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTopToggle;
