import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';

const useGetKeywordsSuggestions = () => {
  const { generalApiCall } = useGeneralApiCall();
  const getKeywordsSuggestions = async ({ topicName, source }) => {
    let pathname = `/api/autocomplete/keywords-from-topic-name?topicName=${decodeURIComponent(topicName)}`;
    let method = 'get';

    let callResults = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });

    return callResults;
  };

  return { getKeywordsSuggestions };
};
export default useGetKeywordsSuggestions;
