import React, { useCallback, useContext, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import idsWithoutImages from './idsWithoutImages.js';
import Share from 'components/Dashboard/components/Share';
import { net_api_url, store } from 'components/Store.js';
import StakeholderHoverLink from 'components/Dashboard/components/StakeholderHoverLink.js';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import useTitleScrolledStyles from 'components/Dashboard/hooks/useTitleScrolledStyles.js';
import { formatEmbargoedDate } from 'components/Dashboard/helpers/formatGeneralDate.js';
import ContentSummary from 'components/Dashboard/components/ContentSummary.js';
import ThumbsDown from 'components/Common/ThumbsDown.js';
import FeedbackOnItem from 'components/Dashboard/FeedbackItem/FeedbackOnItem.js';
import TextSelector from 'components/Common/TextSelector.js';
import isValidURL from 'components/Dashboard/utilities/isValidURL.js';
import getAppSite from 'utils/getAppSite.js';
const dayjs = require('dayjs');

const ShareButtonRender = (props) => {
  const { currentNew, shareButtonWidget, embed } = props;
  const { id, subject, objectId, objectType, embargoed, canonicalUrl } = currentNew ?? {};
  let item = {
    id,
    title: subject,
    objectID: objectId,
    type: objectType,
    embargoed,
  };

  const ShareOptions = {
    item,
    id: item.id,
    title: item.subject,
    embargoed,
    shareButtonWidget,
    itemShare: embed,
  };

  if (canonicalUrl) {
    ShareOptions.link = `${process.env.REACT_APP_APPURL}${canonicalUrl}`;
  }

  return <Share {...ShareOptions} />;
};

const DateHeading = (props) => {
  const { className, currentNew, shareButtonWidget, embed, ThumbsDownProperties, notIncludeBranding } = props;

  return (
    <div className={`row mx-0 justify-content-between align-items-center keyupdate-contente-date pt-2 ${className}`}>
      <p className='heading-date mb-0'>
        <span className='date'>{dayjs(currentNew.dateTimeAdded).format('DD MMMM[,] YYYY')}</span>
        <span className='dot'></span>
        <span className='date'>{dayjs(currentNew.dateTimeAdded).format('HH:mm')}</span>
        {!!currentNew.source && (
          <>
            <span className='dot' />
            <a className='mb-0' href={currentNew?.source} target='_blank' rel='noopener noreferrer'>
              Go to source{getAppSite() === 'usa' ? `: ${new URL(currentNew.source)?.hostname}` : ''}
            </a>
          </>
        )}
      </p>
      <div className='flex-centered'>
        <ThumbsDown {...ThumbsDownProperties} />
        {!notIncludeBranding && (
          <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} embed={embed} />
        )}
      </div>
    </div>
  );
};

const KeyUpdateNewContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, activeResults } = state;

  const { KeyUpdates } = contentResults[activeResults];
  const { list } = search;
  const {
    currentNew,
    shareButtonWidget,
    handPickedNew,
    contentContainer,
    hideSummary,
    reportWidget,
    embed,
    scrollbarRef,
    scrolling,
    isFromPopup,
    notIncludeBranding,
  } = props;
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const createMarkup = (body) => {
    return { __html: body };
  };

  const highlightedConditional = () => {
    return list !== null || list === null || (shareButtonWidget && !handPickedNew);
  };

  const keyupdateContainer = useCallback((node) => {
    if (node !== null && !isMobile && !reportWidget) {
      const createStakeholdersComponent = () => {
        let elements = document.querySelectorAll('.news-content-in a');
        for (let i = 0; i < elements.length; i++) {
          let item = elements[i];
          if (!!item.href && isValidURL(item?.href)) {
            let urlItem = new URL(item.href);
            let pathname = urlItem.pathname;
            if (
              pathname.startsWith('/stakeholders') ||
              pathname.startsWith('/monitor/stakeholders') ||
              pathname.startsWith('/influence/stakeholders') ||
              pathname.startsWith('/stakeholder-mapping')
            ) {
              const stakeholderLink = document.createElement('span');
              const el = createRoot(stakeholderLink);
              el.render(
                <StakeholderHoverLink
                  contactId={pathname.match(/[0-9]+/)[0]}
                  name={item.textContent}
                  contentContainer={contentContainer}
                  navigate={navigate}
                />
              );
              item.parentNode.replaceChild(stakeholderLink, item);
            }
          }
        }
      };
      createStakeholdersComponent();
    }
  }, []);

  const titleSection = useRef(null);

  const HeadingTitle = () => {
    return (
      <h3
        className='main-title news-title pr-lg-4'
        dangerouslySetInnerHTML={createMarkup(
          highlightedConditional() && !!currentNew.subjectHighlighted
            ? currentNew.subjectHighlighted
            : currentNew.subject
        )}
      />
    );
  };

  let { showFixedTitle, titleStyles } = useTitleScrolledStyles(contentContainer, titleSection);
  const index = KeyUpdates ? KeyUpdates?.hits?.findIndex((item) => item.id === currentNew.id) : null;

  const ThumbsDownProperties = {
    item: { objectID: currentNew?.objectId },
    nameSection: 'KeyUpdates',
    show: !shareButtonWidget && !!currentNew?.keywordsFromLists,
    embedFeed: embed,
    contentPage: true,
    alreadyMarked: !!currentNew?.feedback,
    keywordsFromLists: currentNew?.keywordsFromLists,
    thick: true,
  };

  return (
    <>
      <div
        className={`section-title pb-2 keyupdate-content-title ${reportWidget ? 'content-title-in-widget' : 'content-title-with-summary'} content-border-title`}
        ref={titleSection}
      >
        <div className='d-flex align-items-lg-center justify-content-between'>
          <div className='pr-lg-5 pr-3 content-new-title flex-grow-1'>
            <HeadingTitle />
            <DateHeading
              className={'d-none d-sm-flex flex-wrap'}
              currentNew={currentNew}
              shareButtonWidget={shareButtonWidget}
              embed={embed}
              ThumbsDownProperties={ThumbsDownProperties}
              notIncludeBranding={notIncludeBranding}
            />
          </div>
          {!shareButtonWidget && getAppSite() !== 'usa' && (
            <div
              className='content-image content-new-image d-none d-sm-block'
              style={{
                backgroundImage: currentNew.imageUrl
                  ? `url(${currentNew.imageUrl})`
                  : currentNew.imageId !== 0 && !idsWithoutImages.includes(currentNew.imageId)
                    ? `url('https://www.parliamenttoday.com/image.php?imageid=${currentNew.imageId}&mode=1')`
                    : `url('${process.env.REACT_APP_CDNURL}/images/keyupdates-placeholder.png')`,
              }}
            />
          )}
        </div>
        <DateHeading
          className={'d-flex flex-wrap d-sm-none'}
          currentNew={currentNew}
          shareButtonWidget={shareButtonWidget}
          embed={embed}
          ThumbsDownProperties={ThumbsDownProperties}
          notIncludeBranding={notIncludeBranding}
        />
        {currentNew.embargoed && (
          <p className='my-2 hand-picked-item under-embargo-item'>
            <span>
              Embargoed {!!currentNew.embargoedUntil ? `until ${formatEmbargoedDate(currentNew.embargoedUntil)}` : ''}
            </span>
          </p>
        )}
      </div>
      {!shareButtonWidget && <ContentSummary content={currentNew} hide={hideSummary} />}
      <div className='pb-3 pb-lg-4 px-3 px-lg-4 specific-content-container mt-2'>
        {/* FIXED TITLE */}
        <div
          className={`content-item-scrolled-title section-title bg-white ${showFixedTitle && !isFromPopup && !reportWidget ? 'item-scrolled-title-visible' : ''}`}
          style={titleStyles}
        >
          <div className='d-flex justify-content-between scrolled-title-container'>
            <HeadingTitle />
            <ThumbsDown {...ThumbsDownProperties} />
            {!notIncludeBranding && <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} />}
          </div>
        </div>
        {/* END FIXED TITLE */}
        {!!currentNew?.feedback && (
          <FeedbackOnItem
            feedback={currentNew?.feedback}
            keywordsFromLists={currentNew?.keywordsFromLists}
            contentPage={true}
            item={{ objectID: currentNew?.objectId }}
            index={index}
          />
        )}
        <TextSelector currentNew={currentNew} scrollbarRef={scrollbarRef} scrolling={scrolling}>
          <div
            className={`news-content-in pt-2 pb-3 pr-3 context mb-2 ${getAppSite() === 'usa' ? 'news-us-content' : ''}`}
            ref={keyupdateContainer}
            dangerouslySetInnerHTML={createMarkup(currentNew.body?.replaceAll('\n', '\n\n'))}
          />
          {!!currentNew?.attachments?.length > 0 && !currentNew?.extract && (
            <div className='section-title py-3 mb-3 key-update-attachments'>
              <h3 className='general-complementary-title mb-3'>Attachments</h3>
              <ul className='policy-list px-0'>
                {currentNew.attachments.map((item) => {
                  const { filename, id } = item;
                  return <AttachmentLink key={`item-${id}`} filename={filename} id={id} />;
                })}
              </ul>
            </div>
          )}
        </TextSelector>
      </div>
    </>
  );
};

const AttachmentLink = (props) => {
  const [fetching, setFetching] = useState(false);
  const { id, filename } = props;
  return (
    <li
      data-tooltip-content={'Download'}
      data-tooltip-id={'general-tooltip'}
      data-tooltip-float={true}
      key={`attachment-${id}`}
      className='attachment-box'
      onClick={(e) => {
        setFetching(true);
        fetch(`${net_api_url}/api/key-update/attachment/${id}`)
          .then((response) => response.blob())
          .then((blob) => {
            setFetching(false);
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.style = 'display: none';

            if (filename && filename.length) a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch(() => {});
      }}
    >
      <span className='attachment-file-name'>{filename}</span>
      <button disabled={fetching} className={`general-button`}>
        {fetching ? (
          <i className='fas fa-spinner fa-spin mx-3'></i>
        ) : (
          <span className='icon-download title-h5-m mx-3' />
        )}
      </button>
    </li>
  );
};

export { AttachmentLink };
export default KeyUpdateNewContent;
