import { useContext } from 'react';
import { loadEmailAlerts } from 'components/Dashboard/utilities/loadKeyWords';
import { store } from 'components/Store';
import createEmailAlertName from 'components/Settings/Topics/Helpers/createEmailAlertName.js';
import createFrequencyName from 'components/Settings/Topics/Helpers/createFrequencyName.js';
import createResourcesName from 'components/Settings/Topics/Helpers/createResourcesName.js';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';

const useCreateEmailAlerts = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activeSubscriptions, keywordsLists } = state;
  const { planCode } = activeSubscriptions;

  const { getAccessToken } = useGetAccessToken();
  const { generalApiCall } = useGeneralApiCall();
  const { newAgencyPlan } = useTopicOrClientWord();

  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';
  const emailAlertsName = (contentTypesToReceive) => {
    let localContentTypes = contentTypesToReceive;
    if (!showTweets) {
      let resourcesToReceive = localContentTypes.split(', ');
      let contentTweetsPosition = resourcesToReceive.indexOf('Tweets');
      if (contentTweetsPosition >= 0) {
        resourcesToReceive.splice(contentTweetsPosition, 1);
      }
      localContentTypes = resourcesToReceive.join(', ');
    }
    let resourcesName = createResourcesName(localContentTypes, null, planCode);
    return `Includes ${resourcesName.replace('Everything', 'all resources')} ${localContentTypes.split(',').length === 1 ? 'only' : ''}`;
  };

  const createEmailAlerts = (emailAlerts, topics) => {
    const finalEmailAlerts = [];
    let lists = topics ?? keywordsLists;
    let digestCombiningAllAlerts =
      lists?.length > 0 ? emailAlerts.filter((alert) => alert.digestCombiningAllTopics) : [];
    let noDigestCombiningAlerts = emailAlerts.filter((alert) => !alert.digestCombiningAllTopics);
    let orderedAlerts = [...digestCombiningAllAlerts, ...noDigestCombiningAlerts];
    orderedAlerts.forEach((item) => {
      const { frequency, dayOfWeek, timeOfDay, contentTypesToReceive, secondTimeOfDay } = item;
      finalEmailAlerts.push({
        ...item,
        frequencyName: createFrequencyName(frequency, dayOfWeek, timeOfDay, secondTimeOfDay),
        resourcesName: emailAlertsName(contentTypesToReceive),
        nameToShow: createEmailAlertName(frequency),
      });
    });
    if (showTweets) {
      return finalEmailAlerts;
    } else {
      return finalEmailAlerts.filter((item) => {
        let resources = item.contentTypesToReceive.split(', ');
        let resourcesWithoutTweets = resources.filter((item) => item !== 'Tweets' && item !== 'Retweets');
        return resourcesWithoutTweets.length > 0;
      });
    }
  };

  const loadAlerts = async (source) => {
    try {
      let results;
      if (newAgencyPlan) {
        const pathname = `/api/alert-settings/all-teams`;
        const method = 'get';
        results = await generalApiCall({
          pathname,
          method,
          needsAuthentication: true,
          notShowErrorMessage: true,
          requestSource: source,
        });
      } else {
        results = await loadEmailAlerts(getAccessToken, source);
      }

      if (!!results) {
        if (newAgencyPlan) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'clientsAlerts', value: results });
        }
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'emailAlerts',
          value: newAgencyPlan ? results?.flatMap((item) => item.alertSettings) : results,
        });
      }
    } catch (error) {}
  };

  return { createEmailAlerts, loadAlerts };
};

export default useCreateEmailAlerts;
