import { useContext, useEffect, useRef, useState } from 'react';
import { useChangeKeywordsListsOrder } from 'components/Dashboard/hooks/useSearchBarUiFunctions';
import { store } from 'components/Store';
import normalize from 'components/Dashboard/utilities/normalizeString';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import DropdownComponent from 'components/Settings/WebsiteFeeds/DropdownComponent';
import { Link, useLocation } from 'react-router-dom';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const TopicPickerFilterSentimentPopup = (props) => {
  const { filter, selectOption } = props;
  const { name, options } = filter ?? {};

  const createName = () => {
    if (options?.length > 0) {
      if (options.length === 1) {
        return options[0]?.name;
      } else if (options.length === 2) {
        const formatter = new Intl.ListFormat('en-GB', { type: 'disjunction' });
        return `${formatter.format(options?.map((item) => item.name))}`;
      } else {
        return `${options?.length} topics selected`;
      }
    }
  };
  return (
    <>
      <h3>{name}</h3>
      <div className='pb-3 website-feeds-popup-settings'>
        <DropdownComponent
          mainItem={() => (
            <span className='hidden-lines hidden-one-line'>
              {options?.length === 0 ? <span className='main-light-text'>Select a topic</span> : <>{createName()}</>}
            </span>
          )}
          notCloseOnSelect={true}
        >
          <TopicFilterSelector
            maxHeight={226}
            options={options}
            localFilter={filter}
            selectOption={({ newValue }) => {
              selectOption({ filter, newValue: { ...filter, options: newValue } });
            }}
          />
        </DropdownComponent>
      </div>
      {options?.length > 0 && (
        <div className='pb-3'>
          <SentimentOptions
            localFilterState={{
              localFilter: filter,
              setLocalFilter: (newValue) => {
                selectOption({ filter, newValue });
              },
            }}
            localFilter={filter}
          />
        </div>
      )}
    </>
  );
};

const TopicPickerFilterSentiment = (props) => {
  const { localFilterState, selectOption } = props;
  const { localFilter } = localFilterState ?? {};
  const { options } = localFilter ?? {};

  return (
    <>
      <TopicFilterSelector options={options} localFilter={localFilter} selectOption={selectOption} />
      <div className='border-top p-3 bg-blue-hovered'>
        <SentimentOptions localFilterState={localFilterState} />
      </div>
    </>
  );
};

const TopicFilterSelector = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const keywordsListsToUse = keywordsLists.filter((item) => item?.id !== null);
  const { options, selectOption, localFilter, maxHeight = 190 } = props;

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({ keywordsLists: keywordsListsToUse });
  const { newAgencyPlan } = useTopicOrClientWord();
  const lists = changeKeywordsListsOrder();
  const location = useLocation();

  return (
    <CustomScrollbar
      className={`simple-scrollbar`}
      maximalThumbYSize={100}
      autoHeight
      autoHeightMin='100%'
      autoHeightMax={`${maxHeight}px`}
    >
      <div className={'px-3'}>
        {lists?.length > 0 ? (
          <div className=''>
            {keywordsListsToUse?.map((item) => {
              return (
                <TopicElement
                  key={`topicItem-${item?.id}`}
                  item={item}
                  options={options}
                  selectOption={selectOption}
                  localFilter={localFilter}
                />
              );
            })}
          </div>
        ) : (
          <p className='p-3 mb-0 main-light-text'>
            You do not currently have any topics set up{newAgencyPlan ? ' for this client' : ''}.{' '}
            <Link
              to={`/settings/topics/new-topic?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
              className='simple-link-button'
            >
              Create a topic
            </Link>
          </p>
        )}
      </div>
    </CustomScrollbar>
  );
};

const TopicElement = (props) => {
  const { item, options, selectOption, localFilter } = props ?? {};
  const { description, id, name } = item;
  const selected = !!options?.find((item) => item?.id === id && item?.selected);
  const topicName = useRef();
  const labelElement = useRef();
  const [dimentions, setDimentions] = useState({ containerWidth: 0, elementWidth: 0 });
  const { containerWidth, elementWidth } = dimentions;
  const hasElipsis = containerWidth < elementWidth;

  useEffect(() => {
    if (topicName?.current && labelElement?.current) {
      setDimentions({
        containerWidth: labelElement?.current?.offsetWidth,
        elementWidth: topicName?.current?.scrollWidth,
      });
    }
  }, [name, labelElement?.current, topicName?.current]);

  return (
    <div className={`checkbox-item position-relative flex-centered specific-top-margin-20`}>
      <input
        id={`${normalize(name)}-${id}`}
        onChange={() => {
          let newItems = [...options];
          let itemPosition = newItems.findIndex((item) => item.id === id);
          if (itemPosition >= 0) {
            newItems.splice(itemPosition, 1);
            selectOption({ filter: localFilter, newValue: newItems });
          } else {
            selectOption({ filter: localFilter, newValue: [...newItems, { ...item, selected: true }] });
          }
        }}
        type={'checkbox'}
        checked={selected}
      />
      <label
        htmlFor={`${normalize(name)}-${id}`}
        className={`font-weight-normal d-inline-block-centered centered-4 d-inline-block mb-0`}
        style={{ width: `calc(100% - 3em)` }}
        ref={labelElement}
      >
        <p
          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          className='mb-0 line-height-1-5 '
          ref={topicName}
          data-tooltip-content={hasElipsis ? name : ''}
          data-tooltip-id={'general-tooltip'}
        >
          <span className='nowrap-item'>{name}</span>
        </p>
        {description && <p className='topic-description-field mb-0 mt-1'>{description}</p>}
      </label>
    </div>
  );
};

const SentimentOptions = (props) => {
  const { localFilterState } = props;
  const { localFilter, setLocalFilter } = localFilterState ?? {};
  const { sentiment } = localFilter ?? {};

  const sentimentOptions = [
    { name: 'All contributors', value: null },
    { name: 'Positive sentiment', value: 'positive', color: '#006E46' },
    { name: 'Negative sentiment', color: '#B91943', value: 'negative' },
  ];

  return (
    <>
      {sentimentOptions?.map((item, index) => {
        const { name, color, value } = item;
        return (
          <div
            key={`sentiment-${name}`}
            className={`border rounded-pill specific-top-padding-4 specific-bottom-padding-6 px-3 ${index === sentimentOptions?.length - 1 ? '' : 'mb-3'} bg-main-white`}
            style={{ color: color ?? null }}
          >
            <div className='radio-button'>
              <input
                id={normalize(name)}
                onChange={() => {
                  setLocalFilter({ ...localFilter, sentiment: value });
                }}
                type='radio'
                checked={sentiment === value}
              />
              <label className='w-100' htmlFor={normalize(name)}>
                {name}
              </label>
            </div>
          </div>
        );
      })}
    </>
  );
};

export { TopicPickerFilterSentiment };
export default TopicPickerFilterSentimentPopup;
