import React from 'react';
import ModalComponent from 'components/Common/Modal';

const EndTeamTrialModal = (props) => {
  const { teamId, showModal, closeModal, endTeamTrialRequest } = props;

  const submitFormHandle = async () => {
    await endTeamTrialRequest(teamId);
    closeModal();
  };

  return (
    <>
      <ModalComponent maxWidth={500} isOpen={showModal} setIsOpen={closeModal}>
        <h3 className='title-h4-bold px-3 px-lg-5 py-3 mb-0'>End Team Trial</h3>
        <p className='mb-0 py-4 border-top border-bottom px-3 px-lg-5'>Are you sure you want to end this trial?</p>
        <div className='p-3 px-lg-5 text-right'>
          <button className='btn btn-secondary' onClick={closeModal}>
            Cancel
          </button>
          <button className='ml-3 btn btn-danger' onClick={submitFormHandle}>
            End Trial
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default EndTeamTrialModal;
