import React, { useEffect } from 'react';
import { useContext } from 'react';
import { store } from 'components/Store';
import IndividualContentStatistics from 'components/Dashboard/UserContent/ui/IndividualContentStatistics';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useGetEngagementStatisticsItem from './useGetEngagementStatisticsItem';
import NoResultsTeam from 'components/Settings/TeamContent/NoResultsTeam';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import normalize from 'components/Dashboard/utilities/normalizeString';
import camelCasetoNormalStrign from 'components/Dashboard/utilities/camelCaseToNormalString';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags';
import useCallTeamsContent from 'components/Dashboard/apiCalls/callTeamsContent';

function EngagementStatistics() {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { engagementStatisticsResult, engagementStatisticsOnLoad, team } = state;

  const [heightContainer, containerRef] = useHeightContainer();

  const { item } = useGetEngagementStatisticsItem();

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { itemId } = params;

  const { callTeamsContent } = useCallTeamsContent();
  const { addMetaTags } = useAddMetaTags();

  const loadTeamsContent = async (source) => {
    try {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'engagementStatisticsOnLoad',
        value: true,
      });
      //LOAD TEAMS CONTENT
      let teamsContentResult = await callTeamsContent({
        source,
        abbreviated: true,
        publishedOnly: true,
      });
      if (!!teamsContentResult) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'engagementStatisticsResult',
          value: teamsContentResult,
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'engagementStatisticsOnLoad',
          value: false,
        });

        const notItemOnClient = !teamsContentResult?.find((item) => item?.id === parseInt(itemId));
        const itemIdConditional = !itemId || notItemOnClient;

        if (itemId && notItemOnClient) {
          navigate(`/influence/engagement-statistics`, {
            replace: true,
          });
        }

        if (itemIdConditional && teamsContentResult?.length) {
          const type = normalize(camelCasetoNormalStrign(teamsContentResult[0]?.type));
          const id = teamsContentResult[0]?.id;

          let title = `Engagement statistics - Influence - PolicyMogul`;
          document.title = title;
          navigate(`/influence/engagement-statistics/${type}/${id}`, {
            replace: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadTeamsContent(source);
    return () => {
      source.cancel('Engagement statistics api call canceled by the user');
    };
  }, [team]);

  useEffect(() => {
    if (itemId) {
      let title = `Engagement statistics - Influence - PolicyMogul`;
      addMetaTags({ title, hash: null, location, dispatch });
    }
  }, [itemId]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'engagementStatisticItem',
        value: {},
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'engagementStatisticsOnLoad',
        value: true,
      });
    };
  }, []);

  return (
    <>
      {engagementStatisticsResult.length > 0 || engagementStatisticsOnLoad ? (
        <div className='engagement-statistics-influence-section main-content pr-xl-5 px-md-4 px-3 h-100'>
          <div className='engagement-statistics-container bg-white'>
            <div ref={containerRef} />
            <CustomScrollbar
              className={'engagement-statistics-scrollbar'}
              maximalThumbYSize={100}
              style={{ height: `${heightContainer}px` }}
            >
              <IndividualContentStatistics item={item} engagementStatisticsOnLoad={engagementStatisticsOnLoad} />
            </CustomScrollbar>
          </div>
        </div>
      ) : (
        <div className='flex-grow-1'>
          <div className='pl-md-4 pl-3'>
            <div ref={containerRef} />
            <div
              className={'simple-scrollbar'}
              style={{
                height: `${heightContainer}px`,
                marginLeft: '-8px',
              }}
              /* maximalThumbYSize={100} */
            >
              <div className={`pt-2 pr-lg-4 px-2`}>
                <NoResultsTeam
                  text={'Once you publish lobbying material, engagement statistics from policymakers will appear here'}
                  totalResults={[]}
                  results={[]}
                  activeFilters={[]}
                  filtersOptions={[]}
                  applyFilters={[]}
                  checkedFilters={[]}
                  influenceSection={true}
                  engagementStatistics={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EngagementStatistics;
