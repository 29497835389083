import { useState } from 'react';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import normalize from 'components/Dashboard/utilities/normalizeString';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import ModalComponent from 'components/Common/Modal';

const regions = [
  { name: 'Scotland', field: 'Scotland' },
  { name: 'Wales', field: 'Wales' },
  { name: 'Northern Ireland', field: 'NorthernIreland' },
];

const CountriesPopupContainer = (props) => {
  const { countries, disabled, setShowPopup } = props;
  const createRegionsText = () => {
    let text = 'All UK updates';
    if (countries?.includes('AllCountries')) {
      return text;
    }
    if (countries) {
      let localCountries = countries.split(', ').join(',');
      let removeUk = localCountries.replace('UK,', '');
      let countriesArray = removeUk.split(',');
      const lf = new Intl.ListFormat('en-GB');
      let exeptCountries = regions.filter((item) => !countriesArray.includes(item.field)).map((item) => item.name);
      if (exeptCountries.length > 0) {
        text += ` except those specific to ${lf.format(exeptCountries)}`;
      }
    }
    return text;
  };
  return (
    <>
      <div className='d-lg-flex justify-content-between mt-5 align-items-center'>
        <h3 className='topic-title topic-title-email-alerts mb-0'>Regions</h3>
      </div>
      <div
        className={`row mx-0 align-items-center topic-email-alert-container py-2 px-3 my-2 justify-content-between action-state-hover pointer ${disabled ? 'disabled' : ''} topic-label-countries`}
        onClick={() => {
          if (!disabled) {
            setShowPopup(true);
          }
        }}
      >
        <p className='label-country label-all-countries  mb-0'>{createRegionsText()}</p>
        <button className='general-button topic-alert-edit'>
          <span className='icon-edit-pencil-thick'></span>
        </button>
      </div>
    </>
  );
};

const CountriesPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent isOpen={showPopUp} setIsOpen={setShowPopup}>
      <CountriesPopupContent {...props} />
    </ModalComponent>
  );
};

const CountriesPopupContent = (props) => {
  const { countries, setCountries, setShowPopup } = props;
  const [localCountries, setLocalCountries] = useState(
    countries ? countries.split(', ').join(',').split(',') : ['UK', 'Scotland', 'Wales', 'NorthernIreland']
  );

  const changeCountry = (country) => {
    let newLocalCountries = localCountries.includes('AllCountries')
      ? ['UK', 'Scotland', 'Wales', 'NorthernIreland']
      : localCountries;
    let newCountries = [...newLocalCountries.filter((item) => item !== 'AllCountries')];
    if (newCountries.includes(country)) {
      if (newCountries.length > 1) {
        let position = newCountries.indexOf(country);
        newCountries.splice(position, 1);
      } else {
        createNotification('danger', 'You have to select at least one country');
      }
    } else {
      newCountries.push(country);
    }
    setLocalCountries(newCountries);
  };

  const { topicOrClientWord } = useTopicOrClientWord();

  const isChecked = (country) => {
    return localCountries.includes(country) || localCountries.includes('AllCountries');
  };

  const handleConfirm = () => {
    const getCountries = (items) => {
      // eslint-disable-next-line
      let countriesArr = items.split(/\,| ,/);
      countriesArr = countriesArr.filter((item) => item !== 'AllCountries');
      if (!countriesArr.includes('UK')) {
        countriesArr = ['UK', ...countriesArr];
      }
      return countriesArr.map((c) => c.trim()).join(',');
    };
    let preparedCountries = getCountries(localCountries.join(','));
    setCountries(preparedCountries);
    setShowPopup(false);
  };

  return (
    <div className='text-left'>
      <div className='pt-4 section-title px-lg-5 px-4 pb-3 popup-individual-item-container'>
        <h3 className={`topic-title mb-0 font-weight-bold`}>Regional coverage</h3>
      </div>
      <div className='px-lg-5 p-4'>
        <p className='label-country label-all-countries mb-0'>
          By default, you will receive all updates of national relevance for this {topicOrClientWord}
        </p>
      </div>
      <div className='px-lg-5 p-4 border-top'>
        <p className='font-weight-bold mb-4'>
          Deselect region-specific sources that are <span className='text-uppercase text-underline'>not</span> relevant
          to this {topicOrClientWord}
        </p>
        {regions.map((item) => {
          const { name, field } = item;
          const identifier = `${normalize(name)}-${field.toLowerCase()}`;
          return (
            <div className={`filter-item-container`} key={identifier}>
              <div className={`checkbox-item filter-item-${normalize(name)}`}>
                <input
                  id={identifier}
                  onChange={() => {
                    changeCountry(field);
                  }}
                  type={`checkbox`}
                  checked={isChecked(field)}
                />
                <label className={`label-country label-${field.toLowerCase()}`} htmlFor={identifier}>
                  {name}
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`py-3 text-right padding-topic-component border-top flex-centered justify-content-end rounded-bottom bg-salmon`}
      >
        <button className='general-button action-button px-3 px-lg-5 py-2' onClick={handleConfirm}>
          {'Confirm'}
        </button>
      </div>
    </div>
  );
};

export { CountriesPopup };
export default CountriesPopupContainer;
