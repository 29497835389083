import React from 'react';
import { useAdminAgencyUser } from './UserSideNav';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import ModalComponent from 'components/Common/Modal';

const HiddenItemsKeysPopUp = (props) => {
  const { newAgencyPlan } = useAdminAgencyUser();
  const topicOrClientWord = newAgencyPlan ? 'client' : 'topic';
  return (
    <ModalComponent maxWidth={410} isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <div className={`p-4 flex-column`}>
        <h3 className='my-0 main-title mb-3' style={{ marginLeft: '8px' }}>
          Key
        </h3>
        <label className='mb-0 w-100 font-weight-normal pointer-event-none'>
          <div className={`mb-0 frequency-item px-3 py-2 position-relative`}>
            <div className={`stakeholder-checkbox-input topics-hide-checkbox-dropdown`}>
              <input type={'checkbox'} checked={true} />
              <label htmlFor={`hideTopic`} />
            </div>
            User sees {topicOrClientWord}
          </div>
        </label>
        <label className='mb-0 w-100 font-weight-normal pointer-event-none'>
          <div className={`mb-0 frequency-item px-3 py-2 position-relative`}>
            <div
              className={`stakeholder-checkbox-input topics-hide-checkbox-dropdown`}
              style={{
                backgroundColor: '#D1D4D7',
              }}
            >
              <input type={'checkbox'} checked={true} />
              <label htmlFor={`hideTopic`} />
            </div>
            User does not see {topicOrClientWord}, but can un-hide it from {topicOrClientWord}s page
          </div>
        </label>
        <label className='mb-0 w-100 font-weight-normal pointer-event-none'>
          <div className={`mb-0 frequency-item px-3 py-2 position-relative`}>
            <div className={`stakeholder-checkbox-input topics-hide-checkbox-dropdown`}>
              <input type={'checkbox'} checked={false} />
              <label htmlFor={`hideTopic`} />
            </div>
            {uppercaseFirstLetter(topicOrClientWord)} is not visible anywhere for this user <br />
            <strong>(Not possible for Owners or Admins)</strong>
          </div>
        </label>
      </div>
    </ModalComponent>
  );
};

export default HiddenItemsKeysPopUp;
