import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from 'components/Store';

const useModifyAnalyticsState = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { analyticsState } = state;
  const location = useLocation();

  const modifyState = ({ property, newValue }) => {
    let newState = { ...analyticsState, [property]: newValue };
    if (property === 'selectedCountriesInterestByRegion' && !newValue.some((item) => item.value === 'UK')) {
      newState = { ...newState, allowFrontbenchers: false };
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'analyticsState',
      value: newState,
    });
  };

  const changeChartData = ({ chartDataOption }) => {
    let propertyToModify = location.pathname.includes('/analytics/tweets')
      ? 'chartDataOptionSelectedTweets'
      : location.pathname.includes('/analytics/sentiment-changes')
        ? 'chartDataOptionSelectedSentimentChanges'
        : 'chartDataOptionSelected';
    modifyState({ property: propertyToModify, newValue: chartDataOption });
  };

  return { modifyState, changeChartData };
};

export default useModifyAnalyticsState;
