import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActivityElements from './ActivityElements';
import BioElements, { ProfileImage } from './BioElements';
import { store } from 'components/Store';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const MobileUI = (props) => {
  const {
    ScrollContent,
    StakeholderInformation,
    clickOnSeeMore,
    setShowContactsPopup,
    setShowStakeholderCrmPopup,
    activePlan,
  } = props;

  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { stakeholderMobileSection } = state;

  const scrollBarRef = useRef();
  const params = useParams();
  const { contentType } = params;
  const [activeSection, setActiveSection] = useState(
    contentType || clickOnSeeMore || stakeholderMobileSection === 'activity' ? 'activity' : 'profile'
  );

  const changeSection = (section) => {
    setActiveSection(section);
    scrollBarRef.current.scrollTop();
  };
  const topElements = useRef();

  useEffect(() => {
    return () =>
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'stakeholderMobileSection',
        value: '',
      });
  }, []);

  return (
    <div className='bg-white dashboard-root-container'>
      <div ref={topElements}>
        <ProfileImage
          StakeholderInformation={StakeholderInformation}
          setShowContactsPopup={setShowContactsPopup}
          setShowStakeholderCrmPopup={setShowStakeholderCrmPopup}
          activePlan={activePlan}
        />
        <div className='mobile-stakeholder-buttons border-bottom d-flex'>
          <button
            className={`general-button stakeholder-section-button flex-grow-1 ${activeSection === 'profile' ? 'active' : ''}`}
            onClick={() => {
              changeSection('profile');
            }}
          >
            Profile
          </button>
          <button
            className={`general-button stakeholder-section-button flex-grow-1 ${activeSection === 'activity' ? 'active' : ''}`}
            onClick={() => {
              changeSection('activity');
            }}
          >
            <span>Activity</span>
          </button>
        </div>
      </div>
      <div className='flex-grow-1'>
        <CustomScrollbar
          className={'simple-scrollbar'}
          maximalThumbYSize={100}
          onScroll={(scrollValues) => {
            ScrollContent(scrollValues);
          }}
          ref={scrollBarRef}
        >
          <div className='mobile-stakeholders-container'>
            {activeSection === 'profile' && <BioElements {...props} />}
            {activeSection === 'activity' && <ActivityElements {...props} />}
          </div>
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default MobileUI;
