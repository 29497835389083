import React, { useEffect, useState, useContext } from 'react';
import { store } from 'components/Store.js';
import axios from 'axios';
import { loadKeyWords } from 'components/Dashboard/utilities/loadKeyWords.js';
import getCurrentTeam from 'components/Settings/Team/getCurrentTeam';
import EmailAlertsComponent from './ui/EmailAlertsComponent.js';
import { useLocation } from 'react-router-dom';
import SkeletonEmailAlertsPage from './ui/SkeletonEmailAlertsPage.js';
import useCallUpcomingEvents from './callUpcomingEvents.js';
import useCreateEmailAlerts from './useCreateEmailAlerts.js';
import useClientsFunctions from 'components/Dashboard/hooks/useClientsFunctions.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import useControlEventAlerts from './controlEventsAlerts.js';

const EmailAlerts = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { emailAlerts, activeSubscriptions, team, keywordsLists, clientsAlerts, clients, activePlan } = state;
  const { myself } = team;
  const { enableHandpickedContent } = activePlan;
  const { allowEventNewsletters, allowWeeklyAlerts, allowWeekdailyAlerts, allowImmediateAlerts } = activeSubscriptions;
  const [isLoading, setIsLoading] = useState(true);
  const [allUpcomingEvents, setAllUpcomingEvents] = useState({});
  const [onlySendRelevant, setOnlySendRelevant] = useState(false);

  const { getAccessToken } = useGetAccessToken();

  const { createEmailAlerts, loadAlerts } = useCreateEmailAlerts();
  const { getTeams } = useClientsFunctions();
  const { newAgencyPlan } = useTopicOrClientWord();
  const { callUpcomingEvents } = useCallUpcomingEvents();

  const location = useLocation();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getInitialInformation(source);
    return () => {
      source.cancel('Email alerts page canceled by the user.');
    };
  }, []);

  const { controlEventAlerts } = useControlEventAlerts();
  const getInitialInformation = async (source) => {
    try {
      //AE:DISPATCH FUNCTION WILL HAPPEN ON THAT FUNCTION
      await loadAlerts(source);
      if (newAgencyPlan) {
        let clients = await getTeams({ includeKeywordsList: true, source });
        if (!!clients) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'clients', value: clients });
        }
      }
      let upcomingEvents = await callUpcomingEvents(source);
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      let keywordsListsResults = await loadKeyWords(getAccessToken, source);
      //SET STATE PROPERTIES
      if (!!keywordsListsResults) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: keywordsListsResults });
      }
      if (!!currentTeam) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
      }
      //CALL UPCOMING EVENTS
      let title = `Email alerts - Settings - PolicyMogul`;
      document.title = title;
      let locationToStorage = {
        title: title,
        storageLocation: { ...location },
      };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
      if (!!upcomingEvents) {
        setAllUpcomingEvents(transformUpcomingEventsAlerts(upcomingEvents));
        for (let i = 0; i < upcomingEvents.length; i++) {
          let item = upcomingEvents[i];
          if (item.onlySendIfContainsRelevantContent) {
            setOnlySendRelevant(true);
            break;
          }
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  //ALL UPCOMING EVENTS FUNCTIONS
  const { transformUpcomingEventsAlerts, createUpcomingEvent, deleteUpcomingEvent, onlySendEmailEventsRelevant } =
    controlEventAlerts({
      allUpcomingEvents,
      setAllUpcomingEvents,
      onlySendRelevant,
      setOnlySendRelevant,
    });

  let allowAlerts = allowImmediateAlerts && allowWeekdailyAlerts && allowWeeklyAlerts;

  return (
    <>
      {isLoading && <SkeletonEmailAlertsPage />}
      {!isLoading && (
        <>
          <EmailAlertsComponent
            keywordsLists={keywordsLists}
            allowAlerts={allowAlerts}
            emailAlerts={createEmailAlerts(emailAlerts)}
            allUpcomingEvents={allUpcomingEvents}
            createUpcomingEvent={createUpcomingEvent}
            deleteUpcomingEvent={deleteUpcomingEvent}
            onlySendEmailEventsRelevant={onlySendEmailEventsRelevant}
            userEmail={myself?.emailAddress}
            allowEventNewsletters={allowEventNewsletters}
            onlySendRelevant={onlySendRelevant}
            clientsAlerts={clientsAlerts}
            clients={clients}
            enableHandpickedContent={enableHandpickedContent}
          />
        </>
      )}
    </>
  );
};
export default EmailAlerts;
