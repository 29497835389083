import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';

const SkeletonAccount = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='pt-4 pt-lg-5'>
        <div className='settings-box px-lg-5 py-lg-4 p-3' ref={containerRef} style={{ height: `${heightContainer}px` }}>
          <div className='account-list px-0 pb-2'>
            <div className='pr-3' id='settings-container'>
              <ul className='policy-list px-0 hover-content'>
                {Array(7)
                  .fill()
                  .map((item, index) => (
                    <li key={`account${index}`}>
                      <div className='col-lg-5 mb-2 px-0'>
                        <Skeleton width={'80%'} height={19} />
                      </div>
                      <div className='col-lg-7 px-0'>
                        <Skeleton width={'100%'} height={32} />
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonAccount;
