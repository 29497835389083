import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AttachmentLink from 'components/Dashboard/components/AttachmentLink';

const AttachmentDropzone = (props) => {
  const [activeDrag, setActiveDrag] = useState(false);
  const { value, setValue } = props;
  const { temporalFiles, savedFiles } = value;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setValue({ ...value, temporalFiles: !!temporalFiles ? temporalFiles.concat(acceptedFiles) : acceptedFiles });
      setActiveDrag(false);
    },
    onDragEnter: () => {
      setActiveDrag(true);
    },
    onDragLeave: () => {
      setActiveDrag(false);
    },
  });

  const DeleteTemporalResource = (position) => {
    const modifiedTemporalFiles = [...temporalFiles];
    modifiedTemporalFiles.splice(position, 1);
    setValue({
      ...value,
      temporalFiles: modifiedTemporalFiles,
    });
  };

  const DeleteUploadedAttachment = (position) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      const modifiedSavedFiles = [...savedFiles];
      modifiedSavedFiles.splice(position, 1);
      setValue({
        ...value,
        savedFiles: modifiedSavedFiles,
      });
    };
  };

  const DeleteTemporalFile = (props) => {
    const { index } = props;
    return (
      <button
        className='general-button topic-alert-delete'
        data-tooltip-id={'general-tooltip'}
        data-tooltip-content={'Delete'}
        onClick={() => {
          DeleteTemporalResource(index);
        }}
      ></button>
    );
  };

  return (
    <>
      {!!savedFiles && savedFiles.length > 0 && (
        <ul className='policy-list px-0 mb-3'>
          {savedFiles.map((item, index) => (
            <AttachmentLink
              key={`savedfile-${index}`}
              url={item.url}
              filename={item.name}
              includeDeleteButton
              deleteFunction={DeleteUploadedAttachment(index)}
            />
          ))}
        </ul>
      )}
      {!!temporalFiles && temporalFiles.length > 0 && (
        <ul className='policy-list px-0 mb-3'>
          {temporalFiles.map((item, index) => (
            <li
              className='border-main-blue-50 box-rounded d-flex justify-content-between mb-3 align-items-center'
              key={`file-${index}`}
            >
              <p className='attachment-name mb-0'>{item.name}</p>
              <div className='px-3'>
                <DeleteTemporalFile index={index} />
              </div>
            </li>
          ))}
        </ul>
      )}
      <div
        {...getRootProps({
          className: `box-rounded-dashed general-dropzone d-flex justify-content-center align-items-center mb-3 ${activeDrag ? 'active-dropzone' : ''}`,
        })}
      >
        <input {...getInputProps()} />
        <p className='text-center mb-0'>
          {activeDrag ? (
            'Drop your file here'
          ) : (
            <>
              + Drag and drop or <span className='simple-link-button text-underline'>upload a file</span>
            </>
          )}
        </p>
      </div>
    </>
  );
};

export default AttachmentDropzone;
