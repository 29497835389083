import Preloader from 'components/Dashboard/Analytics/ui/AnalyticsDashboard/Preloader';
import { compileData } from 'components/Dashboard/Analytics/MentionsByTopicContainer';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';
import PoliticalMentionsChart from './PoliticalMentionsChart';
import useCallPoliticalMentionsByTopic from './useCallPoliticalMentionsByTopic';
import { AnalyticsPopup } from 'components/Dashboard/Navigation/NotFeaturePopups';
import axios from 'axios';

const GraphItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, analyticsState, activePlan } = state;
  const { enableAnalytics } = activePlan;

  const { selectedTopic } = props;

  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  let topic = selectedTopic ? keywordsListsToUse.find((item) => item.id === selectedTopic.id) : keywordsListsToUse[0];

  const callResults = useRef();

  const { callPoliticalMentionsByTopic } = useCallPoliticalMentionsByTopic();

  callResults.current = async (source) => {
    try {
      if (topic) {
        setIsLoading(true);
        let results = await callPoliticalMentionsByTopic(topic, source);
        if (results) {
          let value = compileData(results.data, props?.analyticsState ?? analyticsState).value;
          let data = { ...results.data, value };
          setData(data);
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callResults.current(source);
    return () => {
      source.cancel('Political mentions cancelled');
    };
  }, [topic]);

  return (
    <>
      <div className='d-flex'>
        {isLoading && <Preloader style={{ minHeight: '98px', transform: 'scale(0.65)', padding: '13px 0' }} />}
        {!isLoading && (
          <PoliticalMentionsChart
            data={data}
            enableAnalytics={enableAnalytics}
            topic={topic}
            setShowPopup={setShowPopup}
          />
        )}
      </div>
      <AnalyticsPopup isOpen={showPopup} setIsOpen={setShowPopup} />
    </>
  );
};

export default GraphItem;
