import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import useShareEmailInstructions from 'components/Settings/EmailDomains/useShareEmailInstructions';
import { validateEmail } from 'utils/validateEmail';
import ModalComponent from 'components/Common/Modal';

const ShareDomainInstructions = ({ closeModal, emailDomainId, showModal }) => {
  const closeModalFunc = () => {
    closeModal();
  };

  const [error, setError] = useState({ type: '', message: '' });
  const dataElements = useRef([]);

  const { shareInstructions, isLoading } = useShareEmailInstructions();

  const validateForm = () => {
    let elements = dataElements.current;
    let fields = Object.keys(elements);
    for (let i = 0; i < fields.length; i++) {
      let field = elements[fields[i]];
      let val = field.value;
      if (field.required && val === '') {
        setError({ type: fields[i], message: 'Please complete this field' });
        return false;
      } else if (!validateEmail(String(elements['emailAddress'].value))) {
        setError({
          type: 'emailAddress',
          message: 'Please enter a valid email',
        });
        return false;
      }
    }
    return true;
  };

  const submitFormHandle = async () => {
    let emailAddress = dataElements.current['emailAddress'].value;
    let sendCopy = dataElements.current['sendCopy'].checked;
    const result = await shareInstructions({
      email: emailAddress,
      sendCopy,
      emailDomainId,
    });
    if (result) closeModalFunc();
  };

  const shareInstructionsHandler = () => {
    let validate = validateForm();
    if (validate) {
      submitFormHandle();
    }
  };

  const handleChange = (e) => {
    if (e?.target?.name === error?.type) {
      setError({ type: '', message: '' });
    }
  };

  const Error = () => {
    return (
      <div className='error-message px-0 px-lg-2 col-lg-4 mt-2 mt-lg-0'>
        <img className='mr-3' src={`${process.env.REACT_APP_CDNURL}/images/warning-icon.svg`} alt='warning-icon' />
        <p className='mb-0 message py-2 px-3'>{error.message}</p>
      </div>
    );
  };

  return (
    <ModalComponent maxWidth={605} isOpen={showModal} setIsOpen={closeModalFunc}>
      <div className='add-domain-modal-container pt-4'>
        <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3'>
          Click here to share these instructions with a colleague
        </h3>
        <div className='settings-box'>
          <div className={`account-list px-0`}>
            <div id='settings-container'>
              <ul className='policy-list px-0 hover-content'>
                <form className='mx-0'>
                  <div className='add-domain-modal-body'>
                    <li className='add-domain-modal-list-item' style={{ flexGrow: 1 }}>
                      <h3>Colleague’s email address</h3>
                      <input
                        className=' mt-2'
                        type='text'
                        autoComplete='off'
                        ref={(input) => (dataElements.current['emailAddress'] = input)}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        required
                        name='emailAddress'
                      />
                      {error.type === 'emailAddress' && <Error />}
                    </li>
                    <div className='send-copy-container position-relative mt-3'>
                      <div className={`stakeholder-checkbox-input`} onClick={(e) => e.stopPropagation()}>
                        <input
                          type={'checkbox'}
                          ref={(input) => (dataElements.current['sendCopy'] = input)}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name='sendCopy'
                          id='sendCopy'
                        />
                        <label htmlFor='sendCopy' />
                      </div>
                      <label className='send-copy-label' htmlFor='sendCopy'>
                        Send me a copy
                      </label>
                    </div>

                    <div className='add-domain-modal-actions' style={{ marginTop: '10px' }}>
                      <button
                        className='add-domain-modal-action-cancel'
                        onClick={(e) => {
                          e.preventDefault();
                          closeModalFunc();
                        }}
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          shareInstructionsHandler();
                        }}
                        className='general-button action-button px-3 py-2 add-domain-modal-action-add'
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <i
                            className='fas fa-spinner fa-spin'
                            style={{
                              margin: '0 auto',
                              color: '#fcfcfc',
                              marginTop: '2px',
                              width: '33.7px',
                            }}
                          ></i>
                        ) : (
                          'Send instructions'
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ShareDomainInstructions;
