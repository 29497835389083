import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import SkeletonRelatedContent from 'components/ContentRelated/SkeletonRelatedContent';

const SkeletonLegislationContent = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div ref={containerRef} style={{ height: `${heightContainer}px` }}>
        <div className='h-100 row mx-0 piece-content-container'>
          <div className={`general-content-column padding-to-nav h-100 main-content-section content-container`}>
            <div className='p-4 pb-5'>
              <div className='section-title pb-2 mb-2'>
                <Skeleton height={24} width={`100%`} />
                <div className='row justify-content-between mx-0 mt-2'>
                  <Skeleton height={19} width={200} />
                  <div className='mt-2 mt-lg-0'>
                    <Skeleton height={19} width={50} />
                    <span className='mx-1'>
                      <Skeleton width={20} height={20} circle={true} />
                    </span>
                    <Skeleton width={20} height={20} circle={true} />
                  </div>
                </div>
              </div>
              <div className='news-content-in pt-2 pb-3 context mb-2'>
                {Array(4)
                  .fill()
                  .map((item, index) => (
                    <div key={index} className='mb-3'>
                      <Skeleton height={19} width={`40%`} />
                      <div className='my-1'>
                        <Skeleton height={19} width={`35%`} />
                      </div>
                    </div>
                  ))}
                <div className='mt-2 mb-3'>
                  <Skeleton height={19} width={`100%`} />
                </div>
                <div className='mt-2 mb-3'>
                  <Skeleton height={19} width={`100%`} />
                </div>
              </div>
            </div>
          </div>
          <div className='related-content-column show-lg px-0 h-100'>
            <SkeletonRelatedContent />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonLegislationContent;
