import React, { useEffect, useState } from 'react';
import createFiltersForTypes from 'components/Dashboard/helpers/createFiltersForTypes';
import createTypeOfResource from 'components/Dashboard/helpers/createTypeOfResource';
import axios from 'axios';
import SearchRelatedContent from './ui/SearchRelatedContent';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import getAppSite from 'utils/getAppSite';

const RelatedContentContainer = (props) => {
  const { generalApiCall } = useGeneralApiCall();
  const {
    sectionValues,
    itemInformation,
    associatedContent,
    setAssociatedContent,
    initialQuery,
    includeAllResults,
    enabledSwiper,
    customResources,
  } = props;
  const { objectId } = itemInformation ?? {};
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const { fields } = sectionValues ?? {};
  //to select results
  const { value, setValue } = fields?.[0] ?? {};
  const [searchValue, setSearchValue] = useState('');
  const [loadingRelatedSuggestions, setLoadingRelatedSuggestions] = useState(false);
  const [relatedSearchResults, setRelatedSearchResults] = useState(null);
  const initialResources =
    getAppSite() === 'usa'
      ? ['Legislation', 'ParliamentaryRecord', 'KeyUpdate']
      : ['LobbyingMaterial', 'Legislation', 'ParliamentaryRecord', 'KeyUpdate', 'Consultation'];
  const resources = customResources ?? initialResources;
  const [typeOfContent, setTypeOfContent] = useState(resources[0]);

  if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
    resources.push('Tweet');
  }

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  //compile menu items and functions of them
  const clickResources = async (item) => {
    setTypeOfContent(item);
    if (searchValue !== '' || (searchValue === '' && !!initialQuery)) {
      let type = item;
      searchFunction(type);
    }
  };

  const CreateResources = () => {
    let finalResources = [];
    resources.forEach((item) => {
      if (item === 'KeyUpdate' || item === 'Consultation' || item === 'PolicyAsk' || item === 'Tweet') {
        finalResources.push(`${item}s`);
      } else {
        finalResources.push(item);
      }
    });
    return finalResources.join(',');
  };

  //search function
  const SearchRelatedResults = async ({ type, pageNumber, value }) => {
    try {
      let method = 'post';
      let pathname = `/api/search/query`;
      let contentType = createTypeOfResource(type ?? typeOfContent);
      let finalContentType =
        contentType === 'LobbyingMaterial' ? 'UserContent' : createTypeOfResource(type ?? typeOfContent);
      let requestProperties = {
        type: finalContentType,
        pageSize: pageSize,
        pageNumber: pageNumber ?? 1,
        query: value,
        includeExplanations: false,
        includeHighlights: true,
      };
      const { filters, sort } = createFiltersForTypes(contentType, objectId);

      if (contentType === 'ParliamentaryRecord') {
        requestProperties.collapseField = 'contentUrl';
      }

      if (filters) {
        requestProperties.filters = filters;
      }
      requestProperties.sort = sort;
      return await generalApiCall({
        method,
        pathname,
        requestProperties,
        requestSource: source,
        needsAuthentication: true,
        bigInt: true,
      });
    } catch (error) {}
  };

  const createItems = ({ arr, section, highlights }) => {
    let finalItems = [];
    arr.forEach((item, index) => {
      const { id, objectID, type, title, body, dateTime, contentUrl, hansardContentType, contributionId } = item;
      let newItem = {
        ...item,
        initialItem: item,
        id: id,
        title: title,
        objectId: objectID,
        contentUrl: `${contentUrl}${!!contributionId ? `#contribution-${contributionId}` : ''}`,
        contentType: type,
        dateTime: dateTime,
        snippet: body,
        section: section,
        contentUrlFragment: null,
        highlights: highlights ? highlights[index] : { highlightedBodySnippets: [hansardContentType ?? body] },
      };
      finalItems.push(newItem);
    });
    return finalItems;
  };

  const searchFunction = async (type, pageNumber) => {
    if (!pageNumber) {
      setLoadingRelatedSuggestions(true);
    }
    let contentType = !!type ? type : typeOfContent;
    let value = searchValue === '' && initialQuery ? initialQuery : searchValue;
    let response = await SearchRelatedResults({ type, value, pageNumber });
    let arr = includeAllResults ? response.hits : response.hits.slice(0, 3);
    let section = contentType;
    let baseItems = !!type ? { ...relatedSearchResults } : {};
    let highlights = response.highlights;
    let items = !!pageNumber ? baseItems[contentType] : [];
    let newResults = {
      ...baseItems,
      pageNumber: response?.pageNumber,
      thereIsMoreContent: response.hits?.length === pageSize,
      [contentType]: [...items, ...createItems({ arr, section, highlights })],
    };
    setRelatedSearchResults(newResults);
    setLoadingRelatedSuggestions(false);
  };

  const uniqueSearchFunction = async (value = searchValue) => {
    setLoadingRelatedSuggestions(true);
    const pluralContentTypes = (type) => {
      switch (type) {
        case 'KeyUpdate':
        case 'Consultation':
        case 'PolicyAsk':
          return `${type}s`;
        default:
          return type;
      }
    };

    for (let i = 0; i < resources.length; i++) {
      let type = resources[i];
      let response = await SearchRelatedResults({ type, value });
      if (!!response) {
        if (response.hits.length > 0 || i === resources.length - 1) {
          let arr = includeAllResults ? response.hits : response.hits.slice(0, 3);
          let section = pluralContentTypes(type);
          let highlights = response.highlights;
          setRelatedSearchResults({
            ...relatedSearchResults,
            pageNumber: response?.pageNumber,
            thereIsMoreContent: response.hits?.length === pageSize,
            [pluralContentTypes(type)]: createItems({ arr, section, highlights }),
          });
          setTypeOfContent(pluralContentTypes(type));
          setLoadingRelatedSuggestions(false);
          break;
        }
      }
    }
  };

  //select a resource function
  const selectResource = (item) => {
    let position = associatedContent?.findIndex((sel) => sel.contentUrl === item.contentUrl);
    let newValue = [...associatedContent];
    if (position >= 0) {
      //selected
      newValue.splice(position, 1);
    } else {
      newValue.push(item);
    }
    setAssociatedContent(newValue);
  };

  const deleteItemFunction = ({ item }) => {
    let position = value?.findIndex((sel) => sel.objectId === item.objectId);
    let positionAssociatedContent = associatedContent?.findIndex((sel) => sel.objectId === item.objectId);
    if (position >= 0) {
      let newValue = [...value];
      newValue.splice(position, 1);
      setValue(newValue);
    }
    if (positionAssociatedContent >= 0) {
      let newAssociatedContent = [...associatedContent];
      newAssociatedContent.splice(positionAssociatedContent, 1);
      setAssociatedContent(newAssociatedContent);
    }
  };

  const onScrollFunction = (e) => {
    if (includeAllResults) {
      const el = e.target;
      const { scrollTop, scrollHeight, clientHeight } = el;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1;
      const { thereIsMoreContent, pageNumber } = relatedSearchResults;
      if (scrolledToBottom && thereIsMoreContent) {
        searchFunction(typeOfContent, pageNumber + 1);
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (!!initialQuery && searchValue === '') {
      uniqueSearchFunction(initialQuery);
    }
  }, [initialQuery]);

  return (
    <SearchRelatedContent
      {...props}
      searchValue={searchValue}
      searchFunction={uniqueSearchFunction}
      setSearchValue={setSearchValue}
      loadingRelatedSuggestions={loadingRelatedSuggestions}
      relatedSearchResults={relatedSearchResults}
      typeOfContent={typeOfContent}
      resources={CreateResources()}
      clickResources={clickResources}
      selectResource={selectResource}
      value={value}
      deleteItemFunction={deleteItemFunction}
      associatedContent={associatedContent}
      onScrollFunction={onScrollFunction}
      enabledSwiper={enabledSwiper}
    />
  );
};

export default RelatedContentContainer;
