import React, { useContext } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import exportAsImage from 'utils/exportAsImage';
import styles from './DownloadChart.module.scss';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import html2canvas from 'html2canvas';
import Skeleton from 'react-loading-skeleton';
import { store } from 'components/Store';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useChangeKeywordsListsOrder } from 'components/Dashboard/hooks/useSearchBarUiFunctions';
import ReactGA from 'react-ga4';
import ReportDesignButton, { hex_is_light } from 'components/Reports/ui/ReportDesignButton';
import useUpdateTitleAnalytics from 'components/Dashboard/Analytics/useUpdateTitleAnalytics';
import ModalComponent from 'components/Common/Modal';

const backgroundOptions = [
  { id: 'F4EFED', value: '#F4EFED', titleColor: '#00122B' },
  { id: '0094CC', value: '#0094CC', titleColor: '#F4EFED' },
  { id: 'F2A805', value: '#F2A805', titleColor: '#00122B' },
  { id: '00122B', value: '#00122B', titleColor: '#F4EFED' },
];

const capitalizeFirstLetter = (text) => {
  return text[0].toUpperCase() + text.slice(1);
};

const ChartKey = ({ item, isSentimentOverTime }) => {
  return (
    <li
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginRight: '20px',
        marginBottom: '10px',
        fontSize: '0.925em',
      }}
    >
      <span
        style={{
          background: isSentimentOverTime
            ? item.backgroundColor
            : item.type === 'line'
              ? item['_dataset']?.borderColor
              : item['_dataset']?.backgroundColor[0],
          borderRadius: `3px`,
          display: 'inline-block',
          height: '12px',
          marginRight: '10px',
          width: '12px',
        }}
      />
      <p
        style={{
          color: '#375167',
          fontWeight: '300',
          margin: 0,
          padding: 0,
        }}
      >
        {item.label}
      </p>
    </li>
  );
};

const DownloadChartPopUp = (props) => {
  const { chartSection, setChartOnDownload, data, chartRef, type, setIsOpen, initialHeader } = props;

  const isSentimentOverTime = type === 'Sentiment changes';
  const isOverallSentiment = type === 'Sentiment snapshot';
  const mapChart = type === 'Interest by region';

  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsState } = state;
  const { chartDataOptionSelected, chartDataOptionSelectedTweets } = analyticsState;

  const { sectionTitle } = useUpdateTitleAnalytics();
  const [showChartTitle, setShowChartTitle] = useState(true);
  const [chartHeader, setChartHeader] = useState(capitalizeFirstLetter(initialHeader));
  const [image, setImage] = useState(null);

  const getLabelsForSentimentOverTime = (data) => {
    const labels = [];
    if (data.findIndex((value) => value < 0) !== -1) {
      labels.push({
        backgroundColor: 'rgb(227, 66, 66)',
        label: 'Negative sentiment',
      });
    }
    if (data.findIndex((value) => value > 0) !== -1) {
      labels.push({
        backgroundColor: 'rgb(163, 228, 86)',
        label: 'Positive sentiment',
      });
    }
    return labels;
  };

  const createChartDataKeys = () => {
    if (chartRef?.current) {
      let keys = [];
      if (isOverallSentiment) {
        data.labels.forEach((item, index) => {
          let info = {
            _dataset: { backgroundColor: [`rgb(${data.colours[index]})`] },
            label: item,
          };
          keys.push(info);
        });
      } else if (isSentimentOverTime) {
        data.values.forEach((item, index) => {
          if (chartRef.current.isDatasetVisible(index)) {
            let info = chartRef.current.getDatasetMeta(index);
            let compiledKeys = getLabelsForSentimentOverTime(info['_dataset'].data);
            keys = compiledKeys;
          }
        });
      } else {
        Object.keys(data.value).forEach((item, index) => {
          if (chartRef.current.isDatasetVisible(index)) {
            let info = chartRef.current.getDatasetMeta(index);
            keys.push(info);
          }
        });
      }
      return keys;
    }
  };

  const chartKeys = createChartDataKeys();
  const exportRef = useRef(null);
  const location = useLocation();
  const sortOption = location.pathname.includes('tweets') ? chartDataOptionSelectedTweets : chartDataOptionSelected;

  const title = `${sectionTitle()} ${
    mapChart ? '' : sectionTitle() !== 'Sentiment changes' ? `by ${sortOption.toLowerCase()}` : ''
  }`;

  useEffect(() => {
    const asyncFn = async () => {
      const returnOriginalCanvasSize = () => {
        return new Promise((resolve, reject) => {
          setTimeout(resolve, 900);
        });
      };
      //Generate image from scaled chart
      setTimeout(async () => {
        if (mapChart) {
          html2canvas(chartRef._container, {
            allowTaint: true,
            useCORS: true, //important to avoid CORS related errors
            width: chartRef._container.clientWidth,
            height: chartRef._container.clientHeight,

            onclone: (doc) => {
              let svg = doc.querySelector('svg.leaflet-zoom-animated');
              let checkbox = doc.querySelector('.chart-map-frontbenchers-checkbox');

              if (checkbox) checkbox.remove();
              const matrixValues = svg.style.transform.match(/matrix.*\((.+)\)/)[1].split(', ');
              svg.style.transform = `none`;
              svg.style.left = `${matrixValues[4]}px`;
              svg.style.top = `${matrixValues[5]}px`;
              svg.style.filter = 'brightness(0.75)';
            },
          })
            .then((canvas) => {
              return canvas.toDataURL('image/png', 1.0);
            })
            .then((img) => {
              setImage(img);
            });
        } else {
          await html2canvas(chartSection.current)
            .then((canvas) => canvas.toDataURL('image/png', 1.0))
            .then((img) => setImage(img));
        }
      }, 800);
      //Return Chart to original size after image is generated
      returnOriginalCanvasSize().then(() => setChartOnDownload(null));
    };
    asyncFn();
  }, [chartSection, setChartOnDownload]);

  const handleChangeInput = (e) => {
    setChartHeader(e.target.value);
  };

  const downloadChart = () => {
    exportAsImage(
      exportRef.current,
      String(chartHeader)
        .replace(/[^a-z0-9]/gi, ' ')
        .trim()
        .split(' ')
        .join('_')
        .toLowerCase()
        // eslint-disable-next-line
        .replaceAll(/\_+/gi, '_'),
      mapChart
    );
    ReactGA.event({
      category: 'Stakeholder Charts',
      action: `Chart Downloaded`,
      label: `${initialHeader} - ${sectionTitle()}`,
    });
  };

  const renderChartKeys = (values) => {
    return (
      <div className={styles.chartKeysContainer}>
        {values.map((item) => (
          <ChartKey key={`item-${item.label}`} item={item} isSentimentOverTime={isSentimentOverTime} />
        ))}
      </div>
    );
  };

  const [designState, setDesignState] = useState({ backgroundColour: '#00122B', includeBranding: true });
  const [isOpenDesign, setIsOpenDesign] = useState(false);
  const mainContainer = useRef();
  const { width, left, top, height } = mainContainer?.current?.getBoundingClientRect() ?? {};

  return (
    <div ref={mainContainer}>
      <div className='flex-centered justify-content-between section-title py-3 px-5 website-popup-title'>
        <h3 className={styles.modalChartHeader}>Download this chart</h3>
        <ReportDesignButton
          property={'enableAnalyticsRemoveBranding'}
          customColours={[
            '#00122B',
            '#0094CC',
            '#F4EFED',
            '#F2A805',
            '#FBDBC5',
            '#FFE9B5',
            '#E2F1EB',
            '#D2E9E0',
            '#DFF1FB',
            '#FFFFFF',
          ]}
          designProps={{ designState, setDesignState }}
          componentText={'chart'}
          chartButton={true}
          isOpenDesign={isOpenDesign}
          setIsOpenDesign={setIsOpenDesign}
        />
      </div>
      <div className={styles.modalChartContainer}>
        <div className={styles.headerTitleGroup}>
          <div className={styles.headerInputGroup}>
            <label htmlFor='chartHeader' className={styles.headerInputGroup__label}>
              Header
            </label>
            <input
              type='text'
              id='chartHeader'
              className={styles.headerInputGroup__input}
              placeholder='Type header...'
              value={chartHeader}
              onChange={handleChangeInput}
              maxLength={50}
            />
          </div>

          <div
            className={styles.checkBoxesGroup}
            style={{ visibility: sortOption === 'Overall' ? 'hidden' : 'visible' }}
          >
            <div className={styles.checkShowTitle}>
              <p>Show title</p>
              <div>
                <input
                  type='checkbox'
                  id='showTitle'
                  className={styles.checkShowTitle__input}
                  checked={showChartTitle}
                  onChange={() => setShowChartTitle(!showChartTitle)}
                />
                <label htmlFor='showTitle' className={styles.checkShowTitle__label} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.DownloadChart} ${mapChart ? 'DownloadChart-map' : ''}`}
          ref={exportRef}
          style={{
            backgroundColor: designState?.backgroundColour,
            color: hex_is_light(designState?.backgroundColour) ? '#00122B' : '#F4EFED',
          }}
        >
          <h4 className={styles.DownloadChart__header}>{chartHeader}</h4>

          <>
            {image ? (
              <div
                className={`${styles.DownloadChart__imgContainer} ${mapChart ? 'imgContainer-map' : ''} ${
                  mapChart && !showChartTitle ? 'imgContainer-no-title-map' : ''
                }`}
              >
                {showChartTitle && sortOption !== 'Overall' && (
                  <p className={`${styles.DownloadChart__sectionTitle} ${mapChart ? 'sectionTitle-map' : ''}`}>
                    {title}
                  </p>
                )}
                {chartKeys && sortOption !== 'Overall' && renderChartKeys(chartKeys)}
                <div className={`${mapChart ? 'sectionImg-map' : ''}`}>
                  <img alt={`${title}-chart`} src={image} />
                </div>
              </div>
            ) : (
              <div className={styles.skeletonImgContainer}>
                <Skeleton />
              </div>
            )}
          </>
          <div
            className={`${styles.DownloadChart__logoContainer} ${mapChart ? 'logoContainer-map' : ''}`}
            style={{ visibility: designState?.includeBranding ? 'visible' : 'hidden' }}
          >
            <p
              style={{ color: `${hex_is_light(designState?.backgroundColour) ? '#00122B' : '#F4EFED'}` }}
              className={styles.DownloadChart__helperText}
            >
              Powered by
            </p>
            <Logo fill={hex_is_light(designState?.backgroundColour) ? '#00122B' : '#F4EFED'} />
          </div>
        </div>
      </div>
      <div className={styles.DownloadChart__buttonGroup}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
          className={styles.DownloadChart__buttonGroup_button}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            downloadChart();
          }}
          className={styles.DownloadChart__buttonGroup_button_download}
        >
          {'Download'}
        </button>
      </div>

      {isOpenDesign && (
        <div
          className='position-fixed rounded z-extra-max'
          style={{
            width: `${width}px`,
            height: `${height}px`,
            top: `${top}px`,
            left: `${left}px`,
            background: 'rgba(0, 18, 43, 0.06)',
          }}
        />
      )}
    </div>
  );
};
const useCreateInitialHeader = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;

  const { sectionTitle } = useUpdateTitleAnalytics();
  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists.filter((item) => item.id !== null),
  });
  const keywordsListsToUse = changeKeywordsListsOrder();
  const createInitialHeader = (chartName, notIncludeTopic, topicName) => {
    let id;
    if (topicParameter) {
      id = parseInt(topicParameter);
    }
    const baseTitle = chartName ?? sectionTitle();
    const section = baseTitle === 'Tweets' ? baseTitle.split(' ')[0] : baseTitle.split(' ')[0].toLowerCase();
    const nameOfTopic =
      topicName ??
      `${searchParameter ? decodeURIComponent(searchParameter) : keywordsListsToUse.find((item) => item.id === id)?.name}`;
    if (section === 'top') {
      return `Top MPs and peers: ${notIncludeTopic ? '' : nameOfTopic}`;
    }
    return `Political ${section}: ${notIncludeTopic ? '' : nameOfTopic}`;
  };
  return { createInitialHeader };
};

const DownloadChartModal = (props) => {
  const { sectionTitle } = useUpdateTitleAnalytics();
  const { createInitialHeader } = useCreateInitialHeader();
  const { isOpen, setIsOpen } = props;
  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      additionalOpenAction={() => {
        ReactGA.event({
          category: 'Stakeholder Charts',
          action: `Popup Opened`,
          label: `${createInitialHeader()} - ${sectionTitle()}`,
        });
      }}
      additionalClass={'create-download-chart-simple-modal'}
      style={{ maxHeight: '84%', maxWidth: '790px', overflow: 'hidden auto' }}
      {...props}
    >
      <DownloadChartPopUp {...props} initialHeader={createInitialHeader()} />
    </ModalComponent>
  );
};

export { backgroundOptions, useCreateInitialHeader };
export default DownloadChartModal;
