import { useLocation } from 'react-router-dom';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import getAppSite from 'utils/getAppSite';

const useCreateHoverText = (props) => {
  const { datahub, chartWord = 'report' } = props ?? {};
  const { topicOrClientWord } = useTopicOrClientWord();
  const hoverText = {
    mentions: `This ${chartWord} shows the number of mentions over time, based on the ${topicOrClientWord} applied, and broken down in various ways. ${datahub ? 'Click to see more data and apply filters' : 'on a point on the graph to see the data breakdown in more detail.'}`,
    tweets: `This ${chartWord} shows the number of Tweets and Retweets over time, based on the ${topicOrClientWord} applied, and broken down in various ways. ${datahub ? 'Click to see more data and apply filters' : 'on a point on the graph to see the data breakdown in more detail.'}`,
    'sentiment-snapshot': `This ${chartWord} shows the breakdown of positive, negative, neutral and mixed sentiment amongst ${getAppSite() === 'usa' ? 'policymakers' : 'parliamentarians'} in the last ${process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS} days, based on the ${topicOrClientWord} applied. ${datahub ? 'Click to see more data and apply filters' : 'on a point on the graph to see the data breakdown in more detail.'}`,
    'sentiment-changes': `This ${chartWord} shows how net sentiment has changed amongst ${getAppSite() === 'usa' ? 'policymakers' : 'parliamentarians'} over time, based on the ${topicOrClientWord} applied. ${datahub ? 'Click to see more data and apply filters' : 'on a point on the graph to see the data breakdown in more detail.'}`,
    'top-mps-and-peers': `This ${chartWord} plots the top political stakeholders by their interest and influence, based on the ${datahub ? 'search' : topicOrClientWord} applied. Hover over a stakeholder to see what makes them interested and/or influential, and click to visit their profile, including all relevant activity.`,
    'interest-by-region': `This ${chartWord} shows the number of mentions based on region of the UK. ${datahub ? 'Click to see more data and apply filters' : 'on a point on the graph to see the data breakdown in more detail.'}`,
  };
  return { hoverText };
};

const HoverText = () => {
  const location = useLocation();
  const sectionName = location.pathname.replace('/analytics/', '');
  const { hoverText } = useCreateHoverText({});

  return (
    <span
      className='chart-icon-information'
      data-tooltip-content={hoverText[sectionName]}
      data-tooltip-id='tooltip-mobile'
      data-tooltip-class-name={'header-tooltip pricing-tooltip-description'}
    >
      <span className='icon-single-info paragraph-p2' />
    </span>
  );
};

export { useCreateHoverText };
export default HoverText;
