import React, { useContext, useState, useEffect } from 'react';
import { store } from 'components/Store.js';
import DeleteFunction from './DeleteFunction';
import { useNavigate } from 'react-router-dom';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall.js';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import ModalComponent from 'components/Common/Modal';

const TopicDeletePopUp = (props) => {
  const { listPosition } = props;
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, showTopicDeletePopup } = state;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [reportsAssociated, setReportsAssociated] = useState([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const { getAccessToken } = useGetAccessToken();

  const getReportsByTopic = async ({ source }) => {
    try {
      const topicId = keywordsLists[listPosition]?.id;
      if (!!topicId) {
        setIsLoading(true);
        const pathname = `/api/report-definition/team-report-definitions?keywordListId=${topicId}`;
        const method = 'get';
        const results = await generalApiCall({
          pathname,
          method,
          needsAuthentication: true,
          notShowErrorMessage: true,
          requestSource: source,
        });
        if (!!results) {
          setReportsAssociated(results);
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  const deleteIndividualReport = async ({ id }) => {
    const method = 'delete';
    const pathname = `/api/report-definition/${id}`;
    await generalApiCall({ method, pathname, needsAuthentication: true, returnCompleteRequest: true });
  };

  const deleteReports = async () => {
    for (let i = 0; i < reportsAssociated.length; i++) {
      await deleteIndividualReport({ id: reportsAssociated[i]?.id });
    }
    return true;
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getReportsByTopic({ source });
    setIsLoadingButton(false);
    return () => {
      setIsLoadingButton(false);
      source.cancel('Topic delete popup cancelled by the user');
    };
  }, []);

  const { topicOrClientWord } = useTopicOrClientWord();
  const { generalApiCall } = useGeneralApiCall();
  const setShowTopicDeletePopup = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showTopicDeletePopup',
      value: val,
    });
  };
  return (
    <ModalComponent maxWidth={500} isOpen={showTopicDeletePopup} setIsOpen={setShowTopicDeletePopup}>
      <div className='delete-keywordcategory-modal py-4'>
        <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
          <div className='text-center'>
            <h3 className='title-h3-bold mb-4'>You can't undo this action</h3>
            {isLoading ? (
              <SkeletonTheme baseColor='#fff'>
                <Skeleton height={19} width={`100%`} />
                <Skeleton height={19} width={`80%`} />
                <div className='my-4'>
                  <Skeleton height={19} width={`40%`} />
                </div>
              </SkeletonTheme>
            ) : (
              <>
                <p className='title-h5-m main-subtle-text mx-auto'>
                  If you delete this {topicOrClientWord}, any <span className='font-weight-bold'>email alerts</span> or{' '}
                  <span className='font-weight-bold'>reports</span> you or your colleagues have created for it will be
                  deleted
                </p>

                {reportsAssociated.length > 0 && (
                  <div className='border px-4 py-2 rounded mt-4'>
                    <p className='title-h5-m main-subtle-text mb-0'>
                      <span className='font-weight-bold'>{reportsAssociated.length}</span> associated report
                      {reportsAssociated.length === 1 ? '' : 's'} will be deleted
                    </p>
                  </div>
                )}

                <div className='my-4 pt-2'>
                  <button className='general-button delete-button px-4 mr-3 py-1'>Cancel</button>
                  <button
                    className='btn btn-general main-danger-button text-white px-4 py-2'
                    onClick={async () => {
                      setIsLoadingButton(true);
                      if (reportsAssociated.length > 0) {
                        await deleteReports();
                      }
                      await DeleteFunction({
                        state: state,
                        dispatch: dispatch,
                        getAccessToken: getAccessToken,
                        listPosition: listPosition,
                        navigate: navigate,
                        closePopUp: true,
                        setIsLoadingButton: setIsLoadingButton,
                      });
                    }}
                  >
                    {isLoadingButton ? <i className='fas fa-spinner fa-spin'></i> : `Delete ${topicOrClientWord}`}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default TopicDeletePopUp;
