import { net_api_url } from 'components/Store';
import axios from 'axios';
const deleteInvitation = async (getAccessToken, source, code) => {
  try {
    let token = await getAccessToken();
    let deleteCall = await axios.delete(`${net_api_url}/api/team/invitation/${code}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    });
    return deleteCall.data;
  } catch (error) {
    console.error(error);
  }
};

export default deleteInvitation;
