import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import createNotification from 'components/Settings/Utilities/CreateNotification';

const useAdminNewsletterSubscriptions = (props) => {
  const { userInformation, setUserInformation, onlySendRelevant, setOnlySendRelevant } = props;
  const { generalApiCall } = useGeneralApiCall();

  const createUpcomingEvent = async (item) => {
    let pathname = '/api/admin/create-newsletter-subscription';
    let method = 'post';
    let requestProperties = {
      recipientUserId: userInformation.id,
      type: item.type,
      onlySendIfContainsRelevantContent: onlySendRelevant,
    };
    let response = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
    if (!!response) {
      setUserInformation({
        ...userInformation,
        newsletterSubscriptions: [...userInformation.newsletterSubscriptions, response],
      });
      createNotification('success', `Email alert has been enabled`);
    }
  };

  const deleteUpcomingEvent = async (item) => {
    let pathname = `/api/admin/delete-newsletter-subscription/${item.id}`;
    let method = 'delete';
    let response = await generalApiCall({ pathname, method, needsAuthentication: true });
    if (!!response) {
      let newUpcomingEvents = [...userInformation.newsletterSubscriptions];
      let position = newUpcomingEvents.findIndex((event) => event.id === item.id);
      newUpcomingEvents.splice(position, 1);
      setUserInformation({ ...userInformation, newsletterSubscriptions: newUpcomingEvents });
      createNotification('success', `Email alert has been paused`);
    }
  };

  const onlySendEmailEventsRelevant = async (e, value) => {
    let el = e.target;
    el.disabled = true;
    setOnlySendRelevant(value);
    let allUpcomingEvents = userInformation.newsletterSubscriptions;
    for (let i = 0; i < allUpcomingEvents.length; i++) {
      let alert = allUpcomingEvents[i];
      if (alert.id) {
        await modifySendEmailEvents(alert.id, value);
      }
    }
    el.disabled = false;
  };

  const modifySendEmailEvents = async (id, value) => {
    let pathname = `/api/admin/update-newsletter-subscription`;
    let method = 'post';
    let requestProperties = {
      id,
      onlySendIfContainsRelevantContent: value,
    };
    await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
  };

  return { adminNewsletterControl: { createUpcomingEvent, deleteUpcomingEvent, onlySendEmailEventsRelevant } };
};

export default useAdminNewsletterSubscriptions;
