import React, { useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { formatGeneralDate } from 'components/Dashboard/helpers/formatGeneralDate';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import sentimentExternalTooltip from './SentimentExternalTooltip';
import { createEmptyMessageCondition } from 'components/Dashboard/Analytics/useCallSentimentOverTime';
import { store } from 'components/Store';
import { EmptyStateRateLimit } from 'components/Dashboard/Analytics/AnalyticsDashboardContainer';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, Filler, Legend);

ChartJS.defaults.font.family = 'Lato';

const SentimentOverTimeChart = React.forwardRef((props, ref) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsRateLimitReached } = state;

  const {
    data,
    dashboard,
    analyticsState,
    setActiveSection,
    clickFunction,
    external,
    heightGraph = 280,
    reportWidget,
    sentimentChangesLeftPadding = 40,
    totalHeight,
  } = props;
  const { labels, values, rawData } = data;
  const { currentView, typeOfChartSentimentChanges } = props?.analyticsState ?? analyticsState;
  const isMobile = useIsMobile();

  const emptyMessage = createEmptyMessageCondition(rawData, values);
  const borderCondition = values?.every((item) => item === 0);
  const subLabelsPlugin = {
    id: 'subLabelsPlugin',
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        chartArea: { left, top, bottom },
      } = chart;
      let positionZero = chart.scales.y.ticks.findIndex((item) => item.value === 0);
      let valueZero = chart.scales.y._gridLineItems[positionZero]?.y1;
      if (top + valueZero >= 1) {
        ctx.save();
        ctx.translate(left - 10, top + valueZero / 2);
        ctx.fillStyle = 'rgba(132, 132, 132, 1)';
        ctx.rotate(-Math.PI / 2);
        ctx.textAlign = 'center';
        ctx.fillText('Positive', 0, 0);
        ctx.restore();
      }

      if (bottom - valueZero > 10) {
        ctx.save();
        ctx.translate(left - 10, valueZero + (bottom - valueZero) / 2);
        ctx.fillStyle = 'rgba(132, 132, 132, 1)';
        ctx.rotate(-Math.PI / 2);
        ctx.textAlign = 'center';
        ctx.fillText('Negative', 0, 0);
        ctx.restore();
      }
    },
  };

  let options = {
    responsive: true,
    layout: {
      padding: {
        left: sentimentChangesLeftPadding,
      },
    },
    plugins: {
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: 'legend-container',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: !external && !reportWidget,
        backgroundColor: 'rgb(244, 239, 237)',
        borderColor: 'rgba(0, 18, 43, 0.3)',
        borderWidth: 1,
        titleColor: 'rgb(0, 18, 43)',
        bodyColor: 'rgb(0, 18, 43)',
        padding: 12,
        titleFont: { size: 14 },
        displayColors: false,
        callbacks: {
          title: function (context) {
            let label = context?.[0]?.label || null;
            if (!!label) {
              return formatGeneralDate(label, false, currentView === 'Month');
            }
          },
          label: function (context) {
            let value = values[context.dataIndex];
            let createWord = () => {
              if (value > 0) {
                return 'Positive';
              } else if (value < 0) {
                return 'Negative';
              } else {
                return 'Neutral';
              }
            };
            return `Net sentiment: ${createWord()}`;
          },
        },
        filter: function (context) {
          return values[context.dataIndex] !== 0;
        },
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          maxTicksLimit: props?.maxTicksLimit ?? (dashboard || isMobile ? 4 : 10),
          callback: function (value) {
            let labelOfTick = this.getLabelForValue(value);
            return formatGeneralDate(labelOfTick, false, currentView === 'Month');
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    onHover: (e) => {
      let points = ref.current.getElementsAtEventForMode(e, 'nearest', { intersect: false }, true);
      if (points.length && setActiveSection) {
        const firstPoint = points[0];
        setActiveSection(firstPoint.index);
      }
    },
    onClick: (e) => {
      if (clickFunction) {
        clickFunction(e);
      }
    },
  };

  if (external && !reportWidget) {
    options = {
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          ...options.plugins.tooltip,
          external: sentimentExternalTooltip,
        },
      },
    };
  }

  if (reportWidget) {
    options = {
      ...options,
      animation: false,
    };
  }

  const colorResolver = (context) => {
    const index = context.dataIndex;
    const value = context.dataset.data[index];
    return value < 0 ? 'rgb(227, 66, 66)' : `rgb(163, 228, 86)`;
  };

  return (
    <>
      {analyticsRateLimitReached ? (
        <EmptyStateRateLimit />
      ) : (
        <div
          style={{ height: totalHeight ? '100%' : `${heightGraph}px` }}
          className={`${emptyMessage ? 'flex-centered justify-content-center' : ''}`}
        >
          {emptyMessage ? (
            <p className='title-h4 px-4 px-lg-0 text-center main-light-text my-0'>
              {analyticsRateLimitReached ? 'Try again soon or sign in for unlimited searches' : 'No data available'}
            </p>
          ) : (
            <>
              {typeOfChartSentimentChanges === 'Line' && (
                <Line
                  ref={ref}
                  options={options}
                  data={{
                    labels,
                    datasets: [
                      {
                        label: 'Positive sentiment',
                        data: values,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        borderWidth: borderCondition ? 0.5 : 0,
                        borderColor: borderCondition ? '#00122B' : 'transparent',
                        backgroundColor: 'rgb(163, 228, 86)',
                        fill: { above: 'rgb(163, 228, 86)', below: 'rgb(227, 66, 66)', target: { value: 0 } },
                      },
                    ],
                  }}
                  plugins={[subLabelsPlugin]}
                />
              )}
              {typeOfChartSentimentChanges === 'Bar' && (
                <Bar
                  ref={ref}
                  options={options}
                  data={{
                    labels,
                    datasets: [
                      {
                        label: 'Positive sentiment',
                        data: values,
                        fill: false,
                        hoverBackgroundColor: colorResolver,
                        borderColor: colorResolver,
                        backgroundColor: colorResolver,
                      },
                    ],
                  }}
                  plugins={[subLabelsPlugin]}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
});

export default SentimentOverTimeChart;
