import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ReferralLinkModal from './ReferralLinkModal';
import removeLocalStorage from './RemoveLocalStorage';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { store } from 'components/Store.js';
import locationSearchDescriptor from 'components/Dashboard/helpers/locationSearchDescriptor';
import parliamentarianUsers from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import normalize from 'components/Dashboard/utilities/normalizeString';
import idsWithoutImages from 'components/Dashboard/KeyUpdates/idsWithoutImages';
import { Portal } from 'react-portal';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import ClientsPicker from './ClientsPicker.js';

const ProfileButton = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, team } = state;
  const { code, subscriptionStatus, enableCrm } = activePlan;
  const location = useLocation();
  const returnUrl = `?returnUrl=${encodeURIComponent(
    `${location?.pathname === '/reports/create' ? '/reports' : `${location.pathname}${locationSearchDescriptor()}${location.hash}`}`
  )}`;

  const [isShowSettingsMenu, setIsShowSettingsMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [showReferralLinkModal, setShowReferralLinkModal] = useState(false);

  useEffect(() => {
    if (Object.keys(activePlan).length > 0) {
      setIsLoading(false);
    }
  }, [activePlan]);

  const { user, logout } = useAuth0();
  const logoutWithRedirect = () => logout({ logoutParams: { returnTo: window.location.origin } });

  const isParliamentarian = parliamentarianUsers(code);
  const { transformTopicOrClientWord, newAgencyPlan } = useTopicOrClientWord();
  const trialCondition = subscriptionStatus === 'InTrial' || subscriptionStatus === 'TrialEnded';
  const profileElement = useRef();
  const { top, left, width, height } = profileElement?.current?.getBoundingClientRect() ?? {};
  const isMobile = useIsMobile();

  return (
    <div className='position-relative' ref={profileElement}>
      {isLoading && (
        <SkeletonTheme color='#f2f2f2'>
          <Skeleton height={33} width={69} style={{ borderRadius: '20px' }} />
        </SkeletonTheme>
      )}
      {!isLoading && (
        <button
          className={`nav-user-profile general-button ${isShowSettingsMenu ? 'active' : ''}`}
          onClick={() => {
            setIsShowSettingsMenu(!isShowSettingsMenu);
          }}
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner'></span>
          </span>
          <span
            className='profile-image'
            style={{
              backgroundImage: `url(${newAgencyPlan && isMobile ? team?.organisationLogo : activePlan?.organisationLogo ?? user.picture})`,
            }}
          />
        </button>
      )}
      {isShowSettingsMenu && (
        <>
          <Portal>
            <div
              className='general-close-background adjustment-of-position'
              onClick={() => setIsShowSettingsMenu(false)}
            />
            <nav
              className='login-navbar py-2 adjustment-of-position'
              style={{
                position: 'fixed',
                top: `${top + height + 10}px`,
                left: `${left + width - 220}px`,
                width: '220px',
              }}
            >
              {parliamentarianUsers(code) && team?.parliamentarianId !== null && (
                <>
                  <a
                    className='settings-button setting-item loginbar-profile-button d-flex'
                    rel='noreferrer'
                    href={`/stakeholders/${team?.parliamentarianId}/${normalize(
                      team?.parliamentarianOfficialName
                    )}?topic-id=all`}
                    target='_blank'
                  >
                    <img
                      className=''
                      alt='stakeholder profile'
                      src={
                        !!team?.parliamentarianImageId && !idsWithoutImages.includes(team?.parliamentarianImageId)
                          ? `https://www.parliamenttoday.com/image.php?imageid=${team?.parliamentarianImageId}&ml=1`
                          : `${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png`
                      }
                    />
                    Public profile
                  </a>
                  <hr className='my-1' />
                </>
              )}

              {newAgencyPlan && (
                <>
                  <div className='d-lg-none navigation-client-picker'>
                    <ClientsPicker
                      dropdownAdditionalClass={'adjustment-of-position'}
                      additionalClass={' client-picker-complete mx-0'}
                    />
                  </div>
                  <Link className='settings-button setting-item d-flex' to={`/settings/clients${returnUrl}`}>
                    <span className='icon-container-clients'>
                      <span className='political-updates-nav-icon icon-clients' />
                    </span>
                    <span>Clients</span>
                  </Link>
                </>
              )}

              <Link className='settings-button setting-item d-flex' to={`/settings/team${returnUrl}`}>
                <span className='icon-container-team'>
                  <span className='political-updates-nav-icon icon-team' />
                </span>
                <span>Team</span>
              </Link>

              {!newAgencyPlan && (
                <Link
                  className='settings-button setting-item d-flex'
                  to={`/settings/${transformTopicOrClientWord({
                    plural: true,
                  })}${returnUrl}`}
                >
                  <span className='icon-container-topics'>
                    <span className='political-updates-nav-icon icon-topics' />
                  </span>
                  <span>{transformTopicOrClientWord({ plural: true, uppercase: true })}</span>
                </Link>
              )}

              <Link className='settings-button setting-item d-flex' to={`/settings/alerts${returnUrl}`}>
                <span className='icon-container-alert-medium'>
                  <span className='political-updates-nav-icon icon-alert-medium' />
                </span>
                <span>Alerts</span>
              </Link>

              <Link className='settings-button setting-item d-flex' to={`/settings/embeddables${returnUrl}`}>
                <span className='icon-container-code'>
                  <span className='political-updates-nav-icon icon-code' />
                </span>
                <span>Embeddables</span>
              </Link>

              <Link className='settings-button setting-item d-flex' to={`/saved-items`}>
                <span className='icon-container-saved-items'>
                  <span className='political-updates-nav-icon icon-saved-items' />
                </span>
                <span>Saved items</span>
              </Link>
              {!isParliamentarian && enableCrm && (
                <>
                  <hr className='my-1' />
                  <Link
                    className='settings-button setting-item d-flex'
                    to={`/settings/sender-email-addresses${returnUrl}`}
                  >
                    <span className='icon-container-at-sign'>
                      <span className='political-updates-nav-icon icon-at-sign' />
                    </span>
                    <span>Sending emails</span>
                  </Link>
                  <hr className='my-1' />
                </>
              )}
              <Link className='settings-button setting-item d-flex' to={`/settings/account${returnUrl}`}>
                <span className='icon-container-suitcase'>
                  <span className='political-updates-nav-icon icon-suitcase' />
                </span>
                <span>Account</span>
              </Link>
              {!isParliamentarian && !trialCondition && !newAgencyPlan && (
                <Link className='settings-button setting-item d-flex' to={`/settings/billing${returnUrl}`}>
                  <span className='icon-container-billing'>
                    <span className='political-updates-nav-icon icon-billing' />
                  </span>
                  <span>Billing</span>
                </Link>
              )}
              <hr className='my-1' />
              <a
                className='settings-button setting-item d-flex'
                href='https://support.policymogul.com'
                onClick={(e) => {
                  e.preventDefault();
                  window.Intercom('startSurvey', 24505456);
                }}
              >
                <span className='icon-container-edit-pencil'>
                  <span className='political-updates-nav-icon icon-edit-pencil' />
                </span>
                <span>Suggest a feature</span>
              </a>
              <a
                className='settings-button setting-item d-flex'
                rel='noreferrer'
                href='https://support.policymogul.com'
                target='_blank'
              >
                <span className='icon-container-question-circle'>
                  <span className='political-updates-nav-icon icon-question-circle' />
                </span>
                <span>Get help</span>
              </a>
              {isParliamentarian ? (
                <button
                  className={`settings-button setting-item d-flex btn btn-default btn-refer ${
                    trialCondition ? '' : 'btn-refer-subscribed'
                  }`}
                  onClick={() => {
                    setIsShowSettingsMenu(false);
                    setShowReferralLinkModal(true);
                  }}
                >
                  <span className='icon-container-logout'>
                    <span className='political-updates-nav-icon icon-share' />
                  </span>
                  <span>Share PolicyMogul</span>
                </button>
              ) : (
                <button
                  className={`settings-button setting-item d-flex btn btn-default btn-refer ${
                    trialCondition ? '' : 'btn-refer-subscribed'
                  }`}
                  onClick={() => {
                    setIsShowSettingsMenu(false);
                    setShowReferralLinkModal(true);
                  }}
                >
                  <span className='icon-container-logout'>
                    <span className='political-updates-nav-icon icon-present' />
                  </span>
                  <span>Refer and earn £300</span>
                </button>
              )}
              {trialCondition && !newAgencyPlan && (
                <Link className='settings-button setting-item d-flex text-light-blue' to={`/pricing${returnUrl}`}>
                  <span className='icon-container-billing'>
                    <span className='political-updates-nav-icon icon-billing' />
                  </span>
                  <span>Choose your plan</span>
                </Link>
              )}
              <hr className='my-1' />
              <button
                className='settings-button setting-item d-flex btn btn-default btn-logout'
                onClick={() => {
                  removeLocalStorage();
                  logoutWithRedirect();
                }}
              >
                <span className='icon-container-logout'>
                  <span className='political-updates-nav-icon icon-logout' />
                </span>
                <span>Logout</span>
              </button>
            </nav>
          </Portal>
        </>
      )}
      <Portal>
        <ReferralLinkModal
          setShowPopup={setShowReferralLinkModal}
          isParliamentarian={isParliamentarian}
          showPopUp={showReferralLinkModal}
        />
      </Portal>
    </div>
  );
};

export default ProfileButton;
