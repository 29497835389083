import { useContext, useEffect, useRef, useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import { store } from 'components/Store';
import { useFeedbackItemCalls } from 'components/Common/ThumbsDown';
import { createSectionName } from 'components/Dashboard/helpers/controlActiveFilters';
import DropdownComponent from 'components/Settings/WebsiteFeeds/DropdownComponent';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import normalize from 'components/Dashboard/utilities/normalizeString';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { useNavigate } from 'react-router-dom';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const FeedbackItemPopup = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { feedbackItemPopup, team, contentResults, activeResults } = state;

  const sectionName = createSectionName();
  const section = contentResults[activeResults][sectionName];
  const navigate = useNavigate();

  const { isOpen, item, isHandPicked, keywords, index, feedback, contentPage, updateFeedback, firstTime } =
    feedbackItemPopup;
  const { myself } = team;
  const { addFeedback } = useFeedbackItemCalls();

  const [loadingStates, setLoadingStates] = useState({ undo: false, confirm: false });
  const [optionSelected, setOptionSelected] = useState('InterestsChanged');
  const [localKeywords, setLocalKeywords] = useState([]);
  const [modifyKeywordListsBasedOnFeedback, setModifyKeywordListsBasedOnFeedback] = useState(false);
  const [freeText, setFreeText] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const keywordsSelected = localKeywords.length > 0;

  const setIsOpen = (value, reasonAdded) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'feedbackItemPopup',
      value: {
        isOpen: value,
        isHandPicked: false,
        ratingScored: null,
        item: null,
        index: -1,
        keywords: [],
      },
    });
    setLocalKeywords([]);
    setLoadingStates({ undo: false, confirm: false });
    setOptionSelected('InterestsChanged');
    setModifyKeywordListsBasedOnFeedback(false);
    setFreeText('');
    if (firstTime && !reasonAdded) {
      const { objectID, parentObjectID } = item;
      addFeedback({ objectId: parentObjectID ?? objectID, ratingReason: 'NoReasonProvided' });
    }
  };

  const addFeedbackReason = async () => {
    try {
      const { objectID, parentObjectID } = item;
      setErrorMessage(null);
      setLoadingStates({ ...loadingStates, confirm: true });
      let ratingReason = isHandPicked ? null : optionSelected;
      let comments = freeText;
      if (!isHandPicked) {
        if (optionSelected === 'InterestsChanged') {
          comments = localKeywords.join(',');
          if (!keywordsSelected) {
            setLoadingStates({ ...loadingStates, confirm: false });
            return createNotification('danger', 'Please select at least one keyword');
          }
        } else if (optionSelected === 'IrrelevantKeywordMatch') {
          comments = keywords.join(',');
        }
      }

      let results = await addFeedback({
        objectId: parentObjectID ?? objectID,
        ratingReason,
        comments,
        modifyKeywordListsBasedOnFeedback,
      });
      if (results?.status === 200) {
        //update state
        let newSection = { ...section };
        if (index !== undefined && index >= 0) {
          newSection.feedItemFlags[index] = {
            ...newSection.feedItemFlags[index],
            ratingReason: optionSelected,
          };
        }
        if (contentPage) {
          if (updateFeedback) {
            //AE: CASE FOR INFLUENCE ITEMS
            updateFeedback({ feedback: { ratingReason: optionSelected, ratingScored: 'Negative' } });
          } else {
            let parameter = sectionName === 'LibraryMaterial' ? 'content' : 'new';
            newSection[parameter] = {
              ...newSection[parameter],
              feedback: { ratingReason: optionSelected, ratingScored: 'Negative' },
            };
          }
        }
        let newContentResults = {
          ...contentResults,
          [activeResults]: {
            [sectionName]: newSection,
          },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: newContentResults });
        const reasonAdded = true;
        setIsOpen(false, reasonAdded);
        const message =
          optionSelected === 'InterestsChanged' && localKeywords.length > 0 && modifyKeywordListsBasedOnFeedback
            ? `${localKeywords.length} keyword${localKeywords.length === 1 ? ' has' : 's have'} been removed from your topics`
            : 'Your feedback has been submitted';
        createNotification('success', message);
        if (modifyKeywordListsBasedOnFeedback && optionSelected === 'InterestsChanged') {
          if (contentPage) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'enforceReloadFeed', value: true });
          } else {
            setTimeout(() => {
              navigate(0);
            }, 300);
          }
        }
      } else {
        setErrorMessage(results?.data);
        setLoadingStates({ ...loadingStates, confirm: false });
      }
    } catch (error) {
      setLoadingStates({ ...loadingStates, confirm: false });
    }
  };

  const changeOption = (option) => {
    setOptionSelected(option);
    setLocalKeywords([]);
    setErrorMessage(null);
  };

  useEffect(() => {
    if (!!feedback) {
      setOptionSelected(feedback.ratingReason ?? 'InterestsChanged');
      setModifyKeywordListsBasedOnFeedback(feedback.modifyKeywordListsBasedOnFeedback ?? false);
      setFreeText(
        (!!feedback?.ratingReason && feedback?.ratingReason === 'SomethingElse') ||
          (!feedback.ratingReason && !!feedback.comments)
          ? feedback?.comments
          : ''
      );

      if (!feedback.modifyKeywordListsBasedOnFeedback && feedback?.ratingReason === 'InterestsChanged') {
        let feedbackKeywords = feedback?.comments?.split(',');
        let newKeywords = feedbackKeywords.filter((item) => keywords.includes(item));
        setLocalKeywords(newKeywords);
      }
    }
  }, [feedback]);

  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} overlayClassName='react-modal-overlay feedback-item-popup'>
      <div className='px-4 px-lg-5 pt-4 rounded-top'>
        <h3 className='title-h5-m-bold mb-1'>
          {isHandPicked
            ? 'This update was hand-curated by our team. Please provide feedback to help us understand why it’s not relevant to you'
            : 'Why is this update irrelevant to you?'}
        </h3>
        <p className='paragraph-p2 light-50-text mb-2' style={{ paddingLeft: '2px' }}>
          Submitting this form will notify our team so we can review your keywords
        </p>
      </div>
      <div className='px-4 pb-4 px-lg-5'>
        {isHandPicked ? (
          <>
            <div className='pb-4'>
              <FreeText freeText={freeText} setFreeText={setFreeText} />
            </div>
          </>
        ) : (
          <>
            <ul className='policy-list px-0 mb-0 pt-4'>
              <li className={`radio-button mb-4`}>
                <input
                  id={'InterestsChanged'}
                  type='radio'
                  checked={optionSelected === 'InterestsChanged'}
                  onChange={() => {
                    changeOption('InterestsChanged');
                  }}
                />
                <label htmlFor={'InterestsChanged'}>
                  Our {agencyUser || newAgencyPlan ? `clients' ` : ''}interests have changed / keyword is no longer
                  relevant
                </label>

                <div className='mt-3 feedback-item-option-container'>
                  <DropdownComponent
                    mainItem={() => (
                      <span className='hidden-lines hidden-one-line w-100'>
                        {keywordsSelected ? (
                          localKeywords.join(', ')
                        ) : (
                          <span className='light-50-text'>{'Select which keywords are no longer relevant'}</span>
                        )}
                      </span>
                    )}
                    notCloseOnSelect
                  >
                    <FeedbackKeywords
                      key={`id-${item?.objectID ?? 'item'}`}
                      keywords={keywords}
                      localKeywords={localKeywords}
                      setLocalKeywords={setLocalKeywords}
                      setOptionSelected={setOptionSelected}
                    />
                  </DropdownComponent>
                </div>
              </li>

              <li className={`radio-button mb-4`}>
                <input
                  id={'IrrelevantKeywordMatch'}
                  type='radio'
                  checked={optionSelected === 'IrrelevantKeywordMatch'}
                  onChange={() => {
                    changeOption('IrrelevantKeywordMatch');
                  }}
                />
                <label htmlFor={'IrrelevantKeywordMatch'}>
                  Keyword(s) are appearing in a context that lacks relevance or usefulness
                </label>
                {optionSelected === 'IrrelevantKeywordMatch' && (
                  <div className='feedback-item-option-container'>
                    <p className='rounded border px-3 py-2 text-red-warning my-3'>
                      Once you confirm, our team may make changes to your keywords or to our algorithms behind the
                      scenes. We’ll be in touch to confirm any changes.
                    </p>
                  </div>
                )}
              </li>

              <li className={`radio-button mb-4`}>
                <input
                  id={'SomethingElse'}
                  type='radio'
                  checked={optionSelected === 'SomethingElse'}
                  onChange={() => {
                    changeOption('SomethingElse');
                  }}
                />
                <label htmlFor={'SomethingElse'}>Something else</label>
                {optionSelected === 'SomethingElse' && (
                  <div className='feedback-item-option-container'>
                    <FreeText freeText={freeText} setFreeText={setFreeText} />
                  </div>
                )}
              </li>
            </ul>
            {localKeywords.length > 0 && optionSelected === 'InterestsChanged' && myself?.role !== 'Member' && (
              <div className='mb-4'>
                <div className='py-1 px-3 rounded bg-main-blue text-white feedback-popup-remove-check'>
                  <div className='checkbox-item'>
                    <input
                      type='checkbox'
                      id='remove-check'
                      checked={modifyKeywordListsBasedOnFeedback}
                      onChange={(e) => {
                        setModifyKeywordListsBasedOnFeedback(e.target.checked);
                      }}
                    />
                    <label className='mb-0' htmlFor='remove-check'>
                      Click here to remove{' '}
                      {localKeywords.length > 1 ? `these ${localKeywords.length} keywords` : 'this keyword'} from your{' '}
                      {agencyUser ? `clients' interests` : 'topics'}. Note that this will impact all members of your
                      team.
                    </label>
                  </div>
                </div>
                {!!errorMessage && <p className='text-red-warning py-3 mb-0'>{errorMessage}</p>}
              </div>
            )}
          </>
        )}
        <div className='d-flex justify-content-end'>
          <button
            className='general-button outline-active-button px-4 py-2 mr-3'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </button>
          <button className='general-button action-button px-4 py-2' onClick={() => addFeedbackReason()}>
            {loadingStates?.confirm ? <i className='fas fa-spinner fa-spin d-inline-block mr-2' /> : ''}Confirm
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

const FeedbackKeywords = (props) => {
  const { localKeywords, setLocalKeywords, keywords, setOptionSelected } = props;

  const listsContainer = useRef(null);
  const referenceHeight = 230;
  const elements = useRef([]);
  const [height, setHeight] = useState(referenceHeight);

  const calculateMaxHeightContainer = () => {
    if (elements.current?.length > 0) {
      let height = 9; //padding-top of box
      for (let i = 0; i < elements.current.length; i++) {
        let el = elements.current[i];
        let currentHeight = height + el?.getBoundingClientRect()?.height + (i === 0 ? 32 : 16); //margin value
        height = currentHeight;
      }
      if (height < referenceHeight) {
        setHeight(height);
      }
    }
  };

  useEffect(() => {
    calculateMaxHeightContainer();
  }, [keywords]);

  return (
    <CustomScrollbar
      className={'main-content-scrollbar main-content-section website-list-scroll-component'}
      style={{ height: `${height}px` }}
      maximalThumbYSize={100}
    >
      <div
        className='website-popup-container-lists large-container'
        style={{ paddingBottom: '1px' }}
        ref={listsContainer}
      >
        {keywords?.map((keyword, index) => {
          return (
            <div
              ref={(div) => (elements.current[index] = div)}
              className={`checkbox-item`}
              key={`keyword-item-${index}`}
            >
              <input
                id={`${normalize(keyword)}-${index}`}
                onChange={() => {
                  let newItems = [...localKeywords];
                  let itemPosition = newItems.indexOf(keyword);
                  if (itemPosition >= 0) {
                    newItems.splice(itemPosition, 1);
                    setLocalKeywords(newItems);
                  } else {
                    setLocalKeywords([...newItems, keyword]);
                  }
                  setOptionSelected('InterestsChanged');
                }}
                type={'checkbox'}
                checked={localKeywords.includes(keyword)}
              />
              <label
                htmlFor={`${normalize(keyword)}-${index}`}
                className={`create-email-alert-label font-weight-normal`}
              >
                <p className='mb-0'>{keyword}</p>
              </label>
            </div>
          );
        })}
      </div>
    </CustomScrollbar>
  );
};

const FreeText = (props) => {
  const { freeText, setFreeText } = props;

  const autoFocusElement = useRef();
  const freeTextElement = useRef();

  autoFocusElement.current = () => {
    let el = freeTextElement.current;
    if (el) {
      setTimeout(() => {
        let textareaLength = el.value.length;
        el.setSelectionRange(textareaLength, textareaLength);
        el.focus();
      }, 1);
    }
  };

  useEffect(() => {
    autoFocusElement.current();
  }, []);

  return (
    <textarea
      ref={freeTextElement}
      value={freeText}
      onChange={(e) => setFreeText(e.target.value)}
      className='form-control main-input notes-textarea my-3 p-3'
      rows={4}
      placeholder='Please enter feedback for our team to review'
    />
  );
};

export default FeedbackItemPopup;
