import { useRef, useState } from 'react';
import TagsInput from 'react-tagsinput';
import handleChangeKeywords from 'components/Settings/Topics/Helpers/handeChangeKeywords';
import KeywordsSuggestions from 'components/Settings/Topics/KeywordsSuggestions';

const AddKeywordsComponent = (props) => {
  const { visibilityOptions, setShowSuggestion, showSuggestion, itemInformation } = props;
  const { keywords } = visibilityOptions;
  const { value, setValue } = keywords;
  const { title } = itemInformation ?? {};
  const [focusElement, setFocusElement] = useState(false);
  const tagsContainer = useRef();
  const inputRef = useRef();
  const handleChange = (newKeywords, changed, changedIndexes) => {
    //SEPARATED BY COMMAS
    const trimmedKeywords = handleChangeKeywords({ newKeywords, changed, changedIndexes });
    setValue(trimmedKeywords);
  };

  const defaultRenderTag = (props) => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    return (
      <span
        key={key}
        {...other}
        style={{
          paddingRight: '25px',
        }}
      >
        {getTagDisplayValue(tag)}
        <button className={`${classNameRemove} general-button`} onClick={(e) => onRemove(key)} />
      </span>
    );
  };

  const defaultRenderLayout = (tagComponents, inputComponent) => {
    return (
      <>
        <div className='d-flex flex-wrap align-items-center'>
          {tagComponents}
          <span className='flex-grow-1'>{inputComponent}</span>
        </div>
      </>
    );
  };

  return (
    <div
      className={`visibility-options-keywords pb-2 px-2 rounded ${focusElement ? 'visibility-options-keywords-focus' : ''} ${showSuggestion ? '' : 'pt-2'}`}
      onClick={() => {
        inputRef.current.focus();
      }}
    >
      {showSuggestion && (
        <div style={{}} className='adimn-keywords-suggestions border-top mb-2'>
          <KeywordsSuggestions
            topicName={title}
            onSelect={(keyword) => {
              handleChange([...value, keyword], [keyword], [value.length]);
              tagsContainer.current?.div?.click();
            }}
            setShowSuggestion={setShowSuggestion}
          />
        </div>
      )}
      <TagsInput
        ref={tagsContainer}
        onlyUnique={true}
        value={value}
        onChange={handleChange}
        renderTag={defaultRenderTag}
        renderLayout={defaultRenderLayout}
        inputProps={{
          placeholder: value.length > 0 ? '+ Add more' : 'Start typing...',
          ref: inputRef,
          style: { width: '100%' },
          autoFocus: true,
          onFocus: () => {
            setFocusElement(true);
          },
          onBlur: (e) => {
            setFocusElement(false);
            if (e.target.value !== '') {
              tagsContainer.current.accept();
            }
          },
        }}
      />
    </div>
  );
};

export default AddKeywordsComponent;
