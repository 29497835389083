import React, { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import CustomScrollbar from 'components/Common/CustomScrollbar';

function EmailRecipientsSkeleton() {
  const [heightContainer, containerRef] = useHeightContainer();
  const scrollBarRef = useRef(null);

  return (
    <div className='email-performance-section-container email-recipients-body recipients-container'>
      <div className='email-recipients-table-header email-recipients-table-row'>
        {[...Array(2).keys()].map((item) => (
          <div className='email-recipients-table-cell' key={item}>
            <Skeleton height={20} width={68} />
            <Skeleton
              height={20}
              width={8}
              style={{
                marginLeft: '20px',
                backgroundColor: 'rgba(194, 194, 194, 0.7)',
              }}
            />
          </div>
        ))}
      </div>
      <>
        <div ref={containerRef} />
        <CustomScrollbar
          className={`main-content-scrollbar stakeholder-list-scrollbar`}
          style={{ height: `${heightContainer - 58}px` }}
          maximalThumbYSize={100}
          ref={scrollBarRef}
        >
          {[...Array(7).keys()].map((item) => (
            <div className='email-recipients-table-row email-recipients-regular-row' key={item}>
              <div className='email-recipients-regular-row-first-cell'>
                <div className='recipients-row-arrow' style={{ borderLeft: '9px solid rgb(194 194 194)' }}></div>
                <Skeleton height={30} width={30} circle />
                <div>
                  <Skeleton height={16} width={130} />
                </div>
              </div>
              <div className='email-recipients-regular-row-second-cell'>
                <Skeleton height={20} width={85} />
              </div>
            </div>
          ))}
        </CustomScrollbar>
      </>
    </div>
  );
}

export default EmailRecipientsSkeleton;
