import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { store } from 'components/Store';
import arraysEqual from 'components/Dashboard/helpers/arrayEqual';
import getUrlParam, { getUrlParamRaw } from 'components/Dashboard/utilities/getUrlParam';
import { replaceCharacter } from 'components/Dashboard/SearchControl/SearchResults';
import returnParamValue from 'components/Dashboard/helpers/returnParamValue';
import setParamsCode from 'components/Dashboard/MainSearchBar/setParamsCode';
import { useLocation, useNavigate } from 'react-router-dom';

const useEditReferenceState = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { referenceState, keywordsLists, search, activePlan } = state;

  const { code } = activePlan;
  const { activeReference, topic } = referenceState;
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const rawParliamentaryRecordWidgetSection = location.pathname.match(
    /\/parliamentary-record\/(hansard-content)|(scottish-official-report)|(welsh-official-report)|(senedd-committee-transcript)\/\d+\//i
  )?.input;
  const parliamentaryRecordWidgetSection =
    rawParliamentaryRecordWidgetSection &&
    (getUrlParam('topic-id') || getUrlParam('search') || getUrlParam('or')) &&
    isAuthenticated;

  const editState = () => {
    if (parliamentaryRecordWidgetSection) {
      return getUrlParam('topic-id');
    } else if (activeReference) {
      return (
        topic ||
        keywordsLists.find(
          (item) =>
            arraysEqual(item.keywords, referenceState.keywords) &&
            arraysEqual(item.andKeywords, referenceState.andKeywords) &&
            arraysEqual(item.keywordExclusions, referenceState.keywordExclusions)
        )
      );
    } else {
      return true;
    }
  };

  const researchFunction = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    dispatch({ type: 'MODIFY_SECTION', parameter: 'unseenNumbersCurrentList', value: {} });
    let searchObject = {
      ...search,
      query: '',
      activeSearch: false,
      list: null,
      searchState: true,
    };
    if (getUrlParamRaw('search')) {
      searchObject = { ...searchObject, activeSearch: true, query: decodeURIComponent(getUrlParamRaw('search')) };

      let keywordsValue = returnParamValue('search').filter(
        (item) => item.startsWith('"') || item.startsWith(`'`) || item.trim().split(' ').length === 1
      );
      keywordsValue = keywordsValue.map((item) => {
        item = replaceCharacter({ item, character: '"' });
        item = replaceCharacter({ item, character: `'` });
        return item;
      });

      urlParams.delete('or');
      urlParams.delete('and');
      urlParams.delete('not');
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'referenceState',
        value: { ...referenceState, keywords: keywordsValue },
      });
    }
    dispatch({ type: 'MODIFY_SECTION', parameter: 'search', value: searchObject });
    let searchValue = returnParamValue('or');
    let andKeywords = returnParamValue('and');
    let keywordExclusions = returnParamValue('not');
    if (searchValue.length > 0 && !getUrlParamRaw('search')) {
      let researchObject = {
        activeReference: true,
        keywords: searchValue,
        andKeywords: andKeywords,
        keywordExclusions: keywordExclusions,
      };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'referenceState', value: researchObject });
    }
    //AE:Necessary in the case we have an URL with a topic-id, althought it shouldn't have any URL with this parameter
    if (urlParams.get('topic-id')) {
      urlParams.delete('topic-id');
    }
    let values = Array.from(urlParams.values());
    let keys = Array.from(urlParams.keys());
    let searchQuery = '';
    keys.forEach((item, index) => {
      searchQuery += `${searchQuery !== '' ? '&' : ''}${item}=${encodeURIComponent(values[index])}`;
    });

    if (isAuthenticated) {
      setParamsCode({ urlParams, code });
    }
    navigate(
      {
        search: !!searchQuery ? `?${searchQuery}` : '',
        hash: location.hash,
      },
      { replace: true }
    );
  };

  return { editState, parliamentaryRecordWidgetSection, rawParliamentaryRecordWidgetSection, researchFunction };
};

export default useEditReferenceState;
