import ConfigData from 'config/ConfigData';

const MembershipOfAPPGsFilters = [
  {
    name: 'APPGs',
    pages: ['InfluenceContacts', 'Stakeholders'],
    type: 'membership',
    subtype: 'MembershipOfAPPGs',
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'allPartyGroups',
    options: [],
    prefix: 'appg',
    optionSelected: '',
    configHidden: ConfigData?.filters?.allPartyGroups?.configHidden,
  },
];

export default MembershipOfAPPGsFilters;
