import ModalComponent from 'components/Common/Modal';
import React, { useState } from 'react';
import { Portal } from 'react-portal';

const DeleteUserPopupContent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setShowPopup, deleteUser } = props;
  return (
    <div className='px-lg-5 px-3 py-5 text-center'>
      <h3 className='topic-title topic-title-email-alerts mb-0'>Are you sure you want to delete this user?</h3>
      <div className='pt-4'>
        <button
          onClick={() => setShowPopup(false)}
          className='border border-grey rounded px-3 py-2 mr-3'
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            setIsLoading(true);
            await deleteUser();
            setIsLoading(false);
            setShowPopup(false);
          }}
          className='general-button topic-delete-button px-3 py-2 border-general border-red-20'
          disabled={isLoading}
        >
          {isLoading && (
            <i
              className='fas fa-spinner fa-spin'
              style={{
                marginTop: '2px',
                width: '33.7px',
              }}
            />
          )}
          Delete user
        </button>
      </div>
    </div>
  );
};

const DeleteUserPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <Portal>
      <ModalComponent maxWidth={500} isOpen={showPopUp} setIsOpen={setShowPopup}>
        <DeleteUserPopupContent {...props} />
      </ModalComponent>
    </Portal>
  );
};

export default DeleteUserPopup;
