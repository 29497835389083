import getAppSite from 'utils/getAppSite';

const changeOrganisationLabel = (organisationType) => {
  switch (organisationType) {
    case 'Civil servant':
      return 'Office or department name';
    case 'Parliamentary or political staff':
      return 'Office or department name';
    case 'Local authority':
      return 'Local authority name';
    case 'Parliamentarian':
    case 'Campaigner':
      return null;
    case 'Other':
      return 'Role';
    default:
      return `${getAppSite() === 'usa' ? 'Organization' : 'Organisation'} name`;
  }
};

export default changeOrganisationLabel;
