import { WidgetEmptyMessage } from 'components/Reports/ui/MostInterestedStakeholder';
import GraphItem from 'components/Dashboard/Analytics/AnalyticsDashboardGraphItem';
import { mentionsGraphNations } from 'components/Dashboard/Analytics/createAnalyticsState';
import { useEffect, useState, useContext } from 'react';
import CountryPicker from 'components/Dashboard/Analytics/MentionsCountryPicker';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from 'components/Store';
import { stakeholderUrlImage } from 'components/Dashboard/Analytics/useCallMostRelevantStakeholders';
import DropdownComponent from 'components/Settings/WebsiteFeeds/DropdownComponent';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useAllowRightPanel } from 'components/Dashboard/Analytics/ui/MentionsByTopic/ChartDataTable';
import { EmptyStateRateLimit } from 'components/Dashboard/Analytics/AnalyticsDashboardContainer';
import { useLocation } from 'react-router-dom';
import getAppSite from 'utils/getAppSite';

const VocalWidgetContainer = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { highlightVocalState } = state;

  const { scrollBarPosition, topic, search, teamId, isFromPopup } = props;
  const [selectedCountries, setSelectedCountries] = useState(mentionsGraphNations);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (highlightVocalState) {
      setTimeout(() => {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'highlightVocalState',
          value: false,
        });
      }, 3000);
    }
  }, [highlightVocalState]);

  const location = useLocation();
  const conditionToShowText = isAuthenticated || location.pathname.startsWith('/embed-analytics');

  return (
    <div
      className={`h-100 d-block px-4 pb-4 box-rounded box-outstanding position-relative most-vocal-main-container ${highlightVocalState ? 'active' : ''}`}
    >
      <div className='flex-centered justify-content-between mb-2'>
        <h3 className='title-h5-m-bold position-relative z-max mb-0 nowrap-item'>
          {conditionToShowText ? 'Most vocal' : 'Key policymakers'}{' '}
          {!isFromPopup && conditionToShowText && (
            <span className='d-none d-xl-inline-block font-weight-bold'>policymakers</span>
          )}
        </h3>
        {getAppSite() === 'global' && (
          <>
            {conditionToShowText ? (
              <CountryPicker
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                scrollBarPosition={scrollBarPosition}
                embedAnalyticsPopup={true}
              />
            ) : (
              <LoggedOutOptions scrollBarPosition={scrollBarPosition} />
            )}
          </>
        )}
      </div>
      <GraphItem
        search={search}
        topic={topic}
        teamId={teamId}
        chartType={'Most vocal policymakers'}
        selectedCountriesProps={selectedCountries}
      />
    </div>
  );
};

const LoggedOutOptions = (props) => {
  const { scrollBarPosition } = props;
  const options = ['Most vocal', 'Most influential', 'Most positive', 'Most negative'];
  const { showPanelWithTitle } = useAllowRightPanel();

  return (
    <div style={{ minWidth: '200px' }}>
      <DropdownComponent
        fixedDropdown={true}
        mainItem={() => <span className='hidden-lines hidden-one-line w-100'>Most vocal</span>}
        notCloseOnSelect
        scrollBarPosition={scrollBarPosition}
      >
        <div className='keyword-lists-website-feeds px-3 py-2'>
          <div className='website-popup-container-lists large-container' style={{ paddingBottom: '1px' }}>
            {options.map((item) => {
              const name = item;
              return (
                <div
                  className={`checkbox-item ${name !== 'Most vocal' ? 'checkbox-item-disabled' : ''}`}
                  key={`topicItem-${name}`}
                >
                  <input
                    id={`${normalize(name)}`}
                    onChange={() => {
                      if (name !== 'Most vocal') {
                        showPanelWithTitle(
                          `See who's shaping the debate on ${!!getUrlParam('search') ? decodeURIComponent(getUrlParam('search')) : 'any issue'}`,
                          true
                        );
                      }
                    }}
                    type={'checkbox'}
                    checked={name === 'Most vocal'}
                  />
                  <label htmlFor={`${normalize(name)}`} className='flex-growcreate-email-alert-label w-100'>
                    <p className='mb-0 flex-centered'>
                      <span className='flex-grow-1'>{name}</span>{' '}
                      {name !== 'Most vocal' && <span className='ml-2 icon-padlock' />}
                    </p>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </DropdownComponent>
    </div>
  );
};

const MostVocalWidget = (props) => {
  const { data } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsRateLimitReached } = state;

  return (
    <div className='h-100 most-vocal-widget'>
      <>
        {analyticsRateLimitReached ? (
          <EmptyStateRateLimit />
        ) : (
          <>
            {data?.length === 0 ? (
              <div style={{ height: '280px', marginTop: '13px' }}>
                {analyticsRateLimitReached ? <EmptyStateRateLimit /> : <WidgetEmptyMessage text={'stakeholders'} />}
              </div>
            ) : (
              <ul className={`policy-list px-0 items-list mb-0 pt-2 pb-0`} style={{ minHeight: '289px' }}>
                {data?.map((stakeholder, index) => (
                  <MostVocalItem data={data} item={stakeholder} key={`stakeholder-${index}`} index={index} />
                ))}
              </ul>
            )}
          </>
        )}
      </>
    </div>
  );
};

const MostVocalItem = (props) => {
  const { item } = props;
  const { imageId, title, numberOfContributions, contentUrl, imageUrl } = item;
  const urlImage = stakeholderUrlImage({ imageId, imageUrl });
  const searchParameter = getUrlParam('search');
  const topicParameter = getUrlParam('topic-id');
  const { isAuthenticated } = useAuth0();

  return (
    <div
      className={`position-relative most-vocal-item report-stakeholder-item d-flex flex-column justify-content-center`}
    >
      <a
        href={`${contentUrl}${isAuthenticated && (searchParameter || topicParameter) ? `?${!!searchParameter ? `search=${searchParameter}` : topicParameter ? `topic-id=${topicParameter}` : ''}` : ''}`}
        className={`d-flex align-items-center py-3`}
        target={'_blank'}
        rel='noopener noreferrer'
      >
        <img src={urlImage} alt={`${title}`} />
        <div className='flex-grow-1 report-stakeholder-item-content '>
          <div className={`d-flex mb-1 justify-content-between`}>
            <h4 className={`title-h5-bold mb-0 nowrap-item text-main-blue`}>{title}</h4>
          </div>
          <div className='report-stakeholder-content'>
            <p className='mb-0 paragraph-p2 main-light-text d-flex align-items-start reports-contribution-text'>
              <>
                {numberOfContributions > 0 ? (
                  <>
                    <span>
                      {numberOfContributions} <span className='d-none d-lg-inline-block'>relevant</span> contribution
                      {numberOfContributions > 1 ? 's' : ''} in the last 12 months
                    </span>
                  </>
                ) : (
                  <>
                    <span>No relevant contributions </span>
                  </>
                )}
              </>
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};

export { VocalWidgetContainer };
export default MostVocalWidget;
