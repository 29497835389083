import React, { useState, useRef, useEffect, useContext } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AddKeywordsComponent from 'components/Admin/ui/AdminTopicPage/AddKeywordsComponent';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import useWindowHeight from 'components/Dashboard/utilities/useWindowHeight';
import useModifyReferencePopupItems from 'components/Dashboard/SubNav/useModifyReferencePopupItems';
import useResetReference from 'components/Dashboard/SubNav/useResetReference';
import scrollUp from 'components/Dashboard/helpers/scrollUp';
import useEditReferenceState from 'components/Dashboard/SubNav/useEditReferenceState';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { store } from 'components/Store';

const ReferenceSectionItems = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableAdvancedSearch } = activePlan;
  const { setShowPopup, referenceState, setOpenSearchBar, keywordsSection } = props;
  const { currentKeywordCategory, setValue, applySearch, clearSearch, changeCurrentKeywordCategory } =
    useModifyReferencePopupItems();
  //we can access this property from the parent component to use if it's needed. e.g: a cancel button
  const { activeReference } = referenceState;
  const { parliamentaryRecordWidgetSection } = useEditReferenceState();
  const dispatchSearch = () => {
    applySearch();
    if (parliamentaryRecordWidgetSection || isMobile) {
      setShowPopup(false);
    }
    if (setOpenSearchBar) {
      setOpenSearchBar(false);
    }
  };

  const dispatchClearSearch = () => {
    clearSearch();
    setShowPopup(false);
  };

  const { resetReference } = useResetReference();

  const dispatchResetReference = () => {
    resetReference();
    dispatchClearSearch();
  };

  const TooltipText = (props) => {
    const { text } = props;
    return (
      <span
        className='pricing-feature-more'
        data-tooltip-content={text}
        data-tooltip-id='research-popup'
        data-tooltip-place='right'
        data-tooltip-class-name='pricing-feature-tooltip topic-category-tooltip'
      >
        More information
      </span>
    );
  };

  const scrollBarRef = useRef(null);
  const { windowHeight } = useWindowHeight();

  const focusKeywordsBox = useRef();
  focusKeywordsBox.current = () => {
    setTimeout(() => {
      if (keywordsSection) {
        let element = document.getElementsByClassName(`keywords-category-${keywordsSection}`)[0];
        if (element) {
          element.click();
          if (keywordsSection === 'andKeywords' || keywordsSection === 'keywordExclusions') {
            //AE: native version of the component but doesn't allow to animate
            //scrollBarRef.current.scrollTop(element.offsetTop - 80)
            scrollUp(scrollBarRef.current.view, element.offsetTop - 80);
          }
        }
      }
      //AE: Time while the popup opens
    }, 300);
  };

  useEffect(() => {
    focusKeywordsBox.current();
  }, []);

  const keywordComponentsProperties = {
    currentKeywordCategory,
    setValue,
  };

  const keywordsActionsProperties = {
    currentKeywordCategory,
    changeCurrentKeywordCategory,
  };

  const isMobile = useIsMobile();

  return (
    <div
      style={{ height: `${(windowHeight * 90) / 100}px` }}
      className='d-flex flex-column research-items-container-popup'
    >
      <div className='section-title text-center section-title-research-popup'>
        <h3 className='title-h2-m mb-0'>{isMobile ? 'Advanced search' : 'Search the political landscape'}</h3>
      </div>

      <div className='flex-grow-1'>
        <CustomScrollbar
          className={'simple-scrollbar indented-scrollbar top-spaced-scrollbar research-scrollbar'}
          maximalThumbYSize={100}
          ref={scrollBarRef}
        >
          <div className='topic-items-container py-4 py-lg-5 research-topic-boxes'>
            <div className='padding-topic-component'>
              <div className='flex-centered justify-content-between mb-3 keyword-rule-title-container'>
                <p className='keyword-rule-title'>
                  <span>
                    <strong>Show updates containing any of these keywords or phrases</strong>{' '}
                  </span>
                  <TooltipText
                    text={
                      'Make sure you want at least one of these words or phrases to appear in each piece of content'
                    }
                  />
                </p>
                <KeywordsActions {...keywordsActionsProperties} parameter={'keywords'} />
              </div>
              <AddKeywordsComponent {...keywordComponentsProperties} parameter={'keywords'} />
            </div>

            <div className='padding-topic-component pt-4'>
              <div className='flex-centered justify-content-between mb-3 keyword-rule-title-container'>
                <p className='keyword-rule-title'>
                  <span>
                    <strong>And also</strong> any of these keywords or phrases
                  </span>
                  <TooltipText
                    text={
                      'Make sure you want at least one of these words or phrases to appear in each piece of content, in addition to at least one keyword from the previous section.'
                    }
                  />
                </p>
                <KeywordsActions {...keywordsActionsProperties} parameter={'andKeywords'} />
              </div>

              <AddKeywordsComponent {...keywordComponentsProperties} parameter={'andKeywords'} />
            </div>
            <div className='padding-topic-component border-top pt-4 mt-4'>
              <div className='flex-centered justify-content-between mb-3 keyword-rule-title-container'>
                <p className='keyword-rule-title'>
                  <span>
                    <strong>Exclude</strong> updates containing any of these keywords or phrases
                  </span>
                  <TooltipText text={'You will not see content that contains any of these keywords'} />
                </p>
                <KeywordsActions {...keywordsActionsProperties} parameter={'keywordExclusions'} />
              </div>
              <AddKeywordsComponent {...keywordComponentsProperties} parameter={'keywordExclusions'} />
            </div>
          </div>
        </CustomScrollbar>
      </div>

      <div className={`py-3 text-right padding-topic-component border-top flex-centered justify-content-end bg-salmon`}>
        {activeReference && (
          <button
            className='general-button action-button action-delete-button px-3 px-lg-4 py-2 mr-3'
            onClick={() => {
              dispatchResetReference();
            }}
          >
            Clear search
          </button>
        )}
        <button
          className='general-button action-button px-3 px-lg-5 py-2'
          disabled={currentKeywordCategory.keywords.length === 0 || !enableAdvancedSearch}
          data-tooltip-class-name='header-tooltip tooltip-width'
          data-tooltip-content={!enableAdvancedSearch ? 'Advanced search is available on Pro, Mogul or Enterprise' : ''}
          data-tooltip-id='general-tooltip'
          onClick={() => {
            if (currentKeywordCategory.keywords.length === 0) {
              dispatchResetReference();
            } else {
              dispatchSearch();
            }
          }}
        >
          {activeReference ? 'Apply changes' : 'Search'}
          {!enableAdvancedSearch && <span class='icon-info ml-2 paragraph-p2'></span>}
        </button>
      </div>
      <ReactTooltip
        effect={'solid'}
        id={'research-popup'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
      />
    </div>
  );
};

const KeywordsActions = (props) => {
  const [openActions, setOpenActions] = useState(false);
  const actionsElement = useRef(null);
  const { parameter, currentKeywordCategory, changeCurrentKeywordCategory } = props;
  const hideActions = () => {
    setOpenActions(false);
  };
  useOutsideElement(actionsElement, hideActions);

  const deleteKeywords = () => {
    changeCurrentKeywordCategory({ property: parameter, value: [] });
    hideActions();
  };

  const copyKeywords = () => {
    let tempInput = document.createElement('input');
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
    tempInput.value = currentKeywordCategory[parameter].join(', ');
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    createNotification('success', `Keywords copied`);
    hideActions();
  };

  return (
    <>
      {currentKeywordCategory[parameter].length > 0 && (
        <div className='position-relative' ref={actionsElement}>
          <button
            onClick={() => {
              setOpenActions(!openActions);
            }}
            className={`indented-button general-button circle-button item-actions-button item-actions-button-horizontal ${openActions ? 'active' : ''}`}
          >
            Open box
          </button>
          {openActions && (
            <div className='box box-shadow py-2 item-actions-box research-item-actions'>
              <button
                className='general-button pointer clear-keywords-button'
                onClick={() => {
                  deleteKeywords();
                }}
              >
                Clear keywords
              </button>
              <button
                className='general-button pointer copy-keywords-button'
                onClick={() => {
                  copyKeywords();
                }}
              >
                Copy keywords
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { ReferenceSectionItems };
