import React, { useEffect } from 'react';
import { ElectionContentBox } from './Election2024';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags';

function LobbyingElection() {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const { addMetaTags } = useAddMetaTags();

  useEffect(() => {
    addMetaTags({
      title: 'Add lobbying material - Election hub - PolicyMogul',
      hash: location.hash,
    });
  }, []);

  return (
    <ElectionContentBox>
      <div className='lobbying-election-content py-3 px-3 px-lg-5'>
        <h1 className='title-h2-bold mt-5'>Have your message heard</h1>
        <p className='title-h4'>
          Influence the political landscape by presenting lobbying and briefing material to candidates
        </p>
        <a
          href={
            isAuthenticated
              ? `/influence/add-content?returnUrl=${encodeURIComponent(location.pathname)}`
              : process.env.REACT_APP_POLICYINFLUENCE_APP_URL
          }
          target='_blank'
          rel='noreferrer'
        >
          <button className='general-button action-button px-4 mt-4'>Add lobbying material</button>
        </a>
      </div>
    </ElectionContentBox>
  );
}

export default LobbyingElection;
