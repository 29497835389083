import React from 'react';
import TweetItem from 'components/Dashboard/SocialMedia/TweetItem';

const RelatedContentItem = (props) => {
  const { selectResource, item, value, searchValue, insertContentLink } = props;
  const { highlights, title, contentUrl, type } = item;
  const createMarkup = (body) => {
    return { __html: body };
  };

  const isTweet = type === 'Tweet';

  return (
    <li className='box-rounded influence-related-content'>
      <div
        className={`related-item-title d-flex align-items-center flex-grow-1 pointer ${value?.findIndex((item) => item?.contentUrl === contentUrl) >= 0 ? 'related-item-title_selected' : ''}`}
        onClick={() => {
          if (insertContentLink) {
            insertContentLink(item);
          } else {
            return [selectResource(item)];
          }
        }}
      >
        <button className={`mr-4 general-button indented-button add-item-button `}>Select</button>
        <div className='flex-grow-1'>
          {isTweet ? (
            <TweetItem item={item} customKeywords={[searchValue]} />
          ) : (
            <>
              <p className='mb-0 influence-general-item-title'>{title}</p>
              {!!highlights && (
                <p
                  className='paragraph-p2 main-light-text my-2 related-content-snippet'
                  dangerouslySetInnerHTML={createMarkup(highlights?.highlightedBodySnippets[0])}
                />
              )}
            </>
          )}
        </div>
      </div>
      {!isTweet && (
        <div className='influence-related-view'>
          <a href={contentUrl} className='view-link' target='_blank' rel='noopener noreferrer'>
            <button className='view-link-button general-button'>View</button>
          </a>
        </div>
      )}
    </li>
  );
};

export default RelatedContentItem;
