import React from 'react';
import ModalComponent from 'components/Common/Modal';

function SalutationModal({ hideSalutationModal, showSalutationModal }) {
  return (
    <ModalComponent maxWidth={597} isOpen={showSalutationModal} setIsOpen={hideSalutationModal}>
      <div className='pt-4'>
        <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3'>Salutation</h3>
        <div className='px-lg-5 px-3'>
          <div className='remove-contacts-wrapper pt-4'>
            <p className='salutation-modal-text'>
              Salutation placeholders are used when sending email campaigns to multiple people, to ensure the
              appropriate salutation is sent to each one.
            </p>
          </div>
        </div>
        <div className='salutation-modal-content-container'>
          <p className='salutation-modal-content-title'>Example</p>
          <div className='salutation-modal-content-img'></div>
        </div>
      </div>
    </ModalComponent>
  );
}

export default SalutationModal;
