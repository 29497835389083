import { useCreateReportsWidgets } from './ui/useCreateReportsWidgets';
import { createDataOptionsMapping } from 'components/Dashboard/Analytics/createAnalyticsState';
import dayjs from 'dayjs';
import getAppSite from 'utils/getAppSite';

const useReportsDefaults = () => {
  const { reportsWidgets } = useCreateReportsWidgets({});
  const getReportsWidgets = ({ layout }) => {
    let widgets = [];
    layout
      .filter((item) => !item.hidden)
      .forEach((widget) => {
        let reportWidget = reportsWidgets.find((item) => item.data.name === widget.name);
        if (reportWidget) {
          widgets.push({ ...reportWidget, columnOffset: widget.columnOffset });
        }
      });
    return widgets.map((item, index) => {
      let widgetDefinition = layout.find((widget) => widget.name === item.data.name)?.definition;
      let definition = widgetDefinition ?? item?.definition;
      return {
        ...item,
        definition: definition,
        id: index,
        title: item.data.name,
        rowSpan: definition?.defaultRowSpan,
        columnSpan: definition?.defaultColumnSpan,
        data: {
          ...item.data,
          state: { ...item.data.state, widgetName: item?.data?.state?.widgetName ?? item.data.name },
        },
      };
    });
  };

  const createMainGraphsTemplate = ({ type }) => {
    const layout = [
      { name: 'Mentions', columnOffset: { 4: 0 } },
      { name: 'Sentiment snapshot', columnOffset: { 4: 2 } },
      {
        name: 'Top MPs and peers',
        columnOffset: { 4: 0 },
        definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 4 },
      },
      {
        name: 'Relevant policymakers',
        columnOffset: { 4: 0 },
        definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 8, defaultColumnSpan: 2 },
      },
      { name: 'Sentiment changes', columnOffset: { 4: 2 } },
      { name: 'Interest by region', columnOffset: { 4: 2 }, hidden: getAppSite() === 'usa' },
    ];
    const initialWidgets = getReportsWidgets({ layout });
    return modifyDateOfGraphs({ widgets: initialWidgets, type });
  };

  const modifyDateOfGraphs = ({ widgets, type, report }) => {
    const dateToReference = report?.updateTo;
    const DateOptionsMapping = createDataOptionsMapping({ date: dateToReference });
    const config = {
      weekly: {
        date: 'Last 7 days',
        view: 'Day',
      },
      monthly: {
        date: 'Last 30 days',
        view: 'Week',
      },
      quarterly: {
        date: 'Last 3 months',
        view: 'Week',
      },
      annual: {
        date: 'Last 12 months',
        view: 'Month',
      },
    };

    return widgets.map((item) => {
      const graphItem = item.data.component === 'Graph';
      const stakeholderItem = item.data.component === 'MostInterestedStakeholders';

      const filterName = config[type]?.date ?? item.data?.state?.dates?.name ?? 'Last 12 months';
      const dateFilter = DateOptionsMapping.find((item) => item.name === filterName);
      let dates = [dateFilter?.filter?.[0], dayjs(dateToReference).startOf('day').format('YYYY-MM-DD')];

      if (graphItem) {
        return {
          ...item,
          data: {
            ...item?.data,
            state: {
              ...item?.data?.state,
              analyticsState: {
                ...item?.data?.state?.analyticsState,
                activeDateInterval: {
                  ...dateFilter,
                  filter: dates,
                },
                currentView: config[type]?.view,
              },
            },
          },
        };
      }
      if (stakeholderItem) {
        return {
          ...item,
          data: {
            ...item?.data,
            state: {
              ...item?.data?.state,
              dates: {
                ...dateFilter,
                filter: dates,
              },
            },
          },
        };
      }
      return item;
    });
  };

  const createCommentaryTemplate = () => {
    const layout = [
      {
        name: 'Your commentary',
        columnOffset: { 4: 0 },
        definition: { minColumnSpan: 2, defaultRowSpan: 2, defaultColumnSpan: 4, prebuild: true },
      },
      {
        name: 'A specific update',
        columnOffset: { 4: 0 },
        definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 4, prebuild: true },
      },
    ];
    return getReportsWidgets({ layout });
  };

  const createMentionsTemplate = () => {
    const layout = [{ name: 'Mentions', columnOffset: { 4: 0 } }];
    return getReportsWidgets({ layout });
  };

  const createReportsDefaults = ({ type }) => {
    switch (type) {
      case 'weekly':
      case 'monthly':
      case 'quarterly':
      case 'annual':
        return createMainGraphsTemplate({ type });
      case 'commentary':
        return createCommentaryTemplate();
      case 'mentions':
        return createMentionsTemplate();
      default:
        return [];
    }
  };
  return { createReportsDefaults, createMainGraphsTemplate, modifyDateOfGraphs };
};

export { useReportsDefaults };
