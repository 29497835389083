import React, { useState, useRef, useEffect } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';

const DropdownComponent = (props) => {
  const {
    items,
    mainItem,
    mainItemClass,
    overlapContent,
    notCloseOnSelect,
    fixedDropdown,
    children,
    disabled,
    nextIcon,
    hideArrowDisabled,
    dropdownClass = '',
    fixedTopSpace = 0,
    scrollBarPosition,
    waitOnOpen = true,
    conditionsToClose = [],
    openState,
    fixedRightSpace,
  } = props;

  const [showDropdown, setShowDropdown] = useState(false);
  const [cutAtBottom, setCutAtBottom] = useState(false);

  const dropdownElement = useRef(null);
  const mainItemElement = useRef(null);
  const optionsElement = useRef();
  const hideDropdown = () => {
    setShowDropdown(false);
  };
  useOutsideElement(dropdownElement, hideDropdown);
  const [dropdownDimentions, setDropdownDimentions] = useState({ top: 0, width: 0 });
  const dropdownElementWidth = dropdownElement?.current?.getBoundingClientRect()?.width;
  const createDimentions = useRef();

  createDimentions.current = () => {
    let node = dropdownElement.current;
    if (node !== null) {
      const optionsHeight = optionsElement?.current?.getBoundingClientRect().height;
      const top = node.getBoundingClientRect().top + node.getBoundingClientRect().height - 2;
      const cutAtBottom = top + optionsHeight > window.innerHeight;
      const distanceToBottom = window.innerHeight - (top + 40);
      setCutAtBottom(cutAtBottom);
      setDropdownDimentions({
        top: cutAtBottom ? node.getBoundingClientRect().top - optionsHeight : top + 1,
        width: node.getBoundingClientRect().width,
        left: node?.getBoundingClientRect()?.left,
        maxHeight: distanceToBottom < 300 ? `${distanceToBottom}px` : 'none',
      });
    }
  };

  useEffect(() => {
    if (!notCloseOnSelect) {
      setShowDropdown(false);
    }
  }, [mainItem, ...conditionsToClose]);

  useEffect(() => {
    //AE: To wait the popup appears and get the correct position
    if (waitOnOpen) {
      setTimeout(() => {
        createDimentions.current();
      }, 300);
    } else {
      createDimentions.current();
    }
    window.addEventListener('resize', createDimentions.current);
    return () => {
      window.removeEventListener('resize', createDimentions.current);
    };
  }, [showDropdown]);

  useEffect(() => {
    if (!!scrollBarPosition || dropdownElementWidth) {
      createDimentions.current();
      setShowDropdown(false);
    }
  }, [scrollBarPosition, dropdownElementWidth]);

  const createStyles = () => {
    if (fixedDropdown) {
      return {
        position: 'fixed',
        top: `${dropdownDimentions.top + fixedTopSpace}px`,
        width: `${dropdownDimentions.width}px`,
        left: `${dropdownDimentions.left + fixedRightSpace}px`,
      };
    } else {
      return { top: `${dropdownElement?.current?.getBoundingClientRect()?.height - 1}px` };
    }
  };

  useState(() => {
    setTimeout(() => {
      if (openState) {
        setShowDropdown(true);
      }
    }, 50);
  }, [openState]);

  return (
    <div className={`position-relative ${showDropdown ? 'dropdown-open' : ''}`} ref={dropdownElement}>
      <button
        className={`general-button d-block w-100 mb-0 hour-select px-3 ${showDropdown ? 'hour-select-opendropdown' : ''} ${cutAtBottom ? 'cut-at-bottom' : ''} ${mainItemClass ?? ''} ${disabled ? 'disabled-hour-select' : ''} ${hideArrowDisabled ? 'hour-select-hidden-arrow' : ''}`}
        ref={mainItemElement}
        disabled={disabled}
        onClick={() => {
          if (!disabled && !hideArrowDisabled) {
            setShowDropdown(!showDropdown);
          }
        }}
      >
        {typeof mainItem === 'function' ? mainItem() : <span className='hidden-lines hidden-one-line'>{mainItem}</span>}
      </button>
      {!!nextIcon && <>{nextIcon()}</>}
      {showDropdown && (
        <div
          className={`frequency-dropdown general-dropdown ${overlapContent ? 'overlap-options' : ''} ${dropdownClass}`}
          style={createStyles()}
          ref={optionsElement}
        >
          {items ? items() : ''}
          {children}
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
