import createNotification from 'components/Settings/Utilities/CreateNotification';
import splitItems from './splitItems';

const countInArray = (array, what) => {
  var count = 0;
  for (var i = 0; i < array.length; i++) {
    if (array[i] === what) {
      count++;
    }
  }
  return count;
};

const handleChangeKeywords = (props) => {
  const { newKeywords, changed, changedIndexes, parameter, currentKeywordCategory, excludeDuplicatedWordsRule } = props;
  //SEPARATED BY COMMAS
  let firstSplit = splitItems(newKeywords, ',');
  let separatedWords = splitItems(firstSplit, ';');
  //AVOID DUPLICATE WORDS IN THE SAME PHRASE SEPARATED BY COMMAS
  let removeDuplicateValues = [];
  separatedWords.forEach((item) => {
    let arrayToCompare = removeDuplicateValues.map((word) => word.trim().toLowerCase());
    if (arrayToCompare.indexOf(item.trim().toLowerCase()) < 0) {
      removeDuplicateValues.push(item);
    }
  });

  //REMOVE WHITE SPACES
  let trimmedKeywords = [];
  removeDuplicateValues.forEach((item) => {
    if (!item.includes('"')) {
      trimmedKeywords.push(item.trim());
    } else {
      trimmedKeywords.push(item);
    }
  });

  //AVOID WORDS IN THE OTHER CATEGORIES
  const avoidCategoryValues = (words) => {
    let categories = ['keywords', 'andKeywords', 'keywordExclusions'];
    categories.forEach((item, index) => {
      if (parameter === item) {
        categories.splice(index, 1);
      }
    });
    let duplicateWords = [];
    words.forEach((word) => {
      categories.forEach((category) => {
        let item = currentKeywordCategory[category];
        item.forEach((secondWord) => {
          if (word.trim().toLowerCase() === secondWord.trim().toLowerCase()) {
            duplicateWords.push(word);
            createNotification(
              'danger',
              'Keyword was removed as it creates a conflict with your existing keyword settings'
            );
          }
        });
      });
    });
    return duplicateWords;
  };

  if (!!currentKeywordCategory) {
    let categoryDuplicateWords = excludeDuplicatedWordsRule ? trimmedKeywords : avoidCategoryValues(trimmedKeywords);
    if (!excludeDuplicatedWordsRule) {
      trimmedKeywords = trimmedKeywords.filter(function (el) {
        return categoryDuplicateWords.indexOf(el) < 0;
      });
    }
  }

  //NOT ALLOW DUPLICATE ITEMS
  if (changedIndexes[0] === trimmedKeywords.length - 1) {
    let item = changed[0];
    let position = trimmedKeywords.indexOf(item);
    let newTags = trimmedKeywords.map((keyword) => keyword.toLowerCase().trim());

    if (countInArray(newTags, item.toLowerCase().trim()) > 1) {
      trimmedKeywords.splice(position, 1);
    }
  }

  return trimmedKeywords;
};

export default handleChangeKeywords;
