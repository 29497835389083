import axios from 'axios';
import { net_api_url } from 'components/Store';
const callCollectList = async (contentType, token, keywordListId, url) => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let requestProperties = {
      eventName: keywordListId === null ? 'MasterTopicView' : 'TopicView',
      contentType: contentType,
      url: `${window.location.origin}${url}`,
    };
    if (keywordListId !== null) {
      requestProperties = { ...requestProperties, keywordListId: keywordListId };
    }

    let contentRelated = await axios.post(`${net_api_url}/api/log/collect`, requestProperties, {
      cancelToken: source.token,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return contentRelated.data;
  } catch (error) {
    console.error(error);
  }
};

export default callCollectList;
