import React, { useState, useRef, useCallback } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';

const DaySelect = (props) => {
  const [showDays, setShowDays] = useState(false);
  const { changeDay, day, allow } = props;
  const dayElement = useRef(null);
  const hideDay = () => {
    setShowDays(false);
  };
  useOutsideElement(dayElement, hideDay);
  const createDays = () => {
    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    let items = [];
    for (let i = 0; i < days.length; i++) {
      items.push(<DayItem key={`day-${i}`} day={days[i]} selectedDay={day} setDay={setDay} />);
    }
    return items;
  };

  let setDay = (day) => {
    changeDay(day);
    setShowDays(false);
  };

  return (
    <div className='position-relative' ref={dayElement}>
      <p
        className={`mb-0 hour-select px-3 ${allow ? '' : 'disabled-hour-select'} ${showDays ? 'hour-select-opendropdown' : ''}`}
        onClick={() => {
          if (allow) {
            setShowDays(!showDays);
          }
        }}
      >
        {day}s
      </p>
      {showDays && (
        <>
          <div className=' frequency-dropdown scroll-container search-bar-lists' id='day-dropdown'>
            {createDays()}
          </div>
        </>
      )}
    </div>
  );
};

const DayItem = (props) => {
  let { day, setDay, selectedDay } = props;
  let scrollDay = useCallback((node) => {
    if (node !== null) {
      if (day === selectedDay) {
        let top = node.getBoundingClientRect().top;
        let element = document.getElementById('day-dropdown');
        let elementTop = element.getBoundingClientRect().top;
        element.scrollTop = top - elementTop;
      }
    }
  }, []);
  return (
    <p
      className={`mb-0 hour-item ${day === selectedDay ? 'hour-item-selected' : ''}`}
      onClick={() => setDay(day)}
      ref={scrollDay}
    >
      {`${day}s`}{' '}
    </p>
  );
};

export default DaySelect;
