import React from 'react';
import InfoTooltip from 'components/Dashboard/Influence/CrmEmails/CrmEmailComponent/SentPerformance/InfoTooltip';

function FrontbenchersCheckBox({
  allowFrontbenchers,
  setAllowFrontbenchers,
  setRightPanelState,
  rightPanelState,
  hide,
}) {
  const handleChange = () => {
    if (setRightPanelState) {
      setRightPanelState({ open: false });
    }
    setAllowFrontbenchers();
  };
  return (
    <>
      {!hide && (
        <div className='chart-map-frontbenchers-checkbox'>
          <div className={`checkbox-input`} onClick={(e) => e.stopPropagation()}>
            <input
              type={'checkbox'}
              onChange={(e) => {
                handleChange(e);
              }}
              checked={allowFrontbenchers}
              name='sendCopy'
              id='sendCopy'
            />
            <label className='inner-label' htmlFor='sendCopy' />
          </div>

          <label
            htmlFor='sendCopy'
            style={
              rightPanelState?.open
                ? {
                    maxWidth: '143px',
                    whiteSpace: 'break-spaces',
                    transform: 'translateY(9px)',
                    lineHeight: '18px',
                  }
                : undefined
            }
          >
            Include Westminster frontbenchers
          </label>
          <div className='info-icon-container'>
            <InfoTooltip
              text={
                'Frontbenchers are excluded by default as they might frequently employ specific language concerning their ministerial duties.'
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FrontbenchersCheckBox;
