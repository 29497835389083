import React, { useContext } from 'react';
import { store } from 'components/Store.js';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import locationSearchDescriptor from 'components/Dashboard/helpers/locationSearchDescriptor.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import LocalStorage from 'utils/LocalStorage.js';
const CreateKeywordListButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, readyKeywordLists } = state;
  const listStoraged = LocalStorage.getItem('keywordList');
  const notHasLists = keywordsLists?.length === 0 && listStoraged === null;
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const { transformTopicOrClientWord, agencyUserBase, agencyUser } = useTopicOrClientWord();
  const sentimentPage =
    location.pathname.toLowerCase() === '/sentiment' || location.pathname.toLowerCase() === '/analytics';

  return (
    <>
      {isAuthenticated && !sentimentPage && (!agencyUserBase || (agencyUserBase && agencyUser)) && (
        <Link
          to={`/settings/${transformTopicOrClientWord({ plural: true })}${notHasLists ? '/new-topic' : ''}?returnUrl=${encodeURIComponent(`${location.pathname}${locationSearchDescriptor()}`)}`}
          className='mr-lg-3 btn-header-intercom-adjustment text-decoration-none'
          style={{ visibility: readyKeywordLists ? 'visible' : 'hidden' }}
          data-intercom-target='Topics top nav button'
        >
          <button className={`btn btn-default btn-set-keywords px-3 pxl-lg-1 px-xl-3 button-my-topics d-flex`}>
            <span className='icon-topics-container'>
              <span className='icon-topics' />
            </span>
            <span className='text'>{transformTopicOrClientWord({ plural: true, uppercase: true })}</span>
          </button>
        </Link>
      )}
    </>
  );
};

export default CreateKeywordListButton;
