import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CalendarEmbed from 'components/embed/CalendarEmbed';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import { store } from 'components/Store';
import ConsultationItem from 'components/Dashboard/ConsultationsSection/consultationItem';
import array_move from 'components/Dashboard/helpers/arrayMove';
import ItemNew from 'components/Dashboard/KeyUpdates/ItemNew';
import SkeletonKeyUpdates, { SkeletonItem } from 'components/Dashboard/KeyUpdates/SkeletonKeyUpdates';
import LegislationItem from 'components/Dashboard/Legislation/LegislationItem';
import LibraryMaterialItem from 'components/Dashboard/LibraryMaterial/LibraryMaterialItem';
import ParliamentaryItem from 'components/Dashboard/ParliamentaryRecord/ParlamentaryItem';
import parliamentarianUsers from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import TweetItem from 'components/Dashboard/SocialMedia/TweetItem';
import CalendarItem from 'components/Dashboard/UpcomingEvents/CalendarItem';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory';
import currentDate from 'components/Dashboard/utilities/currentDateUTC';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import InfluenceItem from './InfluenceListItem';
import { useStepsStructure } from 'components/Dashboard/UserContent/StepsStructure';

const JSONBigInt = require('json-bigint');
const commentatorsTypes = [
  'MP - England',
  'MP - Scotland',
  'MP - Wales',
  'MP - Northern Ireland',
  'Lord',
  'AM',
  'MLA',
  'MSP',
  'Member Of London Assembly',
  'Special Adviser',
];

const PoliticalUpdates = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { code } = activePlan;

  const { createStepsStructure } = useStepsStructure();

  const {
    keywords,
    politicalUpdatesState,
    setPoliticalUpdatesState,
    setThereIsMoreContent,
    objectID,
    id,
    button,
    scrollToTop,
    isLoadingScroll,
    setIsLoadingScroll,
    thereIsMoreContent,
    page,
  } = props;
  const { activeSection, pageNumber } = politicalUpdatesState;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const numberOfResources = 20;
  let Sections = [
    {
      name: 'Key updates',
      type: 'KeyUpdate',
    },
    {
      name: 'Consultations',
      type: 'Consultation',
      filters: [{ field: 'closeDate', value: currentDate, operator: 'DATE_GTE' }],
    },
    {
      name: 'Legislation',
      sort: { field: 'lastImporterUpdatedAtUtc', order: 'desc' },
    },
    {
      name: 'Parliamentary record',
      type: 'ParliamentaryRecord',
      collapseField: 'contentUrl',
      sort: { field: 'lastImporterUpdatedAtUtc', order: 'desc' },
    },
    {
      name: 'Library material',
      type: 'CommonsLibraryArticle',
      icon: 'library-material',
    },
    {
      name: 'Upcoming events',
      type: 'Event',
      icon: 'calendar',
      filters: [{ field: 'eventStartDate', value: currentDate, operator: 'DATE_GTE' }],
      sort: { field: 'dateTime', order: 'ASC' },
    },
    {
      name: 'Tweets',
      type: 'tweet',
      icon: 'twitter',
      filters: commentatorsTypes.map((value) => {
        return { field: 'policyMogulUserType', value, operator: 'str_eq' };
      }),
    },
    {
      name: parliamentarianUsers(code) ? 'Other campaigns' : 'Lobbying material',
      type: 'UserContent',
      icon: 'briefing-material',
      filters: objectID ? [{ field: 'objectID', value: objectID, operator: 'str_not_eq' }] : null,
    },
  ];

  if (parliamentarianUsers(code)) {
    let position = Sections.findIndex((item) => item.name === 'Other campaigns');
    Sections = array_move(Sections, position, 1);
  }

  if (process.env.REACT_APP_ENABLE_TWITTER !== 'true') {
    Sections = Sections.filter((section) => section.name !== 'Tweets');
  }

  const getResults = async ({ activeSection, pageNumber }) => {
    const section = Sections.find((item) => item.name === activeSection);
    const { type, name, filters, collapseField, sort } = section;
    let apiType = type ?? name;
    //AE: CHECK HERE THE TWEETS VALIDATIONS
    let requestHeaders = {
      transformResponse: [(data) => data],
    };

    let requestProperties = {
      type: apiType,
      pageSize: numberOfResources,
      pageNumber,
      includeHighlights: true,
      query: ChangeKeywordsCategory(keywords),
      sort: sort ?? { field: 'dateTime', order: 'desc' },
    };

    if (filters) {
      requestProperties = { ...requestProperties, filters };
    }

    if (collapseField) {
      requestProperties = { ...requestProperties, collapseField };
    }

    let results = await axios.post(
      `${process.env.REACT_APP_DOTNETAPIURL}/api/search/query`,
      requestProperties,
      requestHeaders
    );

    if (results) {
      return JSONBigInt.parse(results.data);
    } else {
      return null;
    }
  };

  const dispatchSearch = useRef();
  dispatchSearch.current = () => {
    if (keywords.length > 0) {
      setIsLoading(true);
      setTimeout(async () => {
        let results = await getResults(politicalUpdatesState);
        let newData = { ...data };
        const { hits, highlights } = newData;
        let newHits = hits ? [...hits, ...results.hits] : results?.hits;
        let newHighlights = highlights ? [...highlights, ...results.highlights] : results?.highlights;
        newHits.forEach((item, index) => {
          if (results?.highlights) {
            item.highlights = newHighlights[index];
          }
        });
        newData = { ...results, hits: newHits, highlights: newHighlights };
        setData(newData);
        setThereIsMoreContent(results?.hits?.length === numberOfResources);
        setIsLoading(false);
        if (isLoadingScroll) {
          setIsLoadingScroll(false);
        }
      }, 300);
    }
  };

  useEffect(() => {
    dispatchSearch.current();
  }, [politicalUpdatesState]);

  const loadingCondition = isLoading && pageNumber === 1 && keywords?.length > 0;
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <div className='d-flex w-100 align-items-start'>
      <ul className='policy-list px-0 political-updates-nav pt-lg-5'>
        {Sections.map((item) => {
          const { type, name, icon } = item;
          const iconClassName = `political-updates-nav-icon icon-${icon ?? normalize(TransformFromPascalCase(type ?? name))} inline-block `;
          return (
            <li
              className={`political-updates-nav-item ${name === activeSection ? 'political-updates-nav-item-active' : ''} transition-colors`}
              key={`side-nav-${type}`}
              onClick={() => {
                scrollToTop();
                setData({});
                setPoliticalUpdatesState({ ...politicalUpdatesState, activeSection: name, pageNumber: 1 });
              }}
            >
              <span className={iconClassName} />
              <span>{name}</span>
            </li>
          );
        })}
      </ul>
      <div className='single-content-width pl-4'>
        <h3 className='title-h5-m-bold political-updates-title pt-4 pt-lg-5 bg-white'>{activeSection}</h3>
        {loadingCondition ? (
          <div className='skeleton-political-updates'>
            <SkeletonKeyUpdates />
          </div>
        ) : (
          <>
            {data?.hits?.length > 0 ? (
              <>
                {activeSection === 'Upcoming events' ? (
                  <>
                    <CalendarEmbed elements={data?.hits} customKeywords={keywords} embedValue={{ value: false }} />
                  </>
                ) : (
                  <ul className='policy-list px-1 all-open-consultations items-list mb-0'>
                    {data?.hits.map((item, index, array) => {
                      const explanations = data?.explanations;
                      const type = item?.type;
                      const props = { index, item, array, explanations, key: `${type}-item-${index}` };

                      switch (type) {
                        case 'Legislation':
                          return <LegislationItem {...props} />;
                        case 'ParliamentaryRecord':
                          return <ParliamentaryItem {...props} showHighlights={true} />;
                        case 'Tweet':
                          return <TweetItem {...props} customKeywords={keywords} />;
                        case 'KeyUpdate':
                          return <ItemNew {...props} />;
                        case 'Consultation':
                          return <ConsultationItem {...props} />;
                        case 'CommonsLibraryArticle':
                          return <LibraryMaterialItem {...props} />;
                        case 'Event':
                          return (
                            <div className={`box-rounded box-list-item pl-4 mt-2`} key={`${type}-item-${index}`}>
                              <div className={`pl-2`}>
                                <CalendarItem {...props} customKeywords={keywords} />
                              </div>
                            </div>
                          );
                        case 'UserContent':
                          return <InfluenceItem {...props} />;
                        default:
                          return '';
                      }
                    })}
                    {thereIsMoreContent && <SkeletonItem />}
                  </ul>
                )}
              </>
            ) : (
              <div
                className='box-top-rounded flex-centered justify-content-center text-center flex-column'
                ref={containerRef}
                style={{ minHeight: `${heightContainer}px` }}
              >
                <p className='title-h4 text-center main-light-text mb-0' style={{ marginTop: '-100px' }}>
                  No{' '}
                  {activeSection === 'Tweets'
                    ? 'Tweets'
                    : `${activeSection.toLowerCase()}${activeSection === 'Parliamentary record' ? 's' : ''}`}{' '}
                  found
                </p>
                {(button === 'Edit' || button === 'Back') && (
                  <div className='mt-4'>
                    <Link
                      to={`/influence/${page}/edit/${id}/${createStepsStructure({})?.findIndex((item) => item.name === 'Details') + 1}/`}
                      className='action-button general-button px-4 inline-block py-2'
                    >
                      Edit keywords
                    </Link>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { commentatorsTypes };
export default PoliticalUpdates;
