import { useNavigate, useParams } from 'react-router-dom';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';

const useDeleteInfuenceItemFunction = () => {
  const { generalApiCall } = useGeneralApiCall();
  const navigate = useNavigate();
  const params = useParams();

  const DeleteInfluenceItemFunction = async (props) => {
    const { itemInformation, source } = props;
    const { page } = params;
    const pathname = `/api/user-generated-content/${page === 'policy-asks' ? 'policy-ask' : page}/${parseInt(itemInformation.id)}`;
    const method = 'delete';
    let results = await generalApiCall({ method, pathname, needsAuthentication: true, requestSource: source });
    if (!!results) {
      createNotification(
        'success',
        `Your ${page === 'policy-asks' ? 'policy ask' : page.replace('-', ' ')} has been deleted successfully`
      );
      return navigate(`/influence/lobbying-material`);
    }
  };

  return { DeleteInfluenceItemFunction };
};

export default useDeleteInfuenceItemFunction;
