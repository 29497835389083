import React, { useState, useRef } from 'react';
import normalize from 'components/Dashboard/utilities/normalizeString';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
const TeamFilters = (props) => {
  const {
    applyFilters,
    activeFilters,
    setIsOpenAddATeamPopup,
    filtersOptions,
    userPage,
    notShowFilters,
    organisation,
  } = props;
  const { search } = activeFilters;
  const [searchValue, setSearchValue] = useState(search);
  const [filterToShow, setFilterToShow] = useState('');
  const filtersElement = useRef(null);
  const hideFilters = () => {
    setFilterToShow('');
  };
  useOutsideElement(filtersElement, hideFilters);
  const userText = () => {
    return userPage ? 'user' : 'account';
  };

  const isMobile = useIsMobile(640);

  return (
    <div className='row mx-0 justify-content-between py-2 py-lg-4 align-items-center team-filters-container'>
      <div className='d-flex  align-items-center' style={{ minHeight: '36px' }}>
        <h3 className='my-lg-0 main-title mb-0 mr-3'>{uppercaseFirstLetter(userText())} list</h3>
      </div>
      {!notShowFilters && (
        <div className={`row mx-0 ${isMobile ? 'mt-2 flex-nowrap' : ''}`}>
          {!userPage && (
            <div className='d-flex simple-search-bar bg-white mr-3 align-items-center'>
              <input
                className='form-control input-empty'
                type='text'
                placeholder={`Search for a ${userText()}`}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    let property = 'search';
                    let value = searchValue;
                    applyFilters({ property, value });
                  }
                }}
              />
              <button
                className='general-button close-icon mr-2'
                style={{ visibility: search !== '' ? 'visible' : 'hidden' }}
                onClick={() => {
                  let property = 'search';
                  let value = '';
                  setSearchValue('');
                  applyFilters({ property, value });
                }}
              ></button>
            </div>
          )}

          {userPage && (
            <div ref={filtersElement} className='d-flex mr-3'>
              {filtersOptions.map((item, index) => {
                const { name, options } = item;
                return (
                  <div className='position-relative filter-main-container' key={`option-${index}`}>
                    <button
                      className={`sort-button ${filterToShow === name ? 'active' : ''}`}
                      onClick={() => {
                        if (filterToShow === name) {
                          setFilterToShow('');
                        } else {
                          setFilterToShow(name);
                        }
                      }}
                    >
                      <span className='filter-name'>{activeFilters[name].name}</span>
                    </button>

                    {filterToShow === name && (
                      <div className={`box filters-container`}>
                        <button
                          onClick={() => {
                            setFilterToShow('');
                          }}
                          className='close-button-popup circle-button general-button hovered-button action-state-hover'
                        >
                          <span className='icon-close paragraph-p5' />
                        </button>

                        <p className='title'>{uppercaseFirstLetter(name)}</p>
                        <div className='filter-options-radio-container'>
                          {options.map((option, indexOption) => (
                            <div className='radio-button mb-2' key={`sort-filter${indexOption}`}>
                              <input
                                id={normalize(option.name)}
                                onChange={() => {
                                  let property = name;
                                  let value = option;
                                  applyFilters({ property, value });
                                }}
                                type='radio'
                                checked={activeFilters[name].name === option.name}
                              />
                              <label htmlFor={normalize(option.name)}>{option.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <button
            className='action-button general-button px-3 filter-action-button nowrap-item'
            onClick={() => {
              if ((organisation?.planCode && !organisation?.planCode?.startsWith('PolicyInfluence')) || !userPage) {
                setIsOpenAddATeamPopup(true);
              }
            }}
            data-tooltip-content={
              organisation?.planCode?.startsWith('PolicyInfluence')
                ? 'Cannot add a new user for influence accounts'
                : undefined
            }
            data-tooltip-id='settings-tooltip'
            data-tooltip-place={'top'}
            disabled={!organisation?.planCode && userPage}
          >
            Add {isMobile ? `${userText()}` : ` new ${userText()}`}
          </button>
        </div>
      )}
    </div>
  );
};

export default TeamFilters;
