import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';

const useCallUpcomingEvents = () => {
  const { generalApiCall } = useGeneralApiCall();
  const callUpcomingEvents = async (source) => {
    let pathname = '/api/newsletter-subscriptions';
    let method = 'get';
    let response = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    return response;
  };

  return { callUpcomingEvents };
};

export default useCallUpcomingEvents;
