import React, { useEffect, useState } from 'react';
import { formatGeneralDate } from 'components/Dashboard/helpers/formatGeneralDate';
import dayjs from 'dayjs';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { OrganisationLogo } from './InfluenceListItem';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import getAppSite from 'utils/getAppSite';

const KeyInformation = React.forwardRef((props, ref) => {
  const { data, currentTab, refs } = props;
  const { relatedContentContainer, attachmentsContainer, overviewContainer, scrollBarContainer } = refs ?? {};
  const {
    relatedContent,
    region,
    expirationDate,
    ownerOrganisation,
    ownerOrganisationWebsite,
    attachments,
    publishedAt,
    websiteLink,
    ownerOrganisationLogo,
    client,
    clientLogo,
    clientKeywordListName,
    type,
  } = data;
  const websiteItem = !!websiteLink ? websiteLink : ownerOrganisationWebsite;
  const [overviewHasScroll, setOverviewHasScroll] = useState(false);
  const isVisibleBox = ref?.current?.getBoundingClientRect()?.height <= scrollBarContainer?.current?.getClientHeight();

  const isBeforeDate = () => {
    return dayjs(expirationDate).isBefore(dayjs());
  };

  useEffect(() => {
    setOverviewHasScroll(overviewContainer?.current?.scrollHeight > scrollBarContainer?.current?.getClientHeight());
  }, [currentTab]);

  const isMobile = useIsMobile();

  const scrollFunction = (ref) => {
    const value = isMobile
      ? ref.current?.offsetTop + scrollBarContainer?.current?.getClientHeight() - 20
      : ref.current?.offsetTop - 20;
    scrollBarContainer?.current?.container?.classList.add('smooth-scrolling');
    scrollBarContainer?.current?.scrollTop(value);
  };

  return (
    <div className={`col-lg-4 pt-4 pt-lg-5 ${isVisibleBox ? 'details-container' : ''}`} ref={ref}>
      <div className='rounded border mb-4 mb-lg-0 '>
        <div className='border-bottom key-information-title flex-centered p-3 details-information rounded-top'>
          <div className='pr-3 flex-grow-1'>
            <p className='font-weight-bold mb-0'>Lead {getAppSite() === 'usa' ? 'organization' : 'organisation'}</p>
            {!!websiteItem || !!client || !!clientKeywordListName ? (
              <div className='details-item flex-centered text-light-blue mb-0'>
                <a
                  href={!/^https?:\/\//i.test(websiteItem) ? `http://${websiteItem}` : websiteItem}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='simple-link-button organisation-name-spacing'
                >
                  {clientKeywordListName ?? client ?? ownerOrganisation}
                </a>
              </div>
            ) : (
              <p className='details-item flex-centered mb-0 organisation-name-spacing'>
                <span>{client ?? ownerOrganisation}</span>
              </p>
            )}
          </div>
          <OrganisationLogo
            imageUrl={clientLogo === null && !!client ? false : clientLogo ?? ownerOrganisationLogo}
            size={120}
            className={'rounded-circle overflow-hidden influence-content-image'}
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div className='p-3'>
          <p className='details-item flex-centered'>
            <span className='details-icon icon-briefing-material' />
            <span>{TransformFromPascalCase(type)}</span>
          </p>
          {!!region && (
            <p className='details-item flex-centered'>
              <span className='details-icon icon-location' />
              <span>{region}</span>
            </p>
          )}
          {publishedAt && (
            <p className='details-item flex-centered'>
              <span className='details-icon icon-calendar text-green-create' />
              <span>Created {formatGeneralDate(publishedAt)}</span>
            </p>
          )}
          {expirationDate && (
            <p className='details-item flex-centered mb-1'>
              <span className='details-icon icon-calendar text-red-dark' />
              <span>
                {isBeforeDate() ? 'Expired' : 'Expires'} {formatGeneralDate(expirationDate)}
              </span>
            </p>
          )}
        </div>
      </div>
      {currentTab === 'Overview' && overviewHasScroll && (
        <>
          {attachments?.length > 0 && (
            <button
              className='w-100 flex-centered text-left details-information general-button rounded px-3 py-2 border mt-3 action-state-hover'
              onClick={() => {
                scrollFunction(attachmentsContainer);
              }}
            >
              <span className='d-inline-block icon-clip key-information-attachments-icon details-icon' />
              <span className='ml-1'>
                {attachments?.length} attachment{attachments?.length === 1 ? '' : 's'}
              </span>
            </button>
          )}
          {relatedContent?.length > 0 && (
            <button
              className='w-100 flex-centered text-left details-information general-button rounded px-3 py-2 border my-3 action-state-hover'
              onClick={() => {
                scrollFunction(relatedContentContainer);
              }}
            >
              <span className='d-inline-block icon-associated-content details-icon' />
              <span>
                {' '}
                {relatedContent?.length} piece{relatedContent?.length === 1 ? '' : 's'} of associated content
              </span>
            </button>
          )}
        </>
      )}
    </div>
  );
});

export default KeyInformation;
