import { useEffect } from 'react';
import Footer from 'components/home/Footer';
import BigBen from 'components/Dashboard/Sentiment/BigBen';
import SentimentSearchBarPage from 'components/Dashboard/Sentiment/SentimentSearchBarPage';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';

const AnalyticsPage = () => {
  const searchParam = getUrlParam('search');
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (searchParam) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = 'scroll';
    }
    return () => {
      body.style.overflowY = 'hidden';
    };
  }, [searchParam]);

  return (
    <div>
      <div className={`bg-main-blue sentiment-main-section sentiment-searchbar-section`}>
        <div className={`sentiment-page-container px-lg-5 `}>
          <SentimentSearchBarPage analyticsPage={true} />
          <BigBen />
        </div>
      </div>
      <Footer sentiment />
    </div>
  );
};

export default AnalyticsPage;
