import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import transformToPascalCase from 'components/Settings/Utilities/TransformToPascalCase';
import { lowerCaseFirstLetter } from 'components/Dashboard/utilities/uppercaseFirstLetter';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useGetTweetsInfo from './useGetTweetsInfo';
import { store } from 'components/Store';
import { useContext } from 'react';

const useInfluenceSaveFunctions = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { promptFromWritingAssistant } = state;

  const location = useLocation();
  const params = useParams();
  const { generalApiCall } = useGeneralApiCall();
  const { getTweetsInfo } = useGetTweetsInfo();
  const { uploadAttachments } = useUploadAttachments();

  const InfluenceSaveFunctions = async (object, notPreview) => {
    try {
      const { InfluenceState, itemInformation, source, setItemInformation, associatedContent, setInfluenceState } =
        object;

      const step = params.step?.split('?')[0];
      const beforePreview =
        InfluenceState?.findIndex((item) => item?.name === 'Associated content') === parseInt(step) - 1;
      let descriptionPosition = InfluenceState.findIndex((item) => item.name === 'Description');
      let summaryField = InfluenceState[descriptionPosition]?.fields?.find((field) => field.name === 'summary');
      const conditionToSummary =
        (beforePreview &&
          !summaryField &&
          !location.pathname.includes('edit') &&
          itemInformation?.contentVersion === 2) ||
        !!location?.state?.writingAssistantId;

      const method = 'post';
      let finalParam;

      if (step === 'preview' && !notPreview && itemInformation.publishedAt === null) {
        finalParam = 'publish';
      } else {
        finalParam = !itemInformation.id ? 'create' : 'update';
      }
      const pathname = `/api/user-generated-content/${finalParam}${finalParam === 'create' ? `?website=policymogul${conditionToSummary ? '&summarise=true' : ''}` : `${conditionToSummary ? '?summarise=true' : ''}`}`;

      let requestProperties = {
        type: params.page === 'policy-asks' ? 'PolicyAsk' : transformToPascalCase(params.page.replace('-', ' ')),
        contentVersion: itemInformation?.contentVersion,
      };
      if (finalParam === 'create') {
        requestProperties = { ...requestProperties, contentVersion: 2 };
      }
      if (itemInformation.id) {
        requestProperties.id = itemInformation.id;
      }

      //workflow for save the organisation website
      let newInfluenceState = [...InfluenceState];

      //hardconding the position of attachments inside the description field
      let attachmentsField = newInfluenceState[descriptionPosition]?.fields?.filter(
        (field) => field.name === 'attachments'
      );
      let attachmentsValue = attachmentsField[0]?.value;
      let finalAttachments;
      if (Object.keys(attachmentsValue).length === 0) {
        finalAttachments = [];
      } else {
        const { temporalFiles, savedFiles } = attachmentsValue;
        let uploadFiles = !!savedFiles ? savedFiles : [];
        if (!!temporalFiles && temporalFiles.length > 0) {
          let files = await uploadAttachments({ files: temporalFiles, source });
          if (!!files) {
            files.forEach((item, index) => {
              uploadFiles.push({
                name: temporalFiles[index].name,
                url: item,
              });
            });
          }
        }
        finalAttachments = {
          temporalFiles: [],
          savedFiles: uploadFiles,
        };
      }

      attachmentsField[0].value = finalAttachments;
      let contentField =
        params.page === 'policy-asks'
          ? 'policyAskContent'
          : `${lowerCaseFirstLetter(transformToPascalCase(params.page.replace('-', ' ')))}Content`;

      newInfluenceState.forEach(async (item) => {
        let fields = item.fields?.filter((i) => i.type === params.page || !i.type);
        for (let i = 0; i < fields?.length; i++) {
          let field = fields[i];
          const { name, value } = field;
          //Not send the organisationWebsite to the API
          if (item.name === 'Description' && field.name !== 'attachments') {
            if (!requestProperties[contentField]) {
              requestProperties[contentField] = {};
            }
            requestProperties[contentField][name] = changeValueForAPI(name, value);
          } else {
            requestProperties[name] = changeValueForAPI(name, value);
          }
        }
      });

      //CREATE SUMMARY WHEN IT'S NORMAL WORKFLOW

      let summaryCreatedByTheAI = false;
      requestProperties = {
        ...requestProperties,
        relatedContent: associatedContent,
      };

      let results = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
        requestSource: source,
      });

      if (!!results) {
        if (!!promptFromWritingAssistant) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'promptFromWritingAssistant', value: null });
        }
        let summaryValue = results[contentField]?.summary;
        if (!!summaryValue) {
          if (!summaryField) {
            newInfluenceState[descriptionPosition].fields = [
              {
                name: 'summary',
                value: summaryValue,
                title: 'Summary',
                subtext: 'Summarise your lobbying material',
                required: false,
              },
              ...newInfluenceState[descriptionPosition].fields,
            ];
          }
          summaryCreatedByTheAI = true;
        }
        //saving associated content
        let associatedContentProperty = newInfluenceState?.findIndex((item) => item.name === 'Associated content');
        let relatedContent = await getTweetsInfo({ relatedContent: results?.relatedContent });
        newInfluenceState[associatedContentProperty].fields[0].value = relatedContent;
        setInfluenceState(newInfluenceState);
        setItemInformation({ ...results, relatedContent, summaryCreatedByTheAI });
        return results;
      }
    } catch (error) {
      console.error(error);
    }
  };
  return { InfluenceSaveFunctions };
};

const useUploadAttachments = () => {
  const { generalApiCall } = useGeneralApiCall();
  const uploadAttachments = async (props) => {
    const { files, source } = props;
    const pathname = '/api/user-upload/upload';
    const method = 'post';
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    const requestProperties = formData;
    let results = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
    });
    return results;
  };
  return { uploadAttachments };
};

const changeValueForAPI = (name, value) => {
  switch (name) {
    case 'attachments':
      let files = [];
      if (!!value.savedFiles) {
        value.savedFiles.forEach((item) => {
          files.push(item.url);
        });
      }
      return files;
    case 'expirationDate':
      return value?.value ?? null;
    default:
      return value;
  }
};

export default useInfluenceSaveFunctions;
