import LocalStorage from 'utils/LocalStorage';

const removeLocalStorage = () => {
  const removeItem = (item) => {
    if (LocalStorage.getItem(item) !== null) {
      LocalStorage.removeItem(item);
    }
  };
  /*
  AE: keywordList, activeFilters are no longer used but it's necessary to keep here to backward compatibility
  */
  removeItem('keywordList');
  removeItem('activeFilters');
  removeItem('listsIds');
  removeItem('clientsIds');
  removeItem('seeUnreadFilter');
  removeItem('contentFilters');
  removeItem('notShowAgainMarkAllPopup');
  removeItem('lastSenderEmailAddress');
  removeItem('firstTimeContactsAdded');
  removeItem('showRelevantExtract');
};

export default removeLocalStorage;
