import React, { useState, useEffect, useRef, useContext } from 'react';
import EmailDomainContainer from './EmailDomainContainer';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import AddEmailDomainModal from './AddEmailDomainModal';
import useEmailDomainFunctions from 'components/Dashboard/hooks/useEmailDomainFunctions';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { useParams } from 'react-router-dom';
import disabledUSAProps from 'components/Settings/Utilities/disabledUSAProps';
import getAppSite from 'utils/getAppSite';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { store } from 'components/Store';

const EmailDomainList = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { clients } = state;

  const { team, isAdminPage, emailDomainsState } = props;
  const { emailDomains, setEmailDomains } = emailDomainsState ?? {};
  const { myself } = team;
  const [heightContainer, containerRef] = useHeightContainer();
  const [showAddEmailDomainModal, setShowAddEmailDomainModal] = useState(false);
  const [expandLastDomain, setExpandLastDomain] = useState(false);
  const [teamId, setTeamId] = useState();

  const params = useParams();
  const { id } = params;

  const allowAddDomain =
    (myself?.role === 'Admin' || myself?.role === 'Owner' || isAdminPage) && getAppSite() !== 'usa';

  const { getEmailDomains } = useEmailDomainFunctions();

  useEffect(() => {
    isAdminPage ? setTeamId(id) : setTeamId(team.teamId);
  }, []);

  const setEmailDomainsRequest = async () => {
    const emailDomainsResult = await getEmailDomains({
      isAdminPage: isAdminPage,
      organisationId: id,
      getAllTeams: newAgencyPlan,
    });
    setEmailDomains(isAdminPage ? emailDomainsResult?.emailDomains : emailDomainsResult);
  };

  const showHideAddEmailDomainModal = () => {
    setShowAddEmailDomainModal(!showAddEmailDomainModal);
  };
  let scrollBarRef = useRef();

  if (scrollBarRef?.current && expandLastDomain) {
    const expandedElement = document.querySelector('.domain-record-container[data-expanded="true"]');
    if (expandedElement) {
      scrollBarRef.current.scrollTop(expandedElement.offsetTop);
    }
  }

  const { newAgencyPlan: localAgencyPlan } = useTopicOrClientWord();
  const newAgencyPlan = props?.newAgencyPlan ?? localAgencyPlan;

  return (
    <>
      <AddEmailDomainModal
        loadInitialInformation={setEmailDomainsRequest}
        isAdminPage={isAdminPage}
        closeModal={showHideAddEmailDomainModal}
        teamId={teamId}
        setExpandLastDomain={setExpandLastDomain}
        domainsList={emailDomains}
        teamName={newAgencyPlan ? clients?.find((item) => item?.teamId === teamId)?.teamName : null}
        openState={{ isOpen: showAddEmailDomainModal, setIsOpen: setShowAddEmailDomainModal }}
      />

      <div className='mt-lg-5 email-domains-container-list'>
        <div className={`email-domains-header ${!newAgencyPlan ? 'border-bottom' : ''}`}>
          <div className='d-flex  align-items-center'>
            <h3 className='email-domains-title'>Domains</h3>
          </div>
          {!newAgencyPlan && (
            <div className='align-items-right'>
              <button
                className={`add-domain-btn ${allowAddDomain ? '' : 'disabled'}`}
                onClick={allowAddDomain ? showHideAddEmailDomainModal : undefined}
                data-tooltip-content={
                  allowAddDomain
                    ? undefined
                    : `Only team owners and admins can register new domains and sender email addresses`
                }
                data-tooltip-id={'settings-tooltip'}
                data-tooltip-class-name={'add-domain-tooltip'}
                {...disabledUSAProps()}
              >
                Add a domain
              </button>
            </div>
          )}
        </div>
        <div ref={containerRef} />
        <CustomScrollbar
          className={'simple-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
          ref={scrollBarRef}
        >
          {newAgencyPlan ? (
            <>
              {emailDomains?.map((item) => {
                return (
                  <EmailDomainsAgencyPlan
                    key={`element-${item?.teamId}`}
                    team={item}
                    emailDomains={emailDomains}
                    allowAddDomain={allowAddDomain}
                    showHideAddEmailDomainModal={showHideAddEmailDomainModal}
                    setEmailDomainsRequest={setEmailDomainsRequest}
                    expandLastDomain={expandLastDomain}
                    setTeamId={setTeamId}
                    isAdminPage={isAdminPage}
                  />
                );
              })}
            </>
          ) : (
            <EmailDomainsElement
              emailDomains={emailDomains}
              allowAddDomain={allowAddDomain}
              showHideAddEmailDomainModal={showHideAddEmailDomainModal}
              setEmailDomainsRequest={setEmailDomainsRequest}
              isAdminPage={isAdminPage}
              teamId={teamId}
              expandLastDomain={expandLastDomain}
            />
          )}
        </CustomScrollbar>
      </div>
    </>
  );
};

const EmailDomainsElement = (props) => {
  const {
    emailDomains,
    allowAddDomain,
    showHideAddEmailDomainModal,
    setEmailDomainsRequest,
    isAdminPage,
    teamId,
    expandLastDomain,
  } = props;
  return (
    <>
      {emailDomains?.length > 0 ? (
        <>
          <div className='email-domains-body'>
            {emailDomains?.map((emailDomain, index) => (
              <EmailDomainContainer
                key={emailDomain.id}
                isAdminPage={isAdminPage}
                teamId={teamId}
                loadInitialInformation={setEmailDomainsRequest}
                emailDomain={emailDomain}
                showHideAddEmailDomainModal={showHideAddEmailDomainModal}
                expandedContainer={index === emailDomains.length - 1 && expandLastDomain}
                allowChanges={allowAddDomain}
                lastItem={index === emailDomains.length - 1}
              />
            ))}
          </div>
        </>
      ) : (
        <div className='admin-general-content widget-padding'>
          <div className='ml-5'>No Domains Registered</div>
        </div>
      )}
    </>
  );
};

const EmailDomainsAgencyPlan = (props) => {
  const {
    team,
    allowAddDomain,
    showHideAddEmailDomainModal,
    setEmailDomainsRequest,
    setTeamId,
    isAdminPage,
    expandLastDomain,
  } = props;
  const { teamName, emailDomains, teamId } = team ?? {};
  return (
    <>
      <div className='padding-topic-component border-bottom border-top py-3 bg-salmon'>
        <div className='px-lg-2 px-xl-4'>
          <div className='flex-centered justify-content-between'>
            <h3 className='title-h4-bold email-based-title topics-item-digest-combining mb-0'>{teamName}</h3>
            <button
              className={`general-button simple-link-button pl-3 py-0`}
              onClick={() => {
                if (allowAddDomain) {
                  setTeamId(teamId);
                  showHideAddEmailDomainModal();
                }
              }}
              {...disabledUSAProps()}
            >
              + Add a domain
            </button>
          </div>
        </div>
      </div>
      <EmailDomainsElement
        emailDomains={emailDomains}
        allowAddDomain={allowAddDomain}
        showHideAddEmailDomainModal={showHideAddEmailDomainModal}
        setEmailDomainsRequest={setEmailDomainsRequest}
        isAdminPage={isAdminPage}
        teamId={teamId}
        expandLastDomain={expandLastDomain}
      />
    </>
  );
};

export default EmailDomainList;
