import { useContext, useEffect, useRef, useState } from 'react';
import WebsiteFeedSettings from './WebsiteFeedSettings';
import { store } from 'components/Store';
import EmbedPage from 'components/embed';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { SubtextOneResource } from 'components/embed/EmbedContent';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import useGetKeywordsListsByClient from './useGetKeywordsListsByClient';
import getAppSite from 'utils/getAppSite';

const WebsiteFeedMain = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, team, keywordsLists } = state;

  const { agencyUserBase, agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const { enableTwitter } = activePlan;

  //AE: For now it's not necessary to load the keywordlists and use a skeleton item, in case we use the website feeds page on the settings section that will be neccessary
  const { setShowPopup, listToUse } = props;
  const topicParameter = getUrlParam('topic-id');
  const [selectedTopics, setSelectedTopics] = useState(
    topicParameter
      ? topicParameter === 'all'
        ? keywordsListsToUse
        : [keywordsListsToUse?.find((item) => item.id === parseInt(topicParameter))]
      : listToUse ?? []
  );
  const initialResources =
    getAppSite() === 'usa'
      ? ['ParliamentaryRecord']
      : [
          'KeyUpdates',
          'Consultations',
          'Legislation',
          'ParliamentaryRecord',
          'CommonsLibraryArticles',
          'Events',
          'CommitteePublications',
        ];
  const [resources, setResources] = useState(
    enableTwitter && process.env.REACT_APP_ENABLE_TWITTER === 'true'
      ? [...initialResources, 'Tweets']
      : [...initialResources]
  );
  const [uponClickOption, setUponClickOption] = useState({
    name: 'separateTab',
    label: 'Content opens in PolicyMogul (separate tab)',
  });
  const [designState, setDesignState] = useState({
    headerColour: '#FFFFFF',
    backgroundColour: '#FFFFFF',
    barColour: '#f9f9f9',
    includeBranding: true,
  });

  const [headerValues, setHeaderValues] = useState({
    header: 'Latest political updates',
    subheader: '',
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [combineUpdates, setCombineUpdates] = useState(true);
  const [client, setClient] = useState(team);
  const [isLoading, setIsLoading] = useState(true);

  const mainEmbedContainer = useRef();
  const embedElement = useRef();
  const titleElement = useRef();
  const settingsElement = useRef();
  const mainElementContainer = useRef();

  const { getKeywordListByClient } = useGetKeywordsListsByClient();
  const topicsByClient = newAgencyPlan ? getKeywordListByClient({ client }) : null;
  const masterTopic =
    selectedTopics.length ===
      (newAgencyPlan ? topicsByClient?.filter((item) => item?.id !== null)?.length : keywordsListsToUse.length) &&
    selectedTopics?.length > 1;
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    const createSubheader = () => {
      const finalResources = resources;
      return `${finalResources.length > 1 ? 'Showing the latest political developments' : SubtextOneResource(finalResources)}${' '}${agencyUserBase ? 'relevant' : 'related'} to ${(masterTopic && combineUpdates) || selectedTopics?.length === 0 ? `${newAgencyPlan ? client?.teamName : team?.teamName}` : selectedTopics?.length > 1 ? '' : selectedTopics[0]?.name}`;
    };
    setHeaderValues({ ...headerValues, subheader: createSubheader() });
  }, [selectedTopics, resources, combineUpdates]);

  useEffect(() => {
    if (newAgencyPlan) {
      let lists = topicsByClient;
      let filteredLists = lists?.filter((item) => item?.id !== null);
      const topicFromParameter =
        topicParameter && filteredLists?.length > 0
          ? filteredLists?.find((item) => item?.id === parseInt(topicParameter))
          : null;
      setSelectedTopics(filteredLists?.length > 0 ? [topicFromParameter ?? filteredLists?.[0]] : []);
    }
  }, [client]);

  const { width, left } = settingsElement?.current?.getBoundingClientRect() ?? {};
  return (
    <div className='bg-white rounded website-feeds-popup h-100' ref={mainEmbedContainer}>
      <CustomScrollbar
        className={'simple-scrollbar'}
        maximalThumbYSize={100}
        onScroll={(scrollValues) => {
          setScrollPosition(scrollValues?.scrollTop);
        }}
      >
        <div className='px-3 px-lg-5 pb-5'>
          <div className='text-center pb-4 px-3 px-lg-0 pt-5 bg-main-white main-sticky-lg' ref={titleElement}>
            <h3 className='title-h3-bold'>
              Embed this live feed on{' '}
              {agencyUser ? `a client's website` : `${newAgencyPlan ? 'any website' : 'your website'}`}
            </h3>
          </div>
          <div className='row mx-0 px-lg-5' ref={mainElementContainer}>
            <div className='col-lg-10 pl-0' ref={settingsElement}>
              <div className='border rounded bg-light-blue-hovered h-100 pb-4 pb-lg-0 website-feeds-popup-settings position-relative'>
                <WebsiteFeedSettings
                  selectedTopics={selectedTopics}
                  setSelectedTopics={setSelectedTopics}
                  resources={resources}
                  setResources={setResources}
                  uponClickOption={uponClickOption}
                  setUponClickOption={setUponClickOption}
                  setShowPopup={setShowPopup}
                  userId={masterTopic && combineUpdates ? team?.owner?.id : null}
                  scrollPosition={scrollPosition}
                  designProps={{ designState, setDesignState }}
                  mainEmbedContainer={mainEmbedContainer}
                  headerState={{ headerValues, setHeaderValues }}
                  combineState={{ combineUpdates, setCombineUpdates }}
                  clientState={{ client, setClient }}
                  topicsByClient={topicsByClient}
                  titleElement={titleElement}
                  masterTopic={masterTopic}
                />
              </div>
            </div>
            {isLoading ? (
              <div className='col-lg-6'>
                <SkeletonTheme baseColor='#f2f2f2'>
                  <Skeleton width={'100%'} height={'67vh'} />
                </SkeletonTheme>
              </div>
            ) : (
              <div
                className='col-lg-6 d-none d-lg-block pr-0 website-feeds-popup-embed'
                ref={embedElement}
                style={{
                  position: 'fixed',
                  height: `${mainEmbedContainer?.current?.getBoundingClientRect()?.height - titleElement?.current?.getBoundingClientRect()?.height - 40}px`,
                  top: `${
                    titleElement?.current?.getBoundingClientRect()?.height +
                    mainEmbedContainer?.current?.getBoundingClientRect()?.top
                  }px`,
                  left: `${left + width + 5}px`,
                  width: `${((mainElementContainer?.current?.getBoundingClientRect()?.width - 64) * 37.5) / 100}px`,
                }}
              >
                <div className='bg-main-blue rounded px-4 py-4 h-100 dashboard-root-container'>
                  <h3 className='section-subtitle paragraph-p5 text-white' style={{ marginBottom: '20px' }}>
                    Your feed
                  </h3>
                  <div className='website-feed-embed-main-container flex-grow-1 overflow-hidden'>
                    {isLoading ? (
                      <SkeletonTheme baseColor='#f2f2f2'>
                        <Skeleton width={'100%'} height={'627px'} />
                      </SkeletonTheme>
                    ) : (
                      <EmbedPage
                        selectedTopics={selectedTopics}
                        uponClickOption={uponClickOption?.name}
                        resources={resources.join(',')}
                        isFromPopup={true}
                        userId={masterTopic && combineUpdates ? team?.owner?.id : null}
                        designState={designState}
                        headerState={{ headerValues, setHeaderValues }}
                        keywordsLists={newAgencyPlan ? topicsByClient : keywordsListsToUse}
                        teamId={newAgencyPlan ? client?.teamId : null}
                        variableWidth={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default WebsiteFeedMain;
