import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import Content from './ui/Content';
import { useSuggestionFunctions } from './WritingCreate';
import axios from 'axios';
import useEmailDomainFunctions from 'components/Dashboard/hooks/useEmailDomainFunctions';
import useEmailDomainsInitialCall from 'components/Dashboard/Influence/useEmailDomainsInitialCall';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags';

const WritingContent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const firstLoad = useRef();
  const { generalApiCall } = useGeneralApiCall();
  const [results, setResults] = useState({});
  const [tryAgainValue, setTryAgainValue] = useState('');
  const { getSuggestion } = useSuggestionFunctions();
  const { needsDomainVerification } = useEmailDomainFunctions();
  const { addMetaTags } = useAddMetaTags();

  const params = useParams();
  const { title: id } = params;
  const location = useLocation();

  useEmailDomainsInitialCall();

  const getContent = async (source) => {
    try {
      const pathname = `/api/writing-assistant-generated-content/${id}`;
      const method = 'get';
      let results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });
      if (results) {
        setResults(results);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  firstLoad.current = async (source) => {
    await getContent(source);
    let title = `AI assistant - PolicyMogul`;
    addMetaTags({ title, hash: location.hash });
  };

  //DELETE FUNCTION
  const deleteItem = async () => {
    try {
      const pathname = `/api/writing-assistant-generated-content/${id}`;
      const method = 'delete';
      let results = await generalApiCall({ pathname, method, needsAuthentication: true, returnCompleteRequest: true });
      if (results?.status === 200) {
        return true;
      }
    } catch (error) {}
  };

  const updateFunction = async ({ prompt, body }) => {
    try {
      const pathname = `/api/writing-assistant-generated-content/update`;
      const method = 'post';
      const requestProperties = {
        ...results,
        prompt,
        body,
        promptData: {
          ...results.promptData,
          newValue: tryAgainValue,
        },
      };
      const response = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
      if (response) {
        setResults(response);
        return true;
      }
    } catch (error) {}
  };

  const navigate = useNavigate();
  const createEmailFunction = async () => {
    if (needsDomainVerification) {
      navigate(`/influence/emails`);
    } else {
      navigate({ pathname: `/influence/emails/create` }, { state: { writingAssistantId: id } });
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    firstLoad.current(source);
    return () => {
      source.cancel('Content cancelled');
    };
  }, []);

  return (
    <Content
      isLoading={isLoading}
      results={results}
      updateFunction={updateFunction}
      setTryAgainValue={setTryAgainValue}
      tryAgainValue={tryAgainValue}
      getSuggestion={getSuggestion}
      deleteItem={deleteItem}
      createEmailFunction={createEmailFunction}
    />
  );
};

export default WritingContent;
