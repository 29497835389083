import React, { useState, useRef } from 'react';
import axios from 'axios';
import { net_api_url } from 'components/Store.js';
import Autosuggest from 'react-autosuggest';
import { throttle } from 'throttle-debounce';
const ParliamentarianNameAutocomplete = React.forwardRef((props, ref) => {
  const { property, modifyValue, itemValue } = props;
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const inputSuggest = useRef(null);

  const getSuggestionValue = (suggestion) => {
    return suggestion.value;
  };

  const renderSuggestion = (suggestion) => {
    return (
      <div className='search-item row mx-0 text-item'>
        <div className='icon'></div>
        <div className='col-15 pl-2'>
          <p className='mb-0'>{suggestion.label}</p>
        </div>
      </div>
    );
  };

  const onChange = (event, { newValue, method }) => {
    if (method !== 'down' && method !== 'up') {
      setValue(newValue);
    }
  };

  const onSuggestionsFetchRequested = (value) => {
    if (value === '') {
      setOptions([]);
      return;
    }
    let url = `${net_api_url}/api/autocomplete/parliamentarians?term=${encodeURIComponent(value)}`;
    axios
      .get(url)
      .then(function (response) {
        setOptions(response.data);
      })
      .catch(function (error) {
        return [];
      });
  };

  const autocompleteSearchThrottled = ({ value }) => {
    throttle(500, onSuggestionsFetchRequested(value));
  };

  const onSuggestionsClearRequested = () => {
    setOptions([]);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    if (suggestionValue !== '') {
      setValue(suggestion.label);
      modifyValue(property, { label: suggestion.label, id: suggestionValue });
      if (parliamentarianNameContainer.current !== null) {
        setTimeout(() => {
          let element = parliamentarianNameContainer.current.parentNode.nextSibling.children[1];
          if (element) {
            element.focus();
          }
        }, 50);
      }
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (value === '') {
        setValue('');
      }
    }
  };

  const parliamentarianNameContainer = useRef(null);
  return (
    <div className={`m-0 position-relative parliamentarian-name`} ref={parliamentarianNameContainer}>
      <Autosuggest
        ref={inputSuggest}
        suggestions={options}
        onSuggestionsFetchRequested={autocompleteSearchThrottled}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={{
          ref,
          placeholder: 'Search for a name',
          value,
          onChange: onChange,
          onKeyDown: onKeyDown,
          className: 'form-control',
          onClick: (e) => {
            if (value !== '') {
              e.target.select();
            }
          },
          onBlur: () => {
            if (itemValue === '') {
              setValue('');
            } else {
              setValue(itemValue.label);
            }
          },
        }}
        highlightFirstSuggestion={true}
      />
    </div>
  );
});

export default ParliamentarianNameAutocomplete;
