import { net_api_url } from 'components/Store';
import axios from 'axios';
const getCurrentTeam = async (getAccessToken, source) => {
  try {
    let token = await getAccessToken();
    let currentTeam = await axios.get(`${net_api_url}/api/team/get-current-team`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source?.token,
    });
    let currentTeamInformation = currentTeam.data;
    return currentTeamInformation;
  } catch (error) {
    console.error(error);
  }
};

export default getCurrentTeam;
