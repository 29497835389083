import React, { useEffect, useState } from 'react';
import useCreateEmailAlerts from 'components/Settings/EmailAlerts/useCreateEmailAlerts';
import CreateANewEmailAlertButton from './CreateANewEmailAlertButton';
import { EmailAlertItem } from './ListOfEmailAlerts';
import { useCollapse } from 'react-collapsed';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const EmailAlertsForClients = (props) => {
  const {
    clientsAlerts,
    allowAlerts,
    clients,
    setIsOpenEditEmailAlert,
    setTeamId,
    setTeamTopics,
    setEmailAlertId,
    stateExpanded,
    enableHandpickedContent,
  } = props;

  const { expandedStates, modifyExpandedStates } = stateExpanded ?? {};
  const isExpanded = expandedStates ? expandedStates['alerts'] : true;
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const { createEmailAlerts } = useCreateEmailAlerts();
  const { newAgencyPlan } = useTopicOrClientWord();
  const numberOfAlerts = clientsAlerts?.flatMap((item) => item.alertSettings)?.filter((item) => !item?.paused)?.length;
  const [showNumber, setShowNumber] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowNumber(!isExpanded);
    }, 400);
  }, [isExpanded]);

  return (
    <>
      <div
        className={`d-flex mb-0  padding-topic-component bg-main-blue py-3 text-white ${newAgencyPlan ? 'pointer title-h4' : 'title-h4-bold'}`}
        {...getToggleProps({
          onClick: () => {
            if (newAgencyPlan) {
              modifyExpandedStates({ property: 'alerts', value: !isExpanded });
            }
          },
        })}
      >
        <div className='d-flex'>
          <span className='icon-topics-master mr-2 mt-1' />
          <div className='pl-2'>
            <p
              className={`${newAgencyPlan ? `mb-0 dropdown-item-element dropdown-item-element-10 ${isExpanded ? 'open' : ''}` : ''}`}
            >
              Based on your clients
            </p>
            {newAgencyPlan && !isExpanded && showNumber && (
              <p className='d-block mb-0 paragraph-p3'>
                {numberOfAlerts} active alert{numberOfAlerts === 1 ? '' : 's'}
              </p>
            )}
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        {clientsAlerts
          ?.filter((item) => item?.myState === 'Enabled')
          ?.map((item) => {
            const { teamId, teamName, alertSettings, adminClient } = item;
            const client = adminClient ?? clients?.find((item) => item?.teamId === teamId);
            const { myKeywordLists, teamMemberKeywordLists } = client ?? {};
            const myLists = myKeywordLists ?? [];
            const teamLists = teamMemberKeywordLists ?? [];
            const lists = [...myLists, ...teamLists];

            const emailAlerts = createEmailAlerts(alertSettings, lists);
            const updateTeamState = () => {
              setTeamId(teamId);
              setTeamTopics(lists);
            };
            return (
              <React.Fragment key={`item-${teamId}`}>
                <div className='padding-topic-component border-bottom py-3 bg-salmon'>
                  <div className='px-lg-2 px-xl-4'>
                    <div className='flex-centered justify-content-between'>
                      <h3 className='title-h4-bold email-based-title topics-item-digest-combining mb-0'>{teamName}</h3>
                      <CreateANewEmailAlertButton
                        allowAlerts={allowAlerts}
                        setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                        additionalFunction={updateTeamState}
                        buttonText={'+  Add a client alert'}
                      />
                    </div>
                  </div>
                </div>

                <div className='padding-topic-component'>
                  {emailAlerts?.length > 0 ? (
                    <div style={{ marginTop: '-20px' }}>
                      {emailAlerts?.map((item) => {
                        const { id } = item;
                        return (
                          <EmailAlertItem
                            {...props}
                            emailAlerts={emailAlerts}
                            setEmailAlertId={setEmailAlertId}
                            setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                            key={`email-alert-${id}`}
                            item={item}
                            keywordsLists={lists}
                            additionalFunction={updateTeamState}
                            teamId={teamId}
                            enableHandpickedContent={enableHandpickedContent}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <p className='py-4 mb-0 main-light-text px-lg-2 px-xl-4'>
                      You do not currently have any email alerts set up for {client?.teamName ?? client?.name ?? ''}
                    </p>
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};

export default EmailAlertsForClients;
