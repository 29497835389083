import * as React from 'react';
import Board from '@cloudscape-design/board-components/board';
import { Container } from '@cloudscape-design/components';
import BoardItem from '@cloudscape-design/board-components/board-item';
import Header from '@cloudscape-design/components/header';
import { boardI18nStrings } from './i18n-strings';
import ReportsAnalyticsItem from './ReportsAnalyticsItem';
import LatestNews from './LatestNews';
import MostInterestedStakeholder from './MostInterestedStakeholder';
import CommentaryComponent, { LocalWidgetCommentary, ReportEmptyWidget } from './CommentaryComponent';
import { MacroItem } from './Macros';
import ReportsActionsElement from './ReportsActionsElement';
import SpecificUpdateComponent from './SpecificUpdateComponent';
import ReportEmptyState from './ReportEmptyState';
import { store } from 'components/Store';

const ReportsBoard = (props) => {
  const globalState = React.useContext(store);
  const { state, dispatch } = globalState;
  const { updateToCreateReport } = state;

  const {
    layout,
    setLayout,
    previewMode,
    updateCommentaryValues,
    macrosState,
    boardContainer,
    report,
    reloadContent,
    setDefaultProperty,
    reportTitle,
    setReportTitle,
    setIsOpenSelectTopicModal,
    defaultProperty,
  } = props;

  return (
    <Board
      renderItem={(item, actions) => (
        <MainWidgetItem
          item={item}
          actions={actions}
          previewMode={previewMode}
          updateCommentaryValues={updateCommentaryValues}
          macrosState={macrosState}
          defaultProperty={defaultProperty}
          layoutState={{ layout, setLayout }}
          boardContainer={boardContainer}
          report={report}
          reloadContent={reloadContent}
          titleState={{ reportTitle, setReportTitle }}
        />
      )}
      onItemsChange={({ detail: { items, addedItem } }) => {
        const maxItems = items;
        //Add an empty widget at the bottom to improve movement on the screen
        const emptyElementPosition = maxItems.findIndex((item) => item.id === 'emptyElement');
        if (emptyElementPosition >= 0) {
          maxItems.splice(emptyElementPosition, 1);
        }
        maxItems.forEach((item, index) => {
          let newItem = { ...item, saved: false };
          if (
            newItem?.data?.component === 'Commentary' &&
            newItem?.data?.state?.value !== '' &&
            index !== maxItems.length - 1
          ) {
            newItem = { ...item, data: { ...item.data, state: { ...item?.data?.state, saved: true } } };
          }
          maxItems[index] = newItem;
        });
        if (maxItems.length === 1 && maxItems[0]?.columnOffset?.[4] === 0) {
          let emptyWidget = {
            columnOffset: { 4: 0 },
            columnSpan: 4,
            data: { name: 'Empty widget', icon: 'consultation', component: 'EmptyWidget' },
            definition: { minRowSpan: 1, minColumnSpan: 4, defaultRowSpan: 1, defaultColumnSpan: 4 },
            id: 'emptyElement',
            rowSpan: 1,
            title: 'Empty widget',
          };
          maxItems.push(emptyWidget);
        }
        if (maxItems.length >= 2) {
          let newItem = { ...addedItem };
          if (!!newItem) {
            let addedItemPosition = maxItems.findIndex((item) => item.id === addedItem?.id);
            let itemsWithoutLast = maxItems.filter((item) => item.id !== addedItem?.id);
            if (
              itemsWithoutLast.filter((item) => item?.columnSpan === 4 && item.data.component !== 'EmptyWidget')
                ?.length === itemsWithoutLast.length
            ) {
              newItem = { ...newItem, columnSpan: 4 };
            }
            maxItems[addedItemPosition] = newItem;
          }
        }
        if (updateToCreateReport && !!addedItem?.data?.state?.topic) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'temporaryReportState', value: maxItems });
          setIsOpenSelectTopicModal(true);
        } else {
          setLayout(maxItems);
        }
      }}
      items={layout}
      i18nStrings={boardI18nStrings}
      empty={
        <ReportEmptyState
          layout={layout}
          setLayout={setLayout}
          reportTitle={reportTitle}
          setReportTitle={setReportTitle}
          setDefaultProperty={setDefaultProperty}
        />
      }
    />
  );
};

const MainWidgetItem = (props) => {
  const {
    item,
    actions,
    layoutState,
    updateCommentaryValues,
    macrosState,
    previewMode,
    boardContainer,
    itemsChangedState,
    report,
    reloadContent,
    titleState,
    defaultProperty,
  } = props;
  const { layout, setLayout } = layoutState;
  const { component, name, state } = item.data;

  const [widgetData, setWidgetData] = React.useState(null);

  const modifyItemLayout = ({ item, state, columnSpan, rowSpan, newCompleteItem }) => {
    let newLayout = [...layout];
    let index = newLayout.findIndex((layoutItem) => layoutItem.id === item.id);
    let newItem = newCompleteItem ?? { ...item, data: { ...item.data, state } };
    if (columnSpan) {
      newItem = { ...newItem, columnSpan };
    }
    if (rowSpan) {
      newItem = { ...newItem, rowSpan };
    }
    newLayout[index] = newItem;
    setLayout(newLayout);
  };

  const modifyRowSpan = ({ item, rowSpan }) => {
    let newLayout = [...layout];
    let index = newLayout.findIndex((layoutItem) => layoutItem.id === item.id);
    newLayout[index] = { ...item, rowSpan };
    setLayout(newLayout);
  };

  const widgetProps = {
    modifyItemLayout,
    previewMode,
    widgetData,
    setWidgetData,
    boardContainer,
    layout,
    setLayout,
    itemsChangedState,
    report,
    titleState,
    defaultProperty,
  };

  const createComponent = ({ component, name, item }) => {
    const commonProps = { item, ...widgetProps };
    switch (component) {
      case 'Graph':
        return <ReportsAnalyticsItem chartType={name} {...commonProps} />;
      case 'LatestNews':
        return <LatestNews {...commonProps} />;
      case 'MostInterestedStakeholders':
        return <MostInterestedStakeholder {...commonProps} />;
      case 'Commentary':
        return (
          <CommentaryComponent
            modifyRowSpan={modifyRowSpan}
            updateCommentaryValues={updateCommentaryValues}
            macrosState={macrosState}
            {...commonProps}
          />
        );
      case 'SpecificUpdate':
        return <SpecificUpdateComponent {...commonProps} />;
      case 'MacroItem':
        return <MacroItem item={item} {...commonProps} />;
      case 'EmptyWidget':
        return <ReportEmptyWidget {...props} />;
      default:
        return <p>{component}</p>;
    }
  };

  React.useEffect(() => {
    if (reloadContent && component !== 'Commentary' && component !== 'SpecificUpdate') {
      setWidgetData(null);
    }
  }, [reloadContent]);

  return (
    <>
      {previewMode ? (
        <Container
          header={
            <Header headingTagOverride='h4'>
              <span className='report-component-name'>{state?.widgetName ?? name}</span>
            </Header>
          }
        >
          {component !== 'Commentary' && (
            <LocalWidgetCommentary
              item={item}
              modifyItemLayout={modifyItemLayout}
              modifyRowSpan={modifyRowSpan}
              previewMode={true}
            />
          )}
          {createComponent({ component, name, item })}
        </Container>
      ) : (
        <BoardItem
          header={
            <Header headingTagOverride='h4'>
              <span className='report-component-name'>{state?.widgetName ?? name}</span>
            </Header>
          }
          i18nStrings={boardI18nStrings}
          settings={
            <ReportsActionsElement
              actions={actions}
              item={item}
              modifyItemLayout={modifyItemLayout}
              setWidgetData={setWidgetData}
              report={report}
            />
          }
        >
          {component !== 'Commentary' && (
            <LocalWidgetCommentary
              item={item}
              modifyItemLayout={modifyItemLayout}
              modifyRowSpan={modifyRowSpan}
              layout={layout}
            />
          )}
          {createComponent({ component, name, item })}
        </BoardItem>
      )}
    </>
  );
};

export default ReportsBoard;
