import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import getAppSite from 'utils/getAppSite';
import addHoverTextProps from 'components/Dashboard/helpers/addHoverTextProps';
import { net_api_url } from 'components/Store';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';
const RoleSelect = (props) => {
  const {
    item,
    disabled,
    team,
    setTeam,
    index,
    teamPage,
    adminPage,
    teamMembers,
    setChangingRoleUserIds,
    сhangingRoleUserIds,
  } = props;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [changingRole, setChangingRole] = useState(false);
  const { getAccessToken } = useGetAccessToken();

  const changeRole = async (id, role) => {
    setChangingRole(true);
    if (setChangingRoleUserIds) {
      setChangingRoleUserIds([...сhangingRoleUserIds, id]);
    }
    let token = await getAccessToken();
    callSwitchRole(token, id, role);
  };
  const callSwitchRole = (token, id, role) => {
    axios
      .post(
        `${net_api_url}${adminPage ? '/api/admin/switch-user-role' : '/api/team/switch-user-role'}`,
        {
          userId: parseInt(id),
          newRole: role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
      .then((res) => {
        createNotification('success', `Role changed successfully`);
        if (teamPage) {
          if (team?.allMembers) {
            setTeam({
              ...team,
              allMembers: team?.allMembers?.map((member) => {
                if (member.id === parseInt(id)) {
                  return { ...member, newRole: role };
                }
                return member;
              }),
            });
          } else if (teamMembers) {
            setTeam([
              ...teamMembers?.map((member) => {
                if (member.id === parseInt(id)) {
                  return { ...member, newRole: role, teamRole: role };
                }
                return member;
              }),
            ]);
          }
        }
        setChangingRole(false);
        if (setChangingRoleUserIds) {
          setChangingRoleUserIds(сhangingRoleUserIds.filter((item) => item === id));
        }
      })
      .catch((error) => {
        if (error.response) {
          createNotification('danger', `An error occurred`);
          setChangingRole(false);
          if (setChangingRoleUserIds) {
            setChangingRoleUserIds(сhangingRoleUserIds.filter((item) => item === id));
          }
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error(error.request);
        }
      });
  };

  const updateRole = async (code, role) => {
    setChangingRole(true);
    let token = await getAccessToken();
    callUpdateRole(token, code, role);
  };

  const callUpdateRole = (token, code, role) => {
    axios
      .post(
        `${net_api_url}/api/team/update-invite`,
        {
          code: code,
          role: role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      )
      .then((res) => {
        let newTeam = team.openInvitations.slice();
        newTeam[index] = res.data.invite;
        setTeam({ ...team, openInvitations: newTeam });
        createNotification('success', `Invitation updated successfully`);
        setChangingRole(false);
      })
      .catch((error) => {
        if (error.response) {
          createNotification('danger', `An error occurred`);
          setChangingRole(false);
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error(error.request);
        }
      });
  };
  const roleElement = useRef(null);
  const [showRole, setShowRole] = useState(false);
  const hideRole = () => {
    setShowRole(false);
  };
  useOutsideElement(roleElement, hideRole);
  const [role, setRole] = useState(adminPage ? item.teamRole : item.role);
  useEffect(() => {
    if (item.newRole) {
      setRole(item.newRole);
    } else {
      setRole(adminPage ? item.teamRole : item.role);
    }
  }, [item, item.newRole]);

  const { code, id } = item;
  const setNewRole = (value) => {
    setRole(value);
    setShowRole(false);
    if (team && !teamPage) {
      updateRole(code, value);
    } else {
      changeRole(id, value);
    }
  };
  const disabledConditional = changingRole || disabled;
  return (
    <div
      className='position-relative role-select-container'
      ref={roleElement}
      {...(getAppSite() === 'usa'
        ? addHoverTextProps({
            disabled: true,
            message: 'Currently disabled for US site',
          })
        : {})}
    >
      <p
        className={`mb-0 hour-select px-3  ${
          showRole ? 'hour-select-opendropdown' : ''
        } ${disabled ? 'disabled-role-select' : ''} ${changingRole ? 'disabled-hour-select' : ''}`}
        onClick={() => {
          if (!disabledConditional) {
            setShowRole(!showRole);
          }
        }}
      >
        {role === 'Member' ? 'Team member' : role}
      </p>

      {showRole && (
        <>
          <div className='frequency-dropdown text-left team-role-dropdown'>
            <p
              className={`mb-0 frequency-item px-3 py-2 ${role === 'Member' ? 'frequency-item-selected' : ''}`}
              onClick={() => {
                setNewRole('Member');
              }}
            >
              Team member
            </p>
            <p
              className={`mb-0 frequency-item px-3 py-2 ${role === 'Admin' ? 'frequency-item-selected' : ''}`}
              onClick={() => {
                setNewRole('Admin');
              }}
            >
              Admin
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default RoleSelect;
