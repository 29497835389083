import React, { useEffect, useRef, useState } from 'react';
import ExplanationItem from './ExplanationItem.js';
import MatchingListItem from './MatchingListItem.js';
import preventLink from './prevenLink.js';
import useWindowHeight from 'components/Dashboard/utilities/useWindowHeight.js';

const RenderingTags = (props) => {
  const { terms, matchingKeywordLists, calendarTags } = props;
  const [showMoreTags, setShowMoreTags] = useState(false);
  const { windowWidth } = useWindowHeight();

  const tagsContainerElement = useRef();
  const tagsElementsContainer = useRef();

  const moreElement = useRef();
  const [moreItems, setMoreItems] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const items = tagsContainerElement?.current
      ? [...tagsContainerElement?.current?.querySelectorAll(`.explanation-container`)]?.filter(
          (item) => item?.offsetTop > 0
        )
      : [];
    setMoreItems(items?.length);
  }, [windowWidth, tagsContainerElement.current]);

  useEffect(() => {
    if (tagsElementsContainer?.current) {
      const children = Array.from(tagsElementsContainer?.current?.children);
      if (children.length > 0) {
        const firstChildTop = children[0].offsetTop;
        const firstRowChildren = children.filter((child) => child.offsetTop === firstChildTop);
        const lastInFirstRow = firstRowChildren[firstRowChildren.length - 1];
        if (!!lastInFirstRow) {
          const { width } = lastInFirstRow?.getBoundingClientRect() ?? {};
          setContainerWidth(width + lastInFirstRow?.offsetLeft);
        }
      }
    }
  }, [windowWidth]);

  return (
    <div className='flex-centered rendering-items-container position-relative' ref={tagsContainerElement}>
      <div
        className='d-flex align-items-start flex-wrap overflow-hidden position-relative'
        style={{ maxHeight: '34px' }}
        ref={tagsElementsContainer}
      >
        {terms.map((item, index) => {
          if (matchingKeywordLists) {
            return <MatchingListItem item={item} key={`keywordlist-${index}`} calendarTags={calendarTags} />;
          } else {
            return <ExplanationItem {...props} name={item} key={`explanation-${index}`} />;
          }
        })}
      </div>

      <div
        className='more-tags-text position-absolute'
        ref={moreElement}
        style={{ visibility: moreItems > 0 ? 'visible' : 'hidden', left: `${containerWidth}px` }}
      >
        <div className='position-relative'>
          <span
            className='more-text nowrap-item'
            onClick={(e) => {
              preventLink(e);
              setShowMoreTags(true);
            }}
          >
            {moreItems} more <span className='icon-arrow-down light-40-text' />
          </span>
          {showMoreTags && (
            <div className='more-tags-container'>
              <p
                className='less-tags px-3 py-2 mb-0'
                onClick={(e) => {
                  preventLink(e);
                  setShowMoreTags(false);
                }}
              >
                less
                <span className='icon-arrow-down light-40-text d-inline-block-centered mt-0 specific-left-margin-4' />
              </p>
              <div className='px-3 pb-2'>
                {terms.slice(terms?.length - moreItems)?.map((item, index) => {
                  if (matchingKeywordLists) {
                    return <MatchingListItem item={item} key={`keywordlist-${index}`} calendarTags={calendarTags} />;
                  } else {
                    return <ExplanationItem {...props} name={item} key={`explanation-${index}`} />;
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderingTags;
