import React, { useEffect, useRef, useState } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import ModalComponent from 'components/Common/Modal';
import { ReportsAnalyticsSettings } from './ReportsAnalyticsItem';
import { LatestPoliticalUpdatesSettings } from './LatestNews';
import { MostInterestedStakeholderSettings } from './MostInterestedStakeholder';
import { SpecificUpdateSettings } from './SpecificUpdateComponent';

const ReportsActionsElement = (props) => {
  const { actions, item, modifyItemLayout, setWidgetData, report } = props;
  const { state, component } = item?.data ?? {};
  const [showActions, setShowActions] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const actionsElement = useRef(null);

  const hideActions = () => {
    setShowActions(false);
  };

  useOutsideElement(actionsElement, hideActions);
  return (
    <>
      <div className='position-relative z-max' ref={actionsElement}>
        <button
          className={`general-button circle-button item-actions-button ${showActions ? 'active' : ''}`}
          onClick={() => {
            setShowActions(!showActions);
          }}
        >
          <span className='icon-edit-pencil-thick title-h6 text-light-blue' />
        </button>
        {showActions && (
          <div className='box box-shadow py-2 item-actions-box reports-actions-box'>
            <button className='general-button d-flex' onClick={() => setIsOpen(true)}>
              <span className='icon-edit-bold-container'>
                <span className='icon-edit-bold mr-2 paragraph-p2' />
              </span>{' '}
              <span>Edit widget {component === 'Commentary' ? 'name' : ''}</span>
            </button>
            {component === 'Commentary' ? (
              <>
                {state?.value !== '' && (
                  <button
                    className='general-button d-flex'
                    onClick={() => {
                      modifyItemLayout({ item, state: { ...state, saved: false } });
                      setShowActions(false);
                    }}
                  >
                    <span className='icon-commentary-filled-container'>
                      <span className='icon-commentary-filled mr-2 paragraph-p2 d-inline-block-centered' />
                    </span>{' '}
                    <span>Edit {item?.data?.state?.type === 'macro' ? 'text' : 'commentary'}</span>
                  </button>
                )}
              </>
            ) : (
              <>
                <button
                  className='general-button d-flex'
                  onClick={() => {
                    let commentary = state?.commentary;
                    if (commentary !== null) {
                      if (commentary.saved) {
                        modifyItemLayout({
                          item,
                          state: { ...state, commentary: { ...state?.commentary, saved: false } },
                        });
                      } else {
                        let editorHeight = actionsElement.current
                          ?.closest("div[class*='awsui_content-wrapper']")
                          ?.querySelector('.local-commentary')
                          ?.getBoundingClientRect()?.height;
                        let editorSpanHeight = Math.floor(editorHeight / 96);
                        modifyItemLayout({
                          item,
                          state: { ...state, commentary: null },
                          rowSpan: item?.rowSpan - editorSpanHeight,
                        });
                      }
                    } else {
                      modifyItemLayout({
                        item,
                        state: { ...state, commentary: { value: '' } },
                        rowSpan: item?.rowSpan + 1,
                      });
                    }
                    setShowActions(false);
                  }}
                >
                  <span className='icon-commentary-filled-container'>
                    <span className='icon-commentary-filled mr-2 paragraph-p2 d-inline-block-centered' />
                  </span>{' '}
                  <span>{state?.commentary ? (state?.commentary?.saved ? 'Edit' : 'Delete') : 'Add'} commentary</span>
                </button>
              </>
            )}
            <button
              className='general-button d-flex delete-widget-button'
              onClick={() => {
                actions.removeItem();
              }}
            >
              <span className='icon-delete-container'>
                <span className='icon-delete mr-2 paragraph-p3' />
              </span>{' '}
              <span>Remove widget</span>
            </button>
          </div>
        )}
      </div>
      <ItemSettingsPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        item={item}
        modifyItemLayout={modifyItemLayout}
        setWidgetData={setWidgetData}
        report={report}
      />
    </>
  );
};

const ItemSettingsPopup = (props) => {
  const { isOpen, setIsOpen, item, modifyItemLayout, setWidgetData, report } = props;
  const { component, name } = item.data;
  const [state, setState] = useState(item?.data?.state ?? {});

  useEffect(() => {
    //AE: This modal component renders not on open but when the componen is loading, so e.g on policymakers the state is updated after the API call for what is necessary update the local state
    setState(item?.data?.state);
  }, [item?.data?.state]);

  const closePopup = (value) => {
    setIsOpen(value);
    setState(item?.data?.state ?? {});
  };

  const createComponent = () => {
    const props = {
      state,
      setState,
      report,
    };
    switch (component) {
      case 'Graph':
        return <ReportsAnalyticsSettings chartType={name} {...props} />;
      case 'LatestNews':
        return <LatestPoliticalUpdatesSettings {...props} />;
      case 'MostInterestedStakeholders':
        return <MostInterestedStakeholderSettings {...props} />;
      case 'SpecificUpdate':
        return <SpecificUpdateSettings {...props} />;
      default:
        return null;
    }
  };

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={closePopup}>
      <div className='reports-edit-container'>
        <h3 className='topic-title mb-0 section-title pl-4 pb-3 website-popup-title bg-white rounded-top'>
          Edit this widget
        </h3>
        <div className='px-4 dashboard-root-container pb-2 overflow-auto report-edit-content scroll-container hover-container'>
          <div className='hover-content'>
            <div className='form-group pt-4 mb-0'>
              <label htmlFor='widgetName' className='font-weight-bold'>
                Widget name
              </label>
              <input
                id='widgetName'
                className='form-control main-input'
                type='text'
                value={state?.widgetName ?? ''}
                onChange={(e) => {
                  setState({ ...state, widgetName: e.target.value });
                }}
              />
            </div>
            {!!createComponent() && <div className='topic-email-alerts-content'>{createComponent()}</div>}
          </div>
        </div>

        <div className='d-flex justify-content-end p-4'>
          <button
            className={`generate-code-button general-button action-button no-select-button px-5 py-2`}
            onClick={() => {
              if (
                component !== 'SpecificUpdate' ||
                (component === 'SpecificUpdate' && item?.data?.state?.highlightUpdate !== state.highlightUpdate)
              ) {
                setWidgetData(null);
              }
              modifyItemLayout({ state, item });
              setIsOpen(false);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ReportsActionsElement;
