import React from 'react';
import { useState } from 'react';
import { store } from 'components/Store.js';
import { useContext } from 'react';
import SearchBarWithTeamContent from './SearchBarWithTeamContent.js';
import transformToPascalCase from 'components/Settings/Utilities/TransformToPascalCase';
import { useParams } from 'react-router-dom';

const EngagementStatisticsDropdown = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { engagementStatisticsResult } = state;
  const [isOpen, setIsOpen] = useState(false);

  const params = useParams();
  const { itemId, type } = params;
  const itemType = transformToPascalCase(type?.split('-').join(' ') ?? '');
  const selectedContact = engagementStatisticsResult.find(
    (item) => item.id === parseFloat(itemId) && item.type === itemType
  )?.title;

  return (
    <>
      {engagementStatisticsResult?.length > 0 ? (
        <SearchBarWithTeamContent
          teamsContent={engagementStatisticsResult}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedContact={selectedContact}
        />
      ) : (
        <div style={{ height: '38px' }}></div>
      )}
    </>
  );
};

export default EngagementStatisticsDropdown;
