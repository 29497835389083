import { useContext, useRef } from 'react';
import TextForCell from 'components/Dashboard/Influence/tableComponents/TextForCell';
import { normalizedDateUtc } from 'components/Dashboard/utilities/normalizedDateUK';
import { Link } from 'react-router-dom';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { store } from 'components/Store';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';

const CrmGroupItem = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { selectedGroups } = state;
  const { selectedAll, selectedIds } = selectedGroups;

  const { item } = props;
  const { id, name, type, description, currentContactCount, createdAt, createdByFullName } = item;

  const isSelected = selectedAll || selectedIds?.includes(id);
  const handleCheck = () => {
    let newSelectedIds = [...selectedIds];
    if (isSelected) {
      let position = newSelectedIds?.indexOf(id);
      newSelectedIds.splice(position, 1);
    } else {
      newSelectedIds.push(id);
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedGroups',
      value: { selectedAll: false, selectedIds: newSelectedIds },
    });
  };

  const rowRef = useRef();
  const titleCellRef = useRef();
  const descriptionCellRef = useRef();
  const groupType = useRef();
  const groupSize = useRef();
  const dateCellRef = useRef();
  const createdByRef = useRef();

  return (
    <tr className={`crm-table-item table-item-alignment-adjustment`} ref={rowRef}>
      <td>
        <div className={`crm-table-item-checkbox ${selectedAll ? 'crm-table-item-checkbox-selected-all' : ''}`}>
          <input
            type={'checkbox'}
            checked={isSelected}
            onChange={handleCheck}
            id={`crmContact-${id}`}
            disabled={selectedAll}
          />
          <label htmlFor={`crmContact-${id}`} />
        </div>
      </td>
      <td className='crm-table-item-name' ref={titleCellRef}>
        <Link className='content-item-link nowrap-item' to={`/influence/my-groups/${id}`}>
          <TextForCell text={name} ref={titleCellRef} type={'title'} />
        </Link>
      </td>
      <td ref={descriptionCellRef} id={1}>
        <TextForCell text={description} ref={descriptionCellRef} />
      </td>
      <td ref={groupType}>
        <span
          className={`group-type group-type-${normalize(type)} d-inline-block-centered`}
          style={{ marginTop: '0px' }}
        />
        <TextForCell text={uppercaseFirstLetter(type)} ref={groupType} />
      </td>
      <td ref={groupSize}>
        <TextForCell text={thousandCommas(currentContactCount.toString())} ref={groupSize} />
      </td>
      <td ref={dateCellRef}>{normalizedDateUtc(createdAt).format('D MMM, YYYY')}</td>
      {/* <td ref={lastContactCellRef}>{}</td> */}
      <td ref={createdByRef}>
        <TextForCell text={createdByFullName ?? 'PolicyMogul'} ref={createdByRef} />
      </td>
    </tr>
  );
};

export default CrmGroupItem;
