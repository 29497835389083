import ConfigData from 'config/ConfigData';

const LegislationFilters = [
  {
    name: 'Sort',
    searchState: 'allResults',
    type: 'sort',
    pages: ['Legislation'],
    hidden: true,
    optionSelected: 'Title ascending',
    options: [
      {
        name: 'Title ascending',
        filter: [{ pages: ['Legislation'], property: { field: 'title', order: 'ASC' } }],
        selected: true,
      },
    ],
    configHidden: ConfigData?.filters?.legislationSort?.configHidden,
  },
  {
    name: 'Type',
    searchState: 'always',
    pages: ['Legislation'],
    filterName: 'contentType',
    options: [
      {
        name: 'Primary legislation',
        filterValue: 'Primary',
        hash: 'primary',
        optionSelected: 'All bills',
        suboptions: [
          {
            name: 'Government bills',
            filter: [
              {
                contentType: 'Primary',
                filter: { field: 'primaryTypeOfBill', value: 'Government Bill', operator: 'str_eq' },
              },
              {
                contentType: 'Secondary',
                filter: { field: 'primaryTypeOfBill', value: 'Secondary', operator: 'str_eq' },
              },
            ],
          },
          {
            name: 'All bills',
          },
        ],
      },
      {
        name: 'Secondary legislation',
        filterValue: 'Secondary',
        hash: 'secondary',
        filter: { field: 'contentType', value: 'Secondary', operator: 'str_eq' },
      },
    ],
    configHidden: ConfigData?.filters?.legislationType?.configHidden,
  },
  {
    type: 'membership',
    subtype: 'usStateOptions',
    prefix: 'state',
    name: 'State',
    pages: ['Legislation'],
    filterName: 'usState',
    optionSelected: '',
    options: [],
    configHidden: ConfigData?.filters?.state?.configHidden,
  },
];

const usStateOptions = [
  { name: 'US Congress' },
  { name: 'District of Columbia' },
  { name: 'Alabama' },
  { name: 'Alaska' },
  { name: 'Arizona' },
  { name: 'Arkansas' },
  { name: 'California' },
  { name: 'Colorado' },
  { name: 'Connecticut' },
  { name: 'Delaware' },
  { name: 'Florida' },
  { name: 'Georgia' },
  { name: 'Hawaii' },
  { name: 'Idaho' },
  { name: 'Illinois' },
  { name: 'Indiana' },
  { name: 'Iowa' },
  { name: 'Kansas' },
  { name: 'Kentucky' },
  { name: 'Louisiana' },
  { name: 'Maine' },
  { name: 'Maryland' },
  { name: 'Massachusetts' },
  { name: 'Michigan' },
  { name: 'Minnesota' },
  { name: 'Mississippi' },
  { name: 'Missouri' },
  { name: 'Montana' },
  { name: 'Nebraska' },
  { name: 'Nevada' },
  { name: 'New Hampshire' },
  { name: 'New Jersey' },
  { name: 'New Mexico' },
  { name: 'New York' },
  { name: 'North Carolina' },
  { name: 'North Dakota' },
  { name: 'Ohio' },
  { name: 'Oklahoma' },
  { name: 'Oregon' },
  { name: 'Pennsylvania' },
  { name: 'Rhode Island' },
  { name: 'South Carolina' },
  { name: 'South Dakota' },
  { name: 'Tennessee' },
  { name: 'Texas' },
  { name: 'Utah' },
  { name: 'Vermont' },
  { name: 'Virginia' },
  { name: 'Washington' },
  { name: 'West Virginia' },
  { name: 'Wisconsin' },
  { name: 'Wyoming' },
];

export { usStateOptions };
export default LegislationFilters;
