import { Portal } from 'react-portal';
import WebsiteFeedMain from './WebsiteFeedMain';
import { useContext, useState } from 'react';
import { store } from 'components/Store';
import WebsiteFeaturePopupContent from './WebsiteFeaturePopup';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import ModalComponent from 'components/Common/Modal';

const EmbedWebsiteButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, activePlan, readyKeywordLists } = state;
  const { enableEmbedWidgets, subscriptionStatus } = activePlan;

  const { settingsPage, listToUse } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [showFeaturePopup, setShowFeaturePopup] = useState(false);

  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      {settingsPage ? (
        <button
          className='action-button general-button px-4 py-2'
          onClick={() => {
            if (enableEmbedWidgets) {
              setShowPopup(true);
            } else {
              setShowFeaturePopup(true);
            }
          }}
        >
          Embed a real-time feed{' '}
          <span className='d-none d-lg-inline-block'>on {newAgencyPlan ? 'any' : 'your'} website</span>
        </button>
      ) : (
        <>
          {readyKeywordLists &&
            subscriptionStatus !== 'SubscribedOverLimit' &&
            subscriptionStatus !== 'OnFreePlanOverLimit' &&
            subscriptionStatus !== 'TrialEnded' &&
            keywordsLists.length > 0 && (
              <button
                className='general-button action-state-hover border code-button ml-2 ml-lg-0  main-subtle-text nowrap-item'
                data-tooltip-id={'general-tooltip'}
                data-tooltip-place={'bottom'}
                onClick={(e) => {
                  if (enableEmbedWidgets) {
                    setShowPopup(true);
                  } else {
                    setShowFeaturePopup(true);
                  }
                }}
              >
                <span className='icon-code-thin paragraph-p4' />{' '}
                <span className='d-none d-lg-inline' style={{ marginLeft: '6px' }}>
                  {' '}
                  Embed feed {!enableEmbedWidgets && <span className='icon-padlock ml-1 paragraph-p4' />}
                </span>
              </button>
            )}
        </>
      )}
      <WebsitePopup setShowPopup={setShowPopup} showPopUp={showPopup} listToUse={listToUse} />
      <WebsiteFeaturePopup setShowPopup={setShowFeaturePopup} showPopUp={showFeaturePopup} />
    </>
  );
};

const WebsitePopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <Portal>
      <ModalComponent
        style={{ height: '94vh', minHeight: 'auto' }}
        maxWidth={1050}
        isOpen={showPopUp}
        setIsOpen={setShowPopup}
      >
        <WebsiteFeedMain {...props} />
      </ModalComponent>
    </Portal>
  );
};

const WebsiteFeaturePopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <Portal>
      <ModalComponent maxWidth={900} isOpen={showPopUp} setIsOpen={setShowPopup}>
        <WebsiteFeaturePopupContent {...props} />
      </ModalComponent>
    </Portal>
  );
};

export default EmbedWebsiteButton;
