import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { store } from 'components/Store';
import { Portal } from 'react-portal';
import ModalComponent from 'components/Common/Modal';

const StakeHolderSharePopUp = () => {
  const location = useLocation();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { shareStakeholderPopupProps } = state;
  const { title, header, urlToShare: urlToShareProp, influenceItem } = shareStakeholderPopupProps ?? {};

  let urlToShare = window.location.origin;
  let analyticsTracking = `${location.search === '' ? '?' : '&'}utm_source=share-button`;
  const url =
    urlToShareProp ??
    `${urlToShare}${location.pathname}${location.search}${location.hash}${
      !location.search.includes('utm_source=share-button') ? analyticsTracking : ''
    }`;

  const closeModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'shareStakeholderPopupProps',
      value: {
        ...shareStakeholderPopupProps,
        show: false,
      },
    });
  };

  return (
    <Portal>
      <ModalComponent maxWidth={500} isOpen={shareStakeholderPopupProps?.show} setIsOpen={closeModal}>
        <div className='pt-4'>
          <div className='p-3 px-lg-5 py-lg-3'>
            <h3 className='main-title'>{header}</h3>
            <div className='d-flex justify-content-center px-4 pb-4 pt-2'>
              <div className='row stakeholder-share-buttons share-popoup'>
                <div>
                  <button
                    className='general-button position-relative p-3 copy-link-button'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      let tempInput = document.createElement('input');
                      tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
                      tempInput.value = url;
                      document.body.appendChild(tempInput);
                      tempInput.select();
                      document.execCommand('copy');
                      document.body.removeChild(tempInput);
                      closeModal();
                      createNotification('success', `Link copied`);
                    }}
                  >
                    <span className='icon-share-link title-h2' style={{ color: '#f2a805' }}></span>
                    <span>Copy link</span>
                  </button>
                  <TwitterShareButton
                    url={url}
                    title={title}
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <span className='icon-twitter-x-circle' style={{ fontSize: '30px' }}></span>
                    <span>Share on X</span>
                  </TwitterShareButton>
                </div>

                {influenceItem && (
                  <div>
                    <LinkedinShareButton
                      title={title}
                      source={'Policy influence'}
                      className={`linkedin-button pointer text-left general-button`}
                      url={url}
                      resetButtonStyle={false}
                    >
                      <span className={`icon-share-image icon-linkedin`} /> <span>Share on LinkedIn</span>
                    </LinkedinShareButton>

                    <FacebookShareButton
                      quote={title}
                      url={url}
                      resetButtonStyle={false}
                      className={`facebook-button pointer text-left general-button`}
                    >
                      <span
                        className={`icon-share-image icon-facebook title-h3`}
                        style={{ marginTop: '-4px', verticalAlign: 'middle' }}
                      />{' '}
                      <span>Share on Facebook</span>
                    </FacebookShareButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </Portal>
  );
};

export default StakeHolderSharePopUp;
