import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';

const changeLabelOfContent = (contentType) => {
  switch (contentType) {
    case 'ScotlandCountrySpecificParliamentaryContribution':
      return 'Official report (Scotland)';
    case 'WalesCountrySpecificParliamentaryContribution':
      return 'Official report (Wales)';
    case 'ScotlandQuestionAndAnswer':
      return 'Written Q&As (Scotland)';
    case 'WalesQuestionAndAnswer':
      return 'Written Q&As (Wales)';
    case 'NorthernIrelandQuestionAndAnswer':
      return 'Written Q&As (NI)';
    case 'SeneddCommitteeTranscriptContribution':
      return 'Senedd committee transcript';
    default:
      return TransformFromPascalCase(contentType);
  }
};
export default changeLabelOfContent;
