import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import { useContext, useState } from 'react';
import { AdminStore } from './AdminState';
import ClientComponent from 'components/Settings/Clients/ClientComponent';
import CreateClient from 'components/Settings/Clients/CreateClient';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const TeamClientPage = (props) => {
  const adminState = useContext(AdminStore);
  const { state, dispatch } = adminState;
  const { organisation } = state ?? {};
  const { teams, maxListsAllowed, maxNumberOfTeams } = organisation ?? {};

  const [isOpen, setIsOpen] = useState(false);
  const [stateExpanded, setStateExpanded] = useState(true);

  const [heightContainer, containerRef] = useHeightContainer();
  const clientsList = teams?.map((item) => {
    const { name, keywordLists, id } = item;
    if (!item?.teamName) {
      return {
        ...item,
        teamName: name,
        teamId: id,
        myKeywordLists: keywordLists,
        teamMemberKeywordLists: [],
      };
    } else if (!item?.myKeywordLists) {
      return {
        ...item,
        myKeywordLists: [],
        teamMemberKeywordLists: [],
      };
    } else {
      return item;
    }
  });

  const setClientList = (teams) => {
    let newOrganisation = { ...organisation };
    newOrganisation = {
      ...newOrganisation,
      teams: teams,
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'organisation', value: newOrganisation });
  };

  const allowAddClients = maxNumberOfTeams === null || clientsList?.length < maxNumberOfTeams;

  return (
    <>
      <div className='row mx-0 justify-content-between py-4 align-items-center'>
        <div className='flex-centered'>
          <h3 className='my-lg-0 main-title mb-0 mr-3'>Team clients</h3>
        </div>
        <div className='team-clients-admin-header mt-2 mt-lg-0'>
          {clientsList?.length > 1 && (
            <button
              className={`bg-white general-button border collapse-expand-button action-state-hover main-subtle-text ${
                stateExpanded ? 'collapse-button' : ''
              }`}
              onClick={() => {
                setStateExpanded(!stateExpanded);
              }}
            >
              {stateExpanded ? 'Expand' : 'Collapse'} all
            </button>
          )}
          <button
            className='action-button general-button px-3 filter-action-button ml-3'
            onClick={() => setIsOpen(!isOpen)}
            disabled={!allowAddClients}
          >
            Add a new client {!allowAddClients && <span className='icon-padlock d-inline-block-centered ml-1' />}
          </button>
        </div>
      </div>
      <div className='box-top-rounded bg-white admin-general-content'>
        <div ref={containerRef} />
        <CustomScrollbar
          className={'topics-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
        >
          <div>
            {teams?.length === 0 ? (
              <p className='p-5 text-center'>No clients created yet</p>
            ) : (
              <>
                {clientsList?.map((item, index, array) => {
                  const { teamId } = item;
                  return (
                    <ClientComponent
                      key={`client-${teamId}`}
                      client={item}
                      clientsListState={{ clientsList, setClientList }}
                      index={index}
                      array={array}
                      maxListsPerTeam={maxListsAllowed}
                      maxNumberOfTeams={maxNumberOfTeams}
                      stateExpanded={stateExpanded}
                    />
                  );
                })}
              </>
            )}
          </div>
        </CustomScrollbar>
      </div>
      <CreateClient
        activeClientState={{ activeClient: null }}
        clientsListState={{ clientsList, setClientList }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default TeamClientPage;
