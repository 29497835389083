import React, { useState, useRef } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import { useAuth0 } from '@auth0/auth0-react';
import NavigationTeamsContents from './NavigationTeamsContents';
import { useParams } from 'react-router-dom';

const SearchBarWithTeamContent = ({ isMainHome, setIsOpen, isOpen, teamsContent, selectedContact }) => {
  const [maxHeightContainer, setMaxHeightContainer] = useState(293);

  const params = useParams();
  let { itemId } = params;

  const showOptionsList = () => {
    if (!!setIsOpen) {
      return isOpen;
    } else {
      return showDropdownContent;
    }
  };
  const [showDropdownContent, setShowDropdownContent] = useState(false);

  const searchBarElement = useRef(null);

  const { isAuthenticated } = useAuth0();

  const hideSearchBar = () => {
    if (!!setIsOpen) {
      setIsOpen(false);
    } else {
      setShowDropdownContent(false);
    }
  };
  useOutsideElement(searchBarElement, hideSearchBar);

  let cursorConditional = !showOptionsList() ? 'pointer' : 'auto';

  const calculateMaxHeightContainer = useRef();
  calculateMaxHeightContainer.current = () => {
    let elements = document.querySelectorAll('.dropdown-keywordlist');
    let newHeight = 38;
    for (let i = 0; i < elements.length; i++) {
      let el = elements[i];
      let currentHeight = newHeight + el.getBoundingClientRect().height;
      if (currentHeight > 293) {
        newHeight = currentHeight - el.getBoundingClientRect().height / 3;
        break;
      } else {
        newHeight = currentHeight;
      }
    }
    if (elements.length > 3) {
      setMaxHeightContainer(newHeight);
    }
  };

  return (
    <>
      {showOptionsList() && <div className='close-tips'></div>}
      <div
        className={`dropdown-keywords-lists-influence search-bar-engagement-statistics ${
          showOptionsList() ? 'dropdown-keywords-lists-open' : ''
        } ${!isAuthenticated ? 'dropdown-kewyord-lists-logged-out' : ''} ${
          !isAuthenticated && isMainHome ? 'dropdown-kewyord-lists-home' : ''
        } mr-lg-3`}
        ref={searchBarElement}
        data-intercom-target='Topics dropdown'
      >
        <div
          className='dropdown-keywords-activeitem flex-centered'
          style={{
            cursor: cursorConditional,
          }}
          onClick={() => {
            if (!!setIsOpen) {
              setIsOpen(true);
            } else {
              setShowDropdownContent(true);
            }
            setTimeout(() => {
              calculateMaxHeightContainer.current();
            }, 20);
          }}
        >
          {/* TYPE ICON */}
          <span className={`icon-briefing-material title-h5-m`} style={{ marginRight: '11px', marginTop: '1px' }} />
          {/*INPUT SEARCHBAR */}
          <p
            className={`search-main-text-value`}
            style={{
              cursor: cursorConditional,
            }}
          >
            <span className={`d-block hidden-lines hidden-one-line`}>
              {selectedContact ?? 'Select lobbying material'}
            </span>
          </p>

          {/* ARROW BUTTON */}
          {isAuthenticated && (
            <span
              className={`search-dropdown ${showOptionsList() ? 'search-dropdown-open' : ''}`}
              data-tooltip-content={`${isAuthenticated && !showOptionsList() ? `Select a team content` : ''}`}
              data-tooltip-id='general-tooltip'
              data-tooltip-place='right'
              onClick={(e) => {
                if (showOptionsList()) {
                  e.stopPropagation();
                  hideSearchBar();
                }
              }}
            ></span>
          )}
        </div>
        <div className={`open-dropdown d-${showOptionsList() ? 'block' : 'none'}`}>
          <div className='open-dropdown-container'>
            {!!teamsContent.length && (
              <NavigationTeamsContents
                teamsContent={teamsContent}
                maxHeightContainer={maxHeightContainer}
                selectedItem={itemId}
                setIsOpen={setIsOpen}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBarWithTeamContent;
