import normalize from 'components/Dashboard/utilities/normalizeString';
import ItemsPalette from '@cloudscape-design/board-components/items-palette';
import { boardItemI18nStrings, paletteI18nStrings } from './i18n-strings';
import { useCreateReportsWidgets } from './useCreateReportsWidgets';
import BoardItem from '@cloudscape-design/board-components/board-item';
import MacrosList from './Macros';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { useReportsDefaults } from 'components/Reports/ReportsDefaults';

const ReportsSidebar = (props) => {
  const { layout, setLayout, macrosState, isLoading, report, defaultProperty } = props;
  const { createWidgets } = useCreateReportsWidgets({ reportData: report });
  const { modifyDateOfGraphs } = useReportsDefaults();
  const initialItems = createWidgets({ layout, report }) ?? [];
  const items = !!defaultProperty
    ? modifyDateOfGraphs({ widgets: initialItems, type: defaultProperty, report })
    : initialItems;
  const [heightContainer, containerRef] = useHeightContainer();

  return (
    <div className='pt-lg-5 pt-3 main-sticky z-100'>
      <div ref={containerRef} />
      <CustomScrollbar
        className={'simple-scrollbar'}
        maximalThumbYSize={100}
        style={{ height: `${heightContainer + 1}px` }}
      >
        <ul className='px-0 mb-0 reports-side-nav pb-3'>
          <h3 className='my-3 title-h5-m-bold sidenav-subtitle'>Customise report</h3>
          <ItemsPalette
            items={items}
            i18nStrings={paletteI18nStrings}
            renderItem={(item) => {
              return <BoardItem i18nStrings={boardItemI18nStrings} header={<SidebarItem item={item} />} />;
            }}
          />
          {isLoading ? (
            <ul className='px-0 mb-0 reports-side-nav pt-4'>
              {Array(8)
                .fill()
                .map((item, index) => (
                  <div className={`mb-4`} style={{ paddingLeft: '32px' }} key={`skeleton-sidenav-${index}`}>
                    <Skeleton height={'24px'} width={'200px'} />
                  </div>
                ))}
            </ul>
          ) : (
            <MacrosList layout={layout} setLayout={setLayout} macrosState={macrosState} />
          )}
        </ul>
      </CustomScrollbar>
    </div>
  );
};

const SidebarItem = (props) => {
  const { item } = props;
  const { name, icon } = item.data;
  const sideNavElement = useRef();
  useEffect(() => {
    let parent = sideNavElement?.current?.parentElement?.parentElement?.parentElement;
    if (parent) {
      parent.classList.add(`report-sidebar-${normalize(name)}`);
    }
  }, []);

  return (
    <div ref={sideNavElement} className={`sidebar-item sidebar-item-report ${normalize(name)}-a `}>
      <div className='flex-centered'>
        <span className={`icon-container-${icon}`}>
          <span className={`political-updates-nav-icon icon-${icon}`} />
        </span>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default ReportsSidebar;
