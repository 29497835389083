import ConfigData from 'config/ConfigData';

export const regions = [
  'London',
  'East',
  'East Midlands',
  'North East',
  'North West',
  'South East',
  'South West',
  'West Midlands',
  'Yorkshire & The Humber',
  'Northern Ireland',
  'Scotland',
  'Wales',
];

export const createRegionOption = (region) => {
  if (region === 'Yorkshire & The Humber') {
    return { name: `Yorkshire & The Humber`, filterValue: 'Yorkshire and The Humber' };
  } else {
    return { name: region };
  }
};

const RegionFilters = [
  {
    name: 'Region',
    pages: ['InfluenceContacts', 'Stakeholders', 'Candidates'],
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'region',
    options: regions.map(createRegionOption),
    prefix: 'region',
    updateFilter: true,
    customOrder: 4,
    configHidden: ConfigData?.filters?.region?.configHidden,
  },
];

export default RegionFilters;
