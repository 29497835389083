import React, { useEffect, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import normalize from 'components/Dashboard/utilities/normalizeString';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

function CalendarPickerFilter(props) {
  const { filter, selectOption, notIncludeName } = props;
  const { options, optionSelected, name } = filter;

  const calendarOptions = options.filter((option) => option.showCalendar);
  const getPreselectedDate = () => {
    if (options.find((option) => option.name === optionSelected)?.dates) {
      if (options.find((option) => option.name === optionSelected)?.dates.split(',').length > 1) {
        new Date();
      } else {
        return new Date(options.find((option) => option.name === optionSelected)?.dates);
      }
    }
    return new Date();
  };

  const getPreselectedRange = () => {
    if (options.find((option) => option.name === optionSelected)?.dates) {
      if (options.find((option) => option.name === optionSelected)?.dates.split(',').length > 1) {
        const preselectedRangeArr = options.find((option) => option.name === optionSelected)?.dates.split(',');
        const firstValue = preselectedRangeArr[0].endsWith('-')
          ? preselectedRangeArr[0].substring(0, preselectedRangeArr[0].length - 1)
          : preselectedRangeArr[0];
        const secondValue = preselectedRangeArr[1].startsWith('-')
          ? preselectedRangeArr[1].substring(1)
          : preselectedRangeArr[1];

        return {
          from: new Date(firstValue),
          to: new Date(secondValue),
        };
      }
    }
    return {
      from: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 6)),
      to: new Date(),
    };
  };

  const [calendarState, setCalendarState] = useState({
    active: false,
    filterProperty: options.find((option) => option.name === optionSelected)?.filterProperty ?? '',
    selectedDay: getPreselectedDate(),
    name: '',
    range: getPreselectedRange(),
  });
  const { from, to } = calendarState.range;
  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    if (calendarState.filterProperty !== 'range') {
      setCalendarState({
        ...calendarState,
        selectedDay: day,
      });
    } else {
      let rangeToRender = calendarState.range.to !== '' ? { from: '', to: '' } : calendarState.range;
      const range = DateUtils.addDayToRange(day, rangeToRender);
      if (range.to !== null) {
        setCalendarState({
          ...calendarState,
          range: range,
        });
      }
    }
  };

  const ApplyCalendarFilters = () => {
    if (calendarState.filterProperty === '') {
      let newValue = { optionSelected: '', options: options };
      selectOption({ filter, newValue });
      return;
    }
    let localOptions = [...calendarOptions];
    let optionsToKeep = options.filter((option) => !option.showCalendar);
    let currentOption = localOptions.find((item) => item.filterProperty === calendarState.filterProperty);
    let filterToApply;
    let dates;

    if (currentOption.filterProperty === 'range') {
      filterToApply = [
        {
          field: 'dateTime',
          value: `${dayjs(calendarState.range.from).utc().startOf('day').format().replace('Z', '')}`,
          operator: 'DATE_GTE',
        },
        {
          field: 'dateTime',
          value: `${dayjs(calendarState.range.to !== '' ? calendarState.range.to : new Date())
            .utc()
            .startOf('day')
            .add(1, 'day')
            .format()
            .replace('Z', '')}`,
          operator: 'DATE_LT',
        },
      ];
      dates = `${calendarState.range.from},${calendarState.range.to !== '' ? calendarState.range.to : new Date()}`;
    } else {
      filterToApply = [
        {
          field: 'dateTime',
          value: `${dayjs(calendarState.selectedDay).utc().startOf('day').format().replace('Z', '')}`,
          operator: calendarState.filterProperty,
        },
      ];
      dates = `${calendarState.selectedDay}`;
    }

    currentOption.filter = filterToApply;
    currentOption.dates = dates;
    let newValue = {
      optionSelected: currentOption.name,
      options: [...optionsToKeep, ...localOptions],
    };
    selectOption({ filter, newValue });
  };

  useEffect(() => {
    ApplyCalendarFilters();
  }, [calendarState]);

  const getOptionValue = (name) => {
    if (name === calendarState.name) {
      if (name === 'Custom') {
        return `${
          calendarState.range.from !== '' ? `${dayjs(calendarState.range.from).format('DD MMMM, YYYY')}` : ''
        } ${
          calendarState.range.to !== ''
            ? `- ${dayjs(calendarState.range.to).format('DD MMMM, YYYY')}`
            : `- ${dayjs(calendarState.range.from).format('DD MMMM, YYYY')}`
        }`;
      } else {
        return dayjs(calendarState.selectedDay).format('DD MMMM, YYYY');
      }
    }
    return '';
  };
  return (
    <>
      {!notIncludeName && <h3>{name}</h3>}
      <div className='calendar-picker-filter'>
        <div className='flex-column justify-content-between'>
          <div className={`radio-button mb-2 `}>
            <input
              id={'any-time'}
              onChange={() => {
                setCalendarState({
                  ...calendarState,
                  active: true,
                  filterProperty: '',
                  name: name,
                });
              }}
              type='radio'
              checked={calendarState.filterProperty === ''}
            />
            <label htmlFor={'any-time'} style={{ height: 'unset' }}>
              Any date
            </label>
          </div>
          {calendarOptions.map((item, index) => {
            const { name } = item;
            return (
              <div
                className={`radio-button mb-2 `}
                key={`dateCalendar${index}`}
                onClick={() => {
                  setCalendarState({
                    ...calendarState,
                    active: true,
                    filterProperty: item.filterProperty,
                    name: name,
                  });
                }}
              >
                <input
                  id={normalize(name)}
                  type='radio'
                  readOnly
                  checked={calendarState.filterProperty === item.filterProperty}
                />
                <label htmlFor={normalize(name)} style={{ height: 'unset' }}>
                  {name === 'Custom' ? 'Between' : name}
                  {calendarState.filterProperty !== item.filterProperty ? '...' : ''}{' '}
                  <span style={{ color: 'rgba(0, 18, 43, 0.6)', lineHeight: 1.3 }}>{getOptionValue(name)}</span>
                </label>
              </div>
            );
          })}
          <div className='d-flex mt-4'>
            <button
              className='general-button delete-button px-4 mr-3'
              onClick={() => {
                setCalendarState({
                  ...calendarState,
                  range: { from: '', to: '' },
                  selectedDay: new Date(),
                });
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        {calendarState.filterProperty !== '' && (
          <div className='daypicker-wrapper'>
            <DayPicker
              month={
                calendarState.filterProperty === 'range' && calendarState.range.from !== ''
                  ? calendarState.range.from
                  : new Date()
              }
              className='Selectable'
              selectedDays={
                calendarState.filterProperty === 'range'
                  ? [calendarState.range.from, { from, to }]
                  : calendarState.selectedDay
              }
              modifiers={
                calendarState.filterProperty === 'range'
                  ? {
                      start: calendarState.range.from,
                      end: calendarState.range.to,
                    }
                  : {}
              }
              onDayClick={handleDayClick}
              firstDayOfWeek={1}
              showOutsideDays
              disabledDays={(day) => day > dayjs().add('1', 'day').toDate()}
              weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CalendarPickerFilter;
