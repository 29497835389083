import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from '../../Store';

const useAddMetaTags = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const location = useLocation();

  const addMetaTags = (props) => {
    clearMetaTags();
    const { title, hash, contentUrl, description, image } = props;

    //SET TITLES
    document.title = title;
    let metaTitle = document.createElement('meta');
    metaTitle.setAttribute('property', 'og:title');
    metaTitle.content = title;
    document.getElementsByTagName('head')[0].appendChild(metaTitle);
    const locationToUse = props?.location ?? location;
    let storageLocation = { ...locationToUse };

    if (contentUrl) {
      storageLocation.pathname = contentUrl;
    }
    if (hash) {
      storageLocation.hash = hash;
    }

    let locationToStorage = {
      title: title,
      storageLocation: storageLocation,
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
    //AE: I use this here because this is a function that applies on every resource to know if the results were loaded.
    dispatch({ type: 'MODIFY_SECTION', parameter: 'useLocalStorageForReference', value: true });

    //SET META ROBOTS
    if (location.pathname.includes('/calendar') || process.env.REACT_APP_APPURL !== 'https://policymogul.com') {
      let meta = document.createElement('meta');
      meta.name = 'robots';
      meta.content = 'noindex, nofollow';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }

    //SET LINK URL
    const influencePathnames = ['policy-asks', 'briefing-material', 'new-release', 'research'];
    const pathToUse = contentUrl ?? location.pathname;
    const lobbyingMaterialEnv = influencePathnames.filter((item) => pathToUse.startsWith(`/${item}`));
    let metaLink = document.createElement('meta');
    let linkToheader = document.createElement('link');
    linkToheader.rel = 'canonical';
    linkToheader.href = `${lobbyingMaterialEnv.length > 0 ? process.env.REACT_APP_POLICYINFLUENCE_APP_URL : process.env.REACT_APP_APPURL}${pathToUse}`;
    metaLink.setAttribute('property', 'og:url');
    metaLink.content = linkToheader.href;
    document.getElementsByTagName('head')[0].appendChild(metaLink);
    document.getElementsByTagName('head')[0].appendChild(linkToheader);

    //SET DESCRIPTION
    if (description && description !== '') {
      let descriptionToShow = description.length > 160 ? `${description.substring(0, 157)}...` : description;
      let metaDescriptionOg = document.createElement('meta');
      let metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      metaDescription.content = descriptionToShow;
      metaDescriptionOg.setAttribute('property', 'og:description');
      metaDescriptionOg.content = descriptionToShow;
      document.getElementsByTagName('head')[0].appendChild(metaDescriptionOg);
      document.getElementsByTagName('head')[0].appendChild(metaDescription);
    }
    //SET IMAGE
    let metaImage = document.createElement('meta');
    metaImage.setAttribute('property', 'og:image');
    let contentImage =
      image !== 0
        ? `https://www.parliamenttoday.com/image.php?imageid=${image}&mode=1`
        : `${process.env.REACT_APP_CDNURL}/images/keyupdates-placeholder.png`;
    metaImage.content = image
      ? contentImage
      : 'https://about.policymogul.com/wp-content/uploads/2018/12/social-policymogul.png';
    document.getElementsByTagName('head')[0].appendChild(metaImage);
  };

  const clearMetaTags = () => {
    clearMetaTag("[property='og:title']");
    clearMetaTag("[property='og:url']");
    clearMetaTag("[property='og:description']");
    clearMetaTag("[property='og:image']");
    clearMetaTag("[name='robots']");
    clearMetaTag("[name='description']");
    clearMetaTag("[rel='canonical']");
  };

  function clearMetaTag(selector) {
    if (document.querySelector(selector) !== null) {
      document.querySelector(selector).remove();
    }
  }

  return { addMetaTags };
};

export default useAddMetaTags;
