import React, { useContext, useEffect, useRef, useState } from 'react';
import useValidateContactsOversubscribed from 'components/Dashboard/utilities/useValidateContactsOversubscribed';
import useContactsApiCalls from 'components/Dashboard/Influence/CrmContacts/useContactsApiCalls';
import Axios from 'axios';
import { store } from 'components/Store';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';

function RemoveTagsFromContacts({
  expandedButton,
  isLoading,
  onlyIconOnMobile,
  setShowRemoveContactTagsModal,
  setShowChampionsStatusModal,
}) {
  const [openOptions, setOpenOptions] = useState(false);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedContactTags, selectedCrmContacts, contentResults, activeResults } = state;
  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();
  const { getContactTagsByQuery } = useContactsApiCalls();

  const containerElement = useRef();
  useOutsideElement(containerElement, () => setOpenOptions(false));

  const getTags = async (source) => {
    let tagsRes = [];
    if (selectedAll) {
      const tagsArray = await getContactTagsByQuery(source);
      if (tagsArray) {
        tagsRes = tagsArray;
      }
    } else {
      let idsArr = selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds.length > 0 ? selectedIds : null;
      if (idsArr) {
        let tagsSet = new Set();
        idsArr.forEach((id) => {
          let contactTags = contentResults[activeResults].CrmContacts?.hits.find((contact) => contact.id === id)?.tags;
          if (contactTags?.length > 0) {
            contactTags.forEach((tag) => tagsSet.add(tag));
          }
        });
        tagsRes = [...tagsSet];
      }
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedContactTags',
      value: tagsRes,
    });
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    getTags(source);
    return () => {
      source.cancel('Contact tags canceled by the user.');
    };
  }, [selectedCrmContacts]);

  return (
    <>
      <div className='contacts-list-bar-action-main-container' ref={containerElement}>
        <button
          className={`contacts-list-bar-action-container-btn ${expandedButton === 'remove' ? 'action-container-btn-expanded' : ''}`}
          onClick={() => {
            setOpenOptions(!openOptions);
          }}
          disabled={isLoading}
        >
          <span
            className={`more-option-button dropdown-item-element dropdown-item-element-13 ${openOptions ? 'open' : ''}`}
          >
            {onlyIconOnMobile && expandedButton !== 'remove' ? '' : 'More'}
          </span>
        </button>
        {openOptions && (
          <div className='login-navbar py-2'>
            <button
              className='settings-button setting-item general-button d-block text-left w-100 position-relative'
              onClick={() => {
                setOpenOptions(!openOptions);
                validateContactsOversubscribed(() => setShowRemoveContactTagsModal(true), 'remove');
              }}
              disabled={selectedContactTags.length === 0}
            >
              <span className={`icon-tag ${isLoading ? 'icon-tag-disabled' : ''} mr-3`} />
              Remove tags
            </button>
            <button
              className='settings-button setting-item general-button d-block text-left w-100 position-relative'
              onClick={() => {
                setOpenOptions(!openOptions);
                setShowChampionsStatusModal();
              }}
            >
              <span className={`icon-edit-pencil-thick mr-3 d-inline-block-centered`} />
              Change champion status
            </button>
            {onlyIconOnMobile && (
              <>
                <button
                  className='settings-button setting-item general-button d-block text-left w-100 position-relative'
                  onClick={() => {
                    dispatch({
                      type: 'MODIFY_SECTION',
                      parameter: 'selectedCrmContacts',
                      value: {
                        selectedAll: false,
                        selectedIds: [],
                        selectedAllVisible: [],
                      },
                    });
                  }}
                >
                  <span className={`icon-close mr-3 d-inline-block-centered centered-1`} />
                  Cancel
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default RemoveTagsFromContacts;
