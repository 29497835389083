import React from 'react';
import useEmailDomainFunctions from 'components/Dashboard/hooks/useEmailDomainFunctions';
import ModalComponent from 'components/Common/Modal';

const ContentPopup = ({ setShowPopup, emailDomainId, isAdminPage, teamId, loadInitialInformation }) => {
  const { deleteEmailDomainRequest } = useEmailDomainFunctions();

  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
      <div className='text-center'>
        <p className='pr-2'>
          Are you sure? This cannot be undone and will remove all email records and stats from PolicyMogul
        </p>

        <div className='my-4'>
          <button
            className='general-button main-button px-4 py-2'
            style={{ backgroundColor: '#9b171f' }}
            onClick={async (e) => {
              let deleteAction = await deleteEmailDomainRequest({
                emailDomainId,
                isAdminPage,
                teamId,
                loadInitialInformation,
              });
              if (deleteAction) {
                setShowPopup(false);
              }
            }}
          >
            Delete Email domain
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteEmailDomainModal = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent maxWidth={500} isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default DeleteEmailDomainModal;
