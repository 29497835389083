import { useState } from 'react';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import createNotification from 'components/Settings/Utilities/CreateNotification';

const useShareEmailInstructions = () => {
  const { generalApiCall } = useGeneralApiCall();
  const [isLoading, setIsLoading] = useState(false);

  const shareInstructions = async ({ email, sendCopy, emailDomainId }) => {
    setIsLoading(true);
    let method = 'post';
    let pathname = '/api/email-domain/send-domain-setup-email';
    let requestProperties = {
      emailDomainId,
      recipientEmail: email,
      ccSender: sendCopy ? true : false,
    };
    let successMessage = 'Instructions shared';

    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      returnCompleteRequest: true,
    });
    if (result) {
      createNotification('success', successMessage);
      return true;
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    shareInstructions,
  };
};

export default useShareEmailInstructions;
