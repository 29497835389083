import React, { useContext, useEffect, useState } from 'react';
import { store } from 'components/Store.js';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import axios from 'axios';
import ReactGA from 'react-ga4';
import CreateEmailAlertForm, { useDefaultValuesEmailAlert } from './CreateEmailAlertForm.js';
import CreateEmailAlertNewTopic from './CreateEmailAlertNewTopic.js';
import { useNavigate } from 'react-router-dom';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import { useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { consultantAgenciesMembers } from 'components/Dashboard/SidebarElements/parliamentarianUsers.js';
import ConfigData from 'config/ConfigData.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import ModalComponent from 'components/Common/Modal.js';

const CreateEmailAlert = (props) => {
  const {
    emailAlertId,
    setIsOpenEditEmailAlert,
    setEmailAlertId,
    topicData,
    emailAlertsToSelect,
    openCreatingNewTopic,
    redirectToHome,
    manageEmailAlerts,
    teamId,
    customKeywordsLists,
    updateEmailAlertForTopic,
    client,
    openCreateEmailAlert,
  } = props;

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activeSubscriptions, net_api_url, emailAlerts, keywordsLists, clientsAlerts, team, activePlan } = state;
  const { enableTwitter } = activeSubscriptions;
  const [creatingNewTopic, setCreatingNewTopic] = useState(!!openCreatingNewTopic);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const navigate = useNavigate();
  const location = useLocation();
  const teamIdParameter = getUrlParam('teamId');

  const { getAccessToken } = useGetAccessToken();
  const { generalApiCall } = useGeneralApiCall();

  const [isSaving, setIsSaving] = useState(false);
  const defaultSlackAlert = getUrlParam('alertType') && getUrlParam('alertType') === 'Slack';

  const { enableHandpickedContent } = activePlan;

  //ALLOW TWEETS

  const topicConditional = !!topicData && emailAlertId === '';
  const createAlertNameAndKeywordLists = () => {
    let keywordListIds = [];
    if (topicConditional) {
      keywordListIds.push(topicData.topicId);
    } else if (keywordsLists.length === 1) {
      keywordListIds.push(keywordsLists[0].id);
    }
    return { keywordListIds };
  };

  const { createEmailAlertData } = useDefaultValuesEmailAlert({
    enableTwitter,
    keywordsLists,
    emailAlerts,
    createAlertNameAndKeywordLists,
  });
  const [emailAlertData, setEmailAlertData] = useState(createEmailAlertData({ emailAlertId, defaultSlackAlert }));

  useEffect(() => {
    setEmailAlertData(createEmailAlertData({ emailAlertId, defaultSlackAlert }));
  }, [emailAlertId]);

  const {
    id,
    frequency,
    timeOfDay,
    secondTimeOfDay,
    dayOfWeek,
    contentTypesToReceive,
    keywordListIds,
    name,
    digestCombiningAllTopics,
    additionalName,
    alertTypes,
    sendEmbargoedKeyUpdatesImmediately,
  } = emailAlertData;

  const closeModal = () => {
    setIsOpenEditEmailAlert(false);
    if (setEmailAlertId) {
      setTimeout(() => {
        setEmailAlertId('');
      }, 200);
    }
    if (location.pathname === '/settings/alerts/create') {
      navigate('/settings/alerts');
    }
    if (redirectToHome) {
      if (!teamIdParameter || (!!teamIdParameter && team?.teamId === parseInt(teamIdParameter))) {
        navigate(`/monitor-inbox/${ConfigData?.monitorInboxInitialPage ?? 'key-updates'}`);
      } else {
        navigate(
          `/settings/${consultantAgenciesMembers(activeSubscriptions.planCode) ? 'clients' : 'topics'}${window.location.search}`
        );
      }
    }
  };

  //SAVE AND DELETE FUNCTIONS
  const saveEmailAlertFunction = async (enforceDigest) => {
    setIsSaving(true);
    try {
      let contentType = contentTypesToReceive.split(', ');
      if (contentType.includes('Events')) {
        let position = contentType.indexOf('Events');
        contentType.splice(position, 1);
      }
      let timeOfDayToUse = timeOfDay;
      let secondTimeOfDayToUse = secondTimeOfDay;
      if (
        frequency === 'TwiceWeekdaily' &&
        (parseFloat(timeOfDay.split(':')[0]) > parseFloat(secondTimeOfDay.split(':')[0]) ||
          (parseFloat(timeOfDay.split(':')[0]) === parseFloat(secondTimeOfDay.split(':')[0]) &&
            parseFloat(timeOfDay.split(':')[1]) > parseFloat(secondTimeOfDay.split(':')[1])))
      ) {
        timeOfDayToUse = secondTimeOfDay;
        secondTimeOfDayToUse = timeOfDay;
      }
      let objectToCreate = {
        name: `${name}${additionalName ? ` ${additionalName}` : ''}`,
        keywordListIds: enforceDigest || digestCombiningAllTopics ? [] : keywordListIds,
        digestCombiningAllTopics: enforceDigest ? true : digestCombiningAllTopics,
        frequency: frequency,
        timeOfDay: timeOfDayToUse,
        dayOfWeek: dayOfWeek,
        contentTypesToReceive: contentType.join(', '),
        alertTypes: alertTypes.join(', '),
        sendEmbargoedKeyUpdatesImmediately:
          frequency !== 'Immediate' && enableHandpickedContent && contentTypesToReceive?.includes('KeyUpdates')
            ? sendEmbargoedKeyUpdatesImmediately
            : null,
      };
      if (frequency === 'TwiceWeekdaily') {
        objectToCreate.secondTimeOfDay = secondTimeOfDayToUse;
      }
      let objectToUpdate = id !== '' ? { ...objectToCreate, id: id } : objectToCreate;
      if (teamId) {
        objectToUpdate = { ...objectToUpdate, teamId: teamId };
      }
      let pathname = `/api/alert-settings/${id !== '' ? 'update' : 'create'}`;
      let method = 'post';
      let res = await generalApiCall({
        pathname,
        method,
        requestProperties: objectToUpdate,
        needsAuthentication: true,
        requestSource: source,
      });
      if (res) {
        let clientPosition;
        if (teamId) {
          clientPosition = clientsAlerts?.findIndex((item) => item.teamId === teamId);
        }

        setEmailAlertData(res);
        if (updateEmailAlertForTopic) {
          updateEmailAlertForTopic({ alert: res });
        } else {
          let position;
          if (id === '') {
            position = emailAlerts.length;
          } else {
            position = emailAlerts.findIndex((alert) => alert.id === emailAlertId);
          }
          let emailAlertsToRender = [...emailAlerts];
          emailAlertsToRender[position] = res;
          if (clientPosition >= 0) {
            let newClientsAlerts = [...clientsAlerts];
            let newClient = { ...clientsAlerts[clientPosition] };
            let alerts = [...newClient?.alertSettings];
            let position = id === '' ? alerts?.length : alerts?.findIndex((alert) => alert.id === emailAlertId);
            alerts[position] = res;
            newClient = {
              ...newClient,
              alertSettings: alerts,
            };
            newClientsAlerts[clientPosition] = newClient;
            dispatch({ type: 'MODIFY_SECTION', parameter: 'clientsAlerts', value: newClientsAlerts });
          }
          dispatch({ type: 'MODIFY_SECTION', parameter: 'emailAlerts', value: emailAlertsToRender });
        }

        createNotification('success', `Successfully ${id !== '' ? 'saved' : 'created'}`);
        setIsSaving(false);

        ReactGA.event({
          category: 'Email alerts',
          action: `Email alert ${id !== '' ? 'updated' : 'created'}`,
          label: name,
        });
      }
      closeModal();
    } catch (error) {
      let message = error?.response?.data
        ? error.response.data
        : `There was an error completing your operation. Please reload the page.`;
      createNotification('danger', message);
      setIsSaving(false);
    }
  };

  const deleteEmailAlertFunction = async (id) => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: true });
    let token = await getAccessToken();
    await axios
      .delete(`${net_api_url}/api/alert-settings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        ReactGA.event({
          category: 'Email alerts',
          action: 'Email alert deleted',
          label: name,
        });
        let clientPosition;
        if (teamId) {
          clientPosition = clientsAlerts?.findIndex((item) => item.teamId === teamId);
        }

        if (clientPosition >= 0) {
          let newClientsAlerts = [...clientsAlerts];
          let newClient = { ...clientsAlerts[clientPosition] };
          let alerts = [...newClient?.alertSettings];
          let position = alerts?.findIndex((alert) => alert.id === id);
          alerts.splice(position, 1);
          newClient = {
            ...newClient,
            alertSettings: alerts,
          };
          newClientsAlerts[clientPosition] = newClient;
          dispatch({ type: 'MODIFY_SECTION', parameter: 'clientsAlerts', value: newClientsAlerts });
        }

        let emailAlertsToRender = emailAlerts.slice();
        let position = emailAlerts.findIndex((item) => item.id === id);
        emailAlertsToRender.splice(position, 1);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'emailAlerts', value: emailAlertsToRender });
        createNotification('success', `Successfully deleted`);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: false });
        closeModal();
        return response;
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: false });
          createNotification('danger', `There was an error completing your operation. Please reload the page.`);
          console.error(error.response);
        } else if (error.request) {
          console.error(error.request);
        }
      });
  };

  const FormProperties = {
    emailAlertData,
    setEmailAlertData,
    saveEmailAlertFunction,
    deleteEmailAlertFunction,
    isSaving,
    openCreatingNewTopic,
    setCreatingNewTopic,
    manageEmailAlerts,
    closeModal,
    enableTwitter,
    customKeywordsLists,
    client,
  };

  const NewTopicProperties = {
    emailAlertsToSelect,
    emailAlertData,
    saveEmailAlertFunction,
    closeModal,
    isSaving,
    setIsSaving,
    setCreatingNewTopic,
    topicData,
  };
  return (
    <Portal>
      <ModalComponent maxWidth={660} isOpen={openCreateEmailAlert} setIsOpen={closeModal}>
        {creatingNewTopic && <CreateEmailAlertNewTopic {...NewTopicProperties} />}
        {!creatingNewTopic && <CreateEmailAlertForm {...FormProperties} />}
      </ModalComponent>
    </Portal>
  );
};

export default CreateEmailAlert;
