import React, { useContext, useMemo, useState } from 'react';
import { store } from 'components/Store';
import { useAuth0 } from '@auth0/auth0-react';
import DropdownComponent from 'components/Settings/WebsiteFeeds/DropdownComponent';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import PoliticalMentionsGraphItem from './PoliticalMentionsGraphItem';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import WebsiteSettingsTopics from 'components/Settings/WebsiteFeeds/WebsiteSettingsTopics';

function PoliticalMentionsWidget(props) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const { currentNew } = props;
  const { keywordsListsIds } = currentNew;

  const { isAuthenticated } = useAuth0();
  const { agencyUser, transformTopicOrClientWord } = useTopicOrClientWord();

  const keywordsListsToUse = useMemo(() => {
    let initialKeywordsLists = keywordsLists
      .filter((item) => item.myState !== 'Hidden')
      .filter((item) => keywordsListsIds?.includes(item.id));
    return initialKeywordsLists.length > 0
      ? initialKeywordsLists
      : keywordsLists.filter((item) => item.myState !== 'Hidden');
  }, [keywordsLists, keywordsListsIds]);

  const getDefaultTopic = () => {
    if (getUrlParam('topic-id') === 'all') {
      return keywordsListsToUse.filter((item) => item.id !== null)?.[0];
    } else {
      return (
        keywordsListsToUse.find((item) => item.id === parseInt(getUrlParam('topic-id'))) ??
        keywordsListsToUse.filter((item) => item.id !== null)?.[0]
      );
    }
  };

  const [selectedTopic, setSelectedTopic] = useState(getDefaultTopic());

  let topicFromKeywordList = (topic) => {
    return keywordsLists?.find((item) => item.id === topic.id);
  };

  return (
    <>
      {isAuthenticated && (
        <div className={`py-3 pl-3 pr-3 mb-3 box-rounded bg-white box-political-mentions-widget`}>
          <h3 className={`font-weight-normal mb-0`}>
            <div
              className={`flex-centered mb-0 justify-content-between summary-header-container`}
              style={{ gap: '15px' }}
            >
              <div
                className={`flex-grow-1 summary-content-dropdown-component-container agency-user-summary flex-centered`}
              >
                <span className='font-weight-bold' style={{ lineHeight: '33.6px' }}>
                  {agencyUser ? 'Political interest for' : 'Political interest:'}
                </span>
                <div className={`tailor-summaries-dropdown-component ${agencyUser ? 'agency' : ''}`}>
                  <DropdownComponent
                    mainItem={() => {
                      return (
                        <div className='not-tailored-title'>
                          <span className={`hidden-lines hidden-one-line`}>
                            {`${topicFromKeywordList(selectedTopic)?.name}`}
                          </span>{' '}
                        </div>
                      );
                    }}
                    hideArrowDisabled={keywordsListsToUse?.length === 1}
                  >
                    <WebsiteSettingsTopics
                      firstItem={
                        <>
                          <span style={{ fontSize: '16px', fontWeight: 700 }}>Select a topic</span>
                          <span
                            className='icon-info main-light-text d-inline-block pointer ml-2'
                            data-tooltip-content={`The list below includes ${transformTopicOrClientWord({
                              plural: true,
                            })} for which this update is relevant`}
                            data-tooltip-id={'general-tooltip'}
                            data-tooltip-class-name={'content-summary-tooltip'}
                            style={{ transform: 'translateY(1px)' }}
                          />
                        </>
                      }
                      selectedTopic={selectedTopic}
                      setSelectedTopic={(item) => {
                        setSelectedTopic(item);
                      }}
                      agencyUser
                      topicsVariant
                      hideButtons
                      hideKeywords
                      keywordsListsToUseProp={keywordsListsToUse}
                      hideAddTopic
                    />
                  </DropdownComponent>
                </div>
              </div>
              <span
                className='icon-info d-inline-block-centered ml-1'
                style={{ marginTop: '-1px' }}
                data-tooltip-content={`Displaying the total number of mentions by policymakers in Parliament in the last six months. Click the graph for full analytics`}
                data-tooltip-id='general-tooltip'
                data-tooltip-class-name={'general-tooltip pricing-feature-tooltip report-popup-tooltip'}
              />
            </div>
          </h3>
          <PoliticalMentionsGraphItem selectedTopic={selectedTopic} />
        </div>
      )}
    </>
  );
}

export default PoliticalMentionsWidget;
