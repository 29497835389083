import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { store } from 'components/Store';

const TableComponent = (props) => {
  const {
    children,
    afterInitialSort,
    columns,
    setScrollPositionX,
    setUpdateBySort,
    scrollBarRef,
    additionalClass,
    ascAbrev = 'ascending',
    descAbrev = 'descending',
    activeIndexState,
    contactsTable,
    stateProperty = 'crmEmailsTableOnSort',
    selectedState,
    propertyOnFirstClick,
  } = props;
  const tableElement = useRef(null);
  const { activeIndex, setActiveIndex } = activeIndexState ?? {};

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex) {
          let width = e.clientX - columns[activeIndex].ref.current.getBoundingClientRect().left;
          if (contactsTable) {
            const championStatusActiveIndex = 2; //AE: Prevent resizing champion status
            const referenceValue = activeIndex === championStatusActiveIndex ? 225 : 160;
            width = width > referenceValue ? width : referenceValue;
          } else {
            width = width > 126 ? width : 126;
          }
          return `${width}px`;
        }
        return `${col.ref.current.offsetWidth}px`;
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    },
    [activeIndex, columns]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  return (
    <table
      className={`crm-table ${additionalClass}`}
      ref={tableElement}
      style={{
        userSelect: activeIndex || activeIndex === 0 ? 'none' : 'auto',
      }}
    >
      <TableHeading
        columns={columns}
        tableElement={tableElement}
        activeIndexState={activeIndexState}
        afterInitialSort={afterInitialSort}
        setScrollPositionX={setScrollPositionX}
        setUpdateBySort={setUpdateBySort}
        scrollBarRef={scrollBarRef}
        ascAbrev={ascAbrev}
        descAbrev={descAbrev}
        stateProperty={stateProperty}
        selectedState={selectedState}
        propertyOnFirstClick={propertyOnFirstClick}
      />
      <tbody>{children}</tbody>
    </table>
  );
};

const TableHeading = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const {
    afterInitialSort,
    tableElement,
    columns = [],
    setScrollPositionX,
    setUpdateBySort,
    scrollBarRef,
    activeIndexState,
    selectedState,
    ascAbrev,
    descAbrev,
    stateProperty,
    propertyOnFirstClick,
  } = props ?? {};

  const { activeIndex, setActiveIndex } = activeIndexState ?? {};
  const { selectedAll, isSelected, handleCheck } = selectedState ?? {};

  const [tableHeight, setTableHeight] = useState('auto');
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const sort = query.get('sort') ? query.get('sort').split('_') : null;

  useEffect(() => {
    if (tableElement.current) {
      setTableHeight(tableElement.current.clientHeight);
    }
  }, [tableElement.current, afterInitialSort]);

  //HANDLING QUERY PARAMS FOR SORTING
  const handleChangeSortParam = (accessorKey) => {
    setScrollPositionX(scrollBarRef.current.scrollLeft);
    if (sort) {
      if (sort[0] === accessorKey) {
        if (sort[1] !== descAbrev) {
          query.set('sort', `${accessorKey}_${descAbrev}`);
        } else {
          query.set('sort', `${accessorKey}_${ascAbrev}`);
        }
      } else {
        query.set('sort', `${accessorKey}_${ascAbrev}`);
      }
    } else {
      query.set('sort', `${accessorKey}_${propertyOnFirstClick ?? ascAbrev}`);
    }
    navigate(
      {
        pathname: location.pathname,
        search: query.toString(),
      },
      { replace: true }
    );
    if (setUpdateBySort) {
      setUpdateBySort(query.get('sort'));
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: stateProperty,
      value: {
        sort: query.get('sort'),
      },
    });
  };

  const columnOnSort = (col) => {
    if (sort) {
      return sort[0] === col.accessorKey ? sort[1] : false;
    }
  };
  const renderSortArrows = (sort) => {
    return (
      <div className='crm-table-arrows-container'>
        <span style={{ opacity: sort === ascAbrev ? 1 : 0.4 }} />
        <span style={{ opacity: sort === descAbrev ? 1 : 0.4 }} />
      </div>
    );
  };

  const mouseDown = (e, index) => {
    e.stopPropagation();
    setActiveIndex(index);
  };

  return (
    <thead>
      <tr>
        {columns.map((col, index) => {
          if (col?.type === 'checkbox') {
            return (
              <th key={index} ref={col.ref}>
                <div className='crm-table-header'>
                  <div className={`checkbox-crm-table ${selectedAll ? 'checkbox-crm-table-select-all' : ''}`}>
                    <input
                      type={'checkbox'}
                      id={`select-all-crm-list`}
                      onChange={handleCheck}
                      checked={isSelected}
                      value={isSelected}
                    />
                    <label htmlFor={`select-all-crm-list`} />
                  </div>
                </div>
              </th>
            );
          } else {
            return (
              <th
                key={index}
                style={{
                  backgroundColor:
                    columnOnSort(col) === descAbrev || columnOnSort(col) === ascAbrev ? '#fafafa' : '#fff',
                }}
                ref={col.ref}
              >
                <div
                  className='crm-table-header'
                  onClick={() => (!col.disabled ? handleChangeSortParam(col.accessorKey) : undefined)}
                >
                  {col.header}
                  {!col.disabled ? renderSortArrows(columnOnSort(col)) : ''}
                </div>
                {!col.notShowResizer && (
                  <div
                    style={{ height: tableHeight }}
                    className='resize-handle-influence-column-container'
                    onMouseDown={(e) => mouseDown(e, index)}
                  >
                    <div
                      className={`resize-handle-influence-column ${
                        activeIndex === index ? 'active-column-influence' : ''
                      }`}
                    />
                  </div>
                )}
              </th>
            );
          }
        })}
      </tr>
    </thead>
  );
};

export default TableComponent;
