import { useContext } from 'react';
import { store } from 'components/Store';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import useCallMentionsByTopic, { createKey, graphDataSections, TweetsFilters } from './useCallMentionsByTopic';
import dayjs from 'dayjs';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import { RegionMetaData } from './ui/MentionsByTopic/PartyMetadata';
import { chartDataOptions } from './createAnalyticsState';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions';
import { sortPositionLabels } from './sortPositionLabels';
import useSelectedCountriesFilters from './useSelectedCountriesFilters';
import useAnalyticsIntervalLimit from './useAnalyticsIntervalLimit';
import { useAuth0 } from '@auth0/auth0-react';
import ChangeSearchQuery from 'components/Dashboard/utilities/changeSearchQuery';
import getAppSite from 'utils/getAppSite';

const colours = {
  positive: '163, 228, 86',
  negative: '227, 66, 66',
  neutral: '212, 212, 212',
  mixed: '195, 147, 76',
};

const useCallOverallSentiment = (props) => {
  const { teamId, reportDateInterval, selectedCountriesProps } = props ?? {};
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, analyticsState } = state;
  const { chartDataOptionSelected } = analyticsState;
  const { isAuthenticated } = useAuth0();

  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const { createFilters } = useCallMentionsByTopic(props);

  const { getCountrySpecificFilters, UKSelected } = useSelectedCountriesFilters({ selectedCountriesProps });

  const { generalApiCall } = useGeneralApiCall();
  const { validateDataForLoggedOutUsers, conditionOfPublicApi } = useAnalyticsIntervalLimit();
  const topicParameter = getUrlParam('topic-id');
  const createDataInterval = () => {
    return dayjs().subtract(process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS ?? 90, 'day');
  };
  const parliamentaryPositionsFilters = chartDataOptions.find(
    (item) => item.name === 'Parliamentary position'
  )?.filters;

  const createDateIntervalFilters = () => {
    if (reportDateInterval) {
      return [...createFilters(reportDateInterval)];
    } else {
      const dateInterval = createDataInterval().startOf('day').format('YYYY-MM-DD');
      return [
        {
          field: 'dateTime',
          value: dateInterval,
          operator: 'date_gte',
        },
        {
          field: 'dateTime',
          value: dayjs().format('YYYY-MM-DD'),
          operator: 'date_lt',
        },
      ];
    }
  };

  const individualApiCall = async ({ topic, section, source, freeSearch }) => {
    let { value, field, type, additionalFilters } = section;
    let pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}aggregation${!!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''}`;
    let method = 'post';
    let filters = [...createDateIntervalFilters()];

    if (getAppSite() === 'global') {
      filters = [
        ...filters,
        ...parliamentaryPositionsFilters,
        ...getCountrySpecificFilters({
          value: value,
          field: field,
        }),
      ];
    } else if (getAppSite() === 'usa') {
      filters = [
        ...filters,
        {
          field: 'contentType',
          value: 'CREC',
          operator: 'str_eq',
        },
      ];
    }

    if (additionalFilters) {
      filters = [...filters, ...additionalFilters];
    }

    let requestProperties = {
      type: type,
      filters,
      bucketField: 'sentiment',
      order: 'asc',
    };

    if (topic?.prospective) {
      const { keywords, andKeywords, keywordExclusions } = topic;
      requestProperties = {
        ...requestProperties,
        query: ChangeKeywordsCategory(keywords),
        andQueries: ChangeKeywordsExclusions(andKeywords),
        mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
      };
    } else if (freeSearch) {
      requestProperties = {
        ...requestProperties,
        query: ChangeSearchQuery(freeSearch),
      };
    } else {
      requestProperties = {
        ...requestProperties,
        keywordListId: topic.id,
      };
    }

    if (teamId) {
      requestProperties = {
        ...requestProperties,
        teamId,
      };
    }

    let results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: !teamId || (freeSearch && isAuthenticated),
      notShowErrorMessage: true,
      requestSource: source,
      returnError: true,
    });
    if (!validateDataForLoggedOutUsers(results)) {
      return [];
    }
    if (!!results) {
      return results;
    }
  };

  const sentiments = ['positive', 'negative', 'neutral', 'mixed'];
  const types = [
    {
      type: 'ParliamentaryRecord',
      contentTypes: ['ParliamentaryContribution', 'QuestionAndAnswer', 'Edm', 'SeneddCommitteeTranscriptContribution'],
    },
  ];

  if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
    types.push({ type: 'Tweet', additionalFilters: TweetsFilters });
  }

  const dataTableIndividualApiCalls = async ({ topic, item, optionData, sentiment, source, freeSearch }) => {
    const { type, contentTypes, additionalFilters } = item;
    let pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}aggregation${!!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''}`;
    let method = 'post';
    let filters = [
      ...createDateIntervalFilters(),
      {
        field: 'sentiment',
        value: sentiment,
        operator: 'str_eq',
      },
    ];

    if (getAppSite() === 'global') {
      filters = [...filters, ...parliamentaryPositionsFilters];
    } else if (getAppSite() === 'usa') {
      filters = [
        ...filters,
        {
          field: 'contentType',
          value: 'CREC',
          operator: 'str_eq',
        },
      ];
    }

    if (optionData.filters) {
      filters = [...filters, ...optionData.filters];
    }

    if (contentTypes && getAppSite() === 'global') {
      contentTypes.forEach((item) => {
        filters = [...filters, ...getCountrySpecificFilters({ value: item, field: 'contentType' })];
      });
    }

    if (additionalFilters) {
      filters = [...filters, ...additionalFilters];
    }

    let requestProperties = {
      type: type,
      filters,
      bucketField: optionData.bucketField,
      order: 'asc',
    };

    if (topic?.prospective) {
      const { keywords, andKeywords, keywordExclusions } = topic;
      requestProperties = {
        ...requestProperties,
        query: ChangeKeywordsCategory(keywords),
        andQueries: ChangeKeywordsExclusions(andKeywords),
        mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
      };
    } else if (freeSearch) {
      requestProperties = {
        ...requestProperties,
        query: ChangeSearchQuery(freeSearch),
      };
    } else {
      requestProperties = {
        ...requestProperties,
        keywordListId: topic.id,
      };
    }

    if (teamId) {
      requestProperties = {
        ...requestProperties,
        teamId,
      };
    }
    let results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: true,
      notShowErrorMessage: true,
      requestSource: source,
      returnError: true,
    });

    if (!validateDataForLoggedOutUsers(results)) {
      return [];
    }

    if (!!results) {
      return results;
    }
  };

  const callOverallDataChart = async ({ chartDataOption, topic, excludeMixedAndNeutral, source, freeSearch }) => {
    let optionData = chartDataOptions.find((item) => item.name === chartDataOption);
    let finalResults = {};
    for (let i = 0; i < types.length; i++) {
      let item = types[i];
      let finalSentiments = excludeMixedAndNeutral ? ['positive', 'negative'] : sentiments;
      for (let a = 0; a < finalSentiments.length; a++) {
        let sentiment = finalSentiments[a];
        let results = await dataTableIndividualApiCalls({
          topic,
          optionData,
          item,
          sentiment,
          source,
          freeSearch,
        });
        if (results) {
          if (!finalResults[sentiment]) {
            finalResults[sentiment] = [];
          }
          finalResults[sentiment].push(results.values);
        }
      }
    }

    Object.keys(finalResults).forEach((result) => {
      let data = finalResults[result];
      let compiledResults = data.reduce((acc, item) => {
        let obj = item;
        Object.keys(obj).forEach((key) => {
          if (!acc[key]) {
            acc[key] = obj[key];
          } else {
            acc[key] += obj[key];
          }
        });
        return acc;
      }, {});
      finalResults[result] = compiledResults;
    });

    let value = Object.keys(finalResults).map((item) => {
      let results = 0;
      let content = finalResults[item];
      for (let i in content) {
        results += content[i];
      }
      return results;
    });

    let rawData = {};

    Object.keys(finalResults).forEach((item) => {
      let content = finalResults[item];
      let initialKeys = Object.keys(content);
      let orderingKeys = initialKeys;
      if (chartDataOption === 'Region') {
        orderingKeys = [];
        RegionMetaData.orderingRegions.forEach((item) => {
          if (initialKeys.includes(item.toLowerCase()) || item === 'Yorkshire and the Humber') {
            orderingKeys.push(item === 'Yorkshire and the Humber' ? 'yorkshire & the humber' : item.toLowerCase());
          }
        });
      }

      orderingKeys.forEach((key) => {
        let name = createKey({ chartDataOption, item: key });
        let condition = optionData.showRowsFor ? optionData.showRowsFor?.includes(name) : true;

        if (!rawData[name] && condition) {
          rawData[name] = {};
        }
        if (condition) {
          rawData[name][item] = content[key];
        }
      });
    });
    const sortedRawData = sortPositionLabels(Object.keys(rawData)).reduce((accumulator, key) => {
      accumulator[key] = rawData[key];
      return accumulator;
    }, {});
    if (value?.length > 0) {
      return { value, rawData: sortedRawData };
    }
  };

  const callOverallSentiment = async ({
    topic = topicParameter
      ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
      : keywordsListsToUse[0],
    chartDataOption = chartDataOptionSelected,
    excludeMixedAndNeutral = false,
    source,
    freeSearch,
  }) => {
    let rawData = {};
    let finalResults = [];
    let value;

    if (chartDataOption === 'Contribution type' || chartDataOption === 'Overall') {
      let graphDataSectionsFiltered = graphDataSections.filter((item) => {
        if (item.value === 'Edm' && !UKSelected) {
          return false;
        } else {
          return true;
        }
      });
      for (let i = 0; i < graphDataSectionsFiltered.length; i++) {
        let section = graphDataSectionsFiltered[i];
        let results = await individualApiCall({ topic, section, source, freeSearch });
        if (results) {
          finalResults.push(results.values);
          rawData[section.name] = results.values;
        }
      }

      let compiledData = finalResults.reduce((acc, item) => {
        let obj = item;
        Object.keys(obj).forEach((key) => {
          if (!acc[key]) {
            acc[key] = obj[key];
          } else {
            acc[key] += obj[key];
          }
        });
        return acc;
      }, {});
      if (Object.keys(finalResults)?.length > 0) {
        value = sentiments.map((item) => compiledData[item] ?? '');
      }
    } else {
      let results = await callOverallDataChart({
        chartDataOption,
        topic,
        excludeMixedAndNeutral,
        source,
        freeSearch,
      });
      if (results) {
        rawData = results.rawData;
        value = results.value;
      }
    }

    if (!!value) {
      return {
        labels: sentiments.map((item) => uppercaseFirstLetter(item)),
        value,
        colours: sentiments.map((item) => colours[item]),
        rawData,
      };
    }
  };

  return { callOverallSentiment };
};

export { colours };
export default useCallOverallSentiment;
