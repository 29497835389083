import callCollectList from 'components/Dashboard/utilities/callCollectList.js';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useGeneralRequestProperties from 'components/Dashboard/hooks/useGeneralRequestProperties.js';
import { useContext, useState } from 'react';
import { useSiteSectionsNames } from 'utils/getAppSite.js';
import { store } from 'components/Store.js';
import useNumberOfUnseenItems from 'components/Dashboard/hooks/useNumberOfUnseenItems.js';
import useGetSectionFilters from 'components/Dashboard/Filters/useGetSectionFilters.js';
import { createSectionName } from 'components/Dashboard/helpers/controlActiveFilters.js';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall.js';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase.js';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter.js';
import { changeTypeOfResource } from 'components/Dashboard/helpers/createTypeOfResource.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags.js';

const useCreateResults = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const [currentlyProcessing, setCurrentlyProcessing] = useState({ attempt: 0, state: true });

  const { numberOfUnseenItems } = useNumberOfUnseenItems();
  const { propsSectionName, customTitle } = props;

  const { createRequestProperties, getLastSeenMarkers, updateMarkers } = useGeneralRequestProperties();
  const { generalApiCall } = useGeneralApiCall();
  const { addMetaTags } = useAddMetaTags();
  const { getName } = useSiteSectionsNames();

  const { contentResults, activeResults, search, keywordsLists } = state;
  const { list } = search;

  const sectionName = createSectionName() ?? propsSectionName;
  const sectionNameForFilters =
    sectionName === 'Influence' && location.pathname.includes('/lobbying-material') ? 'LobbyingMaterial' : sectionName;
  const section = contentResults[activeResults][sectionName];
  const { getSectionFilters } = useGetSectionFilters(sectionNameForFilters);
  const sectionFilters = getSectionFilters();
  const { hash } = sectionFilters ?? {};

  const { id, page } = params;
  const idByUrl = typeof id !== 'undefined';
  const pageSize = sectionName === 'ElectionKeyUpdates' ? 100 : parseInt(process.env.REACT_APP_PAGE_SIZE);
  const { mainSectionTitle, searchResultsSection, inboxSection } = useMainSectionParameter(params);

  async function createResults(pageNumber, setMarker, source) {
    try {
      let directTopic =
        sectionName === 'Influence' && !inboxSection && !!getUrlParam('topic-id')
          ? keywordsLists.find((item) => item?.id === parseInt(getUrlParam('topic-id')))
          : null;
      let noUseQuery = sectionName === 'Influence' && location.pathname.startsWith('/campaigns');
      let { url, requestProperties } = createRequestProperties(pageNumber, null, directTopic, noUseQuery);
      let notLoadingConditional = !pageNumber && (currentlyProcessing?.state || !currentlyProcessing);

      if (notLoadingConditional) {
        setIsLoading(true);
      }

      //GET MARKERS TO UNSEEN CONTENT
      if (inboxSection && !!list && notLoadingConditional && list !== 'noListsCreated' && setMarker) {
        await getLastSeenMarkers({ source });
      }

      let token = isAuthenticated ? await getAccessToken() : '';

      let results =
        list !== 'noListsCreated'
          ? await generalApiCall({
              pathname: url,
              method: !!requestProperties ? 'post' : 'get',
              requestProperties,
              needsAuthentication: isAuthenticated && !!requestProperties,
              requestSource: source,
              notShowErrorMessage: true,
            })
          : {};

      let newsIds = pageNumber ? section.newsIds : [];
      let hitsResults = pageNumber ? section.hits : [];
      let explanations = pageNumber ? section.explanations : [];
      let feedItemFlags = pageNumber ? section.feedItemFlags : [];
      let collapsedHits = pageNumber ? section?.collapsedHits : [];

      if (results) {
        if (
          notLoadingConditional &&
          list !== 'noListsCreated' &&
          ((setMarker && results?.isReady) || searchResultsSection)
        ) {
          numberOfUnseenItems(source);
        }

        let hitsHighlighted =
          results?.highlights?.length > 0
            ? results?.hits.map((item, index) => {
                return {
                  ...item,
                  highlights: results?.highlights[index],
                };
              })
            : results?.hits ?? [];

        hitsResults = hitsResults.concat(hitsHighlighted);
        explanations = explanations.concat(results?.explanations);
        feedItemFlags = results?.feedItemFlags
          ? feedItemFlags.concat(results?.feedItemFlags)
          : section?.feedItemFlags ?? [];
        newsIds = newsIds.concat(results?.hits?.map((item) => item.id));
        collapsedHits = collapsedHits?.concat(results?.collapsedHits ?? []);

        let currentId = idByUrl ? parseInt(id) : results.hits?.length > 0 ? results.hits[0].id : 0;

        let stateResults = {
          ...contentResults,
          [activeResults]: {
            [sectionName]: {
              ...results,
              currentId,
              newsIds,
              explanations,
              feedItemFlags,
              collapsedHits,
              loaded: true,
              currentPositionId: newsIds.indexOf(currentId),
              hits: hitsResults,
              thereIsMoreContent: results.hits?.length === pageSize,
            },
          },
        };

        let urlToHistory = `${location.pathname}${location.search}${!!hash ? `#${hash}` : ''}`;
        navigate({ search: window.location.search, hash: !!hash ? `#${hash}` : '' }, { replace: true });

        let title = `${mainSectionTitle}${
          sectionName === 'ParliamentaryRecord'
            ? getName({ property: 'Parliamentary record' })
            : sectionName === 'Influence'
              ? location.pathname.startsWith('/campaigns')
                ? 'Campaigns'
                : uppercaseFirstLetter(page.replace('-', ' '))
              : uppercaseFirstLetter(TransformFromPascalCase(sectionName))
        } - PolicyMogul`;

        addMetaTags({ title: customTitle ?? title, hash });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: stateResults });

        if (list !== null && list !== 'noListsCreated' && inboxSection) {
          setCurrentlyProcessing({ attempt: currentlyProcessing.attempt + 1, state: results.isReady });
        } else {
          //AE:Added specially for the campaigns page, see if this creates conflicts on different pages
          setCurrentlyProcessing(false);
        }

        setIsLoading(false);

        if (!!list && !pageNumber && list !== 'noListsCreated' && setMarker && inboxSection) {
          await updateMarkers({ contentResults: results });
        }

        if (results.isReady || searchResultsSection) {
          let contentType = changeTypeOfResource(sectionName);
          if (list !== null && list !== 'noListsCreated' && isAuthenticated && notLoadingConditional) {
            await callCollectList(contentType, token, list.id, urlToHistory);
          }
        }
        return stateResults;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const load_more_results = async (source) => {
    if (!!section) {
      let { thereIsMoreContent, pageNumber } = section;
      if (thereIsMoreContent) {
        await createResults(pageNumber + 1, false, source);
      }
    }
  };

  return { load_more_results, createResults, isLoading, setIsLoading, currentlyProcessing, setCurrentlyProcessing };
};

export default useCreateResults;
