import { useContext } from 'react';
import { store } from 'components/Store';

const useSelectCrmContacts = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { selectedCrmContacts } = state;

  const setSelectedCrmContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedCrmContacts',
      value,
    });
  };
  const setPreviousStoredSelectedCrmContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'previousStoredSelectedCrmContacts',
      value,
    });
  };

  //BEHAVIOUR OF CHECKBOX IN TABLE HEADER
  const handleCheck = (e, selectedAll) => {
    const checkedState = selectedAll ?? !e?.target?.checked;
    if (checkedState) {
      setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
      setSelectedCrmContacts({
        selectedAll: false,
        selectedIds: [],
        selectedAllVisible: [],
      });
    } else {
      setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
      setSelectedCrmContacts({
        selectedAll: true,
        selectedIds: [],
        selectedAllVisible: [],
      });
    }
  };

  return { setSelectedCrmContacts, setPreviousStoredSelectedCrmContacts, handleCheck };
};

export default useSelectCrmContacts;
