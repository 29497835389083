import { useLocation } from 'react-router-dom';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';

const sentimentText = (value) => {
  switch (value) {
    case 'mixed':
      return `This text expresses both positive and negative sentiments`;
    case 'neutral':
      return 'This text does not express either positive or negative sentiments';
    default:
      return `This text expresses an  overall ${value} value sentiment`;
  }
};

const Sentiment = (props) => {
  const { item, dotClassName = '' } = props;
  const { sentiment } = item;
  const value = sentiment?.toLowerCase();

  const location = useLocation();
  const pathnamesCondition =
    location.pathname.startsWith('/stakeholders') || location.pathname.startsWith('/analytics');

  return (
    <>
      {sentiment !== null && pathnamesCondition && getUrlParam('topic-id') !== 'all' && !getUrlParam('search') && (
        <span
          data-tooltip-html={`<p class="mb-2 px-3 title-h5-m-bold border-bottom py-3 z-max"><span class="sentiment-dot sentiment-title-dot mr-2 d-inline-block sentiment-dot-${value}"></span> ${sentiment}</p><p class='px-3 mb-0 title-h5'>${sentimentText(value)}</p><a class='mt-2 title-h5 simple-link-button px-3 pb-3 d-block' href='http://support.policymogul.com/en/articles/8041604-sentiment-analysis' target='_blank' rel="noopener noreferrer">Learn more`}
          data-tooltip-id={'clickeable-tooltip'}
          data-tooltip-class-name={'contributions-tooltip sentiment-dot-tooltip z-extra-max'}
          className={`mr-2 sentiment-dot sentiment-dot-${value} ${dotClassName}`}
        />
      )}
    </>
  );
};

export { sentimentText };
export default Sentiment;
