import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import TopicPicker from 'components/Common/TopicPicker';
import ModalComponent from 'components/Common/Modal';
import getAppSite from 'utils/getAppSite';

const Client = (props) => {
  const { keywordsLists, sectionValues, type, setClientValidationState, teamContent } = props;
  const { fields } = sectionValues;
  const { value, setValue } = fields.find((item) => item.name === 'clientKeywordListId');
  const websiteLink = fields.find((item) => item.name === 'websiteLink');
  const influenceTypeName = type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ');

  const location = useLocation();

  const searchFunction = ({ topic }) => {
    setClientValidationState({ isConfirmedClient: false, url: null });
    setValue(topic?.id);
    if (websiteLink?.value === '') {
      websiteLink?.setValue(teamContent?.filter((item) => item?.clientKeywordListId === topic?.id)?.[0]?.websiteLink);
    }
  };
  return (
    <>
      <div className='influence-creation_section'>
        <h3 className='title-h5-m-bold'>Select a client to add lobbying material on their behalf</h3>
        <p className='paragraph-p2 main-light-text'>
          Lobbying material will be shared with {getAppSite() === 'usa' ? 'policymakers' : 'parliamentarians'} and other
          organisations, depending on your visibility settings.
        </p>
        <div className='agency-dropdown'>
          <TopicPicker
            showTopicCases={keywordsLists?.length > 0}
            fixedPosition
            keywordsLists={keywordsLists}
            defaultTopic={value ? keywordsLists.find((item) => item.id === value) : keywordsLists[0]}
            searchFunction={searchFunction}
            disabled={location.pathname.includes('/edit')}
          />
        </div>

        <h3 className='title-h5-m-bold mt-5'>Associate a webpage with this {influenceTypeName}</h3>
        <p className='paragraph-p2 main-light-text'>{`Use your client's homepage unless they have a specific policy or campaign page`}</p>
        <input
          data-hj-allow
          className={'main-input form-control'}
          value={!!websiteLink?.value ? websiteLink?.value : ''}
          onChange={(e) => {
            websiteLink?.setValue(e.target.value);
          }}
        />
      </div>
    </>
  );
};

const ConfirmClientModal = (props) => {
  const {
    clientId,
    isOpen,
    setIsOpen,
    nextFunction = () => {},
    keywordsListsToUse,
    clientValidationState,
    setClientValidationState,
    clientName,
  } = props;

  const client = keywordsListsToUse?.find((item) => item.id === clientId);
  const [authorization, setAuthorization] = useState({ one: false, two: false });
  const navigate = useNavigate();

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <div className='pt-4 section-title px-lg-5 px-4 pb-3'>
          <h3 className={`topic-title mb-0 font-weight-normal`}>You're about to add content on behalf of a client</h3>
        </div>
        <div className='px-lg-5 px-4 py-4 common-outside-filter client-authorization'>
          <div className='checkbox-item mb-3'>
            <input
              id={'authorization-1'}
              onChange={() => {
                setAuthorization({ ...authorization, one: !authorization.one });
              }}
              type='checkbox'
              checked={authorization.one}
            />
            <label htmlFor={'authorization-1'}>
              I have been given authorisation by {clientName ?? client?.name} to publish lobbying material on their
              behalf
            </label>
          </div>

          <div className='checkbox-item'>
            <input
              id={'authorization-2'}
              onChange={() => {
                setAuthorization({ ...authorization, two: !authorization.two });
              }}
              type='checkbox'
              checked={authorization.two}
            />
            <label htmlFor={'authorization-2'}>
              I confirm that PolicyMogul will not be held responsible for any issue arising from the publication of this
              lobbying material as a result of inadequate approval from the client on whose behalf it is being published
            </label>
          </div>
        </div>

        <div className='text-right border-top py-4 px-lg-5 px-4 bg-salmon rounded-bottom'>
          <button
            className='action-button px-4 py-2'
            disabled={!authorization.one || !authorization.two}
            onClick={() => {
              setIsOpen(false);
              if (setClientValidationState) {
                setClientValidationState({ isConfirmedClient: true, url: null });
              }
              if (clientValidationState?.url) {
                let url = new URL(clientValidationState?.url);
                navigate(url?.pathname);
              } else {
                nextFunction();
              }
            }}
          >
            Continue
          </button>
        </div>
      </>
    </ModalComponent>
  );
};

export { ConfirmClientModal };
export default Client;
