import React, { useContext, useRef } from 'react';
import Share from 'components/Dashboard/components/Share';
import { store } from 'components/Store.js';
import calculateDifferenceBetweenDays from 'components/Dashboard/utilities/calculateDifferenceBetweenDays.js';
import useTitleScrolledStyles from 'components/Dashboard/hooks/useTitleScrolledStyles';
import FeedbackOnItem from 'components/Dashboard/FeedbackItem/FeedbackOnItem';
import ThumbsDown from 'components/Common/ThumbsDown';
import TextSelector from 'components/Common/TextSelector.js';
const dayjs = require('dayjs');

const ShareButtonRender = (props) => {
  const { currentNew, shareButtonWidget, embed } = props;
  const { id, title, canonicalUrl, objectId, objectType } = currentNew;
  let item = {
    id,
    title,
    objectID: objectId,
    type: objectType,
  };
  const ShareOptions = {
    item,
    id: item.id,
    title: item.title,
    shareButtonWidget,
    itemShare: embed,
  };

  if (canonicalUrl) {
    ShareOptions.link = `${process.env.REACT_APP_APPURL}${canonicalUrl}`;
  }

  return <Share {...ShareOptions} />;
};
const ConsultationNewContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, activeResults } = state;

  const { Consultations } = contentResults[activeResults];
  const { activeSearch } = search;
  const {
    currentNew,
    shareButtonWidget,
    contentContainer,
    reportWidget,
    embed,
    scrollbarRef,
    scrolling,
    isFromPopup,
    notIncludeBranding,
  } = props;
  const createMarkup = (body) => {
    return { __html: body };
  };

  const renderText = () => {
    let days = calculateDifferenceBetweenDays(currentNew.closeDate);
    if (days >= 0) {
      return `Closing ${days === 0 ? 'today' : `in ${days} day${days === 1 ? '' : 's'}`}`;
    } else {
      return `Closed`;
    }
  };

  const returnTitleToRender = () => {
    if (activeSearch || shareButtonWidget) {
      return currentNew.titleHighlighted ?? currentNew.title;
    } else {
      return currentNew.title;
    }
  };

  const titleSection = useRef(null);
  let { showFixedTitle, titleStyles } = useTitleScrolledStyles(contentContainer, titleSection);
  const alreadyMarkedFeedback = !!currentNew?.feedback;
  const index = Consultations ? Consultations?.hits?.findIndex((item) => item.id === currentNew.id) : null;
  const ThumbsDownProperties = {
    item: { objectID: currentNew?.objectId },
    nameSection: 'Consultations',
    show: !shareButtonWidget && !!currentNew?.keywordsFromLists,
    embedFeed: embed,
    contentPage: true,
    alreadyMarked: alreadyMarkedFeedback,
    keywordsFromLists: currentNew?.keywordsFromLists,
    thick: true,
  };

  return (
    <div className='p-3 p-lg-4 pb-5 specific-content-container'>
      <div className='section-title consultation-content-title pb-2 mb-2' ref={titleSection}>
        <h3 className='main-title news-title pr-lg-4' dangerouslySetInnerHTML={createMarkup(returnTitleToRender())} />
        <div className='row mx-0 justify-content-between align-items-center'>
          {!!currentNew?.link && (
            <a
              className='legislation-source legislation-source-size mb-0'
              href={currentNew.link}
              target='_blank'
              rel='noopener noreferrer'
            >
              Go to source
            </a>
          )}
          <div className='flex-centered'>
            <ThumbsDown {...ThumbsDownProperties} />
            {!notIncludeBranding && (
              <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} embed={embed} />
            )}
          </div>
        </div>
      </div>

      {/* FIXED TITLE */}
      <div
        className={`content-item-scrolled-title section-title bg-white ${showFixedTitle && !isFromPopup && !reportWidget ? 'item-scrolled-title-visible' : ''}`}
        style={titleStyles}
      >
        <div className='d-flex justify-content-between scrolled-title-container'>
          <h3 className='main-title news-title pr-lg-4' dangerouslySetInnerHTML={createMarkup(returnTitleToRender())} />
          <ThumbsDown {...ThumbsDownProperties} />
          {!notIncludeBranding && <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} />}
        </div>
      </div>
      {/* END FIXED TITLE */}

      {alreadyMarkedFeedback && (
        <FeedbackOnItem
          contentPage={true}
          item={{ objectID: currentNew?.objectId }}
          feedback={currentNew?.feedback}
          keywordsFromLists={currentNew?.keywordsFromLists}
          index={index}
        />
      )}

      <div className='consultation-content-subtitle my-3 d-flex justify-content-between consultation-content-key-dates'>
        <h3 className='mb-0'>Key dates</h3>
        <p className={`text-right closing-time px-lg-3 mb-0`}>{renderText()}</p>
      </div>
      <div className='pl-3 pl-lg-4 consultation-contente-dates'>
        <p className='open-consultation-datetitle mb-2'>
          <strong>Open date</strong>: <span>{dayjs(currentNew.openDate).format('DD[/]MM[/]YY')}</span>
        </p>
        <p className='open-consultation-datetitle mb-0'>
          <strong>Close date</strong>: <span>{dayjs(currentNew.closeDate).format('DD[/]MM[/]YY')}</span>
        </p>
      </div>
      <div className='consultation-content-subtitle my-3'>
        <h3 className='mb-0'>Summary</h3>
      </div>
      <TextSelector currentNew={currentNew} scrollbarRef={scrollbarRef} scrolling={scrolling}>
        <div
          className='news-content-in pt-2 pb-3 pr-3 context mb-2 consultation-content'
          dangerouslySetInnerHTML={createMarkup(currentNew.body)}
        ></div>
      </TextSelector>
    </div>
  );
};

export default ConsultationNewContent;
