import React from 'react';
import getAppSite from 'utils/getAppSite';

function DomainUnauthanticatedHeader({ domain, confirmed }) {
  return (
    <div className='domains-unauthenticated-header'>
      {domain ? (
        <p className='unauthenticated-title'>Authenticate your domain</p>
      ) : confirmed ? (
        <p className='unauthenticated-title'>
          {' '}
          <span className='icon-circle-check pr-2'></span>Please check your email
        </p>
      ) : (
        <p className='unauthenticated-title'>Authenticate your email</p>
      )}
      {domain ? (
        <p className='unauthenticated-subtitle'>
          Please share the following instructions with the correct technical person within your{' '}
          {getAppSite() === 'usa' ? 'organization' : 'organisation'}
        </p>
      ) : confirmed ? (
        <p className='unauthenticated-subtitle'>
          By verifying your email address, you will be able to send emails to policymakers, with no indication that they
          have come via PolicyMogul
        </p>
      ) : (
        <p className='unauthenticated-subtitle'>
          This ensures that PolicyMogul is allowed to send emails on your behalf
        </p>
      )}
    </div>
  );
}

export default DomainUnauthanticatedHeader;
