import { useEffect, useRef } from 'react';
import { useState } from 'react';
import useEngagementStatistics from 'components/Dashboard/UserContent/useEngagementStatistics';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import { SharePopup } from './PublishedContentPage';
import normalize from 'components/Dashboard/utilities/normalizeString';
import axios from 'axios';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useContext } from 'react';
import { store } from 'components/Store';
import { useResearchItemsUrl } from 'components/Dashboard/Navigation/NavPages';
import StakeholdersFeaturesUpgradePopup from './StakeholdersFeaturesUpgradePopup';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { useLocation, useNavigate } from 'react-router-dom';
import getAppSite from 'utils/getAppSite';

const IndividualContentStatistics = (props) => {
  const { item, engagementStatisticsOnLoad } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [statistics, setStatistics] = useState({});
  const location = useLocation();
  const isInfluenceSection = location.pathname.includes('influence/engagement-statistics');

  const { loadStatistics } = useEngagementStatistics();
  const loadInfo = useRef();
  loadInfo.current = async (source) => {
    try {
      setIsLoading(true);
      let results = await loadStatistics({ item, source });
      if (results) {
        setStatistics(results);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadInfo.current(source);
    return () => {
      source.cancel('Statistics cancelled by the user');
    };
  }, [item]);

  return (
    <div className='bg-white'>
      {!engagementStatisticsOnLoad ? (
        <div className={`${isInfluenceSection ? 'rounded-top' : ''} border-bottom py-2 main-sticky bg-white`}>
          <div
            className={`${
              isInfluenceSection ? 'influence-statistics-title' : 'influence-content-page'
            } main-content-wrapper px-3`}
          >
            <p className={`text-red-dark mb-0 ${isInfluenceSection ? 'py-2' : ''}`}>
              <span className='icon-visibility d-inline-block mr-2 d-inline-block-centered centered-4' />
              Engagement statistics are only visible to you and other admins on your team
            </p>
          </div>
        </div>
      ) : (
        <SkeletonTheme>
          <div className={`${isInfluenceSection ? 'rounded-top' : ''} border-bottom py-2 main-sticky bg-white`}>
            <div
              className={`${
                isInfluenceSection ? 'influence-statistics-title' : 'influence-content-page'
              } main-content-wrapper px-3`}
            >
              <p className={`text-red-dark mb-0 ${isInfluenceSection ? 'py-2' : ''}`}>
                <Skeleton height={24} width={`60%`} />
              </p>
            </div>
          </div>
        </SkeletonTheme>
      )}

      <div className='influence-content-page main-content-wrapper px-4'>
        <ContentStatistics data={statistics} item={item} isLoading={isLoading} />
      </div>
    </div>
  );
};

const ContentStatistics = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableStakeholders } = activePlan;

  const isMobile = useIsMobile(430);

  const { data, isLoading, item } = props;
  const { type } = item ?? {};
  const { totalEngagement, policyMakers } = data ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenShare, setIsOpenShare] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isInfluenceSection = location.pathname.includes('influence/engagement-statistics');

  const { stakeholderUrl } = useResearchItemsUrl();

  return (
    <>
      {isLoading || !item ? (
        <SkeletonTheme>
          <div className='row py-4'>
            {Array(2)
              .fill()
              .map((item, index) => (
                <div className=' py-6 col-lg-8 mb-3 mb-lg-0' key={index}>
                  <div className='border rounded-lg py-4 bg-white'>
                    <div className='px-4'>
                      <Skeleton height={24} width={`40%`} />
                    </div>
                    <div className='border-bottom py-4'>
                      <div className={`d-lg-flex align-items-center statistics-info-container`}>
                        <div className='statistics-info'>
                          <Skeleton height={52} width={`52px`} />
                          <div className='mt-4'>
                            <Skeleton height={24} width={isInfluenceSection ? '100px' : `180px`} />
                          </div>
                        </div>
                        <div className='statistics-info'>
                          <Skeleton height={52} width={`52px`} />
                          <div className='mt-4'>
                            <Skeleton height={24} width={isInfluenceSection ? '100px' : `180px`} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='statistics-button-action py-4 px-4'>
                      <Skeleton height={28} width={`220px`} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </SkeletonTheme>
      ) : (
        <>
          <div className='engagement-statistics-card-container py-4'>
            <div className='border border-gray-300 rounded-lg pt-4 grow bg-white px-0 statistics-card-container'>
              <h3 className='px-4 title-h4 statistics-info-title'>
                <span className='font-weight-bold'>Total engagement</span> <DataTipEngagement />
              </h3>

              <div className='border-bottom py-4'>
                <div className={`statistics-info-container`}>
                  <div className='statistics-info'>
                    <p className='text-light-blue mb-0 statistics-number'>{totalEngagement?.supporters ?? 0}</p>
                    <p className='main-subtle-text title-h3'>Supporter{totalEngagement?.supporters === 1 ? '' : 's'}</p>
                  </div>
                  <div className='statistics-info'>
                    <p className='text-light-blue mb-0 statistics-number'>{totalEngagement?.views ?? 0}</p>
                    <p className='main-subtle-text title-h3' style={{ paddingLeft: '3px' }}>
                      View{totalEngagement?.views === 1 ? '' : 's'}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className={`general-button text-light-blue action-state-hover pointer py-3 d-block w-100 text-left rounded-bottom statistics-button-action  ${
                  isInfluenceSection ? 'influence-statistics-btn' : ''
                } px-4`}
                onClick={() => {
                  setIsOpenShare(true);
                }}
              >
                Share this {normalize(TransformFromPascalCase(type))?.replace('-', ' ')}{' '}
                <span className='inline-block ml-3 icon-long-arrow-right' />
              </button>
            </div>

            <div className='border border-gray-300 rounded-lg pt-4 grow bg-white px-0 statistics-card-container '>
              <h3 className='px-4 title-h4  statistics-info-title'>
                <span className='font-weight-bold'>Policymaker engagement</span>{' '}
                <DataTipPolicyMakers views={policyMakers?.views} />
              </h3>

              <div className='border-bottom py-4'>
                <div className={`statistics-info-container`}>
                  <div className='statistics-info'>
                    <p className='text-light-blue mb-0 statistics-number'>{policyMakers?.reach ?? 0}</p>
                    <p className='main-subtle-text title-h3'>Reach</p>
                  </div>
                  <div className='statistics-info'>
                    <p className='text-light-blue mb-0 statistics-number'>
                      {policyMakers?.views < 3 ? (
                        <span className='policy-makers-views-less'>
                          <span />
                        </span>
                      ) : (
                        policyMakers?.views ?? 0
                      )}
                    </p>
                    <p className='main-subtle-text title-h3' style={{ paddingLeft: '3px' }}>
                      Interactions
                    </p>
                  </div>
                </div>
              </div>
              <button
                className={`general-button text-light-blue action-state-hover pointer py-3 d-block w-100 text-left rounded-bottom statistics-button-action ${
                  isInfluenceSection ? 'influence-statistics-btn' : ''
                } px-4`}
                onClick={() => {
                  if (enableStakeholders) {
                    navigate(stakeholderUrl);
                  } else {
                    setIsOpen(true);
                  }
                }}
              >
                {`See${isMobile ? '' : ' most'} relevant policymakers `}
                <span className='inline-block ml-3 icon-long-arrow-right' />
              </button>
            </div>
          </div>
        </>
      )}
      <StakeholdersFeaturesUpgradePopup setShowPopup={setIsOpen} showPopUp={isOpen} />
      <SharePopup setShowPopup={setIsOpenShare} showPopUp={isOpenShare} data={item} />
    </>
  );
};

const DataTipEngagement = () => {
  const isMobile = useIsMobile(1200);
  const isSmallMobile = useIsMobile(480);
  return (
    <>
      <span
        className='icon-info main-light-text title-h3 pointer'
        data-tooltip-content=''
        data-tooltip-id={'engagement-tooltip'}
        data-tooltip-class-name={`contributions-tooltip contributions-tooltip-sentiment-changes contributions-tooltip-statistics ${
          isSmallMobile ? 'tooltip-width' : ''
        }`}
      />
      <ReactTooltip
        effect={'solid'}
        id={'engagement-tooltip'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
        globalEventOff={isMobile ? 'click' : undefined}
        event={isMobile ? 'click' : undefined}
        overridePosition={({ left, top }, currentEvent) => {
          const target = currentEvent.target;
          const targetTop = target.getBoundingClientRect().y;
          const targetLeft = target.getBoundingClientRect().x;
          return isSmallMobile ? { left: targetLeft - 270, top: targetTop - 200 } : { left, top };
        }}
      >
        <h3 className='text-light-blue title-h5-bold font-bold mb-2'>Supporters</h3>
        <p className='paragraph-p3 line-height-1-5'>
          The number of people who have added their support to your lobbying material. To increase this number, request
          support by using the share link below.
        </p>
        <h3 className='text-light-blue title-h5-bold mt-3 mb-2'>Views</h3>
        <p className='paragraph-p3 line-height-1-5'>
          The total number of times your lobbying material has been viewed.
        </p>
      </ReactTooltip>
    </>
  );
};

const DataTipPolicyMakers = (props) => {
  const isMobile = useIsMobile(1200);
  const isSmallMobile = useIsMobile(480);
  const { views } = props;
  return (
    <>
      <span
        className='icon-info main-light-text title-h3 pointer'
        data-tooltip-content=''
        data-tooltip-id={'policymakers-tooltip'}
        data-tooltip-class-name={`contributions-tooltip contributions-tooltip-sentiment-changes contributions-tooltip-statistics ${
          isSmallMobile ? 'tooltip-width' : ''
        }`}
      />
      <ReactTooltip
        effect={'solid'}
        id={'policymakers-tooltip'}
        className={'header-tooltip tooltip-width'}
        arrowColor={'transparent'}
        place={'top'}
        globalEventOff={isMobile ? 'click' : undefined}
        event={isMobile ? 'click' : undefined}
        overridePosition={({ left, top }, currentEvent) => {
          const target = currentEvent.target;
          const targetTop = target.getBoundingClientRect().y;
          const targetLeft = target.getBoundingClientRect().x;

          return isSmallMobile ? { left: targetLeft - 270, top: targetTop - 400 } : { left, top };
        }}
      >
        <h3 className='text-light-blue title-h5-bold mb-2'>Reach</h3>
        <p className='paragraph-p3 line-height-1-5'>
          The number of {getAppSite() === 'usa' ? 'policymakers' : 'parliamentarians'} and parliamentary staff who have
          signed up to receive lobbying material, and who will receive this content in their PolicyInfluence and
          PolicyMogul feeds based on their interests.
        </p>
        <p className='mt-3 paragraph-p3 line-height-1-5'>
          Note that all {getAppSite() === 'usa' ? 'policymakers' : 'parliamentarians'} and parliamentary staff can
          search and find lobbying material if they are logged in to PolicyInfluence or PolicyMogul, regardless of
          whether it appears in their feed.
        </p>
        <h3 className='text-light-blue title-h5-bold mt-3 mb-2'>Interactions</h3>
        <p className='paragraph-p3 line-height-1-5'>
          The total number of times your lobbying material has been opened, viewed or interacted with by parliamentary
          staff who are logged in to PolicyInfluence or PolicyMogul.
        </p>{' '}
        {views < 3 ? (
          <p className='paragraph-p3 line-height-1-5'>
            This number will be displayed once your content has three or more views from policymakers
          </p>
        ) : (
          ''
        )}
      </ReactTooltip>
    </>
  );
};

export default IndividualContentStatistics;
