import createNotification from 'components/Settings/Utilities/CreateNotification';

function fallbackCopyTextToClipboard(text, successMessage) {
  let tempInput = document.createElement('input');
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
  tempInput.value = text;
  document.body.appendChild(tempInput);
  tempInput.select();
  try {
    document.execCommand('copy');
    createNotification('success', successMessage ?? 'Text copied');
  } catch (err) {}
  document.body.removeChild(tempInput);
}
function copyTextToClipboard(text, successMessage) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      createNotification('success', successMessage ?? 'Text copied');
    },
    function (err) {}
  );
}

function copyInnerHtml(content, text, successMessage) {
  const { ClipboardItem } = window;
  try {
    const blobInputHTML = new Blob([content], { type: 'text/html' });
    const blobInputPlainText = new Blob([text], { type: 'text/plain' });
    const clipboardItemInputHTML = new ClipboardItem({
      'text/html': blobInputHTML,
      'text/plain': blobInputPlainText,
    });
    navigator.clipboard.write([clipboardItemInputHTML]).then(
      function () {
        createNotification('success', successMessage ?? 'Text copied');
      },
      function (err) {}
    );
  } catch (e) {
    // Handle error with user feedback - "Copy failed!" kind of thing
  }
}

export { copyTextToClipboard, copyInnerHtml };
