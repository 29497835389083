import { net_api_url } from 'components/Store';
import axios from 'axios';
const getTeamKeywordLists = async (getAccessToken, source, showHidden) => {
  try {
    let token = await getAccessToken();
    let keyWordLists = await axios.get(
      `${net_api_url}/api/keyword-list/get-team-keyword-lists${showHidden ? '?includeHidden=true' : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      }
    );
    return keyWordLists.data;
  } catch (error) {
    console.error(error);
  }
};

export default getTeamKeywordLists;
