import React, { useContext, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { Error } from 'components/Dashboard/Influence/CrmContacts/AddContactModal';
import CrmContactsSelect from './CrmContactsSelect/CrmContactsSelect';
import { validateEmail } from 'utils/validateEmail';
import useEmailComponentFunctions from './useEmailComponentFunctions';
import { store } from 'components/Store';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ModalComponent from 'components/Common/Modal';

const ContentPopup = ({ setShowPopup }) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { emailStateAfterSave, team, activePlan, emailStateAfterSavePreviewMode } = state;
  const { myself } = team;
  const { subscriptionStatus } = activePlan;
  const { emailAddress } = myself;
  const [error, setError] = useState(null);
  const [emailInputValue, setEmailInputValue] = useState(emailAddress ?? '');
  const [selectedPreviewAs, setSelectedPreviewAs] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);
  const [emailIsSaved, setEmailIsSaved] = useState(false);
  const { isLoading, saveEmail, sendPreview } = useEmailComponentFunctions();

  const trialUser = subscriptionStatus === 'InTrial';

  const arrSeparator = /,|;/;

  const location = useLocation();

  const validateEmailFunc = () => {
    let err = null;
    let valid = true;
    let emailsArr = emailInputValue.split(arrSeparator);
    emailsArr = emailsArr.filter((email) => email.length > 0);
    emailsArr.forEach((item) => {
      if (!validateEmail(String(item.trim())) && item.trim() !== '') {
        err = 'Please enter a valid email';
        return false;
      }
    });
    setError(err);
    return valid;
  };

  const save = useRef();
  save.current = async (source) => {
    try {
      let res = await saveEmail({
        notRedirect: true,
        noNotification: true,
        returnResult: true,
        source,
      });
      if (res) {
        setEmailIsSaved(true);
      }
    } catch {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (!location.state?.openSendPreview) {
      save.current(source);
    }
    return () => {
      source.cancel('Send preview cancelled by the user');
    };
  }, []);

  useEffect(() => {
    if (emailIsSaved || location.state?.openSendPreview) {
      const records = location.state?.openSendPreview
        ? emailStateAfterSavePreviewMode?.sendRecords
        : emailStateAfterSave?.sendRecords;
      if (records?.length > 0) {
        setContactOptions(records);
        if (location.state?.openSendPreview) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'emailStateAfterSavePreviewMode',
            value: {},
          });
        }
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'currentEmailDetails',
          value: emailStateAfterSave,
        });
      }
    }
  }, [emailStateAfterSave, emailIsSaved, emailStateAfterSavePreviewMode]);

  const handleSendPreview = async () => {
    if (validateEmailFunc() && selectedPreviewAs) {
      let emailsArr = emailInputValue.split(arrSeparator);
      emailsArr = emailsArr.filter((email) => email.length > 0).map((item) => item.trim());
      emailsArr = emailsArr.filter((value, index, array) => array.indexOf(value) === index);
      let SuccessMessage = () => {
        return (
          <span>
            Preview sent to{' '}
            {emailsArr.length > 1 ? (
              emailsArr.map((item, i) => {
                return (
                  <span className='nowrap-item'>
                    <span>{item + (i !== emailsArr.length - 1 ? ', ' : '')}</span> <br />
                  </span>
                );
              })
            ) : (
              <span className='nowrap-item'>{emailsArr[0]}</span>
            )}
          </span>
        );
      };
      const res = await Promise.all(
        emailsArr.map(async (email) => {
          return sendPreview({
            crmEmailSendRecordId: selectedPreviewAs.crmEmailSendRecordId,
            toAddress: email,
          });
        })
      );
      if (res.every((item) => !!item)) {
        setShowPopup(false);
        createNotification('success', <SuccessMessage />);
      }
    }
  };

  const loadOptions = async (props) => {
    return contactOptions
      .filter(
        (contact) =>
          contact.fullName?.toLowerCase()?.includes(props.trim().toLowerCase()) ||
          contact.emailAddress?.toLowerCase()?.includes(props.trim().toLowerCase())
      )
      .map((contact) => ({
        value: contact.crmContactId,
        imageId: contact.imageId,
        label: contact.fullName,
        id: contact.crmContactId,
        email: contact.emailAddress,
        crmEmailSendRecordId: contact.crmEmailSendRecordId,
      }));
  };

  return (
    <div className='send-preview-modal m-0'>
      <h4 className='popup-header px-3 px-lg-5 mt-4 mb-3 title-h4-bold'>Send a preview email</h4>
      <div className='separator' />
      <div className='popup-body p-3 px-lg-5 pb-lg-2'>
        <div className={`account-list ${isLoading ? 'opacity-6' : ''}`}>
          <ul className='policy-list px-0 hover-content mb-0'>
            <form className='mx-0'>
              <div className='px-0'>
                <li className='pt-0'>
                  <h3 className='pb-1'>Send the preview to</h3>
                  <div className='mt-2 account-email-field'>
                    <input
                      type='text'
                      onChange={(e) => {
                        setError(null);
                        setEmailInputValue(e.target.value);
                      }}
                      onBlur={validateEmailFunc}
                      name='email'
                      value={emailInputValue}
                      autoComplete='off'
                      placeholder='Enter email addresses separated by commas'
                      disabled={isLoading}
                      readOnly={trialUser}
                    />
                  </div>
                  {error && <Error message={error} />}
                </li>
                <li className='pt-2 mt-1'>
                  <h3 className='pb-1'>Preview the email as</h3>
                  <div className='mt-2  account-email-field'>
                    <CrmContactsSelect
                      initialValues={' '}
                      setActiveTab={() => {}}
                      setActiveRecipientsOption={() => {}}
                      value={selectedPreviewAs}
                      setValue={setSelectedPreviewAs}
                      multi={false}
                      includeTags={false}
                      placeholder="Search by recipient's name or email"
                      disabled={isLoading}
                      loadOptions={loadOptions}
                      autoFocus={!!emailAddress}
                      emptyMessage={
                        <div className='d-flex justify-content-center align-items-center p-2'>
                          <p
                            style={{
                              color: '#999',
                            }}
                            className='mb-0'
                          >
                            There are no matching contacts in your recipient list
                          </p>
                        </div>
                      }
                      showBottomBar={false}
                    />
                  </div>
                </li>
              </div>
            </form>
          </ul>
        </div>
        <div className='my-2 flex-centered justify-content-end'>
          <button
            className='btn btn-general action-button px-4 py-2'
            onClick={handleSendPreview}
            disabled={!emailInputValue || emailInputValue === '' || !selectedPreviewAs}
          >
            {isLoading === 'preview' ? <i className='fas fa-spinner fa-spin' style={{ width: '35px' }}></i> : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
};

const SendPreviewPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <Portal>
      <ModalComponent maxWidth={500} isOpen={showPopUp} setIsOpen={setShowPopup}>
        <ContentPopup {...props} />
      </ModalComponent>
    </Portal>
  );
};

export default SendPreviewPopup;
