import { useAuth0 } from '@auth0/auth0-react';
import { useSetListsIds } from 'components/Dashboard/MainSearchBar/setListIds.js';
import masterTopicObject from 'components/Dashboard/MainSearchBar/masterTopicObject.js';
import { useContext } from 'react';
import { store } from 'components/Store.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import setParamsCode from 'components/Dashboard/MainSearchBar/setParamsCode.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const useDetectTopicOnTheFirstLoad = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { modifiedListId, activePlan, targetClient } = state;
  const { code } = activePlan;

  const { setListsIds, getStoredListIds } = useSetListsIds();

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { newAgencyPlan } = useTopicOrClientWord();
  const stakeholderMappingSections =
    location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers');

  const detectListOnFirstLoad = (keywordLists) => {
    let topicIdParameter = getTopicIdParameter();
    let paramKeywordId = topicIdParameter !== '' ? topicIdParameter.split('#')[0] : '';
    let list = null;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.delete('view');

    //AE: VERIFY IF THE DEFAULT TOPIC WAS DELETED
    if (activePlan.defaultKeywordListId !== null && isAuthenticated) {
      let list = keywordLists.find((item) => item.id === activePlan.defaultKeywordListId);
      if (!list) {
        return navigate(0);
      }
    }

    const defaultList = () => {
      let defaultList = keywordLists.find((item) => item.id === activePlan?.defaultKeywordListId)
        ? activePlan?.defaultKeywordListId
        : null;
      if (defaultList === null) {
        return masterTopicObject(activePlan, keywordLists);
      } else {
        return keywordLists.find((item) => item.id === activePlan.defaultKeywordListId);
      }
    };
    const storedLists = getStoredListIds({ includeMasterTopic: newAgencyPlan });
    const localStorageId = !!storedLists ? storedLists[0] : undefined;

    if (!isAuthenticated) {
      list = null;
      urlParams.delete('topic_id');
      navigate(
        {
          search: urlParams.toString(),
          hash: window.location.hash,
        },
        { replace: true }
      );
    } else if (paramKeywordId !== '' && targetClient === null) {
      //AE: targeClient === null is because when you switch clients the url contains topic-id so we don't need it on that process, we only need on direct loading
      let topicId = paramKeywordId === 'all' ? null : parseInt(paramKeywordId);
      let positionFromEmailAlert = keywordLists.findIndex((keywordList) => keywordList.id === topicId);
      urlParams.set('topic-id', paramKeywordId);
      urlParams.delete('topic_id');
      //OP: check if topic was hiiden/disabled recently
      let listEnabled = keywordLists.find(
        (keywordList) => keywordList.id === topicId && (keywordList.myState === 'Enabled' || keywordList.id === null)
      );
      if (positionFromEmailAlert >= 0 && listEnabled) {
        list = keywordLists.find((keywordList) => keywordList.id === topicId);
      } else {
        urlParams.delete('topic-id');
        if (keywordLists.length === 1) {
          list = keywordLists[0];
        } else if (keywordLists.length > 1 || location.pathname.match(/\/stakeholders\/\d+\//i)?.input) {
          list =
            location.pathname.match(/\/stakeholders\/\d+\//i)?.input || stakeholderMappingSections
              ? keywordLists.find((item) => item.id !== null)
              : defaultList();
          if (newAgencyPlan && targetClient !== null && localStorageId !== undefined) {
            list = keywordLists.find((item) => item.id === localStorageId);
          }
        } else {
          list = 'noListsCreated';
        }
      }
    } else if (keywordLists.length === 1) {
      //AE: Case when you create a new topic with 0 lists and go back without actually create the topic
      list = keywordLists[0].id === '' ? 'noListsCreated' : keywordLists[0];
    } else if (modifiedListId !== null) {
      list = keywordLists.find((item) => item.id === modifiedListId);
    } else if (keywordLists.length > 1) {
      list = defaultList();
      if (stakeholderMappingSections && list.id === null) {
        let listStored = keywordLists.find((item) => item.id === localStorageId);
        list = localStorageId && !!listStored ? listStored : keywordLists.find((item) => item.id !== null);
      }
    } else if (
      keywordLists.length === 0 ||
      keywordLists.filter((topic) => topic.id && topic.myState === 'Enabled').length === 0
    ) {
      list = 'noListsCreated';
    }

    if (!!list) {
      if (keywordLists.find((item) => item.id === list?.id)?.myState !== 'Enabled' && list !== 'noListsCreated') {
        list.id = null;
      }
      urlParams.delete('search');
      if (list !== 'noListsCreated') {
        //AE: Old condition: (urlParams.toString() === '' || !urlParams.get('topic-id')) Removed because was creating issues switching clients.
        urlParams.set('topic-id', list.id === null ? 'all' : list.id);
        setListsIds(list.id);
      }
      setParamsCode({ urlParams, code });
      navigate(
        {
          search: urlParams.toString(),
          hash: location.hash,
        },
        { replace: true }
      );
    }
    dispatch({ type: 'MODIFY_SECTION', parameter: 'modifiedListId', value: null });
    return list;
  };

  const getTopicIdParameter = () => {
    let firstParameter = getUrlParam('topic-id');
    let secondParameter = getUrlParam('topic_id');
    let searchParameter = firstParameter ?? secondParameter ?? '';
    return searchParameter;
  };

  return { detectListOnFirstLoad, getTopicIdParameter };
};

export default useDetectTopicOnTheFirstLoad;
