import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { store } from 'components/Store.js';
import preventLink from './prevenLink.js';
import RelevanceBars from './RelevanceBars.js';
import ConfigData from 'config/ConfigData.js';
import normalize from 'components/Dashboard/utilities/normalizeString.js';

const ExplanationItem = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { search } = state;
  const { query } = search;

  const { name, embedShowTags, searchTermMatches } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const calculateMatch = (value) => {
    const WeakMatch = parseFloat(process.env.REACT_APP_WEAK_MATCH_THRESHOLD);
    const strongMatch = parseFloat(process.env.REACT_APP_STRONG_MATCH_THRESHOLD);
    let score = value !== null ? parseFloat(value.toFixed(2)) : value;
    switch (true) {
      case score === null:
        return 'Strong';
      case score < WeakMatch:
        return 'Moderate';
      case score > WeakMatch && value < strongMatch:
        return 'Strong';
      case score > strongMatch:
        return 'Very strong';
      default:
        return '';
    }
  };

  return (
    <p className='position-relative explanation-container'>
      <span
        className={`explanation-tag  explanation-${normalize(calculateMatch(searchTermMatches[name]))}`}
        data-tooltip-content={`<span class="${`relevance-bars relevance-bars-hover hover-explanation-${normalize(calculateMatch(searchTermMatches[name]))}"`}><span class="relevance-bars-1"></span><span class="relevance-bars-2"></span><span class="relevance-bars-3"></span></span> ${calculateMatch(searchTermMatches[name])} match for '${name}'`}
        data-tooltip-id={'tags-tooltip'}
        onClick={(e) => {
          preventLink(e);
          if (embedShowTags) {
            window.open(
              `https://policymogul.com/monitor-inbox/${ConfigData?.monitorInboxInitialPage ?? 'key-updates'}pdates?search=${encodeURIComponent(name)}`
            );
          } else {
            if (name !== query) {
              dispatch({ type: 'MODIFY_SECTION', parameter: 'individualItemSearch', value: { query: name } });
            } else {
              navigate(`${location.pathname}?search=${encodeURIComponent(name)}`);
              navigate(0);
            }
          }
        }}
      >
        <RelevanceBars />
        {name}
      </span>
    </p>
  );
};

export default ExplanationItem;
