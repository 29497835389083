import { useLocation } from 'react-router-dom';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { useContext } from 'react';
import { store } from 'components/Store';

const useClientsFunctions = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;
  const { myself } = team ?? {};

  const { generalApiCall } = useGeneralApiCall();
  const location = useLocation();

  const adminPage = location?.pathname?.startsWith('/admin');

  const setCurrentTeam = async ({ teamId }) => {
    try {
      const pathname = `/api/team/set-current-team?teamId=${teamId}`;
      const method = 'post';
      const requestProperties = {};
      const results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        notShowErrorMessage: true,
      });
      return results;
    } catch (error) {}
  };

  const getTeams = async ({ includeKeywordsList, source, includeOption }) => {
    try {
      const options = { includeKeywordLists: !!includeKeywordsList, includeHidden: !!includeOption };
      const searchQuery = [];
      Object.keys(options).forEach((item) => {
        if (!!options[item]) {
          searchQuery.push(`${item}=true`);
        }
      });
      const pathname = `/api/team${searchQuery?.length > 0 ? `?${searchQuery.join('&')}` : ''}`;
      const method = 'get';
      return await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
    } catch (error) {}
  };

  const deleteTeam = async ({ teamId }) => {
    try {
      const pathname = `/api/${adminPage ? 'admin/delete-team' : 'team'}/${teamId}`;
      const method = 'delete';
      return await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        returnCompleteRequest: true,
      });
    } catch (error) {}
  };

  const ownerAdminRole = myself?.role === 'Owner' || myself?.role === 'Admin';

  return { setCurrentTeam, getTeams, deleteTeam, ownerAdminRole };
};

export default useClientsFunctions;
