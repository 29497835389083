import StakeholderPage from 'components/Dashboard/StakeHolders/StakeholderPage';
import React from 'react';
import InfluenceContentContainer from 'components/Dashboard/UserContent/InfluenceContentContainer';
import ContentControl from 'components/Dashboard/ContentControl';
const ContentMainRoutesMapping = {
  'key-updates': {
    element: <ContentControl />,
  },
  consultations: {
    element: <ContentControl />,
  },
  legislation: {
    element: <ContentControl />,
    parameters: ['type', 'id', 'title'],
  },
  'parliamentary-record': {
    element: <ContentControl />,
    parameters: ['type', 'id', 'title'],
  },
  'committee-publication': {
    element: <ContentControl />,
    parameters: ['id', 'type', 'title'],
  },
  'library-material': {
    element: <ContentControl />,
  },
  stakeholders: {
    element: <StakeholderPage />,
    parameters: ['id', 'name', 'contentType'],
  },
  'policy-asks': {
    element: <InfluenceContentContainer page={'policy-asks'} />,
  },
  'briefing-material': {
    element: <InfluenceContentContainer page={'briefing-material'} />,
  },
  'news-release': {
    element: <InfluenceContentContainer page={'news-release'} />,
  },
  research: {
    element: <InfluenceContentContainer page={'research'} />,
  },
};

export { ContentMainRoutesMapping };
