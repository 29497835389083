import React from 'react';
import ModalComponent from 'components/Common/Modal';
const AdminConfirmDeleteItem = (props) => {
  const { setShowPopUp, showPopup, deleteKeywordList, topicId } = props;
  return (
    <ModalComponent isOpen={showPopup} setIsOpen={setShowPopUp} maxWidth={500}>
      <div className='py-4'>
        <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 delete-keywordcategory-modal'>
          <div className='text-center'>
            <p>Are you sure you want to delete this topic?</p>

            <div className='my-4'>
              <button
                className='btn btn-general action-button px-4 py-1'
                onClick={async (e) => {
                  await deleteKeywordList({ e, topicId });
                }}
              >
                Delete topic
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default AdminConfirmDeleteItem;
