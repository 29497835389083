import { useContext, useEffect, useState } from 'react';
import { store } from 'components/Store';
import { hex_is_light } from 'components/Reports/ui/ReportDesignButton';
import SpinnerElement from './ClientSpinnerAnimation';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import ColorThief from 'colorthief/dist/color-thief.umd.js';

const ClientLoadingPage = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { targetClient } = state;
  const { organisationLogo, teamName, colorData } = targetClient ?? {};
  const [animationFinished, setAnimationFinished] = useState(false);
  const [data, setData] = useState(colorData ?? null);

  useEffect(() => {
    if (!!organisationLogo && !colorData) {
      const img = new Image();

      img.addEventListener('load', function () {
        const ct = new ColorThief();
        const arrayRGB = ct.getColor(img, 10);
        setData(arrayRGB);
      });

      img.crossOrigin = 'anonymous';
      img.src = organisationLogo;
    }
  }, []);

  const [heightContainer, containerRef] = useHeightContainer();
  const rgbToHex = (r, g, b) =>
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('');

  return (
    <div
      ref={containerRef}
      className={`top-0 adjustment-of-position main-client-loading-container flex-grow-1 flex-centered justify-content-center ${animationFinished ? 'client-loading-container-fade-out' : ''}`}
      style={{
        height: `${heightContainer}px`,
        backgroundColor: !!data ? `rgba(${data?.join(', ')}, 1)` : '#E0F0F9',
      }}
    >
      <div>
        <div className='client-loading-logo-container mb-5'>
          <SpinnerElement
            organisationLogo={organisationLogo}
            animationFinishedState={{ animationFinished, setAnimationFinished }}
          />
        </div>
        <p
          className='title-h3-bold'
          style={{
            color: !!data ? `${hex_is_light(rgbToHex(data[0], data[1], data[2])) ? '#00122B' : '#fff'}` : '#00122B',
          }}
        >
          <span className='client-loading-text position-relative'>
            Switching to {teamName}
            <span />
            <span />
            <span />
          </span>
        </p>
      </div>
    </div>
  );
};

export default ClientLoadingPage;
