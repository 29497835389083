import { useContext } from 'react';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { store } from 'components/Store';

const useUpdatePaidSubscriptions = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions, team } = state;
  const { myself } = team;
  const { isPaperInvoicing, subscriptionStatus, planName, isLegacyPlan } = activeSubscriptions;
  const { generalApiCall } = useGeneralApiCall();

  const { billingPlanCode } = activeSubscriptions;

  const bannerText = () => {
    if (isPaperInvoicing) {
      return 'You are on paper invoicing';
    } else if (myself?.role !== 'Owner') {
      return 'Please contact your account owner to amend your subscription';
    } else if (subscriptionStatus === 'OnFreePlan' || subscriptionStatus === 'OnFreePlanOverLimit') {
      return 'You are on a free plan';
    } else if (planName === 'Enterprise') {
      return 'You are on an Enterprise plan';
    } else if (subscriptionStatus === 'SubscriptionEnded') {
      return 'Your subscription has ended';
    } else if (isLegacyPlan) {
      return 'You are on a legacy pricing plan';
    } else {
      return '';
    }
  };

  const updatePaidSubscriptionCall = async ({ topicsSelected, contactsSelected, planPrice, includedCrmContacts }) => {
    const pathname = '/api/subscription/update-paid-for-subscription';
    const method = 'post';
    const requestProperties = {
      planPrice: planPrice ?? billingPlanCode,
      numberOfTopics: topicsSelected,
      additionalCrmContacts: includedCrmContacts
        ? contactsSelected > includedCrmContacts
          ? contactsSelected - includedCrmContacts
          : 0
        : contactsSelected - 50,
    };
    let response = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
    return response;
  };

  const getPlanPricing = async ({ billingPlanCode }) => {
    const pathname = `/api/subscription/billing-plan-pricing?billingPlanCode=${encodeURIComponent(billingPlanCode)}`;
    const method = 'get';
    let response = await generalApiCall({ pathname, method, needsAuthentication: true, notShowErrorMessage: true });
    return response;
  };

  return { updatePaidSubscriptionCall, bannerText, getPlanPricing };
};

export default useUpdatePaidSubscriptions;
