import React, { useCallback, useContext, useEffect, useState } from 'react';
import { store } from 'components/Store.js';
import callHightlightContentAPI from 'components/Dashboard/utilities/callHightlightContentAPI.js';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions.js';
import axios from 'axios';
import SkeletonItemTwitter from './SkeletonItemTwitter.js';
import highlightWidget from 'components/Dashboard/apiCalls/callHighlightWidget.js';
import ChangeKeywordsCategory from 'components/Dashboard/utilities/changeKeywordsCategory.js';
import { SaveButton } from 'components/Dashboard/components/Share.js';
import { useLocation } from 'react-router-dom';
import useRememberPosition from 'components/Dashboard/hooks/useRememberPosition.js';
import Sentiment from 'components/Dashboard/components/Sentiment.js';
const dayjs = require('dayjs');
let useFilterFromUrl = true;

const TweetItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, activeResults, contentResults, referenceState, lastSeenMarkers, itemsViewed } = state;
  const { activeReference } = referenceState;
  const { SocialMedia } = contentResults[activeResults];
  const { query, list, activeSearch } = search;
  const {
    item,
    index,
    itemFromWidget,
    keywordListId,
    feedItemFlags: propsFeedItemFlags,
    customKeywords,
    notShowShare,
    listView,
  } = props;
  const feedItemFlags = propsFeedItemFlags ?? SocialMedia?.feedItemFlags ?? [];
  const { name, screenName, tweetHtml, tweetId, dateTime, objectID } = item;
  const [isLoading, setIsLoading] = useState(true);

  const [finalTweetName, setFinalTweetName] = useState(null);
  const [finalTweetHtml, setFinalTweetHtml] = useState(null);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const location = useLocation();

  const createMarkup = (body) => {
    return { __html: body };
  };
  const twitterContent = useCallback((node) => {
    if (node !== null) {
      let elements = document.querySelectorAll('.twitter-content a');
      for (var i = 0; i < elements.length; i++) {
        let el = elements[i];
        el.addEventListener('click', (e) => {
          e.stopPropagation();
        });
      }
    }
  });
  const totalHits = !!SocialMedia ? SocialMedia.totalHits : '';
  useEffect(() => {
    if (useFilterFromUrl) {
      setIsLoading(true);
    }

    if (activeSearch || activeReference || itemFromWidget || customKeywords) {
      highLightingTweets();
    } else {
      setFinalTweetName(name);
      setFinalTweetHtml(tweetHtml);
      setIsLoading(false);
    }
    return () => {
      source.cancel('Tweets canceled by the user.');
    };
  }, [totalHits]);

  const highLightingTweets = async () => {
    let andKeywords = list?.andKeywords ?? [];
    let queryHighlight = list !== null && query === '' ? ChangeKeywordsCategory(list.keywords) : query;

    if (activeReference) {
      andKeywords = referenceState.topic?.andKeywords ?? referenceState.andKeywords;
      queryHighlight = ChangeKeywordsCategory(referenceState.topic?.keywords ?? referenceState.keywords);
    }

    if (customKeywords) {
      andKeywords = [];
      queryHighlight = ChangeKeywordsCategory(customKeywords);
    }

    let propertiesHighlight = [objectID, 'tweet', queryHighlight, ChangeKeywordsExclusions(andKeywords), source];
    let contentHightlighted = itemFromWidget
      ? await highlightWidget('tweet', objectID, keywordListId)
      : await callHightlightContentAPI(propertiesHighlight);

    const replaceString = (string) => {
      if (string.includes(`on <em class='policymogul-keyword-match'>Twitter</em>`)) {
        return string.replace(`on <em class='policymogul-keyword-match'>Twitter</em>`, '');
      } else {
        return string.replace('on Twitter', '');
      }
    };
    setFinalTweetName(contentHightlighted ? replaceString(contentHightlighted.highlightedTitle) : name);
    setFinalTweetHtml(contentHightlighted ? contentHightlighted.highlightedHtmlBody : tweetHtml);
    setIsLoading(false);
    useFilterFromUrl = false;
  };

  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    list !== null &&
    feedItemFlags?.length > 0 &&
    !!lastSeenMarkers['tweetFeedItemId'] &&
    lastSeenMarkers['tweetFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition &&
    listView;

  const shareTweetButtonFunction = (e) => {
    let href = !!e.target.href ? e.target.href : e.target.parentElement.href;
    window.open(href, '_blank', 'noopener noreferrer');
    e.stopPropagation();
    e.preventDefault();
    rememberPosition('SocialMedia', null, index);
  };

  const { rememberPosition } = useRememberPosition();

  return (
    <>
      {isLoading && <SkeletonItemTwitter />}
      {!isLoading && (
        <div
          className='tweet-item-link'
          onClick={() => {
            window.open(
              `https://twitter.com/${screenName}/status/${tweetId?.toString()}`,
              '_blank',
              'noopener noreferrer'
            );
            if (!!SocialMedia) {
              rememberPosition('SocialMedia', null, index);
            }
          }}
        >
          <div className={`tweet-container p-4 context my-2 ${unseenConditional ? 'unseen-item' : ''}`}>
            {unseenConditional && (
              <>
                <span className='unseen-dot' />
              </>
            )}
            <div className='d-flex justify-content-between align-items-start'>
              <div className='twitter-profile-info'>
                <div className='flex-centered'>
                  {/* <div className="twitter-profile-image">
                    <img src={profileImageUrl} alt={`profile image of ${screenName}`}/>
                  </div> */}
                  <div className='profile-info'>
                    <p className='profile-name'>
                      <a
                        href={`https://twitter.com/${screenName}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <span dangerouslySetInnerHTML={createMarkup(finalTweetName)} />
                      </a>
                    </p>
                    <p className='profile-username'>@{screenName}</p>
                  </div>
                </div>
              </div>
              <div className='flex-centered twitter-actions-container'>
                <Sentiment item={item} dotClassName={'twitter-sentiment-dot'} />
                {!location.pathname.toLowerCase().startsWith('/embed') && !notShowShare && (
                  <SaveButton item={item} listItem />
                )}
                <img
                  className='twitter-logo'
                  src={`${process.env.REACT_APP_CDNURL}/images/twitter-logo-blue.svg`}
                  alt='twitter logo'
                />
              </div>
            </div>

            <div
              className='twitter-content mb-4'
              dangerouslySetInnerHTML={createMarkup(finalTweetHtml)}
              ref={twitterContent}
            />

            <div className='d-flex justify-content-between tweet-general-button-container flex-wrap align-items-center'>
              <div className='twitter-action-buttons d-flex'>
                <a
                  className='twitter-reply-icon twitter-action-button'
                  onClick={(e) => {
                    shareTweetButtonFunction(e);
                  }}
                  href={`https://twitter.com/intent/tweet?in_reply_to=${tweetId?.toString()}`}
                >
                  Reply
                </a>

                <a
                  className='twitter-retweet-icon twitter-action-button'
                  onClick={(e) => {
                    shareTweetButtonFunction(e);
                  }}
                  href={`https://twitter.com/intent/retweet?tweet_id=${tweetId?.toString()}`}
                >
                  Retweet
                </a>

                <a
                  className='twitter-like-icon twitter-action-button'
                  onClick={(e) => {
                    shareTweetButtonFunction(e);
                  }}
                  href={`https://twitter.com/intent/like?tweet_id=${tweetId?.toString()}`}
                >
                  Like
                </a>
              </div>
              <a
                data-tooltip-content={`${dayjs(dateTime).format('HH:mm')} · ${dayjs(dateTime).format('DD MMMM[,] YYYY')}`}
                data-tooltip-id={'general-tooltip'}
                className='twitter-date'
                onClick={(e) => {
                  shareTweetButtonFunction(e);
                }}
                href={`https://twitter.com/${screenName}/status/${tweetId?.toString()}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {dayjs(dateTime).format('DD MMMM[,] YYYY')}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TweetItem;
