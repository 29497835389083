import { RemoveContactsContent } from 'components/Dashboard/Influence/CrmContacts/RemoveContactsModal/RemoveContactsModal';
import { useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import { useNavigate } from 'react-router-dom';
import useContactsFunctionForStakeholders from 'components/Dashboard/StakeHolders/StakeholderPage/useContactsFunctionForStakeholder';

const DeleteContactPopup = (props) => {
  const { setIsOpen, isOpen, id } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { addOrDeleteContact } = useContactsFunctionForStakeholders();
  const navigate = useNavigate();

  const removeFromContactLists = async () => {
    setIsLoading(true);
    await addOrDeleteContact({ contactId: id, action: 'delete' });
    navigate({ pathname: '/influence/directory' });
  };

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='rounded-bottom pt-4'>
        <RemoveContactsContent
          contactsCount={1}
          closeModal={() => {
            setIsOpen(false);
          }}
          isLoading={isLoading}
          removeFromContactLists={removeFromContactLists}
          {...props}
        />
      </div>
    </ModalComponent>
  );
};

export default DeleteContactPopup;
