import React from 'react';
import { Link } from 'react-router-dom';
import returnCorrectWPUrl from 'components/Dashboard/utilities/returnCorrectWPUrl';
const Footer = (props) => {
  const { sentiment } = props;
  return (
    <footer className='home-footer py-5'>
      <div className={`container ${sentiment ? 'pt-5' : ''}`}>
        <nav className='col-lg-10 mx-auto row px-0'>
          <ul className='policy-list px-0 col-lg-6'>
            <li className='title-h5-bold mb-2'>Resources</li>
            {sentiment && (
              <li>
                <Link to='/'>About PolicyMogul</Link>
              </li>
            )}
            <li>
              <a href={`${returnCorrectWPUrl()}/were-hiring/`}>We're hiring</a>
            </li>
            <li>
              <a href='https://support.policymogul.com/' target='_blank' rel='noopener noreferrer'>
                Help centre
              </a>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/blog/`}>Blog</a>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/comparison/policymogul-vs-dods/`}>PolicyMogul vs Dods</a>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/comparison/policymogul-vs-dehavilland/`}>PolicyMogul vs DeHavilland</a>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/comparison/policymogul-vs-polimonitor/`}>PolicyMogul vs Polimonitor</a>
            </li>
          </ul>

          <ul className='policy-list px-0 col-lg-5 pl-lg-4'>
            <li className='title-h5-bold mb-2'>Free tools</li>
            <li>
              <a href={`${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}`}>PolicyInfluence</a>
            </li>
            <li>
              <Link to='/data-hub'>Data hub</Link>
            </li>
          </ul>
          <ul className='policy-list px-0 col-lg-5 pl-lg-3'>
            <li className='title-h5-bold mb-2'>Legal</li>
            <li>
              <a href={`${returnCorrectWPUrl()}/terms-of-use/`}>Terms of Use</a>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/terms-and-conditions/`}>Terms &amp; Conditions</a>
            </li>
            <li>
              <a href={`${returnCorrectWPUrl()}/privacy-policy/`}>Privacy &amp; Cookie Policy</a>
            </li>
          </ul>
        </nav>
        <div className='d-flex footer-socials justify-content-center py-5'>
          <a
            className='footer__socials--fb'
            href='https://www.facebook.com/PolicyMogul/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Facebook
          </a>
          <a
            className='footer__socials--tw mx-3'
            href='https://twitter.com/PolicyMogul'
            target='_blank'
            rel='noopener noreferrer'
          >
            Twitter
          </a>
          <a
            className='footer__socials--li'
            href='https://www.linkedin.com/company/policymogul/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Linkedin
          </a>
        </div>
        <div className='text-center footer-copyright pt-4'>
          <p>&copy; {new Date().getFullYear()} PolicyMogul. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
