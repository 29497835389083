import { useContext, useEffect, useState } from 'react';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { store } from 'components/Store';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useGetInitialEmailAddress from './CrmEmailComponent/useGetInitialEmailAddress';

const useEmailComponentFunctions = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { currentEmailDetails, emailSelectedContacts, showSendPreviewPopUp, listDomainsVerified } = state;
  const emailAddresses = listDomainsVerified?.verifiedEmailAddresses;
  const { generalApiCall } = useGeneralApiCall();
  const { getInitialSenderEmailAddress } = useGetInitialEmailAddress();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDiscardPopUp, setShowConfirmDiscardPopUp] = useState(false);
  const [showSendPopUp, setShowSendPopUp] = useState(false);

  const setShowSendPreviewPopUp = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showSendPreviewPopUp',
      value: val,
    });
  };
  useEffect(() => {
    if (location.state?.openSendPreview) {
      setShowSendPreviewPopUp(location.state.openSendPreview);
      setTimeout(() => {
        navigate(
          {
            state: {},
          },
          { replace: true }
        );
      }, 300);
    }
  }, [location.state]);

  const params = useParams();
  let { crmEmailId } = params;

  const emailIsNotValid = (props) => {
    const { preview } = props ?? {};
    let errorMessage = 'Please enter';
    const errorsArr = [];
    if (
      !getInitialSenderEmailAddress(
        currentEmailDetails.senderEmailAddressId,
        listDomainsVerified?.verifiedEmailAddresses
      )
    ) {
      errorsArr.push('sender');
    }
    if (!emailSelectedContacts.length) {
      errorsArr.push('recipient');
    }
    if (!currentEmailDetails.subject.length && !preview) {
      errorsArr.push('subject');
    }
    if (!currentEmailDetails.body.trim().length) {
      errorsArr.push('email body');
    }
    if (errorsArr.length > 0) {
      return `${errorMessage} ${errorsArr.join(', ')} value${errorsArr.length > 1 ? 's' : ''} `;
    } else {
      return false;
    }
  };

  const updateEmailStateAfterSave = (result) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailStateAfterSave',
      value: result,
    });
  };
  const updateEmailStateAfterSavePreviewMode = (result) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailStateAfterSavePreviewMode',
      value: result,
    });
  };
  const removeFromEmailRecipients = async ({ result, removeFromContactsArr, preview }) => {
    if (removeFromContactsArr.length > 0) {
      let method = 'post';
      let pathname = '/api/crm-email/remove-contacts';
      let requestProperties = {
        crmEmailId: result.crmEmailId,
        crmContactIds: removeFromContactsArr,
      };
      let successMessage = null;
      const removeContactsFromList = await generalApiCall({
        method,
        pathname,
        requestProperties,
        successMessage,
        needsAuthentication: true,
      });
      if (removeContactsFromList) {
        updateEmailStateAfterSave(removeContactsFromList);
        if (preview) {
          updateEmailStateAfterSavePreviewMode(removeContactsFromList);
        }
        return removeContactsFromList;
      } else {
        setIsLoading(false);
        return false;
      }
    } else {
      return true;
    }
  };

  const addToEmailRecipientsRecipients = async ({ result, addToContactsArr, preview }) => {
    if (addToContactsArr.length > 0) {
      let successfullyAddded = false;
      const contactIds = addToContactsArr.filter((id) => !id.field);
      const tags = addToContactsArr.filter((val) => val?.field === 'tags');

      if (contactIds.length > 0) {
        let method = 'post';
        let pathname = '/api/crm-email/add-contacts';
        let requestProperties = {
          crmEmailId: result.crmEmailId,
          crmContactIds: contactIds,
        };
        let successMessage = null;
        const addContactsToList = await generalApiCall({
          method,
          pathname,
          requestProperties,
          successMessage,
          needsAuthentication: true,
        });
        if (addContactsToList) {
          updateEmailStateAfterSave(addContactsToList);
          if (preview) {
            updateEmailStateAfterSavePreviewMode(addContactsToList);
          }
          successfullyAddded = true;
        } else {
          setIsLoading(false);
          return false;
        }
      }
      if (tags.length > 0) {
        let method = 'post';
        let pathname = '/api/crm-email/add-contacts-by-query';
        let requestProperties = {
          crmEmailId: result.crmEmailId,
          filters: tags,
          query: '',
        };
        let successMessage = null;
        const addContactsToListByTag = await generalApiCall({
          method,
          pathname,
          requestProperties,
          successMessage,
          needsAuthentication: true,
        });
        if (addContactsToListByTag) {
          updateEmailStateAfterSave(addContactsToListByTag);
          if (preview) {
            updateEmailStateAfterSavePreviewMode(addContactsToListByTag);
          }
          successfullyAddded = true;
        } else {
          setIsLoading(false);
          return false;
        }
      }
      return successfullyAddded;
    } else {
      return true;
    }
  };

  const saveEmail = async ({
    isForSending = false,
    notRedirect = false,
    actionOnSuccess = () => {},
    returnResult = false,
    noNotification = false,
    loadingType,
    preview,
    source,
  }) => {
    setIsLoading(loadingType ? loadingType : isForSending ? 'send' : 'save');
    if (isForSending && emailIsNotValid()) {
      setIsLoading(false);
      return createNotification('danger', emailIsNotValid());
    }
    let method = 'post';
    let pathname = '/api/crm-email/save';
    let requestProperties = {
      SenderEmailAddressId:
        getInitialSenderEmailAddress(currentEmailDetails.senderEmailAddressId, emailAddresses)?.id ?? null,
      Body: currentEmailDetails.body,
      Title: currentEmailDetails.subject.trim(),
      EmailDomainId: currentEmailDetails.emailDomainId,
      CrmEmailId: !currentEmailDetails.crmEmailId ? null : parseInt(currentEmailDetails.crmEmailId),
    };
    let successMessage = isForSending === true || noNotification ? null : 'Draft saved';
    let addRecipientsSuccess;
    let removeRecipientsSuccess;

    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    if (result?.crmEmailId) {
      const initialToList = result?.sendRecords?.map((record) => ({
        id: record.crmContactId,
        email: record.emailAddress,
      }));
      let addToContactsArr = emailSelectedContacts
        .filter((item) => !initialToList.find((li) => li.id === item.id) && item.id !== null)
        .map((item) => item.id);
      let removeFromContactsArr = initialToList
        .filter((item) => !emailSelectedContacts.find((li) => li.id === item.id) && item.id !== null)
        .map((item) => item.id);
      updateEmailStateAfterSave(result);
      if (preview) {
        updateEmailStateAfterSavePreviewMode(result);
      }
      addRecipientsSuccess = await addToEmailRecipientsRecipients({
        result,
        addToContactsArr,
        preview,
      });
      removeRecipientsSuccess = await removeFromEmailRecipients({
        result,
        removeFromContactsArr,
        preview,
      });

      if (!isForSending && addRecipientsSuccess && removeRecipientsSuccess) {
        setIsLoading(false);
        if (!notRedirect) {
          navigate(`/influence/emails`, { replace: true });
        }
        if (actionOnSuccess) {
          actionOnSuccess(result);
        }
        if (successMessage) {
          createNotification('success', successMessage);
        }
        if (result && returnResult) {
          return result;
        }
      }
    }
    if (addRecipientsSuccess && removeRecipientsSuccess && result?.crmEmailId) {
      if (isForSending) {
        sendEmail(result.crmEmailId);
      }
    }
  };

  const sendEmail = async (crmEmailIdArg) => {
    if (!currentEmailDetails.crmEmailId && !crmEmailIdArg) {
      await saveEmail({ isForSending: true });
    }
    if (!!currentEmailDetails.crmEmailId || crmEmailIdArg) {
      let method = 'post';
      let pathname = '/api/crm-email/send';
      let requestProperties = {
        CrmEmailId: parseInt(crmEmailIdArg ?? currentEmailDetails.crmEmailId),
      };
      let successMessage = 'Email sent';
      const result = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
      });
      if (result) {
        setIsLoading(false);
        createNotification('success', successMessage);
        navigate('/influence/emails', { replace: true });
      } else {
        if (crmEmailIdArg) {
          navigate(`/influence/emails/${crmEmailIdArg}`, { replace: true });
        }
      }
      setIsLoading(false);
    }
  };

  const sendPreview = async (props) => {
    const { crmEmailSendRecordId, toAddress } = props ?? {};
    if (!!currentEmailDetails.crmEmailId) {
      setIsLoading('preview');
      let method = 'post';
      let pathname = '/api/crm-email/send-preview';
      let requestProperties = {
        CrmEmailId: parseInt(currentEmailDetails.crmEmailId),
        crmEmailSendRecordId,
        toAddress,
      };

      const result = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
        returnCompleteRequest: true,
      });
      if (result?.status === 200) {
        setIsLoading(false);
        return result;
      } else {
        createNotification('danger', 'An error ocurred.');
      }
    }
  };

  const removeEmail = async () => {
    if (!currentEmailDetails.status) {
      navigate('/influence/emails', { replace: true });
    } else {
      if (currentEmailDetails.crmEmailId) {
        setIsLoading('delete');
        let method = 'post';
        let pathname = '/api/crm-email/delete';
        let requestProperties = {
          CrmEmailId: parseInt(currentEmailDetails.crmEmailId),
        };
        let successMessage = 'Email deleted';
        let result = await generalApiCall({
          method,
          pathname,
          requestProperties,
          needsAuthentication: true,
        });
        if (result) {
          createNotification('success', successMessage);
        }
        navigate('/influence/emails', { replace: true });
        setIsLoading(false);
        return result;
      }
    }
  };

  const getEmail = async ({ emailId, source }) => {
    try {
      if (emailId) {
        crmEmailId = emailId;
      }
      if (crmEmailId && crmEmailId !== 'create') {
        let method = 'get';
        let pathname = `/api/crm-email/get/${crmEmailId}`;
        let requestProperties = {
          From: currentEmailDetails.from,
          Body: currentEmailDetails.body,
          Title: currentEmailDetails.title,
          CrmEmailId: currentEmailDetails.crmEmailId === null ? null : parseInt(currentEmailDetails.crmEmailId),
        };
        const result = await generalApiCall({
          method,
          pathname,
          requestProperties,
          needsAuthentication: true,
          requestSource: source,
          notShowErrorMessage: true,
          returnError: true,
        });
        if (!!result) {
          let title = `${!!result?.data?.subject?.length ? `${result?.data?.subject} - ` : ''}${
            result?.data?.status ? result?.data?.status : 'Email'
          } - PolicyMogul`;
          document.title = title;
        }
        return result;
      }
    } catch (error) {}
  };

  async function createEmailsResults(source) {
    const pageSize = 10000;
    const params = {};
    params.pageNumber = 1;
    params.sortField = 'lastUpdated';
    params.sortOrder = 'descending';
    const urlParams = new URLSearchParams(params);
    let method = 'get';
    let url = `/api/crm-email/search?${urlParams.toString()}&pageSize=${pageSize}`;
    let result = await generalApiCall({
      method,
      pathname: url,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    return result;
  }

  const loadSentEmails = async ({ setSentEmails, source }) => {
    try {
      const res = await createEmailsResults(source);
      let emails = res?.results;

      if (!!emails?.length) {
        const sentEmails = emails.filter((email) => email.status === 'Sent' || email.status === 'PartiallySent');
        if (setSentEmails) {
          setSentEmails(sentEmails);
        }
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'emailPerformanceResult',
          value: sentEmails,
        });
      }
      if (emails) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'emailPerformanceOnLoad',
          value: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    isLoading,
    getEmail,
    saveEmail,
    sendEmail,
    sendPreview,
    removeEmail,
    showConfirmDiscardPopUp,
    setShowConfirmDiscardPopUp,
    loadSentEmails,
    showSendPreviewPopUp,
    setShowSendPreviewPopUp,
    emailIsNotValid,
    showSendPopUp,
    setShowSendPopUp,
  };
};

export default useEmailComponentFunctions;
