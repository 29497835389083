import WritingCreate from 'components/WritingAssistant/WritingCreate';
import InfluenceHeader from './ui/InfluenceHeader';
import useInitialValidations from 'components/useInitialValidations';

const InfluenceAIContainer = () => {
  useInitialValidations();
  return (
    <div className='dashboard-root-container'>
      <InfluenceHeader />
      <WritingCreate individualPage={true} />
    </div>
  );
};

export default InfluenceAIContainer;
