import ModalComponent from 'components/Common/Modal';
import React from 'react';

const ContentPopup = (props) => {
  const { deleteAFolder, setShowPopup, currentFolder } = props;
  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 saved-items-popup'>
      <div className='text-center'>
        <p>Are you sure you want to delete this folder?</p>

        <div className='my-4'>
          <button
            className='general-button main-button px-4 py-2'
            onClick={async (e) => {
              let deleteAction = await deleteAFolder(e, currentFolder.id);
              if (deleteAction) {
                setShowPopup(false);
              }
            }}
          >
            Delete folder
          </button>
        </div>
      </div>
    </div>
  );
};

const ConfirmDeleteFolder = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent maxWidth={560} isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default ConfirmDeleteFolder;
