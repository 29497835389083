import axios from 'axios';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';

const useEngagementStatistics = () => {
  const { getAccessToken } = useGetAccessToken();

  const loadStatistics = async ({ item, source }) => {
    try {
      const token = await getAccessToken();
      let requestProperties = {
        cancelToken: source?.token,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { objectId, supporters } = item;
      let views = await axios.get(
        `${process.env.REACT_APP_DOTNETAPIURL}/api/activity-log/page-view-count?objectId=${objectId}&contentType=UserContent&includeAnonymous=true`,
        requestProperties
      );

      let policymakerViews = await axios.get(
        `${process.env.REACT_APP_DOTNETAPIURL}/api/activity-log/page-view-count?objectId=${objectId}&contentType=UserContent&userTypes=parliamentary%20or%20political%20staff%2Cparliamentarian`,
        requestProperties
      );
      let policymakerReach = await axios.get(
        `${process.env.REACT_APP_DOTNETAPIURL}/api/activity-log/feeds-added-count?objectId=${objectId}&contentType=UserContent&userTypes=parliamentary%20or%20political%20staff%2Cparliamentarian`,
        requestProperties
      );
      return {
        totalEngagement: {
          views: views?.data,
          supporters: supporters?.length,
        },
        policyMakers: {
          views: policymakerViews?.data,
          reach: policymakerReach?.data,
        },
      };
    } catch (error) {}
  };

  return { loadStatistics };
};

export default useEngagementStatistics;
