import { useContext } from 'react';
import { store } from 'components/Store';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';
import { useSetListsIds } from 'components/Dashboard/MainSearchBar/setListIds';
import useClientsFunctions from 'components/Dashboard/hooks/useClientsFunctions';
import getActivePlan from 'components/Settings/Utilities/getActivePlan';
import ColorThief from 'colorthief/dist/color-thief.umd.js';

function useChangeClient() {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { clients, activeResults, contentResults } = state;
  const { getAccessToken } = useGetAccessToken();

  const { setListsIds } = useSetListsIds();

  const { setCurrentTeam } = useClientsFunctions();

  const preloadLogo = async (organisationLogo) => {
    let colorData;
    if (!!organisationLogo) {
      const img = new Image();
      img.addEventListener('load', function () {
        const ct = new ColorThief();
        const arrayRGB = ct.getColor(img, 10);
        colorData = arrayRGB;
      });
      img.crossOrigin = 'anonymous';
      img.src = organisationLogo;
    }
    return colorData;
  };

  const changeClientFunction = async ({ clientId, reports }) => {
    const client = clients.find((item) => item.teamId === clientId);
    const colorData = await preloadLogo(client?.organisationLogo);
    try {
      setListsIds(client?.teamId, true);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'targetClient',
        value: !!colorData ? { ...client, colorData } : client,
      });
      //   AE: This is when you switch clients on an update to be sure it reload results when you go back. Not necessary on reports functionality for now
      if (!reports) {
        if (Object.keys(contentResults[activeResults])?.length > 0) {
          let firstSection = Object.keys(contentResults[activeResults])[0];
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'contentResults',
            value: {
              ...contentResults,
              [activeResults]: {
                [firstSection]: {
                  ...contentResults[activeResults][firstSection],
                  scrollPosition: null,
                },
              },
            },
          });
        }
      }

      await setCurrentTeam({ teamId: client?.teamId });
      const activePlan = await getActivePlan(getAccessToken);
      if (!!activePlan) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
      }
      dispatch({ type: 'MODIFY_SECTION', parameter: 'readySetCurrentTeam', value: true });
    } catch (error) {}
  };

  return { changeClientFunction };
}

export default useChangeClient;
