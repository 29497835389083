import createNotification from 'components/Settings/Utilities/CreateNotification';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { consultantAgenciesMembers } from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import LocalStorage from 'utils/LocalStorage';

const DeleteFunction = async (props) => {
  const { state, dispatch, getAccessToken, listPosition, navigate, closePopUp, setIsLoadingButton } = props;
  const { net_api_url, keywordsLists, remainingKeywords, activeSubscriptions, search } = state;
  const { maxNumKeywordsPerTeam } = activeSubscriptions;
  const currentKeywordCategory = keywordsLists[listPosition];
  const { id, keywords, name } = currentKeywordCategory;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const setShowTopicDeletePopup = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showTopicDeletePopup',
      value: val,
    });
  };
  const closeItems = () => {
    if (closePopUp) {
      if (!!setIsLoadingButton) {
        setIsLoadingButton(false);
      }
      setShowTopicDeletePopup(false);
    }
  };

  try {
    let token = await getAccessToken();
    var response = await fetch(`${net_api_url}/api/keyword-list/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    });
    if (response.status === 200) {
      ReactGA.event({
        category: 'Keyword lists',
        action: 'Keyword list deleted',
        label: name,
      });
      closeItems();
      let newKeywordLists = keywordsLists.slice();
      newKeywordLists.splice(listPosition, 1);
      navigate(`/settings/${consultantAgenciesMembers(activeSubscriptions.planCode) ? 'clients' : 'topics'}`);
      dispatch({ type: 'MODIFY_SECTION', parameter: 'listPosition', value: null });
      if (maxNumKeywordsPerTeam !== null) {
        let newRemainingKeywords = remainingKeywords + keywords.length;
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'remainingKeywords',
          value: newRemainingKeywords,
        });
      }
      if (newKeywordLists.length === 0) {
        LocalStorage.removeItem('keywordList');
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'search',
          value: {
            ...search,
            list: null,
            query: '',
            notSetInitialise: true,
          },
        });
      }
      if (newKeywordLists.length > 0) {
        let newActiveList = newKeywordLists[newKeywordLists.length - 1];
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'search',
          value: {
            ...search,
            list: newActiveList,
          },
        });
      }

      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'keywordsLists',
        value: newKeywordLists,
      });
      createNotification('success', `Successfully deleted`);
    } else {
      let text = await response.text();
      createNotification('danger', `${!!text ? text : 'An error ocurred.'}`);
      closeItems();
    }
  } catch (error) {
    createNotification('danger', `An error ocurred.`);
  }
};

export default DeleteFunction;
