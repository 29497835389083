import React, { useContext } from 'react';
import { store } from 'components/Store.js';
import createUtmParameters from 'components/Dashboard/helpers/createUtmParameters.js';
import ModalComponent from 'components/Common/Modal';

const GimmickPopUp = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { gimmickWord, showGimmickPopup } = state;
  const word = gimmickWord;
  let wordToCompare = word.toLowerCase().trim();
  let mainText = () => {
    switch (wordToCompare) {
      case 'test':
        return 'If you really want to test us, start your free trial';
      case 'hola':
      case 'hello':
      case 'hi':
        let wordToUppercase = word.charAt(0).toUpperCase() + word.slice(1);
        return `${wordToUppercase} yourself. What’s your name? Sign up for free and let us know`;
      default:
        return `Power up your public affairs work with PolicyMogul`;
    }
  };
  const setShowGimmickPopup = (val) => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'showGimmickPopup', value: val });
  };
  return (
    <ModalComponent maxWidth={500} isOpen={showGimmickPopup} setIsOpen={setShowGimmickPopup}>
      <div className='p-3 px-lg-5 pt-5 pb-lg-5'>
        <div className='modal-title px-0 px-lg-3'>
          <h2 className='text-center mt-4 mt-lg-2'>{mainText()}</h2>
        </div>
        <main className='modal__content mt-2 mb-3'>
          <ul className='policy-list px-0 pb-0 mb-0 mx-auto gimmick-list my-5 gimmick-list-home'>
            <li>Monitor the policy landscape with unlimited keywords</li>
            <li>Keep up to date with email alerts</li>
            <li>Identify relevant political stakeholders</li>
          </ul>
          <button
            className='general-button main-button px-5 py-2 d-block mx-auto'
            onClick={() => {
              window.location.assign(
                `/register?reg_source=${wordToCompare === 'test' ? 'test-' : ''}search-popup${createUtmParameters('&')}`
              );
            }}
          >
            Start your free trial
          </button>
        </main>
      </div>
    </ModalComponent>
  );
};

export default GimmickPopUp;
