import React, { useContext, useRef, useState } from 'react';
import { store } from 'components/Store';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import useValidateContactsOversubscribed from 'components/Dashboard/utilities/useValidateContactsOversubscribed';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import { useNavigate } from 'react-router-dom';
import { useResearchItemsUrl } from 'components/Dashboard/Navigation/NavPages';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';

const AddContactButton = ({ asDropdown }) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { showAddContactModal, search, keywordsLists, team } = state;
  const { activeSearch } = search;
  const [openDropdown, setOpenDropdown] = useState(false);
  const hideDropdown = () => {
    setOpenDropdown(false);
  };
  const navigate = useNavigate();
  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();
  const { newAgencyPlan } = useTopicOrClientWord();

  const dropdownElement = useRef(null);
  useOutsideElement(dropdownElement, hideDropdown);

  const isSmallMobile = useIsMobile(480);
  const showHideAddContactModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddContactModal',
      value: !showAddContactModal,
    });
  };

  const { stakeholderUrl } = useResearchItemsUrl();

  return (
    <>
      {isSmallMobile && !asDropdown ? (
        <button
          className='add-to-contact-mobile-button'
          onClick={() => {
            validateContactsOversubscribed(showHideAddContactModal, 'add_custom_contact', 1);
          }}
        />
      ) : (
        <div ref={dropdownElement}>
          <button
            className={`general-button sort-button ${
              asDropdown ? 'top-nav-create-contact-dropdown' : ''
            } d-lg-block top-nav-create-contact-button ${
              openDropdown ? 'top-nav-create-contact-dropdown-expanded' : ''
            }`}
            onClick={() => {
              if (asDropdown) {
                setOpenDropdown(!openDropdown);
              } else {
                validateContactsOversubscribed(showHideAddContactModal, 'add_custom_contact', 1);
              }
            }}
          >
            {asDropdown ? (
              <>
                <span className='pr-1'>
                  Add contacts{' '}
                  {newAgencyPlan ? <span className='d-lg-inline-block d-none'> for {team?.teamName}</span> : ''}
                </span>

                <span className='create-contact-dropdown-arrow'></span>
              </>
            ) : (
              <span>
                Create <span className='d-none d-lg-inline-block'>new</span> contact
              </span>
            )}
          </button>
          {openDropdown && (
            <div className='open-dropdown'>
              <div
                className='open-dropdown-option filter-item-dropdown'
                onClick={() => {
                  if (!activeSearch) {
                    dispatch({
                      type: 'MODIFY_SECTION',
                      parameter: 'search',
                      value: {
                        ...search,
                        activeSearch: true,
                        list: keywordsLists.filter((item) => item?.id !== null)?.[0] ?? null,
                      },
                    });
                  }
                  navigate(stakeholderUrl, { replace: true });
                }}
              >
                Find stakeholders
              </div>
              <div
                className='open-dropdown-option filter-item-dropdown'
                onClick={() => {
                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'showAddContactModal',
                    value: !showAddContactModal,
                  });
                }}
              >
                Add your own contact
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AddContactButton;
