import React, { useState, useRef } from 'react';
import useEmailDomainFunctions from 'components/Dashboard/hooks/useEmailDomainFunctions';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { validateEmail } from 'utils/validateEmail';

function AddEmailDomainForm({
  setExpandLastDomain,
  teamId,
  loadInitialInformation = async () => {},
  isAdminPage,
  domainsList,
  closeModal = () => {},
  noDomainsPage,
  senderAddressesPage,
}) {
  const [error, setError] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const dataElements = useRef([]);

  const { newAgencyPlan } = useTopicOrClientWord();

  const { addDomainSenderRequest, addEmailDomainListRequest } = useEmailDomainFunctions();
  const validateForm = () => {
    let elements = dataElements.current;
    let fields = Object.keys(elements);
    for (let i = 0; i < fields.length; i++) {
      let field = elements[fields[i]];
      let val = field.value;
      if (field.required && val === '') {
        setError({ type: fields[i], message: 'Please complete this field' });
        return false;
      } else if (!validateEmail(String(elements['emailAddress'].value))) {
        setError({
          type: 'emailAddress',
          message: 'Please enter a valid email',
        });
        return false;
      }
    }
    return true;
  };

  const submitFormHandle = async () => {
    let displayName = dataElements.current['displayName'].value;
    let domainName = dataElements.current['emailAddress'].value.split('@')[1];
    let userName = dataElements.current['emailAddress'].value.split('@')[0];

    setIsLoading(true);
    let senderResult;
    let result;

    if (!noDomainsPage) {
      if (senderAddressesPage) {
        if (domainsList.find((domain) => domain.name === domainName)) {
          result = domainsList.find((domain) => domain.name === domainName);
          senderResult = await addDomainSenderRequest({
            emailDomainId: result.id,
            displayName,
            userName,
            isAdminPage,
            teamId: newAgencyPlan ? teamId : null,
          });
        } else {
          result = await addEmailDomainListRequest({
            domainName,
            isAdminPage,
            teamId,
          });
          if (result?.id) {
            senderResult = await addDomainSenderRequest({
              emailDomainId: result.id,
              displayName,
              userName,
              isAdminPage,
              teamId: newAgencyPlan ? teamId : null,
            });
          }
        }
      } else {
        result = await addEmailDomainListRequest({
          domainName,
          isAdminPage,
          teamId,
        });
        if (result?.id) {
          senderResult = await addDomainSenderRequest({
            emailDomainId: result.id,
            displayName,
            userName,
            isAdminPage,
            successMessage: 'Domain registered',
            teamId: newAgencyPlan ? teamId : null,
          });
        }
      }
    } else {
      result = await addEmailDomainListRequest({
        domainName,
        isAdminPage,
        teamId,
      });
      if (result?.id) {
        senderResult = await addDomainSenderRequest({
          emailDomainId: result.id,
          displayName,
          userName,
          isAdminPage,
          successMessage: 'Domain registered',
        });
      }
    }

    if (senderResult) {
      if (!!setExpandLastDomain) {
        setExpandLastDomain(true);
      }
      await loadInitialInformation(teamId);
      closeModal();
    }
    setIsLoading(false);
  };

  const addDomain = () => {
    let validate = validateForm();
    if (validate) {
      submitFormHandle();
    }
  };

  const handleChange = (e) => {
    if (e?.target?.name === error?.type) {
      setError({ type: '', message: '' });
    }
  };

  const Error = () => {
    return (
      <div className='error-message px-0 px-lg-2 col-lg-4 mt-2 mt-lg-0'>
        <img className='mr-3' src={`${process.env.REACT_APP_CDNURL}/images/warning-icon.svg`} alt='warning-icon' />
        <p className='mb-0 message py-2 px-3'>{error.message}</p>
      </div>
    );
  };
  return (
    <div className={`${!noDomainsPage ? 'settings-box' : ''}  position-relative rounded-bottom`}>
      <div className={`account-list px-0`}>
        <div id='settings-container'>
          <ul className='policy-list px-0 hover-content mb-0'>
            <form
              className='mx-0'
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  addDomain();
                }
              }}
              id='add-email-domain-form'
            >
              <div className='add-domain-modal-body px-lg-5 px-3 pb-4'>
                <li className='add-domain-modal-list-item' style={{ flexGrow: 1 }}>
                  <h3>Email display name</h3>
                  <p>Use a name that matches the email address to increase open rates</p>
                  <input
                    autoFocus={true}
                    className='mt-2'
                    type='text'
                    autoComplete='off'
                    ref={(input) => (dataElements.current['displayName'] = input)}
                    name='displayName'
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                  />
                  {error.type === 'displayName' && <Error />}
                </li>

                <li className='add-domain-modal-list-item' style={{ flexGrow: 1 }}>
                  <h3>Email address</h3>
                  <input
                    className=' mt-2'
                    type='text'
                    autoComplete='off'
                    ref={(input) => (dataElements.current['emailAddress'] = input)}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                    name='emailAddress'
                  />
                  {error.type === 'emailAddress' && <Error />}
                </li>

                <div className='add-domain-modal-actions mt-4'>
                  {!noDomainsPage && (
                    <button
                      className='add-domain-modal-action-cancel'
                      onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                      }}
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                  )}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      addDomain();
                    }}
                    className='general-button action-button px-3 py-2 add-domain-modal-action-add'
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <i
                        className='fas fa-spinner fa-spin'
                        style={{
                          margin: '0 auto',
                          color: '#fcfcfc',
                          marginTop: '2px',
                          width: '33.7px',
                        }}
                      ></i>
                    ) : noDomainsPage ? (
                      'Next'
                    ) : senderAddressesPage ? (
                      'Add a sender email address'
                    ) : (
                      'Add and verify domain'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AddEmailDomainForm;
