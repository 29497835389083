import React, { useContext } from 'react';
import EngagementStatisticsDropdown from 'components/Dashboard/Influence/EngagementStatisticsDropdown';
import InfluenceSectionTitle from 'components/Dashboard/Influence/InfluenceSectionTitle/InfluenceSectionTitle';
import { store } from 'components/Store';
import { Link, useParams } from 'react-router-dom';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';

function EngagementStatisticsNav() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { engagementStatisticItem } = state;

  const params = useParams();
  const { type } = params;
  const isMobile = useIsMobile();
  const isTablet = useIsMobile(1168);

  return (
    <div className={`w-100 flex-centered`}>
      <div className='flex-grow-1 flex-centered'>
        <InfluenceSectionTitle />
      </div>
      <div className={`flex-centered `}>
        {!isMobile && <EngagementStatisticsDropdown />}
        <Link
          target='_blank'
          to={engagementStatisticItem?.canonicalUrl}
          className='text-decoration-none ml-lg-0 ml-2'
          style={{ visibility: Object.keys(engagementStatisticItem)?.length > 0 && type ? 'visible' : 'hidden' }}
        >
          <button className='d-block sort-button open-content-type-button'>
            Open {!isTablet ? `this ${type?.split('-').join(' ')}` : ''}
            <span className='icon-external-bold' style={{ marginLeft: '8px', opacity: 0.6, fontWeight: 600 }}></span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default EngagementStatisticsNav;
