import { useRef, useState } from 'react';
import DropdownComponent from 'components/Settings/WebsiteFeeds/DropdownComponent';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import normalize from 'components/Dashboard/utilities/normalizeString';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import useGetSectionFilters from 'components/Dashboard/Filters/useGetSectionFilters';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useParams } from 'react-router-dom';

const ChampionStatusOptions = [
  {
    name: 'No status',
    value: null,
    customOperator: 'not_exists',
    color: '#fff',
  },
  {
    name: 'Promoter',
    color: '#d9ead3',
  },
  {
    name: 'Detractor',
    color: '#fbdfdf',
  },
  {
    name: 'Neutral',
    color: '#faf8f8',
  },
];

const useChangeChampionStatus = () => {
  const { generalApiCall } = useGeneralApiCall();
  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { filters } = sectionFilters;
  const params = useParams();
  const { groupId } = params;

  const changeChampionStatus = async ({ id, ids, value, selectedAll }) => {
    try {
      const pathname = `/api/crm-contact/set-champion-status${selectedAll ? `${groupId ? '-by-group' : '-by-query'}` : ''}`;
      const method = 'post';
      let requestProperties = {
        championStatus: value,
      };
      if (!!id) {
        requestProperties = {
          ...requestProperties,
          id,
        };
      }
      if (!!ids) {
        requestProperties = {
          ...requestProperties,
          ids,
        };
      }
      if (selectedAll) {
        requestProperties = {
          ...requestProperties,
          filters,
          query: getUrlParam('search') ?? '',
        };
      }
      if (!!groupId) {
        requestProperties = {
          ...requestProperties,
          crmGroupId: groupId,
        };
      }
      let result = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        returnCompleteRequest: true,
      });
      return result?.status === 200;
    } catch (error) {}
  };
  return { changeChampionStatus };
};

const ChampionStatus = (props) => {
  const { scrollBarPosition, contact, updateChampionStatus, notShowSelected, conditionsToClose = [] } = props;
  const { championStatus, id } = contact ?? {};
  const [isLoading, setIsLoading] = useState(false);

  const { changeChampionStatus } = useChangeChampionStatus();

  const containerElement = useRef();

  const activeOption = ChampionStatusOptions?.find((option) => {
    const valueToCompare = option?.name === 'No status' ? null : option?.name;
    return championStatus === valueToCompare;
  });

  const updateOption = async ({ value }) => {
    try {
      if (!isLoading) {
        setIsLoading(value);
        const results = await changeChampionStatus({ id, value });
        if (!!results) {
          setIsLoading(false);
          if (updateChampionStatus) {
            updateChampionStatus({ id, value });
          }
          createNotification('success', 'Champion status changed');
        }
      }
    } catch (error) {
      setIsLoading(false);
      createNotification('danger', 'An error ocurred.');
    }
  };

  return (
    <div className='w-100' ref={containerElement}>
      <DropdownComponent
        scrollBarPosition={scrollBarPosition}
        fixedDropdown={true}
        mainItemClass={`status-${normalize(activeOption?.name)}`}
        mainItem={activeOption?.name}
        waitOnOpen={false}
        conditionsToClose={conditionsToClose}
      >
        <ul className='policy-list p-0 m-0'>
          {ChampionStatusOptions?.map((item) => {
            const { name } = item;
            const valueToCompare = name === 'No status' ? null : name;
            const loadingState = isLoading === valueToCompare;
            const updateFunction = props?.updateFunction ?? updateOption;
            return (
              <li
                onClick={() => {
                  if (!loadingState) {
                    updateFunction({ value: valueToCompare });
                  }
                }}
                className={`list-action-button py-2 px-3 flex-centered pointer list-action-button-${normalize(name)}`}
                key={`item-${name}`}
              >
                <span className='flex-grow-1'>{name}</span>
                {!loadingState && activeOption?.name === name && !notShowSelected && (
                  <span className='icon-tick paragraph-p5' />
                )}
                {loadingState && <i className='fas fa-spinner fa-spin ml-2' />}
              </li>
            );
          })}
        </ul>
      </DropdownComponent>
    </div>
  );
};

export { useChangeChampionStatus, ChampionStatusOptions };
export default ChampionStatus;
