import { useContext, useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import { store } from 'components/Store';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import { useGroupFunctions } from './GroupFunctions';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { useNavigate, useParams } from 'react-router-dom';

const RemoveGroupsModal = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { contentResults, activeResults, selectedGroups, team } = state;

  const { selectedAll, selectedIds } = selectedGroups;
  const { Groups } = contentResults[activeResults];

  const [isLoading, setIsLoading] = useState(false);

  const { openState, groupsCount } = props;
  const contactsText = groupsCount === 1 ? `this group` : `these ${thousandCommas(groupsCount)} groups`;
  const { isOpen, setIsOpen } = openState ?? {};

  const params = useParams();
  const navigate = useNavigate();
  const { groupId } = params;

  const { newAgencyPlan } = useTopicOrClientWord();
  const { deleteGroup } = useGroupFunctions();

  const removeGroupsFromList = async () => {
    try {
      setIsLoading(true);
      let groupsToDelete = selectedAll ? Groups?.map((item) => item?.id) : selectedIds;
      if (!!groupId) {
        groupsToDelete = [groupId];
      }
      for (let i = 0; i < groupsToDelete?.length; i++) {
        let id = groupsToDelete[i];
        await deleteGroup({ id });
      }
      if (!!Groups) {
        let newGroups = [...Groups];
        const GroupsResults = {
          ...contentResults,
          [activeResults]: {
            Groups: newGroups?.filter((item) => !groupsToDelete?.includes(item?.id)),
          },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: GroupsResults,
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedGroups',
          value: {
            selectedAll: false,
            selectedIds: [],
          },
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'updateGroupsList',
          value: true,
        });
      }

      createNotification('success', `Group${groupsCount === 1 ? '' : 's'} successfully deleted.`);

      if (groupId) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'group',
          value: null,
        });
        navigate('/influence/my-groups');
      } else {
        setIsLoading(false);
        setIsOpen(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        maxWidth={558}
        overlayClassName={'react-modal-overlay modal-close-position-adjustment'}
      >
        <div>
          <h3 className='pt-4 topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 pl-3 pr-5'>
            {newAgencyPlan
              ? `Remove ${contactsText} from ${team?.teamName}'s contact list`
              : `Are you sure you want to delete ${contactsText}`}{' '}
            ?
          </h3>
          <div className='px-lg-5 px-3'>
            <div className='remove-contacts-wrapper pt-4'>
              <p className='remove-contacts-title main-subtle-text'>
                The individual records in {groupsCount === 1 ? 'this group' : 'these groups'} will continue to appear in
                your directory, and groups can be recreated later if needed
              </p>
            </div>
          </div>
          <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
            <span className='remove-contacts-helper-text-icon mr-2 d-inline-block-centered centered-6' />
            These changes impact all members of your team
          </p>
          <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white pt-4 rounded-bottom'>
            <button
              onClick={() => setIsOpen(false)}
              className='danger-button-outlined px-3 py-2 mr-3'
              disabled={isLoading}
            >
              Cancel
            </button>
            <button onClick={removeGroupsFromList} className='danger-button px-3 py-2' disabled={isLoading}>
              {isLoading ? (
                <i
                  className='fas fa-spinner fa-spin'
                  style={{
                    margin: '0 41.2px',
                    color: '#fcfcfc',
                    marginTop: '2px',
                    width: '33.7px',
                  }}
                ></i>
              ) : groupsCount === 1 ? (
                `Delete group`
              ) : (
                `Delete ${thousandCommas(groupsCount)} groups`
              )}
            </button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

const RemoveGroupsButton = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults } = state;
  const { Groups } = contentResults[activeResults];
  const groupsCount = Groups?.length;

  const { selectedGroups } = state;
  const { selectedAll, selectedIds } = selectedGroups;
  const [isOpen, setIsOpen] = useState(false);
  const finalNumber = selectedAll ? groupsCount : selectedIds?.length;

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className='stakeholder-subnav-deselect-btn text-red'
      >
        Delete <span className='d-none d-md-inline-block'>group{finalNumber === 1 ? '' : 's'}</span>
      </button>
      <RemoveGroupsModal groupsCount={finalNumber} openState={{ isOpen, setIsOpen }} />
    </>
  );
};

export { RemoveGroupsModal };
export default RemoveGroupsButton;
