import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Navigation from 'components/Dashboard/Navigation';
import Footer from './Footer.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import getUtmParameters from 'components/Dashboard/helpers/getUtmParameters.js';
import useScrollBody from './useScrollBody.js';
import { HomeLogos, Products, Questions } from './HomeComponents.js';
import useOnScreen from 'components/Dashboard/hooks/useOnScreen.js';
import returnCorrectWPUrl from 'components/Dashboard/utilities/returnCorrectWPUrl.js';
import { HomeSearchButton, SpeakerButton, VideoPlayer } from './HomeHeroComponents.js';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags.js';
const WebFont = require('webfontloader');

const Home = () => {
  const location = useLocation();
  const [fontLoaded, setFontLoaded] = useState(false);
  const { addMetaTags } = useAddMetaTags();

  useScrollBody();
  useEffect(() => {
    let title = `PolicyMogul - Political monitoring platform for UK public affairs and policymakers`;
    let hash = location.hash;
    let contentUrl = '/';
    let description =
      'PolicyMogul is an all-in-one public affairs and political monitoring platform. We make it easy to monitor, influence and analyse parliament, government and policymakers';
    addMetaTags({ title, hash, contentUrl, description });
    getUtmParameters(location);
    requestAnimationFrame(draw);
    focusInput.current();
    WebFont.load({
      google: {
        families: ['Lato'],
      },
      active: function () {
        setFontLoaded(true);
      },
    });
  }, []);

  const [dataSpin, setDataSpin] = useState(0);
  const [optionSelected, setOptionSelected] = useState('political updates');
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleLogos, setIsVisibleLogos] = useState(false);
  const [finishAnimation, setFinishAnimation] = useState(false);
  const [speakerOptionSelected, setSpeakerOptionSelected] = useState('PolicyMogul background music');
  const videoElementRef = useRef();

  const fps = 30;
  const interval = useRef(30000 / fps);
  const counter = useRef(0);
  let then;
  let delta;

  const draw = (now) => {
    if (counter.current < 5) {
      if (!then) {
        then = now;
      }
      requestAnimationFrame(draw);
      if (counter.current === 1) {
        //OP: show first step almost immediatelly and next ones with 'normal' interval
        interval.current = 60000 / fps;
      }
      delta = now - then;
      if (delta > interval.current) {
        // update time stuffs
        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.

        then = now - (delta % interval.current);
        setDataSpin(counter.current + 1);
        const newCounter = counter.current + 1;
        counter.current = newCounter;
        if (newCounter === 5) {
          setTimeout(() => {
            setFinishAnimation(true);
          }, 300);
        }
      }
    }
  };

  const isMobile = useIsMobile(720);
  const navigate = useNavigate();
  const homeMainContainer = useRef();
  const centerText = useRef(null);
  const homeLogos = useRef(null);
  const isIntersectingText = useOnScreen(centerText, '0px 0px -10% 0px');
  const isIntersectingLogos = useOnScreen(homeLogos, '0px 0px -10% 0px');

  const inputElement = useRef();
  const focusInput = useRef();
  focusInput.current = () => {
    if (!!window.IntersectionObserver && window.innerWidth > 992) {
      let element = inputElement.current;
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              element.focus();
            }
          });
        },
        { rootMargin: '0px 0px -30% 0px' }
      );
      observer.observe(element);
    }
  };

  useEffect(() => {
    if (isIntersectingText && !isVisible) {
      setIsVisible(true);
    }
  }, [isIntersectingText]);

  useEffect(() => {
    if (isIntersectingLogos && !isVisibleLogos) {
      setIsVisibleLogos(true);
    }
  }, [isIntersectingLogos]);

  useEffect(() => {}, []);

  const elementRef = useRef();
  const getIntrinsicWidth = (el) => {
    if (el) {
      const originalDisplay = el.style.display;
      const originalWidth = el.style.width;

      el.style.display = 'inline';
      el.style.width = 'auto';

      const intrinsicWidth = el.offsetWidth;

      el.style.display = originalDisplay;
      el.style.width = originalWidth;
      return intrinsicWidth;
    }
  };

  return (
    <>
      <Navigation />
      <div className='home-container'>
        <main className='home'>
          <section className='home-main-information pb-lg-5' ref={homeMainContainer} data-spin-to={dataSpin}>
            <div className='home-animation-square'>
              <div className='home-animation-square-element' />
            </div>
            <div className='home-main-container main-content-wrapper mx-auto' style={{ opacity: fontLoaded ? 1 : 0 }}>
              <div className='d-lg-flex justify-content-between px-3 px-lg-5 pt-3 pt-lg-5'>
                <div
                  className='main-information-container pt-lg-5 pr-lg-5'
                  style={
                    isMobile
                      ? null
                      : {
                          minHeight: '482px',
                        }
                  } /*AE: HEIGHT WITH THE THIRD LINE WHICH IS HIDDEN WHEN THE ANIMATION FINISH */
                >
                  <h1 className={`text-center text-lg-left ${finishAnimation ? 'home-animation-finishes' : ''}`}>
                    <span ref={elementRef}>Champions of</span>
                    <span className='accent accent-3 home-spinner' data-spin-to={dataSpin}>
                      <span className='spinner-container d-block'>
                        <span className='spinner-item'>public affairs</span>
                        <span className='spinner-item'>cutting through </span>
                        <span className='spinner-item'>knowing who </span>
                        <span className='spinner-item'>actually having</span>
                        <span className='spinner-item'>avoiding nasty</span>
                        <span className='spinner-item'>public affairs</span>
                      </span>
                    </span>
                    <span
                      className={`accent accent-3 home-spinner ${finishAnimation ? 'd-none' : ''}`}
                      data-spin-to={dataSpin}
                    >
                      <span className='spinner-container d-block'>
                        <span className='spinner-item' style={{ opacity: 0 }}>
                          affairs
                        </span>
                        <span className='spinner-item'>the noise</span>
                        <span className='spinner-item'>to target</span>
                        <span className='spinner-item'>an impact</span>
                        <span className='spinner-item'>surprises</span>
                        <span className='spinner-item' style={{ opacity: 0 }}>
                          affairs
                        </span>
                      </span>
                    </span>
                  </h1>

                  <div
                    className={`pt-4 mt-lg-3 overflow-hidden mx-auto mx-lg-0`}
                    style={{
                      width: finishAnimation && !isMobile ? `${getIntrinsicWidth(elementRef.current)}px` : '100%',
                    }}
                  >
                    <div className={`d-lg-flex  home-hero-buttons ${finishAnimation ? 'finished' : ''}`}>
                      <Link
                        to={'/register'}
                        className='flex-grow-1 d-block text-center general-button action-button rounded-pill px-4 py-2 home-hero-button nowrap-item'
                      >
                        <span>Start your free trial</span>
                      </Link>
                      <a
                        href={`${returnCorrectWPUrl()}/demo`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='d-block text-center flex-grow-1 general-button bg-main-white reset-link reset-link-color rounded-pill px-4 py-2 hovered-button home-hero-button nowrap-item my-3 my-lg-0'
                      >
                        <span>Book a demo</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='home-video-container py-2 my-4 my-lg-0 px-3 p-0'>
                  <SpeakerButton
                    videoElementRef={videoElementRef}
                    optionSelectedState={{
                      optionSelected: speakerOptionSelected,
                      setOptionSelected: setSpeakerOptionSelected,
                    }}
                  />
                  <div className='home-video-image mb-5 mb-lg-0'>
                    <VideoPlayer
                      optionSelectedState={{
                        optionSelected: speakerOptionSelected,
                      }}
                      videoElementRef={videoElementRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='bg-salmon home-policy-mogul-action-section'>
            <div className='main-content-wrapper mx-auto px-lg-5 px-3'>
              <div className='bg-yellow p-4 p-lg-5 z-50 position-relative rounded'>
                <div className='px-lg-5 d-lg-flex align-items-center'>
                  <div className='pr-lg-5'>
                    <h3 className='title-h1-bold mb-1 action-title'>See PolicyMogul in action</h3>
                    <p className='title-h4 mb-4 mb-lg-0'>Search for any keyword or phrase</p>
                  </div>
                  <div className='flex-grow-1 pl-lg-4'>
                    {isMobile && (
                      <div className='pb-2'>
                        <HomeSearchButton optionSelected={optionSelected} setOptionSelected={setOptionSelected} />
                      </div>
                    )}
                    <div className='home-searchbar-container rounded-pill bg-main-white py-1 pr-2 pl-3 flex-centered'>
                      <div className='flex-grow-1'>
                        <input
                          ref={inputElement}
                          className='input-empty specific-y-padding-3 py-lg-2 rounded-pill title-h5-m w-100'
                          placeholder='e.g. animal welfare'
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              const pathname =
                                optionSelected === 'political updates' ? '/search-results' : '/data-hub/dashboard';
                              navigate({ pathname: pathname, search: `?search=${decodeURIComponent(e.target.value)}` });
                            }
                          }}
                        />
                      </div>
                      {!isMobile && (
                        <HomeSearchButton optionSelected={optionSelected} setOptionSelected={setOptionSelected} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pb-5 py-lg-5 bg-salmon overflow-hidden'>
            <div className='main-content-wrapper flex-column flex-lg-row flex-centered mx-auto pt-5 py-lg-5 mb-lg-5 px-lg-5 px-3'>
              <div
                className={`left-panel ${isVisible ? 'panel-active' : ''} order-1 order-lg-0 pt-4 pt-lg-0 px-0 ml-lg-1`}
              >
                <img
                  className='left-panel-1'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/20241107-left-panel-image-1.png`}
                  alt='people'
                />
                <img
                  className='left-panel-2'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/left-panel-image-2.png`}
                  alt='people'
                />
                <img
                  className='left-panel-3'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/left-panel-image-3.png`}
                  alt='people'
                />
                <img
                  className='left-panel-4'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/left-panel-image-4.png`}
                  alt='people'
                />
                <img
                  className='left-panel-5'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/left-panel-image-5.png`}
                  alt='people'
                />
              </div>
              <div
                className='mx-auto text-center order-0 order-lg-1 pt-5 pb-3 py-lg-0'
                ref={centerText}
                style={{ maxWidth: '350px' }}
              >
                <p className='text-uppercase paragraph-p3-bold  main-light-text'>Join the best</p>
                <p className='title-h1-bold mb-4 '>
                  Connecting thousands of public affairs professionals with political intelligence and policymakers
                </p>
              </div>
              <div className={`order-2 right-panel ${isVisible ? 'panel-active' : ''} px-0 mr-lg-1`}>
                <img
                  className='right-panel-1'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/right-panel-image-1.png`}
                  alt='people'
                />
                <img
                  className='right-panel-2'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/right-panel-image-2.png`}
                  alt='people'
                />
                <img
                  className='right-panel-3'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/right-panel-image-3.png`}
                  alt='people'
                />
                <img
                  className='right-panel-4'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/20241107-right-panel-image-4.png`}
                  alt='people'
                />
                <img
                  className='right-panel-5'
                  src={`${process.env.REACT_APP_CDNURL}/images/panels/20241107-right-panel-image-5.png`}
                  alt='people'
                />
              </div>
            </div>
            <img
              src={`${process.env.REACT_APP_CDNURL}/images/panels/panels-mobile.png`}
              className='w-100 d-lg-none mb-4'
              alt='people'
            />
          </section>

          <section className='bg-salmon' ref={homeLogos}>
            <div
              className='main-content-wrapper px-3 px-lg-5 mx-auto overflow-hidden pb-5 home-logos-transition'
              style={{ opacity: isVisibleLogos ? 1 : 0 }}
            >
              <HomeLogos />
            </div>
          </section>

          <Questions />
          <Products />
          <section className='bg-yellow py-5 how-it-comes-together'>
            <div className='py-5 text-center '>
              <p className='title-h1-bold px-4 px-lg-0'>See how it all comes together</p>
              <div className='pt-3'>
                <Link to={'/register'} className='general-button action-button rounded-pill px-4 py-2'>
                  Start your free trial
                </Link>
                <Link
                  to={`${returnCorrectWPUrl()}/demo`}
                  className='ml-3 general-button bg-main-white reset-link reset-link-color hovered-button rounded-pill px-4 py-2'
                >
                  Book a demo
                </Link>
              </div>
            </div>
          </section>
          <section className='py-5'>
            <div className='py-lg-5 home-blockquote-width mx-auto'>
              <div className='row mx-0 align-items-center'>
                <div className='col-lg-4 pl-0 pr-5 d-none d-lg-block'>
                  <img
                    src={`${process.env.REACT_APP_CDNURL}/images/quotes-images/20102024-jayne-almond-deliveroo.jpg`}
                    alt='photograph of Bhavina Bharkhada'
                    className='img-fluid rounded image-render-optimized'
                  />
                </div>
                <div className='col-lg-11'>
                  <img
                    alt='quote'
                    className='blockquote-image'
                    src={`${process.env.REACT_APP_CDNURL}/images/quote_open.png`}
                  />
                  <blockquote className='title-h3 pr-5  mt-3'>
                    PolicyMogul offers top quality monitoring and the team is always so willing to help with any
                    questions. They offer a huge range of tools which we didn't even know we needed until we started
                    using them
                  </blockquote>
                  <p className='mb-0 title-h5-m'>Jayne Almond, Head of Public Affairs, UK&I, Deliveroo</p>
                  <div className='text-right mt-3 mt-lg-0'>
                    <img
                      alt='quote'
                      className='blockquote-image'
                      src={`${process.env.REACT_APP_CDNURL}/images/quote_close.png`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Home;
