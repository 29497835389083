import { useState } from 'react';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';

const CustomTooltip = ({ showDelay = 400, content, children }) => {
  let showTimeout;
  const [active, setActive] = useState(false);
  const isMobile = useIsMobile(1200);

  const showTip = () => {
    showTimeout = setTimeout(() => {
      setActive(true);
    }, showDelay);
  };

  const hideTip = () => {
    setActive(false);
    clearInterval(showTimeout);
  };

  return (
    <div
      onMouseEnter={isMobile ? undefined : showTip}
      onMouseLeave={isMobile ? undefined : hideTip}
      onClick={isMobile ? () => setActive(!active) : undefined}
      style={{
        position: 'absolute',
        width: '110%',
        height: '110%',
        top: '-5%',
        left: '-5%',
      }}
    >
      {children}
      {active && (
        <>
          <div>{content}</div>
          {isMobile && (
            <div
              style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                top: 0,
                left: 0,
                zIndex: 9,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomTooltip;
