import React, { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';

const easeInOutQuart = (t, b, c, d) => {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b;
  return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
};

const Wheel = ({ progress, size, backgroundColor }) => {
  const canvasRef = useRef(null);
  const radius = size / 2;
  const MIN_PROGRESS_THRESHOLD = 0.02; // Umbral mínimo para evitar mostrar la línea al inicio

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const render = () => {
      if (progress < MIN_PROGRESS_THRESHOLD) {
        context.clearRect(0, 0, size, size);
        return; // No renderizar si el progreso es 0 o menor
      }

      let progressVal = easeInOutQuart(progress, 0, 1, 1);

      context.clearRect(0, 0, size, size);

      let offsetX = size / 2;
      let offsetY = size / 2;

      let currentRadius = Math.max(0, progressVal * radius);
      let angle = progressVal * Math.PI * 2;

      let x = Math.cos(angle - Math.PI) * (radius - currentRadius) + offsetX;
      let y = Math.sin(angle - Math.PI) * (radius - currentRadius) + offsetY;

      context.fillStyle = backgroundColor;
      context.strokeStyle = backgroundColor;

      context.save();

      context.beginPath();
      context.arc(offsetX, offsetY, radius, 0, 2 * Math.PI);
      context.fill();

      context.globalCompositeOperation = 'source-in';

      let x2 = Math.cos(-angle) * currentRadius;
      let y2 = Math.sin(-angle) * currentRadius;

      context.beginPath();
      context.arc(offsetX + x2, offsetY - y2, radius, angle, angle + Math.PI);
      context.lineWidth = currentRadius * 2;
      context.stroke();

      context.restore();

      context.beginPath();
      context.arc(x, y, currentRadius, 0, 2 * Math.PI);
      context.fill();
    };

    render();
  }, [progress, radius, size, backgroundColor]);

  return <canvas ref={canvasRef} width={size} height={size}></canvas>;
};

const SpinnerElement = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { changingClientFinished } = state ?? {};

  const { organisationLogo, size = 200, backgroundColor = '#fff', animationFinishedState, duration = 1500 } = props;
  const [imageClass, setImageClass] = useState('');
  const [animationComplete, setAnimationComplete] = useState(false);

  const [progress, setProgress] = useState(0);
  const [animatingIn, setAnimatingIn] = useState(true);
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const { animationFinished, setAnimationFinished } = animationFinishedState ?? {};

  useEffect(() => {
    setTimeout(() => {
      setImageClass('appearing');
    }, 750);
  }, []);

  useEffect(() => {
    if (changingClientFinished) {
      setTimeout(() => {
        //setImageClass('dissapearing');
        //handleRetract();
        setAnimationFinished(true);
      }, 1500);
    }
  }, [changingClientFinished]);

  useEffect(() => {
    if (animationFinished) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'targetClient', value: null });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'changingClientFinished', value: false });
      setAnimationFinished(false);
    }
  }, [animationFinished]);

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      if (deltaTime >= 16.67) {
        setProgress((prev) => {
          const newProgress = animatingIn ? prev + (1 / duration) * 16.67 : prev - (1 / duration) * 16.67;
          if (newProgress >= 1) {
            setAnimatingIn(false);
            setAnimationComplete(true);
            return 1;
          } else if (newProgress <= 0) {
            cancelAnimationFrame(requestRef.current);
            return 0;
          }
          return newProgress;
        });
        previousTimeRef.current = time;
      }
    } else {
      previousTimeRef.current = time;
    }
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (animatingIn) {
      requestRef.current = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [animatingIn]);

  /* const handleRetract = () => {
    setAnimatingIn(false);
    setAnimationComplete(false);
    requestRef.current = requestAnimationFrame((time) => {
      previousTimeRef.current = time;
      animate(time);
    });
  }; */

  return (
    <div className={`client-animation-container w-100 h-100 ${animationComplete && !animatingIn ? 'animated' : ''}`}>
      <div className='position-absolute top-0 left-0'>
        <div>
          <Wheel progress={progress} size={size} backgroundColor={backgroundColor} />
        </div>
      </div>
      <div className='w-100 h-100 flex-centered text-center justify-content-center position-relative z-10'>
        <img
          className={`client-loading-image ${imageClass}`}
          src={`${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(organisationLogo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`)}&mime=${encodeURIComponent('image/png')}&cache=true`}
          alt='organisation logo'
        />
      </div>
    </div>
  );
};

export default SpinnerElement;
