import LocalStorage from 'utils/LocalStorage';
import { useCreateSectionStored } from './useGetSectionFilters';

const useSetFiltersLocalStorageByType = () => {
  const { sectionStored } = useCreateSectionStored();

  const setFiltersLocalStorageByType = ({ type, options }) => {
    let storedFilters = LocalStorage.getItem('contentFilters');
    let finalObject = {};
    if (storedFilters) {
      let filtersParsed = JSON.parse(storedFilters);
      if (Array.isArray(filtersParsed)) {
        finalObject = {
          ...finalObject,
          initialFilters: filtersParsed,
        };
      } else {
        finalObject = filtersParsed;
      }
    }
    let sectionToStore = sectionStored;
    finalObject[sectionToStore].map((item) => {
      if (item.type === type) {
        return { ...item, options };
      } else {
        return item;
      }
    });
  };

  return { setFiltersLocalStorageByType };
};

export default useSetFiltersLocalStorageByType;
