import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ContentItemsPanel from './ui/ContentItemsPanel';
import useRightPanelIndividualCall from './useRightPanelIndividualCall';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';

const OverallSentimentRightPanel = (props) => {
  const { contentState, analyticsState, setRightPanelState } = props;
  const { chartDataOptionSelected } = analyticsState;
  const { text, dataKey, noResults, sentiment } = contentState;

  const chartOptionName = chartDataOptionSelected;
  const contributionGraph = chartOptionName === 'Contribution type' || chartOptionName === 'Overall';

  let sections = ['Spoken parliamentary contributions', 'Written questions', 'EDMs', 'Committee contributions'];
  if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
    sections = [...sections, 'Tweets', 'Retweets'];
  }

  const searchParameter = getUrlParam('search');
  const sectionsValues = useRef([]);
  const [activeSection, setActiveSection] = useState();
  const [isLoading, setIsLoading] = useState(!noResults);
  const [results, setResults] = useState(null);

  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const isLoadedComponent = useRef(false);

  const { individualApiCall } = useRightPanelIndividualCall({ analyticsState, contentState });

  const initialtItems = useRef();
  initialtItems.current = async ({ source }) => {
    try {
      if (noResults) {
        setResults({ hits: [] });
        sectionsValues.current = [];
      } else {
        isLoadedComponent.current = false;
        sectionsValues.current = [];
        setIsLoading(true);
        if (contributionGraph) {
          let results = await individualApiCall({
            type: dataKey,
            sentiment,
            snapshot: true,
            source,
            freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
          });
          if (results) {
            setActiveSection(dataKey);
            setResults({ ...results, thereIsMoreContent: results.hits?.length === pageSize });
            setIsLoading(false);
            isLoadedComponent.current = true;
          }
        } else {
          let values = [];
          for (let i = 0; i < sections.length; i++) {
            let type = sections[i];
            let results = await individualApiCall({
              type,
              sentiment,
              snapshot: true,
              freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
              source,
            });
            if (results) {
              values.push({
                name: type,
                value: results?.totalHits,
                results: { ...results, thereIsMoreContent: results.hits?.length === pageSize },
              });
            }
          }
          if (values.length > 0) {
            let finalValues = values.filter((item) => item.value > 0);
            sectionsValues.current = finalValues;
            setActiveSection(finalValues[0]?.name);
            setResults(finalValues[0]?.results);
            setIsLoading(false);
            isLoadedComponent.current = true;
          }
        }
      }
    } catch (error) {}
  };

  const callContentItems = async ({ pageNumber }) => {
    try {
      let callResults = await individualApiCall({
        type: activeSection,
        pageNumber,
        sentiment,
        snapshot: true,
        freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
      });
      if (callResults) {
        let hits = results.hits;
        let highlights = results.highlights;

        hits = hits.concat(callResults.hits);
        highlights = highlights.concat(callResults.highlights);

        setResults({ ...callResults, hits, highlights, thereIsMoreContent: callResults.hits?.length === pageSize });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialtItems.current({ source });
    return () => {
      source.cancel('Content items panel cancelled by the user');
    };
  }, [contentState]);

  useEffect(() => {
    if (isLoadedComponent.current && sectionsValues?.current?.length > 0) {
      let section = sectionsValues.current.find((item) => item.name === activeSection);
      setIsLoading(true);
      setResults(noResults ? { hits: [] } : section?.results);
      setTimeout(() => setIsLoading(false), 300);
    }
  }, [activeSection]);

  const load_more_results = async () => {
    let { thereIsMoreContent, pageNumber } = results;
    if (thereIsMoreContent) {
      await callContentItems({ pageNumber: pageNumber + 1 });
    }
  };
  const handleScroll = (scrollValues) => {
    const { scrollTop, clientHeight, scrollHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      load_more_results();
    }
  };

  return (
    <ContentItemsPanel
      handleScroll={handleScroll}
      setRightPanelState={setRightPanelState}
      text={text}
      sectionsValues={sectionsValues.current}
      isLoading={isLoading}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      results={results}
      overallSentiment={true}
    />
  );
};

export default OverallSentimentRightPanel;
