import Skeleton from 'react-loading-skeleton';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';

const ReportSkeleton = () => {
  const [heightContainerReport, containerRefReport] = useHeightContainer();
  return (
    <div className={`main-content pr-xl-5 px-md-4 px-3 pt-lg-5 pt-3 mx-auto`}>
      <div className='border rounded-top h-100 bg-main-white dashboard-root-container '>
        <div className={`report-title-elements flex-centered px-lg-5 px-3 border-bottom py-4`}>
          <div className='flex-grow-1'>
            <>
              <div className='mb-2'>
                <Skeleton height={'24px'} width={'80%'} />
              </div>
              <Skeleton height={'24px'} width={'50%'} />
            </>
          </div>
          <div className='pl-4 flex-centered'>
            <Skeleton height={'84px'} width={'90px'} />
          </div>
        </div>
        <div
          className={`py-4 px-3 reports-board-main-container position-relative`}
          ref={containerRefReport}
          style={{ minHeight: `${heightContainerReport}px` }}
        >
          <ReportBodySkeleton />
        </div>
      </div>
    </div>
  );
};

const ReportBodySkeleton = () => {
  return (
    <div className='w-100 reports-editor-container' style={{ minHeight: `100%` }}>
      {Array(4)
        .fill()
        .map((item, index) => (
          <div
            className='border rounded border-dashed text-center flex-centered justify-content-center'
            style={{ gridArea: '2 span / 2 span' }}
            key={index}
          >
            <Skeleton height={'24px'} width={'400px'} />
          </div>
        ))}
    </div>
  );
};

export { ReportBodySkeleton };
export default ReportSkeleton;
