import React, { useRef } from 'react';
import RateSection from './RateSection';
import useGetEmailStats from 'components/Dashboard/Influence/CrmEmails/CrmEmailComponent/useGetEmailStats';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import TopClickedLinks from './TopClickedLinks';
import TopEngagedLinks from './TopEngagedLinks';
import SentPerformanceSkeleton from './SentPerformanceSkeleton';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const SentPerformance = ({ emailId, setActiveTab, setActiveRecipientsOption, hasLink, currentEmailDetails }) => {
  const rateSections = ['open', 'click', 'reply'];
  const { stats, isLoading } = useGetEmailStats();
  const [heightContainer, containerRef] = useHeightContainer();
  const scrollBarRef = useRef(null);

  const deliveryItems = {
    Recipients: currentEmailDetails?.sendRecords?.length,
    'Successful deliveries': stats?.totalDeliveries,
    Bounces: stats?.totalBounces,
    'Spam reports': stats?.totalComplaints,
  };

  const handleOpenRecipients = (type) => {
    const typeToTabMapping = {
      open: 'Opened',
      click: 'Clicked',
      reply: 'Replied',
      'Successful deliveries': 'Delivered',
      Bounces: 'Bounced',
      Unsubscribes: 'Unsubscribed',
      'Spam reports': 'Spam reports',
      Recipients: 'All',
    };
    setActiveTab('Recipients');
    setActiveRecipientsOption(typeToTabMapping[type]);
  };

  return (
    <div className={`${emailId ? '' : 'email-performance-wrapper'}`}>
      {isLoading ? (
        <SentPerformanceSkeleton />
      ) : (
        <>
          <div ref={containerRef} />
          <CustomScrollbar
            className={`main-content-scrollbar email-performance-scrollbar`}
            style={{ height: `${heightContainer}px` }}
            maximalThumbYSize={100}
            ref={scrollBarRef}
          >
            <div className='email-performance-container'>
              <div className='email-performance-horizontal-container'>
                {rateSections.map((section, index) => (
                  <RateSection
                    key={`rateSection-${index}`}
                    type={section}
                    performance={stats}
                    handleOpenRecipients={handleOpenRecipients}
                    deliveryItems={deliveryItems}
                  />
                ))}
              </div>
              <div className='email-performance-horizontal-container'>
                <TopClickedLinks mostClickedLinks={stats?.mostClickedLinks} hasLink={hasLink} />
                <TopEngagedLinks contactEngagement={stats?.contactEngagement} />
              </div>
            </div>
          </CustomScrollbar>
        </>
      )}
    </div>
  );
};

export default SentPerformance;
