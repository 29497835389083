import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { store } from 'components/Store';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';

const useCallCollectContent = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { list } = state;

  const urlParam = getUrlParam('topic-id');
  const { isAuthenticated } = useAuth0();

  const { generalApiCall } = useGeneralApiCall();
  const callCollectContent = async ({ objectId, objectType, contentUrl, topic }) => {
    const listToSend = urlParam ? (urlParam === 'all' ? null : { id: parseInt(urlParam) }) : topic ?? list ?? null;
    let pathname = `/api/log/collect`;
    let method = 'post';
    let requestProperties = {
      keywordListId: !listToSend || !isAuthenticated ? null : listToSend?.id,
      eventName: 'ContentView',
      objectId: objectId,
      contentType: objectType,
      url: `${window.location.origin}${contentUrl}`,
    };
    await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: isAuthenticated,
      notShowErrorMessage: true,
    });
  };

  return { callCollectContent };
};

export default useCallCollectContent;
