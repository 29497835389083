import React from 'react';
import SimpleSearchBar from 'components/Dashboard/Navigation/SimpleSearchBar';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { createFiltersProperties } from 'components/Dashboard/helpers/controlActiveFilters';
import { useLocation, Link } from 'react-router-dom';
import CalendarListControls from 'components/Dashboard/UpcomingEvents/CalendarListControls';
import MoreFiltersButton from 'components/Dashboard/Filters/ui/MoreFiltersButton';

const ResearchBarItems = (props) => {
  const { searchBarText, referenceState, openPortal, enableAdvancedSearch } = props;
  const { activeReference } = referenceState;
  const filtersProperties = createFiltersProperties();

  const location = useLocation();
  const isMobile = useIsMobile();
  const searchbarCondition = searchBarText === '';
  const openCondition = activeReference && enableAdvancedSearch && !searchbarCondition;
  return (
    <div
      className={`bg-white monitor-items-bar research-bar ${openCondition ? 'pointer' : ''}`}
      onClick={() => {
        if (openCondition) {
          openPortal();
        }
      }}
    >
      <div className='mx-auto main-content-wrapper flex-centered justify-content-between'>
        <div className='side-left-element py-1 pl-lg-5 pl-3 d-none d-lg-block'>
          <p className={`mb-0 title-h4 nowrap-item `}>Search</p>
        </div>
        <div className={`main-content pr-xl-5 px-md-4 px-3 flex-centered justify-content-between`}>
          {searchbarCondition ? (
            <div className='sub-nav-searchbar'>
              <SimpleSearchBar homePage enforceShortPlaceholder />
            </div>
          ) : (
            <p
              className={`mb-0 py-1 flex-grow-1 hidden-lines hidden-one-line showing-results-text`}
              dangerouslySetInnerHTML={{ __html: searchBarText }}
            />
          )}
          <div className='flex-centered'>
            {isMobile && (
              <>
                <div className='ml-2'>
                  <MoreFiltersButton section={filtersProperties?.section} />
                </div>
                {enableAdvancedSearch && (
                  <button
                    className='sort-button filter-button mark-button  active-mark-as-read-button ml-2'
                    onClick={() => {
                      openPortal();
                    }}
                  >
                    <span class='indented-button mark-arrow'>Advanced search</span>
                  </button>
                )}
              </>
            )}
            {location.pathname.includes('/calendar') && isMobile && <CalendarListControls />}
            {(location.pathname.includes('/briefing-material') || location.pathname.includes('/policy-asks')) && (
              <Link
                to={`/influence/${location.pathname.includes('/policy-asks') ? 'policy-asks' : 'briefing-material'}/create/1?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`}
                className='rounded-button action-button research-clear-button nowrap-item add-influence-button'
              >
                {`${location.pathname.includes('/policy-asks') ? 'Create a policy ask' : 'Add briefing material'}`}
              </Link>
            )}
          </div>
          {openCondition && (
            <button
              className='d-none d-lg-block general-button circle-button indented-button dropdown-edit-keyword-list ml-3 active'
              data-tooltip-content='Edit search'
              data-tooltip-id='general-tooltip'
              data-tooltip-place='bottom'
            >
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResearchBarItems;
