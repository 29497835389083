import currentDateFilter from 'components/Dashboard/utilities/currentDateFilter';
import currentDate from 'components/Dashboard/utilities/currentDateUTC';

const createFiltersForTypes = (type, objectID) => {
  let filters;
  let sort;
  if (type === 'Event') {
    filters = [{ field: 'eventStartDate', value: `${currentDate}`, operator: 'DATE_GTE' }];
    sort = { field: 'eventStartDateTimestamp', order: 'ASC' };
  } else if (type === 'Consultation') {
    filters = [currentDateFilter];
    sort = { field: 'closeDateTimestamp', order: 'ASC' };
  } else if (type === 'ParliamentaryRecord' || type === 'Legislation') {
    sort = { field: 'lastImporterUpdatedAtUtc', order: 'DESC' };
  } else if (type === 'BriefingMaterial' || type === 'PolicyAsk') {
    filters = [{ field: 'contentType', value: type, operator: 'str_eq' }];
  } else if (type === 'LobbyingMaterial' && objectID) {
    filters = [{ field: 'objectID', value: objectID, operator: 'str_not_eq' }];
    sort = { field: 'dateTime', order: 'DESC' };
  } else {
    sort = { field: 'dateTime', order: 'DESC' };
  }
  return { filters, sort };
};

export default createFiltersForTypes;
