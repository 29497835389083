import ConfigData from 'config/ConfigData';

const ParliamentaryRecordFilters = [
  {
    name: 'Regions',
    searchState: 'always',
    pages: ['ParliamentaryRecord'],
    filterName: 'contentType',
    type: 'parliamentaryRecordMultipleHierarchy',
    options: [
      {
        name: 'Westminster',
        selectedOptions: [],
        houseFilterSelectedOptions: [],
        houseFilter: {
          name: 'House',
          filterName: 'house',
          options: [
            {
              name: 'Commons',
              filterValue: 'commons',
            },
            {
              name: 'Lords',
              filterValue: 'lords',
            },
          ],
        },
        options: [
          {
            name: 'Hansard',
            filterValue: 'ParliamentaryContribution',
          },
          {
            name: 'Written Q&As',
            filterValue: 'QuestionAndAnswer',
            hash: 'westminster-written-q-and-a',
          },
          {
            name: 'EDMs',
            filterValue: 'Edm',
            hash: 'edm',
          },
        ],
      },
      {
        name: 'Holyrood (Scotland)',
        selectedOptions: [],
        options: [
          {
            name: 'Official Report',
            filterValue: 'ScotlandCountrySpecificParliamentaryContribution',
            hash: 'scottish-official-report',
          },
          {
            name: 'Written Q&As',
            filterValue: 'ScotlandQuestionAndAnswer',
            hash: 'scottish-written-q-and-a',
          },
        ],
      },
      {
        name: 'Senedd (Wales)',
        selectedOptions: [],
        options: [
          {
            name: 'Official Report',
            filterValue: 'WalesCountrySpecificParliamentaryContribution',
            hash: 'senedd-official-report',
          },
          {
            name: 'Written Q&As',
            filterValue: 'WalesQuestionAndAnswer',
            hash: 'senedd-written-q-and-a',
          },
          {
            name: 'Committee transcripts',
            filterValue: 'SeneddCommitteeTranscriptContribution',
            hash: 'senedd-transcripts',
          },
        ],
      },
      {
        name: 'Stormont (NI)',
        selectedOptions: [],
        options: [
          {
            name: 'Written Q&As',
            filterValue: 'NorthernIrelandQuestionAndAnswer',
            hash: 'ni-written-q-and-a',
          },
        ],
      },
    ],
    showAllOptions: true,
    configHidden: ConfigData?.filters?.regions?.configHidden,
  },
  {
    name: 'Written Q&As',
    searchState: 'questionsCondition',
    pages: ['ParliamentaryRecord'],
    filterName: 'questionHasAnswer',
    additionalFilter: { field: 'questionHasAnswer', value: 'n/a', operator: 'str_eq' },
    notSendIfOptionsAreSelected: true,
    options: [
      {
        name: 'Answered',
        filterValue: 'True',
      },
      {
        name: 'Unanswered',
        filterValue: 'False',
      },
    ],
    configHidden: ConfigData?.filters?.written_q_a?.configHidden,
  },
  {
    name: 'Section',
    pages: ['ParliamentaryRecord'],
    filterName: 'house',
    optionSelected: '',
    options: [
      {
        name: 'House',
      },
      {
        name: 'Senate',
      },
      {
        name: 'Daily Digest',
      },
      {
        name: 'Extensions of Remarks',
      },
    ],
    configHidden: ConfigData?.filters?.section?.configHidden,
  },
];

export default ParliamentaryRecordFilters;
