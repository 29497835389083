import React, { useContext, useEffect, useState } from 'react';
import { store } from 'components/Store.js';

const CouponBanner = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { activeDiscountCode, subscriptionStatus } = activePlan;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const activePlanKeys = Object.keys(activePlan);
    if (activePlanKeys.length > 0) {
      setLoading(false);
    }
  }, [activePlan]);

  return (
    <>
      {!loading && (
        <>
          {activeDiscountCode !== null &&
            activeDiscountCode.name !== '' &&
            subscriptionStatus !== 'Subscribed' &&
            subscriptionStatus !== 'SubscribedOverLimit' && (
              <p className='coupon-banner py-2 px-4'>
                Your {activeDiscountCode.name} discount will be applied at checkout
              </p>
            )}
        </>
      )}
    </>
  );
};

export default CouponBanner;
