import { useEffect, useContext, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { store } from 'components/Store.js';
import useEmailDomainFunctions from 'components/Dashboard/hooks/useEmailDomainFunctions';

const useEmailDomainsInitialCall = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const { getEmailAddressesCall, getEmailDomains, syncEmailDomain } = useEmailDomainFunctions();

  const call = useRef();
  call.current = async (source) => {
    try {
      if (isAuthenticated && !location?.state?.writingAssistantId) {
        //LOAD EMAIL DOMAINS AND EMAIL ADDRESSES
        if (location.pathname.includes('/influence/emails') || location.pathname === '/influence/directory') {
          await syncEmailDomain({ isAdminPage: false, source });
        }
        let domainsResult = await getEmailDomains({ isAdminPage: false, source });
        let domainList = [];
        if (domainsResult?.length) {
          domainList = domainsResult.filter((e) => e.verificationStatus === 'Success');
        }
        const emailAddressesResult = await getEmailAddressesCall(source);
        let emailsList = [];
        if (emailAddressesResult?.length) {
          emailsList = emailAddressesResult.filter((e) => e.isVerified);
        }
        if (!!domainsResult && !!emailAddressesResult) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'listDomainsVerified',
            value: {
              verifiedDomains: domainList,
              allDomains: domainsResult,
              allEmailAddresses: emailAddressesResult,
              verifiedEmailAddresses: emailsList,
              domainsProccessing: false,
            },
          });
        }
        //
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    call.current(source);
    return () => {
      source.cancel('Email domains canceled by the user.');
    };
  }, []);
};
export default useEmailDomainsInitialCall;
