import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CrmContacts from 'components/Dashboard/Influence/CrmContacts';
import CrmContactsTableSkeleton from 'components/Dashboard/Influence/CrmContacts/CrmContactsTable/CrmContactsSkeleton';
import { useContext, useEffect, useRef, useState } from 'react';
import { useGroupFunctions } from './GroupFunctions';
import axios from 'axios';
import { store } from 'components/Store';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { CreateGroupModal } from './CreateGroupButton';
import { SingleGroupFilter } from './GroupFilters';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useGroupsAlertsFunctions from './useGroupsAlertsFunctions';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useChangeClient from 'components/Dashboard/Navigation/useChangeClient';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags';

const GroupView = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { team, clients } = state ?? {};

  const [isLoading, setIsLoading] = useState(true);
  const { getGroup } = useGroupFunctions();
  const { getGroupsAlerts } = useGroupsAlertsFunctions();

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { groupId } = params;
  let switchTeamIdParam = getUrlParam('switch-team-id');
  const { newAgencyPlan } = useTopicOrClientWord();
  const { changeClientFunction } = useChangeClient();
  const { addMetaTags } = useAddMetaTags();

  const switchClientCondition =
    newAgencyPlan &&
    switchTeamIdParam &&
    parseFloat(switchTeamIdParam) !== team.teamId &&
    clients?.find((client) => client?.teamId === parseFloat(switchTeamIdParam));

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      let results = await getGroup({ id: groupId, source });
      await getGroupsAlerts({ source });
      if (results?.status === 404 && !switchClientCondition) {
        return navigate('/influence/my-groups');
      }
      if (!!results) {
        let title = `${results?.name} - My groups - Influence - PolicyMogul`;
        addMetaTags({ title });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel(`Groups view cancelled by the user.`);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'group',
        value: null,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'contentResults',
        value: {
          allResults: {},
          searchResults: {},
        },
      });
    };
  }, [team, switchClientCondition]);

  const handleswitchTeamId = useRef();
  handleswitchTeamId.current = async () => {
    if (switchClientCondition) {
      await changeClientFunction({ clientId: parseFloat(switchTeamIdParam) });
    }
    if (switchTeamIdParam) {
      const params = new URLSearchParams(location.search);
      params.delete('switch-team-id');
      navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
    }
  };

  useEffect(() => {
    handleswitchTeamId.current();
  }, [switchTeamIdParam, switchClientCondition]);

  return (
    <>
      {isLoading ? (
        <div className={`main-content pl-md-4 pl-3 h-100 pt-4`}>
          <div className='d-flex flex-column h-100'>
            <CrmContactsTableSkeleton />
          </div>
        </div>
      ) : (
        <CrmContacts groupPage={true} filters={[]} />
      )}
    </>
  );
};

const GroupSideBar = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { group } = state;

  const { filters } = group ?? {};
  const StaticGroup = group?.type === 'Static';

  const [isOpen, setIsOpen] = useState(false);
  const [heightContainer, containerRef] = useHeightContainer();

  return (
    <>
      <div className='pl-lg-5 pl-3 h-100'>
        <div className='box-top-rounded h-100 group-sidebar'>
          {!group ? (
            <SkeletonTheme baseColor='#fff'>
              <div className='flex-centered border-bottom px-3 py-2 justify-content-between'>
                <Skeleton height={15} width={`100px`} />
              </div>
              <div className='p-3'>
                <Skeleton height={15} width={`100%`} />
                <Skeleton height={15} width={`50%`} />
              </div>
            </SkeletonTheme>
          ) : (
            <>
              <div
                className='flex-centered border-bottom justify-content-between'
                style={{ padding: '14px 18px 13px 18px' }}
              >
                <h3 className='mb-0 title-h5-bold'>Group filters</h3>
                {!StaticGroup && (
                  <button
                    className='simple-link-button general-button paragraph-p2'
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Edit filters
                  </button>
                )}
              </div>
              <div ref={containerRef} />
              <CustomScrollbar className='simple-scrollbar' style={{ height: `${heightContainer}px` }}>
                {StaticGroup ? (
                  <p className='text-center main-light-text py-5 mb-0 px-4'>
                    This is a static group and does not have any filters
                  </p>
                ) : (
                  <div className='p-3 groups-sidebar'>
                    {filters
                      ?.filter((item) => item.groupFilters?.length > 0)
                      ?.map((item, index) => {
                        return <SingleGroupFilter item={item} index={index} key={`filter-group-${index}`} />;
                      })}
                  </div>
                )}
              </CustomScrollbar>
            </>
          )}
        </div>
      </div>
      <CreateGroupModal maxWidth={750} openState={{ isOpen, setIsOpen }} defaultView={'filters'} />
    </>
  );
};

export { GroupSideBar };
export default GroupView;
