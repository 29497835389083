import React from 'react';
import InfluenceItem from 'components/Dashboard/UserContent/ui/InfluenceListItem';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import NoResultsTeam from './NoResultsTeam';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { useLocation } from 'react-router-dom';

const TeamContentComponent = (props) => {
  const { results } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const location = useLocation();

  const influenceSection = location.pathname.includes('influence');
  return (
    <div className={`${influenceSection ? 'pl-md-4 pl-3' : 'pt-lg-5'}`}>
      <>
        <div ref={containerRef} />
        <CustomScrollbar
          className={'simple-scrollbar'}
          style={{
            height: `${heightContainer}px`,
            marginLeft: influenceSection ? '-8px' : '',
          }}
          maximalThumbYSize={100}
        >
          <div className={`pt-2 pr-lg-4 px-2`}>
            {results.length === 0 && <NoResultsTeam {...props} influenceSection={influenceSection} />}
            {results.length > 0 && (
              <>
                {results.map((item, index) => (
                  <InfluenceItem key={`item${index}`} item={item} index={index} array={results} />
                ))}
              </>
            )}
          </div>
        </CustomScrollbar>
      </>
    </div>
  );
};

export default TeamContentComponent;
