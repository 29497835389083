import { useContext, useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { store } from 'components/Store';
import { useChangeKeywordsListsOrder } from 'components/Dashboard/hooks/useSearchBarUiFunctions';
import { useSetListsIds } from 'components/Dashboard/MainSearchBar/setListIds';
import TopicPicker from 'components/Common/TopicPicker';

const ReportDiscardChangesModal = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { setIsOpen, isOpen, discardFunction, saveOrCreateReport, setPathnameChanged } = props;
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (value) => {
    setIsOpen(value);
    setPathnameChanged(null);
    dispatch({ type: 'MODIFY_SECTION', parameter: 'clientToSwitchOnReports', value: null });
  };

  return (
    <ModalComponent
      maxWidth={500}
      isOpen={isOpen}
      setIsOpen={closeModal}
      overlayClassName='react-modal-overlay report-extra-index-modal'
    >
      <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
        <div className='text-center'>
          <p className='title-h5-m'>Do you want to discard the changes you've made?</p>

          <div className='my-4'>
            <button
              style={{
                backgroundColor: '#9b171f',
              }}
              className='general-button action-button px-4 py-2 mr-3'
              onClick={async () => {
                setIsOpen(false);
                await discardFunction();
              }}
            >
              Discard changes
            </button>
            <button
              disabled={isLoading}
              className='general-button action-button px-4 py-2'
              onClick={async () => {
                setIsLoading(true);
                await saveOrCreateReport({});
                setIsLoading(false);
                await discardFunction();
                closeModal(false);
              }}
            >
              {isLoading && <i className='fas fa-spinner fa-spin'></i>} Save report
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const ReportSelectTopicModal = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, temporaryReportState } = state;
  const { setListsIds } = useSetListsIds();

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists.filter((item) => item.id !== null),
  });
  const keywordsListsToUse = changeKeywordsListsOrder();

  const { isOpen, setIsOpen, setLayout } = props;
  const [selectedTopic, setSelectedTopic] = useState(keywordsListsToUse?.[0] ?? {});
  const { topicOrClientWord } = useTopicOrClientWord();

  const closeModal = (value) => {
    setIsOpen(value);
    dispatch({ type: 'MODIFY_SECTION', parameter: 'updateToCreateReport', value: null });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'updateBeforCreateReportLocation', value: null });
  };

  return (
    <ModalComponent maxWidth={500} isOpen={isOpen} setIsOpen={closeModal}>
      <h3 className='title-h4-bold px-3 px-lg-5 py-4 section-title'>Choose a topic for widget data</h3>

      <div className='px-3 px-lg-5 pb-4'>
        <div className='pt-4 pb-3'>
          <TopicPicker
            keywordsLists={keywordsListsToUse}
            showTopicCases={true}
            searchFunction={({ topic }) => {
              setSelectedTopic(topic);
              setListsIds(topic?.id);
            }}
            defaultTopic={selectedTopic}
            fixedPosition
            placeholder={`Select a ${topicOrClientWord}`}
            showTopicSelected={true}
            dataTooltip={'modal-tooltip'}
          />
        </div>

        <div className={`pt-4 pb-2 d-flex justify-content-end`}>
          <button
            className='action-button rounded-button px-4 py-2'
            onClick={() => {
              closeModal(false);
              if (!!temporaryReportState) {
                let newState = [...temporaryReportState];
                newState.map((item) => {
                  if (item?.data?.state?.topic) {
                    return {
                      ...item,
                      data: {
                        ...item?.data,
                        state: {
                          ...item?.data?.state,
                          topic: selectedTopic,
                        },
                      },
                    };
                  } else {
                    return item;
                  }
                });
                setLayout(newState);
                dispatch({ type: 'MODIFY_SECTION', parameter: 'temporaryReportState', value: null });
              }
              dispatch({ type: 'MODIFY_SECTION', parameter: 'topicForReport', value: selectedTopic });
            }}
            disabled={Object.keys(selectedTopic)?.length === 0}
          >
            Create report
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export { ReportDiscardChangesModal, ReportSelectTopicModal };
