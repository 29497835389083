import { useContext, useEffect, useMemo, useState } from 'react';
import { useUploadLogoFunction } from 'components/Reports/ui/ReportLogo';
import ClientActions from './ClientActions';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useCollapse } from 'react-collapsed';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import preventLink from 'components/Dashboard/TagsList/prevenLink';
import ClientEmailAlertComponent from './ClientEmailAlertComponent';
import { store } from 'components/Store';
import ModalComponent from 'components/Common/Modal';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import ClientPickerForDuplicate from './ClientPickerForDuplicate';
import { AdvancedRulesButton } from 'components/Settings/Topics/TopicsLists';
import HideClientCheckbox from './ClientHideCheckbox';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import useClientsFunctions from 'components/Dashboard/hooks/useClientsFunctions';

const ClientComponent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions } = state;

  const maxListsPerTeam =
    props?.maxListsPerTeam !== undefined ? props?.maxListsPerTeam : activeSubscriptions?.maxListsPerTeam;
  const maxNumberOfTeams =
    props?.maxNumberOfTeams !== undefined ? props?.maxNumberOfTeams : activeSubscriptions?.maxNumberOfTeams;
  const teamParameter = getUrlParam('teamId');

  const { client, clientsListState, array, index, stateExpanded, hidingState, clientListScrollbar } = props;
  const { clientsList, setClientList } = clientsListState ?? {};
  const { currentlyHiding } = hidingState ?? {};
  const { organisationLogo, teamName, teamId, myKeywordLists, teamMemberKeywordLists } = client ?? {};
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const location = useLocation();
  const { logoUrl } = useUploadLogoFunction({ logo: organisationLogo });
  const adminPage = location?.pathname?.startsWith('/admin');

  const lists = !!myKeywordLists && !!teamMemberKeywordLists ? [...myKeywordLists, ...teamMemberKeywordLists] : [];
  const updateEmailAlertForTopic = ({ topic, alert }) => {
    let newClientLists = [...clientsList];
    let clientPosition = newClientLists?.findIndex((item) => item.teamId === client?.teamId);
    let newMyKeywordLists = [...newClientLists[clientPosition]?.myKeywordLists];
    let topicPosition = newMyKeywordLists?.findIndex((item) => item.id === topic?.id);
    if (topicPosition >= 0) {
      newMyKeywordLists[topicPosition] = {
        ...newMyKeywordLists[topicPosition],
        hasAlertSettings: true,
        myAlertSettings: [...newMyKeywordLists[topicPosition]?.myAlertSettings, alert],
      };
    }
    newClientLists[clientPosition] = {
      ...newClientLists[clientPosition],
      myKeywordLists: newMyKeywordLists,
    };
    setClientList(newClientLists);
  };

  const addNewTopicCondition = maxListsPerTeam === null || lists.length < maxListsPerTeam;

  useEffect(() => {
    setExpanded(!stateExpanded);
  }, [stateExpanded]);

  useEffect(() => {
    if (!!teamParameter && client?.teamId === parseInt(teamParameter)) {
      setExpanded(true);
    }
  }, [teamParameter]);

  const enabledClients = clientsList?.filter(
    (item) => (item?.myState === 'Enabled' && !item.currentState) || item?.currentState === 'Enabled'
  );

  const handPickedKeywords = useMemo(() => {
    let handPickedKeywords = {};
    client?.keywordLists?.forEach((keywordList) => {
      handPickedKeywords = { ...handPickedKeywords, [keywordList?.id]: keywordList.ptHandPickedContentKeywords };
    });
    return handPickedKeywords;
  }, [client]);

  return (
    <div
      style={{ zIndex: `${array?.length - index}` }}
      className='px-lg-5 px-3 border-bottom py-4 action-state-hover position-relative'
      {...getToggleProps({
        onClick: () => {
          if (!isOpenPopup && !currentlyHiding) {
            setExpanded((prevExpanded) => !prevExpanded);
          }
        },
      })}
    >
      <div className='flex-centered justify-content-between '>
        <div className='flex-centered'>
          <div className='flex-centered justify-content-center' style={{ width: '70px', height: '70px' }}>
            <img className='w-100' src={logoUrl} alt={'Client logotype'} />
          </div>
          <div className='position-relative pl-4'>
            <p
              className={`title-h4-bold main-subtle-text dropdown-item-element dropdown-item-element-10 mb-0 ${isExpanded ? 'open' : ''}`}
            >
              {teamName}
            </p>
            <p className={`mb-0 ${lists?.length === 0 ? 'text-main-danger' : ''}`}>
              {lists?.length} topic{lists?.length === 1 ? '' : 's'}
              {Object.values(handPickedKeywords).some((keywords) => keywords.length > 0) && adminPage && (
                <p className='paragraph-p3 rounded-button badge-spacing highlighted-badge d-inline-block ml-3'>
                  Hand-picked
                </p>
              )}
            </p>
          </div>
        </div>
        {isExpanded && (
          <div className='flex-centered client-component-actions'>
            {(enabledClients?.length > 1 ||
              (client?.myState === 'Hidden' && !client?.currentState) ||
              client?.currentState === 'Hidden') &&
              !adminPage && (
                <HideClientCheckbox hidingState={hidingState} item={client} clientsListState={clientsListState} />
              )}
            <ClientAddTopicButton adminPage={adminPage} teamId={teamId} addNewTopicCondition={addNewTopicCondition} />
            <ClientActions
              adminPage={adminPage}
              setIsOpenPopup={setIsOpenPopup}
              clientsListState={clientsListState}
              activeClientState={{ activeClient: client }}
            />
          </div>
        )}
      </div>

      <div {...getCollapseProps()}>
        <div className='client-component-list-container'>
          {lists.length > 0 ? (
            <>
              {lists.map((item) => {
                const { id } = item;
                return (
                  <ClientListItem
                    key={`topic-${id}`}
                    openPopupState={{ isOpenPopup, setIsOpenPopup }}
                    teamId={teamId}
                    lists={lists}
                    updateEmailAlertForTopic={updateEmailAlertForTopic}
                    clientListState={clientsListState}
                    client={client}
                    item={item}
                    adminPage={adminPage}
                    maxListsPerTeam={maxListsPerTeam}
                    maxNumberOfTeams={maxNumberOfTeams}
                    handPickedKeywords={handPickedKeywords[id]}
                    clientListScrollbar={clientListScrollbar}
                  />
                );
              })}
            </>
          ) : (
            <p className='main-light-text mb-0 individual-items-vertical-padding'>
              You have not created any topics for this client yet
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const ClientAddTopicButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions } = state;
  const { subscriptionStatus } = activeSubscriptions;
  const trialStatus = subscriptionStatus === 'InTrial' || subscriptionStatus === 'TrialEnded';

  const { addNewTopicCondition, teamId, adminPage } = props;

  const location = useLocation();
  const params = useParams();
  const { ownerAdminRole } = useClientsFunctions();
  const allowUserCondition = adminPage || ownerAdminRole;

  return (
    <Link
      to={
        addNewTopicCondition
          ? `${adminPage ? `/admin/organisation/${params?.id}/topics` : '/settings/topics'}/new-topic?teamId=${teamId}`
          : `/pricing?returnUrl=${encodeURIComponent(location.pathname)}`
      }
      className={`paragraph-p2 border-general border-main-blue-50 reset-link  px-4 py-1 rounded-button general-button flex-centered bg-main-white ${addNewTopicCondition ? 'link-action-hover' : 'light-50-text contact-button-launch-messenger'}`}
      data-tooltip-content={
        addNewTopicCondition
          ? ''
          : `${allowUserCondition ? `${trialStatus ? 'Your trial has a limit of three topics per client' : `You have reached the limit of topics you can add for this client. Contact us to add more`}` : `Please contact your team admin`}`
      }
      data-tooltip-id={'settings-tooltip'}
      data-tooltip-place={'top'}
      onClick={(e) => {
        if (!addNewTopicCondition) {
          preventLink(e);
          if (allowUserCondition) {
            window.Intercom('show');
          }
        }
      }}
    >
      <span className='icon-topics-thin title-h3 mr-3' />
      <span>Add topic</span>
    </Link>
  );
};

const ClientListItem = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const {
    item,
    openPopupState,
    teamId,
    updateEmailAlertForTopic,
    lists,
    clientListState,
    client,
    adminPage,
    maxListsPerTeam,
    maxNumberOfTeams,
    handPickedKeywords,
    clientListScrollbar,
  } = props;

  const { isOpenPopup, setIsOpenPopup } = openPopupState;
  const { name, id, keywords, description } = item;

  const [isOpen, setIsOpen] = useState(false);

  const params = useParams();
  const adminRoute = `/admin/organisation/${params?.id}/topics/${id}?teamId=${client?.teamId}`;

  return (
    <>
      <div
        className='email-alert-information-box my-4 client-topic-box'
        onClick={(e) => {
          if (!isOpenPopup) {
            preventLink(e);
          }
        }}
      >
        <div className='keywords-adjustment individual-items-vertical-padding'>
          <div className='mb-2 d-flex justify-content-between pb-2 align-items-center'>
            <div className='flex-grow-1'>
              <div className='d-flex align-items-center justify-content-between'>
                <p className='mb-0 no-select-item individual-topic-subtitle font-weight-bold'>{name}</p>
                {handPickedKeywords?.length > 0 && adminPage && (
                  <p className='paragraph-p3 rounded-button badge-spacing highlighted-badge mx-3 d-inline-block'>
                    Hand-picked
                  </p>
                )}
              </div>
              {description && <p className='mb-0 main-light-text '>{description}</p>}
            </div>
            <div className='flex-centered'>
              {!adminPage && (
                <ClientEmailAlertComponent
                  openPopupState={openPopupState}
                  topic={item}
                  teamId={teamId}
                  topics={lists}
                  updateEmailAlertForTopic={updateEmailAlertForTopic}
                />
              )}
              <button
                className='general-button border light-70-text paragraph-p3 edit-email-alert-button'
                onClick={async (e) => {
                  preventLink(e);
                  setIsOpen(true);
                  setIsOpenPopup(true);
                }}
              >
                <span
                  className='icon-associated-content ml-0 mr-2 d-inline-block paragraph-p5'
                  style={{ transform: `rotate(-90deg)` }}
                ></span>
                <span>Duplicate topic</span>
              </button>
              <Link
                to={adminPage ? adminRoute : `/settings/topics/${id}?teamId=${client?.teamId}`}
                className='d-block text-right ml-3'
                onClick={() => {
                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'scrollTopicPosition',
                    value: clientListScrollbar?.current?.scrollTop,
                  });
                }}
              >
                <button className='general-button border light-70-text paragraph-p3 edit-email-alert-button client-edit-topic'>
                  <span className='icon-edit-pencil ml-0 mr-2'></span>
                  Edit <span className='d-none d-md-inline-block'>topic</span>
                </button>
              </Link>
            </div>
          </div>

          <div className='border bg-white rounded client-list-keywords'>
            <p className='px-3 py-2 mb-3 mb-lg-0 individual-topic-actual-text'>{keywords.join(', ')}</p>
            {handPickedKeywords?.length > 0 && adminPage && (
              <>
                <p className='px-3 mb-0 no-select-item individual-topic-subtitle paragraph-p2-bold'>
                  Parliament Today hand-picked keywords and phrases
                </p>
                <p className='px-3 pt-1 pb-3 mb-3 mb-lg-0 individual-topic-actual-text'>
                  {handPickedKeywords.join(', ')}
                </p>
              </>
            )}
            <AdvancedRulesButton item={item} />
          </div>
        </div>
      </div>
      <DuplicateTopicPopup
        openState={{ isOpen, setIsOpen }}
        client={client}
        clientListState={clientListState}
        topic={item}
        setIsOpenPopup={setIsOpenPopup}
        maxListsPerTeam={maxListsPerTeam}
        maxNumberOfTeams={maxNumberOfTeams}
        adminPage={adminPage}
      />
    </>
  );
};

const DuplicateTopicPopup = (props) => {
  const { openState, clientListState, topic, setIsOpenPopup, adminPage, maxListsPerTeam, maxNumberOfTeams } =
    props ?? {};
  const { clientsList, setClientList } = clientListState ?? {};
  const { isOpen, setIsOpen } = openState ?? {};

  const [localClients, setLocalClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { generalApiCall } = useGeneralApiCall();

  const duplicateTopic = async ({ topic, teamId }) => {
    try {
      let pathname = adminPage ? `/api/admin/create-keyword-list` : `/api/keyword-list/create`;
      let method = 'post';
      let requestProperties = {
        ...topic,
        name: `${topic?.name} (copy)`,
        teamId: `${teamId}`,
      };
      return await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
    } catch (error) {}
  };

  const duplicateTopicFunction = async ({ topic }) => {
    try {
      setIsLoading(true);
      let results = {};
      for (let i = 0; i < localClients.length; i++) {
        let teamId = localClients[i];
        let result = await duplicateTopic({ topic, teamId });
        if (!!result) {
          results[teamId] = result;
        }
      }
      let newClientLists = [...clientsList];
      for (let i = 0; i < Object.keys(results)?.length; i++) {
        let localTeamId = Object.keys(results)[i];
        let result = results[localTeamId];
        let clientPosition = newClientLists?.findIndex((client) => client?.teamId === parseInt(localTeamId));
        let newKeywordList = [
          { ...result, myAlertSettings: [], hasAlertSettings: false },
          ...newClientLists[clientPosition]?.myKeywordLists,
        ];
        newClientLists[clientPosition] = {
          ...newClientLists[clientPosition],
          myKeywordLists: newKeywordList,
          keywordLists: newKeywordList,
          teamMemberKeywordLists: newClientLists[clientPosition]?.teamMemberKeywordLists,
        };
      }
      setClientList(newClientLists);
      createNotification('success', 'Topic created successfully');
      closeModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const closeModal = (value) => {
    setIsOpen(value);
    setLocalClients([]);
    setIsOpenPopup(false);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={closeModal}
      maxWidth={600}
      overlayClassName={`react-modal-overlay report-create-overlay`}
    >
      <h3 className='title-h4-bold px-3 px-lg-5 py-4 section-title'>Choose client(s) to duplicate this topic for</h3>

      <div className='px-3 px-lg-5 pb-4'>
        <div className='pt-4 pb-3 client-duplicate-container'>
          <ClientPickerForDuplicate
            closeModal={closeModal}
            clientsLists={clientsList}
            localClientState={{ localClients, setLocalClients }}
            maxListsPerTeam={maxListsPerTeam}
            maxNumberOfTeams={maxNumberOfTeams}
          />
        </div>

        <div className={`pt-4 pb-2 d-flex justify-content-end`}>
          <button
            className='action-button rounded-button px-4 py-2'
            disabled={isLoading || localClients?.length === 0}
            onClick={() => {
              duplicateTopicFunction({ topic });
            }}
          >
            {isLoading && <i className='fas fa-spinner fa-spin mr-2'></i>} Duplicate topic
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ClientComponent;
