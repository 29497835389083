import React, { useContext, useEffect, useRef, useState } from 'react';
import TeamUserList from './TeamUserList';
import IndividualUserPage from './IndividualUserPage';
import TeamTopicPage from './TeamTopicsPage';
import UserSideNav from './ui/UserSideNav';
import TeamPlanPage from './TeamPlanPage';
import TeamEmailDomainPage from './TeamEmailDomainPage';
import OrganizationPage from './OrganizationPage';
import AdminNotesPage from './AdminNotesPage';
import { Route, Routes, useParams } from 'react-router-dom';
import { useGetTeamTopics } from './useGetTeamTopics.js';
import Loading from 'components/Login/Loading';
import { AdminStore } from './AdminState';
import TeamClientPage from './TeamClientPage.js';
import axios from 'axios';

const TeamUserPage = () => {
  const adminState = useContext(AdminStore);
  const { dispatch } = adminState;

  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const getOrganisationInfo = useRef();
  const { getTeamTopics } = useGetTeamTopics();

  getOrganisationInfo.current = async (source) => {
    try {
      let organisationinfo = await getTeamTopics({ organisationId: params?.id, source });
      if (!!organisationinfo) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'organisation', value: organisationinfo });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getOrganisationInfo.current(source);
    return () => {
      source.cancel('Organisation api call canceled by the user');
    };
  }, []);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='row mx-auto main-content-wrapper main-content-settings'>
          <UserSideNav teamId={params.id} />
          <div className='px-0 main-content pr-xl-5 px-md-4 px-3'>
            <Routes>
              <Route path={`/users`} element={<TeamUserList />} />
              <Route path={`/users/:userId`} element={<IndividualUserPage />} />
              <Route path={`/topics/*`} element={<TeamTopicPage />}>
                <Route path={':topicId'} element={<TeamTopicPage />} />
              </Route>
              <Route path={`/clients`} element={<TeamClientPage />} />
              <Route path={`/plan`} element={<TeamPlanPage />} />
              <Route path={`/crm`} element={<TeamEmailDomainPage />} />
              <Route path={`/organisation`} element={<OrganizationPage />} />
              <Route path={`/notes`} element={<AdminNotesPage />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamUserPage;
