import { useCallback, useContext } from 'react';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { store } from 'components/Store';

const useGroupsAlertsFunctions = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { groupsAlerts } = state;

  const { generalApiCall } = useGeneralApiCall();

  const changeGroupsAlerts = useCallback((val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'groupsAlerts',
      value: val,
    });
  }, []);

  const getAlertIdsArray = (string = '') => {
    return string?.split(',').map((item) => item.trim()) ?? [];
  };

  const changeGroupAlert = async ({ type, groupId }) => {
    try {
      if (type === 'off') {
        let newAlerts = [...groupsAlerts];
        const alertState = getAlertState(groupId);
        const currentGroup = groupsAlerts.find((group) => group.type === alertState);
        if (currentGroup) {
          let newGroupIds = [...getAlertIdsArray(currentGroup.settings)];
          newGroupIds = [...newGroupIds.filter((id) => id !== groupId)];
          if (newGroupIds.length === 0) {
            //OP: Delete alert, since no ids related to this alert
            await removeGroupAlert({ id: currentGroup.id });
            newAlerts = [...newAlerts.filter((alert) => alert.type !== currentGroup.type)];
          } else {
            await updateGroupAlert({ group: currentGroup, ids: newGroupIds });
            let newAlertObj = { ...currentGroup };
            newAlertObj = { ...newAlertObj, settings: newGroupIds.join(',') };
            newAlerts = [...newAlerts.map((alert) => (alert.type === currentGroup.type ? newAlertObj : alert))];
          }
          changeGroupsAlerts(newAlerts);
        }
      } else {
        const changedAlertsArray = await changeAlertsArray(groupsAlerts, type, groupId);
        let newAlerts = [...changedAlertsArray];
        const currentGroup = groupsAlerts.find((group) => group.type === type);
        if (currentGroup) {
          let newGroupIds = [...getAlertIdsArray(currentGroup.settings)];
          if (newGroupIds?.includes(groupId)) {
            newGroupIds = [...newGroupIds.filter((id) => id !== groupId)];
          } else {
            newGroupIds = [...newGroupIds, groupId];
          }
          if (newGroupIds.length === 0) {
            //OP: Delete alert, since no ids related to this alert
            await removeGroupAlert({ id: currentGroup.id });
            newAlerts = [...newAlerts.filter((alert) => alert.type !== currentGroup.type)];
          } else {
            await updateGroupAlert({ group: currentGroup, ids: newGroupIds });
            let newAlertObj = { ...currentGroup };
            newAlertObj = { ...newAlertObj, settings: newGroupIds.join(',') };
            newAlerts = [...newAlerts.map((alert) => (alert.type === currentGroup.type ? newAlertObj : alert))];
          }
        } else {
          //OP: Create alert
          const newAlertRes = await updateGroupAlert({ group: { type }, ids: [groupId], create: true });
          if (newAlertRes) {
            newAlerts = [...newAlerts, newAlertRes];
          }
        }
        changeGroupsAlerts(newAlerts);
      }
    } catch (err) {}
  };

  const changeAlertsArray = async (alerts, targetType, groupId) => {
    let newAlerts = [...alerts];
    try {
      const type = targetType === 'DailyCrmGroupChanges' ? 'WeeklyCrmGroupChanges' : 'DailyCrmGroupChanges';
      const currentGroup = groupsAlerts.find((group) => group.type === type);
      if (currentGroup) {
        let newGroupIds = [...getAlertIdsArray(currentGroup.settings)];
        if (newGroupIds.includes(groupId)) {
          newGroupIds = [...newGroupIds.filter((id) => id !== groupId)];
          if (newGroupIds.length === 0) {
            //OP: Delete alert, since no ids related to this alert
            await removeGroupAlert({ id: currentGroup.id });
            newAlerts = [...newAlerts.filter((alert) => alert.type !== currentGroup.type)];
          } else {
            await updateGroupAlert({ group: currentGroup, ids: newGroupIds });
            let newAlertObj = { ...currentGroup };
            newAlertObj = { ...newAlertObj, settings: newGroupIds.join(',') };
            newAlerts = [...newAlerts.map((alert) => (alert.type === currentGroup.type ? newAlertObj : alert))];
          }
        }
      }
    } catch (err) {}
    return newAlerts;
  };

  const getAlertState = (id) => {
    const dailyCrmGroupChanges = groupsAlerts.find((alert) => alert.type === 'DailyCrmGroupChanges');
    const weeklyCrmGroupChanges = groupsAlerts.find((alert) => alert.type === 'WeeklyCrmGroupChanges');
    if (dailyCrmGroupChanges && dailyCrmGroupChanges.settings?.includes(id)) {
      return 'DailyCrmGroupChanges';
    } else if (weeklyCrmGroupChanges && weeklyCrmGroupChanges.settings?.includes(id)) {
      return 'WeeklyCrmGroupChanges';
    } else {
      return null;
    }
  };

  const updateGroupAlert = async ({ group, ids, create }) => {
    const { id, type } = group;
    const settings = ids.length > 1 ? ids.join(',') : `${ids}`;
    let method = 'post';
    let pathname = `/api/newsletter-subscriptions/${create ? 'create' : 'update'}`;
    let requestProperties = {
      type,
      settings,
    };
    if (!create) {
      requestProperties = { ...requestProperties, id };
    }
    const res = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    if (!!res) {
      return res;
    }
  };

  const removeGroupAlert = async ({ id }) => {
    let method = 'delete';
    let pathname = `/api/newsletter-subscriptions/${id}`;
    const res = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
    });
    if (!!res) {
      return res;
    }
  };

  const getGroupsAlerts = async ({ source }) => {
    try {
      let method = 'get';
      let pathname = `/api/newsletter-subscriptions`;

      const result = await generalApiCall({
        method,
        pathname,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });
      if (!!result) {
        changeGroupsAlerts(result);
      }
      return result;
    } catch (error) {}
  };

  return {
    getGroupsAlerts,
    getAlertState,
    changeGroupAlert,
  };
};

export default useGroupsAlertsFunctions;
