import { useNavigate, useParams } from 'react-router-dom';
import { initialCrmEmailState, store } from 'components/Store';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import { useContext } from 'react';
import LocalStorage from 'utils/LocalStorage';

const useSendEmailToContacts = () => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { listDomainsVerified } = state;
  const { generalApiCall } = useGeneralApiCall();
  const navigate = useNavigate();
  const params = useParams();

  const { groupId } = params;

  const sendEmailToContacts = async ({
    contactsToSendIds,
    query,
    requestSource,
    filters,
    setIsLoading,
    focusToField,
  }) => {
    try {
      const unverifiedEmail =
        !listDomainsVerified.verifiedDomains.length && !listDomainsVerified.verifiedEmailAddresses.length;

      if (unverifiedEmail) {
        navigate('/influence/emails', { replace: true });
        return;
      }
      setIsLoading(true);
      let method = 'post';
      let pathname = '/api/crm-email/save';

      let requestProperties = {
        Body: initialCrmEmailState().body,
        Title: '',
        EmailDomainId: 1,
        CrmEmailId: null,
        SenderEmailAddressId: JSON.parse(LocalStorage.getItem('lastSenderEmailAddress'))?.emailAddressId,
      };

      const result = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
        requestSource,
      });

      if (result?.crmEmailId) {
        const method = 'post';
        const pathname =
          contactsToSendIds === 'all' ? '/api/crm-email/add-contacts-by-query' : '/api/crm-email/add-contacts';
        const searchQuery = query.get('search');
        let addContactsToList;

        if (groupId && contactsToSendIds === 'all') {
          const groupResult = await generalApiCall({
            method,
            pathname: `/api/crm-email/update-attached-group`,
            requestProperties: {
              crmEmailId: result?.crmEmailId,
              crmContactGroupId: groupId,
            },
            requestSource,
            needsAuthentication: true,
          });
          if (!!groupResult) {
            addContactsToList = await generalApiCall({
              method,
              pathname: `/api/crm-email/refresh-contacts-from-group`,
              requestProperties: {
                crmEmailId: result?.crmEmailId,
              },
              needsAuthentication: true,
            });
          }
        } else {
          const requestProperties =
            contactsToSendIds === 'all'
              ? {
                  crmEmailId: result.crmEmailId,
                  filters: filters,
                  query: searchQuery || '',
                }
              : {
                  crmEmailId: result.crmEmailId,
                  crmContactIds: contactsToSendIds,
                };

          addContactsToList = await generalApiCall({
            method,
            pathname,
            requestSource,
            requestProperties,
            needsAuthentication: true,
          });
        }

        if (!!addContactsToList) {
          navigate(
            {
              pathname: `/influence/emails/${result?.crmEmailId}`,
              search: focusToField ? '?focusToField=true' : '',
            },
            {
              state: focusToField ? { autofocusFieldFromUrl: 'to' } : {},
              replace: true,
            }
          );
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };
  return { sendEmailToContacts };
};

export default useSendEmailToContacts;
