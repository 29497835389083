import { formatCalendarDate } from 'components/Dashboard/helpers/formatGeneralDate';
import normalize from 'components/Dashboard/utilities/normalizeString';

const setFilterHashes = (props) => {
  const {
    sortFilter,
    unreadFilter,
    searchFilter,
    filtersToCompile,
    sortModeFilter,
    toggleFilter,
    dateFilter,
    parliamentaryFilters,
    tagsIsNotFilter,
    nationsFilter,
    topicPickerFilter,
  } = props;
  let hashes = [''];

  if (unreadFilter && unreadFilter.value) {
    hashes.push('unread-only');
  }

  if (sortFilter && !sortFilter.hidden) {
    let optionSelected = sortFilter.options.find((item) => item.name === sortFilter.optionSelected);
    hashes[0] = optionSelected && optionSelected.hash ? optionSelected.hash : normalize(sortFilter.optionSelected);
  } else if (sortModeFilter && sortModeFilter.optionSelected && typeof sortModeFilter.optionSelected === 'string') {
    hashes[0] = normalize(sortModeFilter.optionSelected);
  }
  //DATE HASHES
  if (dateFilter && dateFilter.optionSelected !== '' && !dateFilter.hidden) {
    let dateOptionSelected = dateFilter.options.find((date) => date.name === dateFilter.optionSelected);
    let { prefix } = dateFilter;
    let { showCalendar, name, hash } = dateOptionSelected;
    if (showCalendar) {
      let dates = dateOptionSelected.dates.split(',');
      hashes.push(
        `${prefix ? `${prefix}:` : ''}${normalize(hash ? hash : name)}-${formatCalendarDate(
          dates.length > 1 ? dates[0].slice(0, -1) : dates[0]
        )}${dates.length > 1 ? `-and-${formatCalendarDate(dates[1].substring(1, dates[1].length))}` : ''}`
      );
    } else {
      hashes.push(normalize(name));
    }
  }

  if (toggleFilter && toggleFilter.value && !toggleFilter.hidden) {
    hashes.push(normalize(toggleFilter.name));
  }

  if (nationsFilter) {
    let optionSelected = nationsFilter.options.find((item) => item.name === nationsFilter.optionSelected);
    if (optionSelected) {
      hashes.push(normalize(optionSelected.name));
    }
  }

  //Parliamentary filters

  if (parliamentaryFilters) {
    parliamentaryFilters.options.forEach((option) => {
      const { selectedOptions, selected, options, houseFilter, houseFilterSelectedOptions } = option;
      if (selected) {
        //PLAIN FILTERS
        selectedOptions.forEach((suboption) => {
          const suboptionSelected = options.find((item) => item.name === suboption);
          const { hash, name } = suboptionSelected;
          hashes.push(hash ?? normalize(name));
        });
        //HOUSES
        if (houseFilter) {
          houseFilterSelectedOptions.forEach((houseFilterSelected) => {
            const houseOptionSelected = houseFilter.options.find((item) => item.name === houseFilterSelected);
            const { hash, name } = houseOptionSelected;
            hashes.push(hash ?? normalize(name));
          });
        }
      }
    });
  }

  if (tagsIsNotFilter) {
    filtersToCompile.push(tagsIsNotFilter);
  }

  //Common filters
  filtersToCompile.forEach((filter) => {
    let { options, hidden, updateHash } = filter;
    options.forEach((option) => {
      let { suboptions, selected, hash, name, optionSelected } = option;
      if (selected && (!hidden || updateHash)) {
        hashes.push(
          `${filter?.prefix ? `${filter.prefix}:` : ''}${
            filter.type === 'membership' || filter.filterName === 'tags'
              ? encodeURIComponent(name)
              : hash ?? normalize(name)
          }`
        );
        //AE: It seems this part is only being applied for legislation goverment bills hash
        if (optionSelected && !optionSelected.startsWith('All')) {
          let subHash = suboptions.find((suboption) => suboption.name === optionSelected);
          hashes.push(subHash.hash ?? normalize(subHash.name));
        }
      }
    });
  });

  if (searchFilter && searchFilter.value !== '') {
    hashes.push(`search-within:${encodeURIComponent(searchFilter.value)}`);
  }

  if (topicPickerFilter) {
    const { options, prefix } = topicPickerFilter;
    options.forEach((option) => {
      let { selected, id } = option;
      if (selected) {
        hashes.push(`${prefix}:${id}`);
      }
    });
  }

  return hashes.join('~');
};

export { setFilterHashes };
