import TransformFromPascalCase from 'components/Settings/Utilities/TransformFromPascalCase';
import transformToPascalCase from 'components/Settings/Utilities/TransformToPascalCase';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useAddMetaTags from '../utilities/addMetaTags';

const useUpdateTitleAnalytics = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const sectionName = isAuthenticated
    ? location.pathname.replace('/analytics/', '')
    : location.pathname.replace('/data-hub/', '');
  const { addMetaTags } = useAddMetaTags();

  const sectionTitle = () => {
    if (sectionName === 'top-mps-and-peers') {
      return 'Top MPs and peers';
    } else {
      return TransformFromPascalCase(transformToPascalCase(sectionName));
    }
  };

  const updateTitleAnalytics = () => {
    let title = `Analytics - ${sectionTitle()} - PolicyMogul`;
    addMetaTags({ title, hash: location.hash });
  };
  return { updateTitleAnalytics, sectionTitle };
};

export default useUpdateTitleAnalytics;
