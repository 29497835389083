import getAppSite from 'utils/getAppSite';

const createPartyMetaData = () => {
  if (getAppSite() === 'usa') {
    return {
      showParties: ['Republican', 'Democratic', 'Independent'],
    };
  } else {
    return {
      showParties: ['Conservative', 'Labour', 'Lib Dems', 'SNP', 'Plaid Cymru'],
      changeNames: {
        'Liberal Democrat': 'Lib Dems',
        'Scottish National Party': 'SNP',
        'Democratic Unionist Party': 'DUP',
      },
    };
  }
};
const PartyMetaData = createPartyMetaData();

const FilterPartyNames = {
  'Lib Dems': 'Liberal Democrat',
  SNP: 'Scottish National Party',
  DUP: 'Democratic Unionist Party',
};

const RegionMetaData = {
  orderingRegions: [
    'London',
    'South East',
    'South West',
    'North East',
    'North West',
    'East Midlands',
    'West Midlands',
    'Yorkshire and the Humber',
    'Scotland',
    'Wales',
    'Northern Ireland',
    'East',
  ],
};

export { RegionMetaData, FilterPartyNames };
export default PartyMetaData;
