import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { store } from 'components/Store.js';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import EmptyMessage from 'components/Dashboard/components/EmptyMessage.js';
import { OrderCategories } from './EventsHierarchy.js';
import { useNavigate, useParams } from 'react-router-dom';
import EventsGrouped from './EventsGroups.js';
import CalendarItem from './CalendarItem.js';
import SkeletonCalendarList from './SkeletonCalendarLists.js';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import SortArray from 'components/Dashboard/utilities/SortArray.js';
import CalendarPicker, { GoToTodayButton } from './CalendarPicker.js';
import CalendarListControls from './CalendarListControls.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useResetReference from 'components/Dashboard/SubNav/useResetReference.js';
import CustomScrollbar from 'components/Common/CustomScrollbar.js';
import Loading from 'components/Login/Loading.js';
import { useResetClientNavigation } from 'components/Dashboard/Navigation/ClientNavigation.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags.js';
const dayjs = require('dayjs');

const CalendarList = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { net_api_url, contentResults, activeResults, keywordsLists, clients } = state;
  const { UpcomingEvents } = contentResults[activeResults];
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const navigate = useNavigate();
  const { startdate } = params;
  const date = startdate === undefined ? dayjs().format('YYYY-MM-DD') : startdate;

  const [currentButtonToday, setCurrentButtonToday] = useState(true);
  const [month, setMonth] = useState(startdate === undefined ? new Date() : dayjs(startdate).toDate());
  const { generalApiCall } = useGeneralApiCall();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const { resetReference } = useResetReference();

  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const { resetClientState } = useResetClientNavigation();
  const { addMetaTags } = useAddMetaTags();

  const keyPressFunction = (e) => {
    if (e.code === 'ArrowRight') {
      navigate(`/calendar/${dayjs(date).add(1, 'day').format('YYYY-MM-DD')}`);
    } else if (e.code === 'ArrowLeft') {
      navigate(`/calendar/${dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')}`);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    resetClientState();
    if (keywordsLists.length > 0 || !isAuthenticated || newAgencyPlan) {
      searchEvents(date, source);
      document.addEventListener('keydown', keyPressFunction);
      return () => {
        document.removeEventListener('keydown', keyPressFunction);
        source.cancel('Calendar canceled by the user.');
      };
    } else {
      setIsLoading(false);
    }
  }, [date]);

  const searchEvents = async (day, source) => {
    try {
      setIsLoading(true);
      resetReference();
      let url = `${net_api_url}/api/search/query`;
      let token = isAuthenticated ? await getAccessToken() : '';

      let requestOptions = {
        cancelToken: source.token,
      };

      if (isAuthenticated) {
        requestOptions.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      let filters = [
        { field: 'eventStartDate', value: `${dayjs(day).format('YYYY-MM-DD')}`, operator: 'DATE_GTE' },
        { field: 'eventStartDate', value: `${dayjs(day).add(1, 'day').format('YYYY-MM-DD')}`, operator: 'DATE_LT' },
      ];
      let eventsRequest = await axios.post(
        url,
        {
          type: 'Event',
          query: '',
          pageSize: 10000,
          filters,
          sort: { field: 'eventStartDateTimestamp', order: 'ASC' },
          pageNumber: 1,
        },
        requestOptions
      );

      let highlightEvents = UpcomingEvents?.highlightEvents;
      let recallHighlightEvents = UpcomingEvents?.monthLoaded !== dayjs(day).get('month') || !highlightEvents;
      let upcomingEventsResults = {
        ...eventsRequest.data,
      };

      if (eventsRequest?.data) {
        if (startdate === undefined) {
          navigate(`/calendar/${dayjs().format('YYYY-MM-DD')}`, { replace: true });
        }
        if (isAuthenticated && recallHighlightEvents) {
          highlightEvents = newAgencyPlan
            ? await highlightEventsForClients({ day, clients, source })
            : await compareToHighlightEvents(day, source);
        }
        if (!!highlightEvents || !isAuthenticated) {
          upcomingEventsResults = {
            ...upcomingEventsResults,
            highlightEvents,
            monthLoaded: dayjs(day).get('month'),
          };

          let resultsForState = {
            ...contentResults,
            [activeResults]: {
              UpcomingEvents: upcomingEventsResults,
            },
          };

          let title = `${dayjs(date).format('dddd[,] DD MMM YYYY')} - Calendar - PolicyMogul`;
          let hash = '';
          let contentUrl = `/calendar/${dayjs(date).format('YYYY-MM-DD')}`;
          addMetaTags({ title, hash, contentUrl });
          dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  const compareToHighlightEvents = async (day, source, customLists) => {
    let listToUse = customLists ?? keywordsLists;
    let lists = listToUse?.filter((item) => item.id !== null);
    let results = {};
    if (lists?.length > 0) {
      for (let i = 0; i < lists.length; i++) {
        let list = lists[i];
        let individualCall = await individualApiCallToHighlight(list.id, day, source);
        if (individualCall) {
          results[list.id] = individualCall?.hits;
        } else {
          return false;
        }
      }
    }
    return results;
  };

  const highlightEventsForClients = async ({ clients, day, source }) => {
    try {
      if (!!clients) {
        let results = {};
        for (let i = 0; i < clients?.length; i++) {
          const client = clients[i];
          const { myKeywordLists, teamMemberKeywordLists } = client ?? {};
          const lists = [...myKeywordLists, ...teamMemberKeywordLists];
          if (lists?.length > 0) {
            let individualCall = await compareToHighlightEvents(day, source, lists);
            if (!!individualCall) {
              results[client?.teamId] = individualCall;
            }
          }
        }
        return results;
      }
    } catch (error) {}
  };

  const individualApiCallToHighlight = async (id, day, source) => {
    try {
      let pathname = `/api/search/query-by-keyword-list`;
      let method = 'post';
      let requestProperties = {
        type: 'Event',
        pageSize: 10000,
        filters: [
          {
            field: 'eventStartDate',
            value: `${dayjs(day).startOf('month').format('YYYY-MM-DD')}`,
            operator: 'DATE_GTE',
          },
          {
            field: 'eventStartDate',
            value: `${dayjs(day).endOf('month').add(1, 'day').format('YYYY-MM-DD')}`,
            operator: 'DATE_LT',
          },
        ],
        pageNumber: 1,
        keywordListId: id,
        includeExplanations: false,
        unseenContentOnly: false,
      };
      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        requestSource: source,
        needsAuthentication: true,
        notShowErrorMessage: true,
      });
      return results;
    } catch (error) {}
  };

  const arrayElements = UpcomingEvents === undefined ? [] : UpcomingEvents.hits;
  const eventsToShow = EventsGrouped(arrayElements);
  const categoriesNames = Object.keys(eventsToShow);
  const categoriesToShow = OrderCategories(categoriesNames);
  const [commonCategoriesToShow, lordsCategoriesToShow, announcementsCategoriesToShow] = categoriesToShow;
  const [heightContainer, containerRef] = useHeightContainer();

  const isMobile = useIsMobile();

  return (
    <>
      {isMobile && (
        <div className={`bg-white monitor-items-bar research-bar`}>
          <div className='mx-auto main-content-wrapper'>
            <div className='main-content pr-xl-5 px-md-4 px-3'>
              <CalendarListControls />
            </div>
          </div>
        </div>
      )}
      <div className='bg-main-white monitor-items-bar'>
        <div className='flex-centered main-content-wrapper mx-auto'>
          <div className='side-left-element d-none d-lg-block pl-lg-5 pl-3'>
            <p class='mb-0 title-h4 nowrap-item'>Calendar</p>
          </div>

          <div
            className={`main-content pr-xl-5 px-md-4 px-3 flex-centered justify-content-between calendar-main-title d-none d-lg-flex`}
          >
            <div>
              <CalendarListControls />{' '}
            </div>
            <p className='events-color-badge paragraph-p3 mb-0'>
              Events coinciding with your {agencyUser || newAgencyPlan ? `clients'` : ''} interests will be highlighted
              in yellow
            </p>
          </div>
        </div>
      </div>
      <div className='pb-lg-0 flex-grow-1 d-flex flex-column mx-auto main-content-wrapper'>
        <div className='d-flex'>
          <div className='side-left-element d-none d-lg-block calendar-reference-filters d-none d-lg-block pt-lg-5 pt-3 pl-lg-5 pl-3'>
            <CalendarPicker month={month} setMonth={setMonth} setCurrentButtonToday={setCurrentButtonToday} />
            <GoToTodayButton
              currentButtonToday={currentButtonToday}
              setCurrentButtonToday={setCurrentButtonToday}
              setMonth={setMonth}
            />
          </div>
          <div className='pr-xl-5 px-md-4 px-3 main-content pt-lg-5 pt-2'>
            {isLoading && <SkeletonCalendarList />}
            {!isLoading && (
              <div ref={containerRef} className=' calendar-page-container pb-0'>
                {arrayElements.length === 0 ? (
                  <EmptyMessage
                    emptyMessageResourceName={'events'}
                    notIncludeButton
                    additionalText='Try selecting another date'
                  />
                ) : (
                  <CustomScrollbar
                    className={'main-content-scrollbar top-content-scrollbar'}
                    style={{ height: `${heightContainer}px` }}
                    maximalThumbYSize={100}
                  >
                    <div className='p-5'>
                      {arrayElements.length !== 0 && (
                        <>
                          <div className='position-relative' style={{ zIndex: 3 }}>
                            <RenderItem
                              content={commonCategoriesToShow}
                              mainCategory={'commons'}
                              eventsToShow={eventsToShow}
                            />
                          </div>
                          <div className='position-relative' style={{ zIndex: 2 }}>
                            <RenderItem
                              content={lordsCategoriesToShow}
                              mainCategory={'lords'}
                              eventsToShow={eventsToShow}
                            />
                          </div>
                          <div className='position-relative' style={{ zIndex: 1 }}>
                            <RenderItem
                              content={announcementsCategoriesToShow}
                              mainCategory={'announcements'}
                              eventsToShow={eventsToShow}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </CustomScrollbar>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const RenderItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults } = state;
  const { UpcomingEvents } = contentResults[activeResults];

  const { content, mainCategory, eventsToShow } = props;
  const { newAgencyPlan } = useTopicOrClientWord();

  const checkIfEventIsHighlighted = (event) => {
    let highlightEvents = UpcomingEvents?.highlightEvents;
    let highlightedLists = [];
    if (highlightEvents) {
      if (newAgencyPlan) {
        for (let i in highlightEvents) {
          let client = highlightEvents[i];
          for (let a in client) {
            let hits = client[a];
            let item = hits.find((hit) => hit.id === event.id);
            if (item) {
              highlightedLists.push(a);
            }
          }
        }
      } else {
        for (let i in highlightEvents) {
          let hits = highlightEvents[i];
          let item = hits.find((hit) => hit.id === event.id);
          if (item) {
            highlightedLists.push(i);
          }
        }
      }
    }
    return highlightedLists;
  };

  return (
    <>
      {content.length > 0 && (
        <>
          {content.map((item, index, array) => {
            let eventsToShowOrdered = eventsToShow[item].sort(SortArray('dateTime'));
            return (
              <div className='pb-4 position-relative' key={`${mainCategory}-${index}`} style={{ zIndex: array.length }}>
                <h4 className={`calendar-category calendar-category-${mainCategory}`} key={`category${index}`}>
                  {item}
                </h4>
                {eventsToShowOrdered.map((event, index, arr) => (
                  <CalendarItem
                    key={`event${index}`}
                    item={event}
                    array={arr}
                    index={index}
                    highlightedLists={checkIfEventIsHighlighted(event)}
                  />
                ))}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default withAuthenticationRequired(CalendarList, {
  onRedirecting: () => <Loading />,
});
