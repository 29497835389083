import { Link } from 'react-router-dom';
import ModalComponent from 'components/Common/Modal';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { stakeholderUrlImage } from 'components/Dashboard/Analytics/useCallMostRelevantStakeholders';
import CustomScrollbar from 'components/Common/CustomScrollbar';

const AnalyticsStakeholderPopup = (props) => {
  const { isOpen, setIsOpen, stakeholderResults } = props;
  const searchParameter = getUrlParam('search');

  const { hits } = stakeholderResults ?? {};
  const referenceValue = 280;
  const valueHeight = hits?.length * 126 + 48;
  return (
    <ModalComponent
      maxWidth={800}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      overlayClassName='react-modal-overlay report-analytics-stakeholder-popup'
    >
      <div className='bg-main-blue text-white px-lg-5 px-3 pt-5 pb-3 stakeholders-analytics-popup-top-container'>
        <p className='title-h4' style={{ maxWidth: '500px' }}>
          You’re looking at political data that includes mentions of ‘{decodeURIComponent(searchParameter)}’. Would you
          rather go to a stakeholder’s page?
        </p>
      </div>
      <div style={{ height: `${valueHeight <= referenceValue ? valueHeight : referenceValue}px` }}>
        <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
          <div className='px-lg-5 px-3 py-4'>
            {hits?.map((item) => {
              const { id, contentUrl, imageId, title, summary, imageUrl } = item;
              const urlImage = stakeholderUrlImage({ imageId, imageUrl });
              return (
                <div className='d-flex align-items-start py-3' key={`stakeholder-${id}`}>
                  <img className='stakeholder-analytics-image' src={urlImage} alt={'Stakeholder profile'} />
                  <div className='flex-grow-1 px-4 pt-2'>
                    <h4 className={`title-h5-m-bold mb-0 nowrap-item text-main-blue`}>{title}</h4>
                    <p className='main-light-text hidden-lines hidden-one-line title-h5'>{summary}</p>
                  </div>
                  <div className='pt-3'>
                    <Link to={contentUrl} className='action-button rounded-button px-4 py-1 nowrap-item'>
                      Go to stakeholder page
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </CustomScrollbar>
      </div>
    </ModalComponent>
  );
};

export default AnalyticsStakeholderPopup;
