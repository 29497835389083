const adjustPDFScale = (node) => {
  let { width } = node.getBoundingClientRect();
  let elements = document.querySelectorAll("div[id^='page'][id$='-div']");
  if (elements.length > 1) {
    for (let i = 0; i < elements.length; i++) {
      let item = elements[i];
      let { width: itemWidth, height: itemHeight } = item.getBoundingClientRect();
      let ratio = width / itemWidth;
      let widthDiff = itemWidth - width - 61;
      let heightDiff = itemHeight * (1 - ratio);
      if (ratio && !isNaN(ratio) && ratio < 1) {
        item.style.transform = `scale(${ratio})`;
        item.style.marginLeft = `-${widthDiff * ratio}px`;
        if (i !== 0) {
          item.style.marginTop = `-${heightDiff}px`;
        } else {
          item.style.marginTop = `-${heightDiff * ratio - 80}px`;
        }
      }
    }
  }
};

export default adjustPDFScale;
