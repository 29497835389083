import React from 'react';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import InfluenceHeader from './InfluenceHeader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonCreateComponent = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <InfluenceHeader />
      <div className='pt-lg-5'>
        <div className='row mx-auto pl-lg-5 influence-general-container'>
          <div className='d-none d-lg-block influence-menu-items '>
            {Array(5)
              .fill()
              .map((item, index) => (
                <div key={index} className='mb-3'>
                  <Skeleton height={19} width={`100%`} />
                </div>
              ))}
          </div>
          <div className='influence-content-container flex-grow-1'>
            <div
              className='mx-auto px-lg-5 px-xl-0'
              style={{
                maxWidth: '960px',
              }}
            >
              <div className='influence-creation_section influence-creation_header row mx-0 justify-content-between align-items-center main-sticky'>
                <Skeleton height={24} width={400} />
                <Skeleton height={24} width={100} />
              </div>
              <div
                className='simple-box influence-creation_content'
                ref={containerRef}
                style={{
                  minHeight: `${heightContainer}px`,
                  margin: '0 auto',
                  maxWidth: '960px',
                }}
              >
                <div className='influence-creation_section'>
                  <Skeleton height={19} width={'40%'} />
                  <Skeleton height={19} width={'70%'} />

                  <div className='mt-4'>
                    <Skeleton height={36} width={'100%'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonCreateComponent;
