import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { createSectionName } from '../helpers/controlActiveFilters';
import { createNavigationLinksOnSection, getIdPositionOnSection } from '../helpers/navigationFunctions';
import { useHeightContainer } from '../utilities/useHeightContainer';
import { useContext, useRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ContentRelated from '../../ContentRelated';
import SkeletonKeyUpdatesContent from '../KeyUpdates/SkeletonKeyUpdatesContent';
import SkeletonConsultationContent from '../ConsultationsSection/SkeletonConsultationContent';
import SkeletonLegislationContent from '../Legislation/SkeletonLegislationContent';
import SkeletonParliamentaryContent from '../ParliamentaryRecord/SkeletonParliamentaryContent';
import useLoadContentResults from './useLoadContentResults';
import KeyUpdateNewContent from '../KeyUpdates/KeyUpdateNewContent';
import NavigationLinks from '../components/NavigationLinks';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import ConsultationNewContent from '../ConsultationsSection/ConsultationNewContent';
import LegislationNewContent from '../Legislation/LegislationNewContent';
import LibraryMaterialMainContent from '../LibraryMaterial/LibraryMaterialMainContent';
import changeStakeholderLinks from '../helpers/changeStakeholderLinks';
import ResearchPopUp from '../SubNav/ui/ResearchPopUp';
import CommitteePublicationNewContent from '../CommitteePublication/CommitteePublicationNewContent';
import ParliamentaryNewContent from '../ParliamentaryRecord/ParliamentaryNewContent';
import CustomScrollbar from '../../Common/CustomScrollbar';

const ContentControl = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults, loadingCreateSummary, activePlan, keywordsLists, referenceState } = state;
  const { enableSummariser } = activePlan;

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, type } = params;

  const sectionName = createSectionName(true);
  const section = contentResults[activeResults][sectionName];
  const getIdPosition = getIdPositionOnSection(section, id);
  const createNavigationLinks = createNavigationLinksOnSection(section);

  const [isLoading, setIsLoading] = useState(true);
  const [navigationLinks, setNavigationLinks] = useState(createNavigationLinks(getIdPosition()));
  const [idPosition, setIdPosition] = useState(getIdPosition());
  const [scrolling, setScrolling] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [keywordsSection, setKeywordsSection] = useState('keywords');
  const [updatingContribution, setUpdatingContribution] = useState(false);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);

  const [heightContainer, containerRef] = useHeightContainer();
  const { loadNew, isLoadingRelatedContent } = useLoadContentResults({ setNavigationLinks, idPosition, setIsLoading });

  const contentContainer = useRef(null);
  const scrollbarRef = useRef(null);
  const topicParam = getUrlParam('topic-id');

  const barNavigationConditional =
    idPosition !== null && (navigationLinks.next !== null || navigationLinks.prev !== null);
  const currentNew = section?.new ?? '';

  const sectionContentContainer = useCallback((node) => {
    if (node !== null) {
      let links = document.querySelectorAll('.main-update-content-container a');
      changeStakeholderLinks(links, navigate);
    }
  }, []);

  const referencePopupProps = {
    showPopup,
    setShowPopup,
    keywordsSection,
    setKeywordsSection,
  };

  const handleScroll = (scrollValues) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollValues;
    const scrolledToBottom = scrollTop === scrollHeight - clientHeight;
    setReachedTheEnd(scrolledToBottom);

    if (section?.totalContributions) {
      let contributions = section.totalContributions.hits.map((item) => item.contributionId);
      let elements = document.querySelectorAll('.contribution');
      for (let i = 0; i < elements.length; i++) {
        let el = elements[i];
        const { top } = el.getBoundingClientRect();
        const value = contentContainer.current.getBoundingClientRect().top;
        if (top >= value && top < value + 100) {
          let contributionId = el.dataset.contributionId;
          if (contributionId && contributions.includes(contributionId) && !updatingContribution) {
            let className = 'debate-item-contributiondebateitem-highlighted';
            let currentElement = document.getElementsByClassName(`${className}`)[0];
            if (currentElement) {
              currentElement.classList.remove(className);
            }
            el.parentElement.classList.add(className);
            navigate(
              {
                search: location.search,
                hash: `contribution-${contributionId}`,
              },
              { replace: true }
            );
            break;
          }
        }
      }
      if (location.hash.includes('contribution-')) {
        let hashId = location.hash.replace('#contribution-', '');
        let position = contributions.indexOf(hashId);
        let newId;
        if (scrollTop >= 0 && scrollTop < 100 && position !== 0) {
          newId = `contribution-${contributions[0]}`;
        } else if (scrolledToBottom) {
          let element = document.getElementById(`${location.hash.replace('#', '')}`);
          if (element?.getBoundingClientRect().top < 0) {
            newId = `contribution-${contributions[contributions.length - 1]}`;
          }
        }
        if (newId) {
          navigate(
            {
              search: location.search,
              hash: newId,
            },
            { replace: true }
          );
        }
      }
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setIdPosition(getIdPosition());
    setNavigationLinks(createNavigationLinks(getIdPosition()));
    loadNew(source);

    return () => {
      source.cancel('Current new cancelled by the user.');
    };
  }, [id, type, referenceState, topicParam]);

  return (
    <>
      {/*AE: SWITCH SKELETONS */}
      {isLoading && (
        <>
          {(() => {
            switch (sectionName) {
              case 'KeyUpdates':
                return <SkeletonKeyUpdatesContent />;
              case 'Consultations':
                return <SkeletonConsultationContent />;
              case 'Legislation':
                return <SkeletonLegislationContent />;
              case 'ParliamentaryRecord':
              case 'CommitteePublications':
                return <SkeletonParliamentaryContent />;
              default:
                return <SkeletonKeyUpdatesContent />;
            }
          })()}
        </>
      )}

      {!isLoading && (
        <div ref={containerRef}>
          <div className='row px-0 piece-content-container'>
            <div className={`general-content-column padding-to-nav p-0 z-100`}>
              <div ref={contentContainer} />
              <div className='main-content-section content-container' style={{ height: `${heightContainer}px` }}>
                <CustomScrollbar
                  className={'simple-scrollbar indented-scrollbar-small'}
                  style={{ height: `${heightContainer - (barNavigationConditional ? 43 : 0)}px` }}
                  minimalThumbYSize={55}
                  maximalThumbYSize={100}
                  ref={scrollbarRef}
                  onScroll={(scrollValues) => {
                    handleScroll(scrollValues);
                  }}
                  onScrollStart={() => {
                    setScrolling(true);
                  }}
                  onScrollStop={() => {
                    setScrolling(false);
                  }}
                >
                  <div className='main-update-content-container' ref={sectionContentContainer}>
                    {(() => {
                      const props = {
                        currentNew,
                        contentContainer,
                        setIsLoading,
                        setUpdatingContribution,
                        hideSummary: enableSummariser !== undefined && !enableSummariser,
                        scrollbarRef,
                        scrolling,
                      };
                      switch (sectionName) {
                        case 'KeyUpdates':
                          return <KeyUpdateNewContent {...props} />;
                        case 'Consultations':
                          return <ConsultationNewContent {...props} />;
                        case 'Legislation':
                          return <LegislationNewContent {...props} />;
                        case 'ParliamentaryRecord':
                          return <ParliamentaryNewContent {...props} />;
                        case 'CommitteePublications':
                          return <CommitteePublicationNewContent {...props} />;
                        case 'LibraryMaterial':
                          return <LibraryMaterialMainContent {...props} />;
                        default:
                          return <SkeletonKeyUpdatesContent />;
                      }
                    })()}
                  </div>
                </CustomScrollbar>
                <NavigationLinks
                  barNavigationConditional={barNavigationConditional}
                  navigationLinks={navigationLinks}
                  disabled={loadingCreateSummary}
                />
              </div>
            </div>
            <div className='related-content-column show-lg px-0'>
              <ContentRelated
                data={currentNew}
                content={section?.contentRelated}
                reachedTheEnd={reachedTheEnd}
                relatedStakeholders={section?.relatedStakeholders}
                relatedStakeholdersRendered={section?.relatedStakeholdersRendered}
                totalContributions={section?.totalContributions}
                mainSection={sectionName}
                isLoadingRelatedContent={isLoadingRelatedContent}
                height={heightContainer}
                referencePopupProps={referencePopupProps}
                keywordsListsIds={currentNew?.keywordsListsIds}
              />
            </div>
          </div>
        </div>
      )}
      <ResearchPopUp
        keywordsLists={keywordsLists.filter((item) => item.id !== null)}
        setShowPopup={setShowPopup}
        showPopUp={showPopup}
        keywordsSection={keywordsSection}
        referenceState={referenceState}
      />
    </>
  );
};

export default ContentControl;
