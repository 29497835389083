import ReactGA from 'react-ga4';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import axios from 'axios';
import getActiveSubscriptions from 'components/Settings/Billing/getActiveSubscriptions';
import { useSetListsIds } from 'components/Dashboard/MainSearchBar/setListIds';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useContext } from 'react';
import { store } from 'components/Store';
import useClientsFunctions from 'components/Dashboard/hooks/useClientsFunctions';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { useNavigate } from 'react-router-dom';
import ConfigData from 'config/ConfigData';
import getAppSite from 'utils/getAppSite';
import getActivePlan from 'components/Settings/Utilities/getActivePlan';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';
import { useFilters } from 'components/Dashboard/Filters/FiltersContext';
import LocalStorage from 'utils/LocalStorage';

const DEFAULT_LIST_NAME = 'Your topic';

const useSaveFunctions = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { net_api_url, search, team, keywordsLists, activeSubscriptions, listPosition } = state;

  const { allowImmediateAlerts, allowWeekdailyAlerts, allowWeeklyAlerts } = activeSubscriptions;

  const allowAlerts = allowImmediateAlerts && allowWeekdailyAlerts && allowWeeklyAlerts;
  const navigate = useNavigate();

  const { getAccessToken } = useGetAccessToken();
  const { setCurrentTeam } = useClientsFunctions();
  const { newAgencyPlan } = useTopicOrClientWord();
  const { setListsIds } = useSetListsIds();
  const { clearFilters } = useFilters();

  const { source, redirectToHome, showCreateEmailAlert, setIsLoadingButton, teamId } = props;

  const teamIdParameter = getUrlParam('teamId');
  const currentKeywordCategory = keywordsLists[listPosition];
  const { keywords, id, name, isMyList, keywordExclusions, andKeywords, description, countries } =
    currentKeywordCategory === undefined ? {} : currentKeywordCategory;
  const { myself } = team ?? {};

  const callCreateKeywordList = async () => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: true });
    try {
      let token = await getAccessToken();
      let requestProperties = {
        name: name.length === 0 ? DEFAULT_LIST_NAME : name,
        keywords,
        keywordExclusions,
        andKeywords,
        countries: getAppSite() === 'usa' ? 'USA' : countries,
        description: !description || description.trim() === '' ? null : description,
      };

      if (teamId) {
        requestProperties = {
          ...requestProperties,
          teamId: teamId,
        };
      }

      let createList = await axios.post(`${net_api_url}/api/keyword-list/create`, requestProperties, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });

      let listData = createList.data;

      if (newAgencyPlan && teamIdParameter) {
        await setCurrentTeam({ teamId: parseInt(teamIdParameter) });
        const activePlan = await getActivePlan(getAccessToken);
        if (!!activePlan) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
        }
      }

      createNotification('success', `Successfully saved`);
      ReactGA.event({
        category: 'Keyword lists',
        action: 'Keyword list created',
        label: name,
      });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: false });
      applySearchFromKeywords(listData.id);
      updateKeywordList(listData);
      changeSortFilter();

      if (redirectToHome || (showCreateEmailAlert && !allowAlerts)) {
        navigate(`/monitor-inbox/${ConfigData?.monitorInboxInitialPage ?? 'key-updates'}`);
      }
      if (showCreateEmailAlert && allowAlerts) {
        showCreateEmailAlert();
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: false });
      createNotification('danger', `There was an error completing your operation. Please reload the page.`);
    } finally {
      setIsLoadingButton(false);
    }
  };

  const callUpdateKeywordList = async () => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: true });
    try {
      let token = await getAccessToken();
      let listUpdate;
      if (myself?.role === 'Admin' || myself?.role === 'Owner' || isMyList) {
        listUpdate = await axios.post(
          `${net_api_url}/api/keyword-list/update`,
          {
            id: id,
            name: name.length === 0 ? DEFAULT_LIST_NAME : name,
            keywords,
            keywordExclusions,
            andKeywords,
            countries,
            description: !description || description.trim() === '' ? null : description,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );
      }
      let activeSubscriptions = await getActiveSubscriptions(getAccessToken, source);

      if (newAgencyPlan && teamIdParameter) {
        await setCurrentTeam({ teamId: parseInt(teamIdParameter) });
        const activePlan = await getActivePlan(getAccessToken);
        if (!!activePlan) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
        }
      }

      dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: activeSubscriptions });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: false });
      createNotification('success', `Successfully saved`);
      ReactGA.event({
        category: 'Keyword lists',
        action: 'Keyword list updated',
        label: name,
      });
      applySearchFromKeywords();
      let listData = listUpdate ? listUpdate.data : {};
      updateKeywordList(listData);
      changeSortFilter();
      if (redirectToHome) {
        navigate(`/monitor-inbox/${ConfigData?.monitorInboxInitialPage ?? 'key-updates'}`);
      }
    } catch (error) {
      let errorMessage = 'There was an error completing your operation. Please reload the page.';
      if (error.response.data) {
        errorMessage = error.response.data;
      }
      createNotification('danger', `${errorMessage}`);
      dispatch({ type: 'MODIFY_SECTION', parameter: 'IsSavingKeywordList', value: false });
    } finally {
      setIsLoadingButton(false);
    }
  };

  const applySearchFromKeywords = (listId) => {
    let listIdToSave = listId ?? id;
    dispatch({ type: 'MODIFY_SECTION', parameter: 'activeResults', value: 'searchResults' });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'modifiedListId', value: listIdToSave });
    setListsIds(listIdToSave);
    LocalStorage.removeItem('contentFilters');
    clearFilters();
  };

  const updateKeywordList = (listData) => {
    let keywordsListsToRender = JSON.parse(JSON.stringify(keywordsLists));
    let { id, name, keywords, keywordExclusions, andKeywords, isHandpicked, description } = listData;
    let newList = {
      ...keywordsListsToRender[listPosition],
      id,
      name,
      keywords,
      keywordExclusions,
      andKeywords,
      isHandpicked,
      description,
      isSaved: true,
      myState: 'Enabled',
    };
    if (!keywordsListsToRender[listPosition]?.teamId) {
      newList = {
        ...newList,
        teamId: !!teamIdParameter ? parseInt(teamIdParameter) : null,
      };
    }
    keywordsListsToRender[listPosition] = newList;
    dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: keywordsListsToRender });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'InitialKeywordsState', value: keywordsListsToRender });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'search',
      value: { ...search, list: newList },
    });
  };

  const changeSortFilter = () => {
    let filters = {
      'sort-topic': {
        name: 'Most recent',
        sortFilter: { field: 'dateTime', order: 'DESC' },
      },
    };
    LocalStorage.setItem('activeFilters', JSON.stringify(filters));
  };

  return {
    callCreateKeywordList,
    callUpdateKeywordList,
  };
};

export default useSaveFunctions;
