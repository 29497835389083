import React from 'react';
import { ReferenceSectionItems } from './ResearchPopUpItems';
import ModalComponent from 'components/Common/Modal';

const ContentPopup = (props) => {
  return <ReferenceSectionItems {...props} />;
};

const ResearchPopUp = (props) => {
  const { showPopUp, setShowPopup } = props;

  return (
    <ModalComponent isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default ResearchPopUp;
