import ModalComponent from 'components/Common/Modal';
import React from 'react';

const TopicDiscardPopUp = (props) => {
  const { discardFunction, showTopicDiscardPopup, setShowTopicDiscardPopup } = props;
  return (
    <ModalComponent maxWidth={500} isOpen={showTopicDiscardPopup} setIsOpen={setShowTopicDiscardPopup}>
      <div className='delete-keywordcategory-modal py-4'>
        <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
          <div className='text-center'>
            <p>Do you want to discard the changes you've made?</p>

            <div className='my-4'>
              <button className='general-button delete-button px-4 mr-3 py-1'>Cancel</button>
              <button
                className='btn btn-general action-button px-4 py-1'
                onClick={async () => {
                  discardFunction();
                }}
              >
                {'Discard changes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default TopicDiscardPopUp;
