import WritingMyContent from './WritingMyContent';
import WritingCreate from './WritingCreate';
import WritingContent from './WritingContent';
import { useState } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { store } from 'components/Store';
import getCurrentUser from 'components/Settings/Account/getCurrentUser';
import axios from 'axios';
import Loading from 'components/Login/Loading';
import { Route, Routes } from 'react-router-dom';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';

const WritingAssitant = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, userProfile } = state;

  const { getAccessToken } = useGetAccessToken();

  const [isLoading, setIsLoading] = useState(true);

  const initialCall = useRef();

  initialCall.current = async (source) => {
    try {
      if (Object.keys(userProfile).length === 0) {
        let user = await getCurrentUser(getAccessToken, source);
        if (!!user) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'userProfile',
            value: user,
          });
        }
      }
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Get current user canceled by the user.');
    };
  }, [activePlan]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route index element={<WritingMyContent />} />
          <Route path={`/create/:title`} element={<WritingCreate />} />
          <Route path={`/content/:title`} element={<WritingContent />} />
        </Routes>
      )}
    </>
  );
};

export default WritingAssitant;
