import React from 'react';
import SearchBarWithTopic from './SearchBarWithTopics.js';
import { useAuth0 } from '@auth0/auth0-react';
import useDashboardInitialController from 'components/Dashboard/SearchControl/useDashboardInitialController';
import { useLocation } from 'react-router-dom';

const MainSearchBar = (props) => {
  const { searchResults } = useDashboardInitialController();
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  return (
    <SearchBarWithTopic
      searchResults={searchResults}
      isInHome={!isAuthenticated && location.pathname === '/'}
      isMonitor={!isAuthenticated && location.pathname.includes('/monitor')}
    />
  );
};

export default MainSearchBar;
