import { useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { store } from 'components/Store';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import useCompileFiltersFunction from './useCompileFilters';
import useCompileInitialFilters from './useCompileInitialFilters';
import { useFilters } from './FiltersContext';

const useCreateSectionStored = () => {
  const { mainSection } = useParams();
  const location = useLocation();

  const createSectionStored = () => {
    if (mainSection) {
      return mainSection;
    } else if (location.pathname.includes('/search-results')) {
      return 'search-results';
    } else if (location.pathname.includes('/monitor-inbox')) {
      return 'monitor-inbox';
    } else if (location.pathname.includes('/stakeholder-mapping')) {
      return 'stakeholder-mapping';
    } else if (location.pathname.includes('/have-your-voice-heard')) {
      return 'have-your-voice-heard';
    } else if (location.pathname.includes('/influence/directory')) {
      return 'influence-contacts';
    } else if (location.pathname.includes('/general-election-2024/candidates')) {
      return 'election-candidates';
    }
  };
  const sectionStored = createSectionStored();
  return { sectionStored };
};

const useGetSectionFilters = (section) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, referenceState } = state;
  const { sectionStored } = useCreateSectionStored();

  const { contentFilters, filtersState } = useFilters();
  const { list } = search;

  const { compileInitialFilters } = useCompileInitialFilters();

  const { compileFiltersFunction, activeResultsConditional } = useCompileFiltersFunction(section);
  const mountedItem = useRef(false);
  const location = useLocation();

  const { inboxSection, referenceSection, searchResultsSection } = useMainSectionParameter();
  //AE: THIS CONDITION IS NECESSARY TO AVOID THAT IF YOU'RE IN A DIFFERENT SECTION FROM KEYUPDATES AND GO HERE, THE LIST GETS THE VALUE OF THE PREVIOUS SECTION AND CREATES A DUPLICATED CALL.
  const createCallConditional = () => {
    let callConditional;
    if (inboxSection) {
      callConditional = list !== null;
    } else if (referenceSection || searchResultsSection) {
      callConditional = list === null;
    } else if (
      location.pathname.includes('/stakeholder-mapping') ||
      location.pathname.includes('/policymakers') ||
      location.pathname.includes('/campaigns') ||
      location.pathname.includes('/general-election-2024/candidates') ||
      location.pathname.includes('/general-election-2024/key-updates')
    ) {
      callConditional = true;
    } else {
      callConditional = false;
    }
    return callConditional;
  };

  useEffect(() => {
    mountedItem.current = true;
  }, [referenceState]);

  const getSectionFilters = useCallback(() => {
    //AE: Small adjustment for the stakeholder-mapping page, probably related to the parameters that is not clear why sometimes is ok and sometimes not.
    const initialFilters =
      Object.keys(contentFilters).length > 0 && contentFilters[sectionStored]
        ? contentFilters[sectionStored]
        : compileInitialFilters(sectionStored);

    const sectionFilters = initialFilters.filter(
      (filter) =>
        filter.pages.includes(section) && activeResultsConditional(filter, initialFilters) && !filter.configHidden
    );
    const filters = compileFiltersFunction(sectionFilters);
    return filters;
  }, [filtersState, contentFilters, sectionStored, location.pathname, search]);

  return {
    getSectionFilters,
    callConditional: createCallConditional(),
  };
};

export { useCreateSectionStored };
export default useGetSectionFilters;
