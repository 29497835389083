import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { store } from 'components/Store';

const useCloseButtonFunctionality = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const location = useLocation();
  const pathnames = ['/search-results', '/calendar', '/saved-items'];
  const closeButtonFunctionality = () => {
    let filtered = pathnames.filter((item) => location.pathname.startsWith(item));
    if (filtered?.length > 0) {
      return;
    } else {
      let prevUrl = `${location.pathname}${location.search}${location.hash}`;
      if (location.pathname?.startsWith('/reports/create')) {
        prevUrl = `/reports`;
      }
      return dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'prevUrl',
        value: prevUrl,
      });
    }
  };
  return { closeButtonFunctionality };
};
const SavedItemsIcon = (props) => {
  const { home } = props;
  const location = useLocation();
  const { closeButtonFunctionality } = useCloseButtonFunctionality();

  return (
    <Link
      to={`/saved-items${location.pathname.includes('/saved-items') ? location.search : ''}`}
      className={`saved-items-icon mr-2 mr-lg-3 ${home ? 'action-state-hover' : ''} ${location.pathname.includes('/saved-item') ? 'active' : ''}`}
      data-tooltip-content={location.pathname.includes('/saved-items') ? '' : 'Saved items'}
      data-tooltip-id={'general-tooltip'}
      onClick={() => {
        closeButtonFunctionality();
      }}
    >
      <span className='icon-saved-items' />
    </Link>
  );
};

export { useCloseButtonFunctionality };
export default SavedItemsIcon;
