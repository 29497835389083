import { useContext, useState } from 'react';
import ModalComponent from 'components/Common/Modal';
import { store } from 'components/Store';
import { useParams } from 'react-router-dom';
import { useGroupFunctions } from 'components/Dashboard/Influence/CrmGroups/GroupFunctions';
import useContactsApiCalls from 'components/Dashboard/Influence/CrmContacts/useContactsApiCalls';
import createNotification from 'components/Settings/Utilities/CreateNotification';

const RemoveContactsFromGroupModal = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedCrmContacts, contentResults, activeResults } = state;

  const { selectedAllVisible, selectedIds, selectedAll } = selectedCrmContacts;
  const { CrmContacts } = contentResults[activeResults] ?? {};

  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, setIsOpen } = props ?? {};

  const params = useParams();
  const { addRemoveContactToGroup } = useGroupFunctions();
  const { removeContactsFromGroupByQuery } = useContactsApiCalls();

  const { groupId } = params;
  const contactsCount =
    selectedAllVisible.length > 0
      ? selectedAllVisible.length
      : selectedIds.length > 0
        ? selectedIds.length
        : CrmContacts?.totalHits;

  const finalNumber = selectedAll ? contactsCount : selectedIds?.length;

  const removeContactsFromGroup = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        let newContacts = { ...CrmContacts };
        if (selectedAll) {
          await removeContactsFromGroupByQuery({ crmContactGroupId: groupId });
          newContacts = {
            ...newContacts,
            hits: [],
            totalHits: 0,
          };
        } else {
          for (let i = 0; i < selectedIds?.length; i++) {
            await addRemoveContactToGroup({ contactId: selectedIds[i], groupId: groupId, action: 'remove' });
          }
          const newHits = newContacts.hits?.filter((item) => !selectedIds.includes(item?.id));
          newContacts = { ...newContacts, hits: newHits, totalHits: newContacts?.totalHits - selectedIds?.length };
        }
        const crmContactsResults = {
          ...contentResults,
          [activeResults]: {
            CrmContacts: newContacts,
          },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: crmContactsResults,
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedCrmContacts',
          value: {
            selectedAll: false,
            selectedIds: [],
            selectedAllVisible: [],
          },
        });
        createNotification('success', `Successfully deleted`);
        setIsLoading(false);
        setIsOpen(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} maxWidth={558}>
        <div>
          <h3 className='pt-4 topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 pl-3 pr-5'>
            {finalNumber} contact{finalNumber === 1 ? '' : 's'} will be removed from this group
          </h3>
          <div className='px-lg-5 px-3'>
            <div className='remove-contacts-wrapper pt-4'>
              <p className='remove-contacts-title main-subtle-text'>
                The individual records in this group will continue to appear in your directory, and can be manually
                added to static groups at any time
              </p>
            </div>
          </div>
          <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
            <span className='remove-contacts-helper-text-icon mr-2 d-inline-block-centered centered-6' />
            These changes impact all members of your team
          </p>
          <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white pt-4 rounded-bottom'>
            <button
              onClick={() => setIsOpen(false)}
              className='danger-button-outlined px-3 py-2 mr-3'
              disabled={isLoading}
            >
              Cancel
            </button>
            <button className='danger-button px-3 py-2' onClick={removeContactsFromGroup} disabled={isLoading}>
              {isLoading && (
                <i
                  className='fas fa-spinner fa-spin'
                  style={{
                    margin: '0 41.2px',
                    color: '#fcfcfc',
                    marginTop: '2px',
                    width: '33.7px',
                  }}
                ></i>
              )}
              Remove contact{finalNumber === 1 ? '' : 's'}
            </button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default RemoveContactsFromGroupModal;
