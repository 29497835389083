import parliamentarianUsers, {
  consultantAgenciesMembers,
  membershipMembers,
} from 'components/Dashboard/SidebarElements/parliamentarianUsers.js';
const setParamsCode = (props) => {
  const { urlParams, code } = props;
  //AE: PARLIAMENTARIAN and User QUERIES
  if (parliamentarianUsers(code)) {
    urlParams.set('view', 'p');
  }
  if (consultantAgenciesMembers(code)) {
    urlParams.set('view', 'c');
  }
  if (membershipMembers(code)) {
    urlParams.set('view', 'm');
  }
};

export default setParamsCode;
