const addHoverTextProps = (props) => {
  const { message, ...restProps } = props;

  return {
    'data-tooltip-content': message,
    'data-tooltip-id': 'settings-tooltip',
    'data-tooltip-place': 'top',
    ...restProps,
  };
};

export default addHoverTextProps;
