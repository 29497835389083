import React, { useState, useEffect, useRef, useContext } from 'react';
import { store } from 'components/Store.js';
import { useAuth0 } from '@auth0/auth0-react';
import { net_api_url } from 'components/Store.js';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import axios from 'axios';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { useLocation } from 'react-router-dom';
import SkeletonAccount from './SkeletonAccount.js';
import OrganisationLogo from './OrganisationLogo.js';
import getCurrentTeam from 'components/Settings/Team/getCurrentTeam';
import RemoveLogoPopup from './RemoveLogoPopup.js';
import CustomScrollbar from 'components/Common/CustomScrollbar.js';
import { userTypes } from 'components/Login/CreateProfile/CreateProfileFirstStep.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import disabledUSAProps from 'components/Settings/Utilities/disabledUSAProps.js';
import getAppSite from 'utils/getAppSite.js';

const Account = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { team, userProfile } = state;
  const { myself } = team;
  const { email, firstName, lastName, phoneNumber, jobTitle, organisation, organisationWebsite } = userProfile;
  const { user } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const [isLoading, setIsLoading] = useState(true);
  const [organisationLogo, setOrganisationLogo] = useState(userProfile?.organisationLogo ?? null);
  const [userType, setUserType] = useState(userProfile?.userType ?? null);

  const [showRemoveLogoPopup, setShowRemoveLogoPopup] = useState(false);
  const [heightContainer, containerRef] = useHeightContainer();
  const [error, setError] = useState({ type: '', message: '' });
  const [saveLoading, setSaveLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);

  const dataElements = useRef([]);
  const location = useLocation();

  const loadProfile = useRef();
  loadProfile.current = async (source) => {
    try {
      setIsLoading(true);
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      if (currentTeam) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
        let title = `Account - Settings - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadProfile.current(source);
    return () => {
      source.cancel('Account profile canceled by the user.');
    };
  }, []);

  const saveProfile = async () => {
    try {
      let token = await getAccessToken();
      let email = dataElements.current['email'].value;
      let password = dataElements.current['password'].value;
      let firstName = dataElements.current['firstName'].value;
      let lastName = dataElements.current['lastName'].value;
      let fullName = `${firstName} ${lastName}`;
      let userType = dataElements.current['userType'].value;
      let phoneNumber = dataElements.current['phoneNumber'].value;
      let organisationWebsite = dataElements.current['organisationWebsite'].value;
      let body = {
        userId: user.sub,
        fullName: fullName,
        firstName: firstName,
        lastName: lastName,
        userType: userType,
        phoneNumber: phoneNumber,
        organisationWebsite: myself.role === 'Member' ? userProfile.organisationWebsite : organisationWebsite,
        organisationLogo,
      };

      switch (userType) {
        case 'Parliamentarian':
        case 'Prospective parliamentary candidate':
          body = {
            ...body,
            jobTitle: '',
            organisation: '',
          };
          break;
        case 'Campaigner':
          body = {
            ...body,
            jobTitle: '',
            organisation: '',
          };
          break;
        case 'Other':
          body = {
            ...body,
            jobTitle: dataElements.current['organisation'].value,
            organisation: '',
          };
          break;
        default:
          body = {
            ...body,
            jobTitle: '',
            organisation: dataElements.current['organisation'].value,
          };
      }

      body = !dataElements.current['password'].disabled ? { ...body, password: password } : { ...body };
      body = email !== userProfile.email ? { ...body, email: email } : { ...body };
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      };

      let response = await fetch(`${net_api_url}/api/user/update`, requestOptions);

      if (!response.ok) {
        if (response.text) {
          let errorMessage = await response.text();
          let errorBody = JSON.parse(errorMessage);

          switch (errorBody.message) {
            case 'The specified new email already exists':
            case 'Cannot update password and email simultaneously':
              setError({ type: 'email', message: errorBody.message });
              setSaveLoading(false);
              break;
            case 'PasswordStrengthError: Password is too weak':
              setError({
                type: 'password',
                message:
                  'Invalid password, should be: * At least 8 characters in length * Should contain: * lower case letters (a-z) * upper case letters (A-Z) * numbers (i.e. 0-9)',
              });
              setSaveLoading(false);
              break;
            case 'Organisation website must start with https:// or http://':
              setError({ type: 'organisationWebsite', message: errorBody.message });
              setSaveLoading(false);
              break;
            case 'If you do not have a parliament.uk or gov.uk email address, please contact us at support@policymogul.com':
              setError({ type: 'userType', message: errorBody.message });
              setSaveLoading(false);
              break;
            default:
              setError({ type: 'email', message: errorBody.message });
              setSaveLoading(false);
          }
        } else {
          console.error(response);
        }
      } else {
        // TRUC: Needed to refresh the user's session
        await getAccessToken({
          cacheMode: 'off',
        });
        createNotification('success', `Successfully saved`);
        setSaveLoading(false);
        // TRUC: Reloading window as the session may no longer be verified if they changed their email address
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeElement = (el) => {
    el.disabled = false;
    el.focus();
  };

  const validateForm = () => {
    let elements = dataElements.current;
    let completeFields = Object.keys(elements);
    let parliamentarianValidation =
      userType !== 'Parliamentarian' && userType !== 'Campaigner' && userType !== 'Prospective parliamentary candidate';
    let parliamentarianFields = ['email', 'password', 'firstName', 'lastName'];
    let fields = parliamentarianValidation ? completeFields : parliamentarianFields;
    if (!elements['email'].value.includes('@')) {
      setError({ type: 'email', message: 'Please enter a valid email' });
      return false;
    }

    for (let i = 0; i < fields.length; i++) {
      // If we are on password and it is disabled, ignore this field
      if (!(fields[i] === 'password' && dataElements.current['password'].disabled)) {
        let field = elements[fields[i]];
        let val = field.value;
        if (field.required && val === '') {
          setError({ type: fields[i], message: 'Please complete this field' });
          setSaveLoading(false);
          if (fields[i] === 'organisation') {
            setTimeout(() => (document.getElementById('settings-container').scrollTop += 200), 100);
          }
          return false;
        }
      }
    }
    return true;
  };

  const saveChanges = () => {
    let validate = validateForm();
    if (validate) {
      setSaveLoading(true);
      saveProfile();
    }
  };

  const organisationName = () => {
    switch (userType) {
      case 'Other':
        return 'Your occupation';
      case '':
        return 'Your occupation';
      case 'Lobbying or PR agency':
        return `Name of lobbying or PR agency`;
      case 'Parliamentary or political staff':
        return `Which office or department do you work for?`;
      case 'Civil servant':
        return `Which department do you work for?`;
      case 'Think tank or pressure group':
        return `Name of ${getAppSite() === 'usa' ? 'organization' : 'organisation'}`;
      case 'Local authority':
        return `Which local authority do you work for?`;
      case 'Consultant':
        return `Name of consultancy`;
      case 'Media':
        return `Name of outlet`;
      case null:
        return '';
      default:
        return `Name of ${userType.toLowerCase()}`;
    }
  };

  const Error = () => {
    return (
      <div className='error-message px-0 px-lg-2 col-lg-4 mt-2 mt-lg-0'>
        <img className='mr-3' src={`${process.env.REACT_APP_CDNURL}/images/warning-icon.svg`} alt='warning-icon' />
        <p className='mb-0 message py-2 px-3'>{error.message}</p>
      </div>
    );
  };

  const organisationProps = {
    organisationLogo,
    setShowRemoveLogoPopup,
    setOrganisationLogo,
    setIsSaved,
  };

  return (
    <>
      {isLoading && <SkeletonAccount />}
      {!isLoading && (
        <div className='pt-lg-5'>
          <div className='settings-box px-lg-5 pb-lg-0 pt-4 p-3 position-relative'>
            <h3 className='main-title topics-title mb-3'>Account</h3>
            <div ref={containerRef} />
            <CustomScrollbar
              className={`main-content-scrollbar setting-scrollbar`}
              style={{ height: `${heightContainer}px` }}
              maximalThumbYSize={100}
            >
              <div className={`account-list px-0 pb-2 ${!isSaved ? 'account-spacing-button' : ''}`}>
                <div className='pr-3' id='settings-container'>
                  <ul className='policy-list px-0 hover-content'>
                    <form className='row mx-0'>
                      <div className='col-lg-8 px-0 pr-lg-5 account-left-items'>
                        <li>
                          <h3>Email address</h3>
                          <div className='pl-2 mt-2  account-email-field'>
                            <input
                              className='col-15'
                              defaultValue={email}
                              type='text'
                              disabled
                              ref={(input) => (dataElements.current['email'] = input)}
                              onChange={() => {
                                setIsSaved(false);
                              }}
                              required
                            />
                            <span
                              className='edit-item'
                              onClick={() => {
                                changeElement(dataElements.current['email']);
                              }}
                              {...disabledUSAProps()}
                            >
                              Editar
                            </span>
                          </div>
                          {error.type === 'email' && <>{Error()}</>}
                        </li>
                        <li>
                          <h3>Password</h3>
                          <div className='pl-2 mt-2 account-email-field'>
                            <input
                              className='col-15'
                              type='password'
                              defaultValue={Math.floor(Math.random() * 100000000)}
                              disabled
                              ref={(input) => (dataElements.current['password'] = input)}
                              onChange={() => {
                                setIsSaved(false);
                              }}
                              required
                            />
                            <span
                              className='edit-item'
                              onClick={() => {
                                changeElement(dataElements.current['password']);
                                dataElements.current['password'].value = '';
                              }}
                              {...disabledUSAProps()}
                            >
                              Editar
                            </span>
                          </div>
                          {error.type === 'password' && <>{Error()}</>}
                        </li>
                        <li>
                          <h3>First name</h3>
                          <input
                            className='mt-2'
                            defaultValue={firstName}
                            type='text'
                            ref={(input) => (dataElements.current['firstName'] = input)}
                            onChange={() => {
                              setIsSaved(false);
                            }}
                            {...disabledUSAProps()}
                            required
                          />
                          {error.type === 'firstName' && <>{Error()}</>}
                        </li>

                        <li>
                          <h3>Last name</h3>
                          <input
                            className=' mt-2'
                            defaultValue={lastName}
                            type='text'
                            ref={(input) => (dataElements.current['lastName'] = input)}
                            onChange={() => {
                              setIsSaved(false);
                            }}
                            {...disabledUSAProps()}
                            required
                          />
                          {error.type === 'lastName' && <>{Error()}</>}
                        </li>

                        <li>
                          <h3 className='pl-0 mb-2 mb-lg-0'>Phone number</h3>
                          <input
                            className=' mt-2'
                            defaultValue={phoneNumber}
                            type='tel'
                            ref={(input) => (dataElements.current['phoneNumber'] = input)}
                            onChange={() => {
                              setIsSaved(false);
                            }}
                            {...disabledUSAProps()}
                          />
                        </li>
                      </div>
                      <div className='col-lg-8 px-0 pl-lg-5'>
                        <li>
                          <h3>User type</h3>
                          <select
                            className='custom-select login-data-input mt-2'
                            defaultValue={userProfile.userType}
                            ref={(select) => (dataElements.current['userType'] = select)}
                            onChange={(e) => {
                              setIsSaved(false);
                              setError({ type: '', message: '' });
                              setUserType(e.target.value);
                            }}
                            {...disabledUSAProps()}
                            required
                          >
                            <option value='' hidden>
                              Choose your user type
                            </option>
                            {userTypes.map((item) => {
                              return (
                                <option key={item.name} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                          {error.type === 'userType' && <>{Error()}</>}
                        </li>
                        {userType !== 'Parliamentarian' &&
                          userType !== 'Prospective parliamentary candidate' &&
                          userType !== 'Campaigner' &&
                          userType !== null && (
                            <>
                              <li>
                                <h3>{organisationName()}</h3>
                                {(() => {
                                  let value;
                                  if (userType !== userProfile.userType) {
                                    value = '';
                                  } else {
                                    value = userProfile.userType === 'Other' ? jobTitle : organisation;
                                  }
                                  return (
                                    <input
                                      className='mt-2'
                                      defaultValue={value}
                                      type='text'
                                      ref={(input) => (dataElements.current['organisation'] = input)}
                                      onChange={() => {
                                        setIsSaved(false);
                                      }}
                                      required
                                      {...disabledUSAProps()}
                                    />
                                  );
                                })()}
                                {error.type === 'organisation' && <>{Error()}</>}
                              </li>
                            </>
                          )}
                        <li>
                          <h3>{getAppSite() === 'usa' ? 'Organization' : 'Organisation'} website</h3>
                          <input
                            className='organisation-website-input mt-2'
                            defaultValue={organisationWebsite}
                            type='text'
                            ref={(input) => (dataElements.current['organisationWebsite'] = input)}
                            onChange={() => {
                              setIsSaved(false);
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 13) {
                                e.preventDefault();
                              }
                            }}
                            disabled={myself.role === 'Member'}
                            {...disabledUSAProps()}
                          />
                          {error.type === 'organisationWebsite' && <>{Error()}</>}
                        </li>
                        <OrganisationLogo {...organisationProps} />
                      </div>
                    </form>
                  </ul>
                </div>
              </div>
            </CustomScrollbar>
            {!isSaved && (
              <div className='py-3 account-save-container px-3 px-lg-5'>
                <button className='action-button btn general-button py-2 px-4' onClick={saveChanges}>
                  {saveLoading ? <i className='fas fa-spinner fa-spin'></i> : 'Save'}
                </button>
              </div>
            )}
            <RemoveLogoPopup {...organisationProps} showRemoveLogoPopup={showRemoveLogoPopup} />
          </div>
        </div>
      )}
    </>
  );
};
export default Account;
