import React, { useContext, useRef, useState } from 'react';
import { net_api_url, store } from 'components/Store';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import SalutationInfoButton from './SalutationInfoButton';
import { AddTagsContent } from './AddTagsModal/AddTagsModal';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { validateEmail } from 'utils/validateEmail';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken';
import ModalComponent from 'components/Common/Modal';

function AddContactModal({ showHideAddContactModal, showAddContactModal }) {
  const location = useLocation();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { addedCustomContacts, crmContactsQtty, team } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ type: '', message: '' });
  const [tags, setTags] = useState([]);

  const dataElements = useRef([]);
  const [heightContainer, containerRef] = useHeightContainer();
  const { newAgencyPlan } = useTopicOrClientWord();

  const tagsInput = useRef(null);
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  const { getAccessToken } = useGetAccessToken();
  let requestHeaders = {
    cancelToken: source.token,
  };

  const closeModal = () => {
    showHideAddContactModal();
  };

  const validateForm = () => {
    let elements = dataElements.current;
    let fields = Object.keys(elements);
    for (let i = 0; i < fields.length; i++) {
      let field = elements[fields[i]];
      let val = field.value;
      if (field.required && val === '') {
        setError({ type: fields[i], message: 'Please complete this field' });
        return false;
      } else if (!validateEmail(String(elements['email'].value))) {
        setError({
          type: 'email',
          message: 'Please enter a valid email',
        });
        return false;
      }
    }
    return true;
  };
  const saveContact = async () => {
    setIsLoading(true);
    try {
      let token = await getAccessToken();
      let emailAddress = dataElements.current['email'].value;
      let firstName = dataElements.current['firstName'].value;
      let lastName = dataElements.current['lastName'].value;
      let salutation = dataElements.current['salutation'].value;
      let description = dataElements.current['description'].value;

      requestHeaders.headers = {
        Authorization: `Bearer ${token}`,
      };
      let requestProperties = {
        emailAddress,
        firstName,
        lastName,
        salutation,
        description,
        tags: !!tags.length ? tags.map((tag) => tag.value) : null,
      };

      const result = await Axios.post(`${net_api_url}/api/crm-contact/add-custom`, requestProperties, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      if (result.status === 200) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'addedCustomContacts',
          value: [...addedCustomContacts, requestProperties],
        });
        if (location.pathname.includes('influence/emails') && location.pathname.split('/')[3]) {
          const contact = result.data;
          const contactToContactSelect = {
            value: contact.email,
            imageId: contact.imageId,
            label: contact.title,
            id: contact.id,
          };
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'newContactFromSelect',
            value: contactToContactSelect,
          });
        }
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'crmContactsQtty',
          value: crmContactsQtty + 1,
        });
        let successMessage = `Contact ${firstName} ${lastName} saved`;
        createNotification('success', successMessage);
        closeModal();
      }
    } catch (error) {
      let warningMessage = 'Error occurred, please try again later';
      if (error?.response?.message) {
        warningMessage = error?.response?.message;
      }
      if (error?.response?.data === 'A contact with this email address already exists') {
        warningMessage = error?.response?.data;
      }
      createNotification('danger', warningMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const addContact = () => {
    let validate = validateForm();
    if (validate) {
      saveContact();
    }
  };

  const handleChange = (e) => {
    if (e?.target?.name === 'firstName') {
      dataElements.current['salutation'].value = e.target.value;
    }
    if (e?.target?.name === error?.type) {
      setError({ type: '', message: '' });
    }
  };

  const tagsInputHeight = tagsInput?.current && Number(tagsInput?.current?.div?.clientHeight) > 41 ? 116 : 0;
  return (
    <ModalComponent maxWidth={790} isOpen={showAddContactModal} setIsOpen={closeModal}>
      <div className='add-contact-modal-container pt-4'>
        <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3'>
          Create contact {newAgencyPlan ? `for ${team?.teamName}` : ''}
        </h3>
        <div className='px-lg-5 px-3 pt-3 pb-0 position-relative add-contact-modal-body'>
          <div ref={containerRef} />
          <CustomScrollbar
            className={`main-content-scrollbar setting-scrollbar`}
            style={{
              height: heightContainer - tagsInputHeight,
              marginBottom: '81px',
            }}
            maximalThumbYSize={100}
          >
            <div className={`account-list px-0 pb-2`}>
              <div className='px-3' id='settings-container'>
                <ul className='policy-list px-0 hover-content'>
                  <form className='mx-0'>
                    <div className='px-0'>
                      <div className='update-custom-contact-names-container'>
                        <li className='add-contact-list-item' style={{ flexGrow: 1 }}>
                          <h3>First name</h3>
                          <input
                            className='mt-2'
                            type='text'
                            ref={(input) => (dataElements.current['firstName'] = input)}
                            name='firstName'
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          {error.type === 'firstName' && <Error message={error.message} />}
                        </li>

                        <li style={{ flexGrow: 1 }}>
                          <h3>Last name</h3>
                          <input
                            className=' mt-2'
                            type='text'
                            ref={(input) => (dataElements.current['lastName'] = input)}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                            name='lastName'
                          />
                          {error.type === 'lastName' && <Error message={error.message} />}
                        </li>
                      </div>
                      <div className='update-custom-contact-names-container'>
                        <li>
                          <h3>Email address</h3>
                          <div className='mt-2 account-email-field'>
                            <input
                              type='text'
                              required
                              ref={(input) => (dataElements.current['email'] = input)}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              name='email'
                            />
                          </div>
                          {error.type === 'email' && <Error message={error.message} />}
                        </li>
                        <li>
                          <SalutationInfoButton />
                          <div className='mt-2  account-email-field'>
                            <input
                              type='text'
                              required
                              ref={(input) => (dataElements.current['salutation'] = input)}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              name='salutation'
                            />
                          </div>
                          {error.type === 'salutation' && <Error message={error.message} />}
                        </li>
                      </div>
                      <li className='custom-contact-item tags-no-pseudo-elem'>
                        <h3>
                          Tags <span className='font-weight-normal opacity-8'>(Optional)</span>
                        </h3>
                        <div className='mt-2 account-email-field tags-modal-component tags-no-pseudo-elem'>
                          <AddTagsContent
                            titleText={1}
                            contactsCount={1}
                            isLoading={isLoading}
                            addTagsToContacts={() => {}}
                            setTags={setTags}
                            tags={tags}
                            closeModal={() => {}}
                            customContact
                          />
                        </div>
                      </li>
                      <li
                        style={{
                          paddingBottom: '15px',
                        }}
                      >
                        <h3>
                          Description <span className='font-weight-normal opacity-8'>(Optional)</span>
                        </h3>
                        <div className='mt-2  account-email-field'>
                          <textarea
                            type='text'
                            style={{ resize: 'none' }}
                            ref={(input) => (dataElements.current['description'] = input)}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name='description'
                          />
                        </div>
                        {error.type === 'description' && <Error message={error.message} />}
                      </li>
                    </div>
                  </form>
                </ul>
              </div>
            </div>
          </CustomScrollbar>
        </div>
        <div className='d-flex justify-content-end px-4 confirm-results-section confirm-add-contact-modal'>
          <button
            onClick={addContact}
            className='general-button action-button px-3 py-2'
            disabled={isLoading}
            style={{ minWidth: '141px' }}
          >
            {isLoading ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  margin: '0 auto',
                  color: '#fcfcfc',
                  marginTop: '2px',
                  width: '33.7px',
                }}
              ></i>
            ) : (
              `Create contact ${newAgencyPlan ? `for ${team?.teamName}` : ''}`
            )}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
}

const Error = ({ message }) => {
  return (
    <div className='form-error-message'>
      <img className='mr-3' src={`${process.env.REACT_APP_CDNURL}/images/warning-icon.svg`} alt='warning-icon' />
      <p className='mb-0 message py-2 px-3'>{message}</p>
    </div>
  );
};

export { Error };
export default AddContactModal;
