import { useEffect, useRef, useState } from 'react';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import TopicPickerList from './TopicPickerList';
import { Portal } from 'react-portal';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import useReportIconAutoselect from 'components/Reports/useReportIconAutoselect';

import data from '@emoji-mart/data';
import { init } from 'emoji-mart';

const TopicPicker = (props) => {
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const { topicOrClientWord, newAgencyPlan } = useTopicOrClientWord();

  const {
    allowFreeSearch,
    emptyInputStatus,
    keywordsLists,
    mainSearchValue,
    searchFunction = () => {},
    showTopicCases,
    closeButtonFunction,
    defaultTopic,
    fixedPosition,
    placeholder,
    disabled,
    closeButtonMessage = 'See all activity',
    topicButtonDataTip = `${!showDropdownContent ? `Select a ${topicOrClientWord}` : ''}`,
    closeButtonPosition = 'top',
    customClassOpen = '',
    showTopicSelected,
    dataTooltip = 'general-tooltip',
    showActions,
    onlySearchbar,
    aiIcons,
  } = props;
  const [searchValue, setSearchValue] = useState(mainSearchValue ?? '');
  const [topic, setTopic] = useState(defaultTopic ?? keywordsLists[0]);
  const [customIcon, setCustomIcon] = useState('');

  const topicContainer = useRef(null);
  const dropdownElement = useRef(null);
  const inputElement = useRef(null);
  const searchTimeout = useRef();

  const showTopic = allowFreeSearch ? !showDropdownContent && showTopicCases && searchValue === '' : showTopicCases;
  const { callLoggedOutIconSearch } = useReportIconAutoselect();

  const iconCondition = showTopicCases || allowFreeSearch;
  const hideSearchBar = () => {
    setShowDropdownContent(false);
  };
  useOutsideElement(dropdownElement, hideSearchBar);

  const applySearchQueryOrTopic = ({ freeSearch, topic }) => {
    if (topic) {
      setTopic(topic);
      setSearchValue('');
    }
    searchFunction({ freeSearch, topic });
    setShowDropdownContent(false);
  };

  const openSearchBar = () => {
    if (!onlySearchbar) {
      setShowDropdownContent(true);
    }
    setTimeout(() => {
      if (inputElement.current && allowFreeSearch) {
        inputElement.current.focus();
      }
    }, 100);
  };

  useEffect(() => {
    init({ data });
  }, []);

  useEffect(() => {
    setTopic(defaultTopic ?? keywordsLists[0]);
  }, [defaultTopic]);

  useEffect(() => {
    setSearchValue(mainSearchValue);
  }, [mainSearchValue]);

  const positionStyles = () => {
    if (fixedPosition) {
      let { top, left, width, height } = topicContainer.current ? topicContainer.current.getBoundingClientRect() : {};
      return {
        position: 'fixed',
        top: top ? top + height : 0,
        left,
        width,
      };
    } else {
      return {};
    }
  };

  const isMobile = useIsMobile();
  const createKeywordsLists = () => {
    return (
      <div
        className={`${customClassOpen} open-dropdown d-${showDropdownContent ? 'block' : 'none'} ${
          fixedPosition ? 'dropdown-fixed-position' : ''
        }`}
        style={positionStyles()}
        ref={dropdownElement}
      >
        <div className='open-dropdown-container'>
          <TopicPickerList
            inputSearch={allowFreeSearch ? inputElement : null}
            keywordsLists={keywordsLists}
            applyTopic={applySearchQueryOrTopic}
            showDropdownContent={showDropdownContent}
            topic={topic}
            searchValue={mainSearchValue ?? ''}
            showTopicSelected={showTopicSelected}
            dataTooltip={dataTooltip}
            showActions={showActions}
          />
        </div>
      </div>
    );
  };

  const onBlurFunction = async (e) => {
    const inputValue = searchValue;
    if (inputValue !== '' && aiIcons) {
      let value = await callLoggedOutIconSearch({ value: inputValue });
      if (value) {
        setCustomIcon(value);
      }
    }
  };

  return (
    <>
      {showDropdownContent && (
        <div className='general-close-background z-extra-max' onClick={() => hideSearchBar()}></div>
      )}
      <div
        className={`z-extra-max dropdown-keywords-lists ${showDropdownContent ? 'dropdown-keywords-lists-open' : ''} ${
          emptyInputStatus ? 'dropdown-keywords-influence' : ''
        } ${fixedPosition ? 'dropdown-keywords-lists-fixed-position' : ''} ${
          keywordsLists.length === 0 ? 'dropdown-keyword-lists-empty' : ''
        } ${disabled ? 'dropdown-keywords-lists-disabled' : ''} ${newAgencyPlan ? 'dropdown-keywords-new-agency' : ''}`}
        ref={topicContainer}
      >
        <div
          className={`dropdown-keywords-activeitem d-flex align-items-center ${showTopic && !disabled ? 'pointer' : ''} `}
          onClick={(e) => {
            if (!disabled) {
              if (showDropdownContent && !allowFreeSearch) {
                hideSearchBar();
              } else {
                openSearchBar();
              }
            }
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {/* TYPE ICON */}
          {iconCondition && (
            <>
              {aiIcons && searchValue !== '' && customIcon ? (
                <span className='report-list-icon text-center d-inline-block-centered'>
                  <em-emoji set={'apple'} id={customIcon} size={'1.45em'} style={{ lineHeight: 1 }} />
                </span>
              ) : (
                <span
                  className={`main-icon-topic-picker ${
                    showTopic
                      ? `icon-topics-thin ${keywordsLists.length === 0 ? 'main-light-text' : ''}`
                      : 'icon-search'
                  }`}
                />
              )}
            </>
          )}
          {/*INPUT SEARCHBAR */}
          {(!showTopic || keywordsLists.length === 0) && (
            <input
              className='search-main-input'
              ref={inputElement}
              value={searchValue ?? ''}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={
                allowFreeSearch
                  ? `${isMobile ? 'Search for any keyword' : `Search by keyword or ${topicOrClientWord}`}`
                  : placeholder ?? `Select a ${topicOrClientWord}`
              }
              disabled={!allowFreeSearch}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  applySearchQueryOrTopic({ freeSearch: e.target.value });
                }
              }}
              onKeyUp={(e) => {
                if (searchTimeout.current) clearTimeout(searchTimeout.current);
                searchTimeout.current = setTimeout(() => onBlurFunction(e), 500);
              }}
              style={!searchValue && !iconCondition ? { paddingLeft: '11px', paddingTop: '1px' } : undefined}
            />
          )}
          {showTopic && keywordsLists?.length > 0 && (
            <p className={`search-main-text-value ${!disabled ? 'pointer' : ''}`}>
              <span className='d-block hidden-lines hidden-one-line'>{topic?.name}</span>
            </p>
          )}

          {/* CLOSE BUTTON  */}
          {closeButtonFunction && (
            <div
              className='close-icon-container'
              style={{
                paddingRight: '63px',
                visibility: (allowFreeSearch ? showTopic || mainSearchValue !== '' : showTopic) ? 'visible' : 'hidden',
              }}
            >
              <button
                className='general-button close-icon'
                data-tooltip-content={isMobile ? '' : `${closeButtonMessage}`}
                data-tooltip-id={dataTooltip}
                data-tooltip-place={closeButtonPosition}
                onClick={(e) => {
                  e.stopPropagation();
                  if (closeButtonFunction) {
                    closeButtonFunction(inputElement);
                  }
                  setSearchValue('');
                }}
              />
            </div>
          )}

          {/* ARROW BUTTON */}
          {!onlySearchbar && (
            <span
              className={`search-dropdown ${showDropdownContent ? 'search-dropdown-open' : ''}`}
              data-tooltip-content={topicButtonDataTip}
              data-tooltip-id={dataTooltip}
              data-tooltip-place='right'
            ></span>
          )}
        </div>
        {fixedPosition ? <Portal>{createKeywordsLists()}</Portal> : <>{createKeywordsLists()}</>}
      </div>
    </>
  );
};

export default TopicPicker;
