import React, { useState, useRef, useEffect } from 'react';
import { SideBarItems } from 'components/Dashboard/SidebarElements';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';

const SectionTitle = (props) => {
  const { sectionName } = props;
  const isMobile = useIsMobile();
  const [showBox, setShowBox] = useState(false);

  const actionsElement = useRef(null);
  const location = useLocation();
  const hideActions = () => {
    setShowBox(false);
  };

  const showItemDropdown = isMobile && !location.pathname.includes('/stakeholder-mapping');
  const titleElement = useRef();

  return (
    <>
      <div
        className='position-relative flex-grow-1 d-flex flex-wrap justify-content-between pr-4 align-items-center'
        ref={actionsElement}
      >
        <div>
          <h3 className='my-lg-0 main-title mb-0' ref={titleElement}>
            <span
              className={`font-weight-bold ${showItemDropdown ? 'dropdown-item-element pointer dropdown-item-element-main-title' : ''} ${showBox ? 'open' : ''}`}
              onClick={() => {
                if (showItemDropdown) {
                  setShowBox(!showBox);
                }
              }}
            >
              {sectionName}
            </span>
          </h3>
          {showBox && (
            <>
              <MobileMenu showBoxState={{ showBox, setShowBox }} titleElement={titleElement}>
                <SideBarItems closeMenu={hideActions} />
              </MobileMenu>
            </>
          )}
        </div>
        {/* {inboxSection && detectActiveFilters() && <TotalResults />} */}
      </div>
    </>
  );
};

const MobileMenu = (props) => {
  const [boxHeight, setBoxHeight] = useState();
  const { showBoxState, titleElement, children } = props;
  const { showBox, setShowBox } = showBoxState ?? {};
  const mobileMenu = useRef(null);

  useEffect(() => {
    let height = window.innerHeight - mobileMenu?.current?.getBoundingClientRect()?.top - 20;
    setBoxHeight(height);
  }, [showBox]);

  const { top, left, height } = titleElement?.current?.getBoundingClientRect() ?? {};
  return (
    <>
      {showBox && <div className='general-close-background z-extra-max' onClick={() => setShowBox(false)} />}
      <Portal>
        <div
          className='login-navbar py-2 overflow-auto scroll-container z-extra-max'
          ref={mobileMenu}
          style={{
            position: 'fixed',
            top: `${top + height + 8}px`,
            left: `${left}px`,
            maxHeight: `${boxHeight}px`,
            width: '270px',
          }}
        >
          {children}
        </div>
      </Portal>
    </>
  );
};

export { MobileMenu };
export default SectionTitle;
