import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
let checkSearchTitleValue = (val, title) => {
  if (val) {
    let substrings = val.toLowerCase().trim().split(' ');
    let str = title.toLowerCase();
    return new RegExp(substrings.join('|')).test(str);
  } else {
    return false;
  }
};

const searchIncludesTitleConditional = (title) => {
  let searchValue = decodeURIComponent(getUrlParam('search')).split('#')[0];
  let value = checkSearchTitleValue(searchValue, title);
  return value;
};

const relevantActivityFalseCondition = () =>
  getUrlParam('relevant-activity') && getUrlParam('relevant-activity') === 'false';

export { searchIncludesTitleConditional, checkSearchTitleValue, relevantActivityFalseCondition };
