import { Link, useLocation } from 'react-router-dom';
import ModalComponent from 'components/Common/Modal';
import { useContext } from 'react';
import { store } from 'components/Store';

const ContentPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { subscriptionStatus } = activePlan;

  const { proMessage } = props;
  const location = useLocation();
  const trialUser = subscriptionStatus === 'InTrial' || subscriptionStatus === 'TrialEnded';

  return (
    <>
      <div className='section-title px-4 py-4'>
        <h3 className='export-message-title  mb-0 filters-register-title'>
          <span>Filters</span>
        </h3>
      </div>

      <div className='px-4 py-3 section-title'>
        {trialUser ? (
          <>
            <p>Sorting and filtering are not available during the trial period.</p>
            <p className='mb-0'>Please upgrade to a paid subscription to use this feature.</p>
          </>
        ) : (
          <>
            <p>
              Sorting and filtering is {proMessage ? 'a research feature available' : 'available to paying subscribers'}{' '}
              on the Mogul or Enterprise plans.{' '}
            </p>

            <p className='mb-0'>
              To sort and filter results, please upgrade to a {proMessage ? '' : 'paid'} Mogul or Enterprise
              subscription.
            </p>
          </>
        )}
      </div>
      <Link to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`} className='py-3 d-block text-center'>
        <button className='px-3 py-2 action-button general-button'>Compare plans</button>
      </Link>
    </>
  );
};

const FilterRegisterPopup = (props) => {
  return (
    <ModalComponent isOpen={props.showPopup} setIsOpen={props.setShowPopup} maxWidth={390}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default FilterRegisterPopup;
