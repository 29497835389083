import dayjs from 'dayjs';
import normalize from 'components/Dashboard/utilities/normalizeString';
import removeHyphenFromString from 'components/Dashboard/utilities/removeHyphenFromString';
import PartyMetaData from './ui/MentionsByTopic/PartyMetadata';
import getAppSite from 'utils/getAppSite';

const createChartDataOptions = () => {
  let options = [
    {
      name: 'Overall',
    },
    {
      name: 'Party',
      bucketField: 'contactParty',
    },
  ];
  if (getAppSite() === 'global') {
    options = [
      ...options,
      {
        name: 'Region',
        bucketField: 'contactRegion',
      },
      {
        name: 'Parliamentary position',
        bucketField: 'contactPositionCategories',
        showRowsFor: ['Backbenchers', 'Government', 'Shadow government', 'MSs', 'MSPs'],
        filters: [
          {
            field: 'contactPositionCategories',
            value: 'Backbencher',
            operator: 'str_eq',
          },
          {
            field: 'contactPositionCategories',
            value: 'Government',
            operator: 'str_eq',
          },
          {
            field: 'contactPositionCategories',
            value: 'Shadow Government',
            operator: 'str_eq',
          },
          {
            field: 'contactPositionCategories',
            value: 'Shadow Cabinet',
            operator: 'str_eq',
          },
          {
            field: 'contactPositionCategories',
            value: 'Cabinet',
            operator: 'str_eq',
          },
          { field: 'contactPositionCategories', value: 'MS', operator: 'str_eq' },
          { field: 'contactPositionCategories', value: 'MSP', operator: 'str_eq' },
        ],
      },
      {
        name: 'Contribution type',
      },
    ];
  }
  return options;
};

const chartDataOptions = createChartDataOptions();

const createDataOptionsMapping = ({ date }) => {
  let currentDate = date ? dayjs(date) : dayjs();
  return [
    {
      name: 'Last 7 days',
      filter: [currentDate.subtract(7, 'day').startOf('day').format('YYYY-MM-DD')],
      normalized: 'last-7-days',
    },
    {
      name: 'Last 30 days',
      filter: [currentDate.subtract(29, 'day').startOf('day').format('YYYY-MM-DD')],
      normalized: 'last-30-days',
    },
    {
      name: 'Last 3 months',
      filter: [currentDate.subtract(3, 'month').startOf('day').format('YYYY-MM-DD')],
      normalized: 'last-3-months',
    },
    {
      name: 'Last 6 months',
      filter: [currentDate.subtract(6, 'month').startOf('day').format('YYYY-MM-DD')],
      normalized: 'last-6-months',
    },
    {
      name: 'Last 12 months',
      filter: [currentDate.subtract(1, 'year').startOf('day').format('YYYY-MM-DD')],
      normalized: 'last-12-months',
    },
    {
      name: 'Custom',
      filterProperty: 'range',
    },
  ];
};

const DateOptionsMapping = createDataOptionsMapping({});

const analyticsStateMapping = {
  currentView: 'resolution',
  activeDateInterval: 'dates',
  typeOfChart: 'style',
  chartDataOptionSelected: 'group-by',
  selectedCountries: 'nations',
  allowFrontbenchers: 'include-westminster-frontbenchers',
};

const getSelectedCategories = () => {
  let hashParts = window.location.hash.replace('#', '').split('~');
  const groupBy = hashParts.find((item) => item.startsWith('group-by:'))?.split(':')?.[1];
  if (groupBy === 'party') {
    return PartyMetaData.showParties;
  } else {
    return null;
  }
};

export const mentionsGraphNations = [
  { name: 'MPs & peers', value: 'UK' },
  { name: 'MSs (Wales)', value: 'Wales' },
  { name: 'MSPs (Scotland)', value: 'Scotland' },
];

const createAnalyticsState = (initialState) => {
  let initialObject = {
    currentView: 'Month',
    activeDateInterval: {
      name: 'Last 12 months',
      filter: [dayjs().subtract(1, 'year').startOf('day').format('YYYY-MM-DD')],
    },
    typeOfChart: 'Bar',
    typeOfChartSentimentChanges: 'Line',
    chartDataOptionSelected: 'Overall',
    chartDataOptionSelectedSentimentChanges: 'Party',
    chartDataOptionSelectedTweets: 'Contribution type',
    typeOfChartInterestByRegion: 'Heat map',
    partySelectedCategories: getSelectedCategories(),
    selectedCountries: mentionsGraphNations,
    selectedCountriesInterestByRegion: [mentionsGraphNations[0]],
    allowFrontbenchers: false,
  };
  if (initialState) {
    return initialObject;
  }
  let hashParts = window.location.hash.replace('#', '').split('~');
  let createdAnalyticsState = initialObject;

  let currentViewHash = hashParts.find((item) => item.includes(analyticsStateMapping['currentView']));
  let activeDateIntervalHash = hashParts.find((item) => item.includes(analyticsStateMapping['activeDateInterval']));
  let typeOfChartHash = hashParts.find((item) => item.includes(analyticsStateMapping['typeOfChart']));
  let chartDataOptionSelectedHash = hashParts.find((item) =>
    item.includes(analyticsStateMapping['chartDataOptionSelected'])
  );
  let nationsHash = hashParts.find((item) => item.includes(analyticsStateMapping['selectedCountries']));
  let allowFrontbenchersHash = hashParts.find((item) => item.includes(analyticsStateMapping['allowFrontbenchers']));

  //CURRENT VIEW HASH
  if (currentViewHash) {
    createdAnalyticsState.currentView = removeHyphenFromString(currentViewHash.split(':')[1]);
  }

  //ACTIVE DATE HASH
  if (activeDateIntervalHash) {
    if (activeDateIntervalHash.split(':')[1].includes('between')) {
      let fromToDates = activeDateIntervalHash.split(':')[1].replace('between-', '').split('-and-');
      createdAnalyticsState.activeDateInterval = {
        name: 'Custom',
        filter: fromToDates,
      };
    } else {
      const activeDateIntervalOption = DateOptionsMapping.find(
        (item) => item.normalized === activeDateIntervalHash.split(':')[1]
      );
      createdAnalyticsState.activeDateInterval = {
        name: activeDateIntervalOption.name,
        filter: activeDateIntervalOption.filter,
      };
    }
  }

  //TYPE OF CHART HASH
  if (typeOfChartHash) {
    if (window.location.pathname.includes('/sentiment-changes')) {
      let option = typeOfChartHash.split(':')[1];
      if (option === 'Line' || option === 'Bar') {
        createdAnalyticsState.typeOfChartSentimentChanges = removeHyphenFromString(typeOfChartHash.split(':')[1]);
      }
    } else if (window.location.pathname.includes('/interest-by-region')) {
      createdAnalyticsState.typeOfChartInterestByRegion = removeHyphenFromString(typeOfChartHash.split(':')[1]);
    } else {
      createdAnalyticsState.typeOfChart = removeHyphenFromString(typeOfChartHash.split(':')[1]);
    }
  }

  //CHART DATA OPTION SELECTED HASH
  if (chartDataOptionSelectedHash) {
    chartDataOptions.forEach((item) => {
      const { name } = item;
      if (chartDataOptionSelectedHash.split(':')[1] === normalize(name === 'Overall' ? 'none' : name)) {
        if (window.location.pathname.includes('/analytics/tweets')) {
          createdAnalyticsState.chartDataOptionSelectedTweets = name;
        } else if (window.location.pathname.includes('/analytics/sentiment-changes')) {
          createdAnalyticsState.chartDataOptionSelectedSentimentChanges = name;
        } else {
          createdAnalyticsState.chartDataOptionSelected = name;
        }
      }
    });
  }

  //SELECTED COUNTRIES HASH
  if (nationsHash) {
    let nationsFromHash = nationsHash.split(':')?.[1]?.split('-');
    if (window.location.pathname.includes('/analytics/interest-by-region')) {
      createdAnalyticsState.selectedCountriesInterestByRegion = mentionsGraphNations.filter((item) =>
        nationsFromHash.includes(normalize(item.value))
      );
    } else {
      createdAnalyticsState.selectedCountries = mentionsGraphNations.filter((item) =>
        nationsFromHash.includes(normalize(item.value))
      );
    }
  }

  //ALLOW FRONTBENCHERS
  if (allowFrontbenchersHash) {
    createdAnalyticsState.allowFrontbenchers = allowFrontbenchersHash.split(':')[1] === 'true';
  }

  if (!createdAnalyticsState.selectedCountriesInterestByRegion.some((item) => item.value === 'UK')) {
    createdAnalyticsState.allowFrontbenchers = false;
  }

  return createdAnalyticsState;
};

export { chartDataOptions, createAnalyticsState, analyticsStateMapping, DateOptionsMapping, createDataOptionsMapping };
