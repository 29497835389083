import React, { useContext, useState } from 'react';
import { store } from 'components/Store';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import useGetSectionFilters from 'components/Dashboard/Filters/useGetSectionFilters';
import { useLocation } from 'react-router-dom';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import useNewlyAddedContacts from 'components/Dashboard/hooks/useNewlyAddedContacts';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { useCallContactIdsResults } from 'components/Dashboard/StakeHolders/StakeHolderList';
import { useFilters } from 'components/Dashboard/Filters/FiltersContext';
import ModalComponent from 'components/Common/Modal';

function RemoveContactsModal({
  resetSelectedContacts = () => {},
  totalHits = 0,
  initialCall,
  showRemoveContactsModal,
}) {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { crmContactsQtty, selectedCrmContacts, removedContactsQntty, deleteStakeholderId } = state;
  const [isLoading, setIsLoading] = useState(false);
  const { setContactIdsCheck, contactIdsCheck } = useCallContactIdsResults();

  const { resetNewlyAddedContacts } = useNewlyAddedContacts();

  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { filters } = sectionFilters;
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const searchQuery = query.get('search');

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const contactsCount = deleteStakeholderId
    ? 1
    : selectedAllVisible.length > 0
      ? selectedAllVisible.length
      : selectedIds.length > 0
        ? selectedIds.length
        : totalHits;

  const { generalApiCall } = useGeneralApiCall();
  const { clearFilters } = useFilters();

  const hideRemoveContactsModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'deleteStakeholderId',
      value: null,
    });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactsModal',
      value: false,
    });
  };

  const closeModal = () => {
    hideRemoveContactsModal();
  };

  //API CALL TO REMOVE CONTACTS FROM LIST
  const removeFromContactLists = async () => {
    setIsLoading(true);
    const requestProperties = deleteStakeholderId
      ? {
          stakeholderContactIds: [deleteStakeholderId],
        }
      : selectedAll
        ? {
            filters: filters,
            query: searchQuery,
          }
        : {
            ids: selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds,
          };
    let pathname = deleteStakeholderId
      ? '/api/crm-contact/delete-by-stakeholder-contact-id'
      : selectedAll
        ? '/api/crm-contact/delete-by-query'
        : '/api/crm-contact/delete';
    let method = 'post';
    try {
      let results = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
      });
      setIsLoading(false);
      if (selectedAll) {
        clearFilters();
      } else {
        initialCall?.current();
      }
      createNotification(
        'success',
        `${thousandCommas(contactsCount)} contact${contactsCount > 1 ? 's' : ''} successfully removed`
      );
      resetSelectedContacts();
      resetNewlyAddedContacts();
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'crmContactsQtty',
        value: crmContactsQtty - contactsCount,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'removedContactsQntty',
        value: removedContactsQntty + contactsCount,
      });
      if (deleteStakeholderId) {
        const newContactIdsCheck = {
          ...contactIdsCheck,
          [deleteStakeholderId]: false,
        };
        setContactIdsCheck(newContactIdsCheck);
      }

      closeModal();
      return results;
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent maxWidth={558} isOpen={showRemoveContactsModal} setIsOpen={closeModal}>
      <RemoveContactsContent
        contactsCount={contactsCount}
        closeModal={closeModal}
        isLoading={isLoading}
        removeFromContactLists={removeFromContactLists}
      />
    </ModalComponent>
  );
}

const RemoveContactsContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const { contactsCount, closeModal, isLoading, removeFromContactLists } = props;
  const contactsText = contactsCount === 1 ? `this contact` : `these ${thousandCommas(contactsCount)} contacts`;
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <div>
      <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 pl-3 pr-5'>
        {newAgencyPlan
          ? `Remove ${contactsText} from ${team?.teamName}'s contact list`
          : `Are you sure you want to remove ${contactsText}`}{' '}
        ?
      </h3>
      <div className='px-lg-5 px-3'>
        <div className='remove-contacts-wrapper pt-4'>
          <p className='remove-contacts-title main-subtle-text'>
            By removing contacts from your contact list, you will lose any tags and notes associated with them, and will
            no longer be able to send emails or perform data exports.
          </p>
        </div>
      </div>
      <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
        <span className='remove-contacts-helper-text-icon mr-2' />
        These changes impact all members of your team
      </p>
      <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white pt-4 rounded-bottom'>
        <button onClick={closeModal} className='danger-button-outlined px-3 py-2 mr-3' disabled={isLoading}>
          Cancel
        </button>
        <button onClick={removeFromContactLists} className='danger-button px-3 py-2' disabled={isLoading}>
          {isLoading ? (
            <i
              className='fas fa-spinner fa-spin'
              style={{
                margin: '0 41.2px',
                color: '#fcfcfc',
                marginTop: '2px',
                width: '33.7px',
              }}
            ></i>
          ) : contactsCount === 1 ? (
            `Remove contact`
          ) : (
            `Remove ${thousandCommas(contactsCount)} contacts`
          )}
        </button>
      </div>
    </div>
  );
};

export { RemoveContactsContent };
export default RemoveContactsModal;
