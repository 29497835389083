import { net_api_url } from 'components/Store';
import axios from 'axios';
const resendInvitation = async (getAccessToken, source, code) => {
  try {
    let token = await getAccessToken();
    let resendCall = await axios.post(
      `${net_api_url}/api/team/resend-invite`,
      {
        code: code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      }
    );
    return resendCall.data;
  } catch (error) {
    console.error(error);
  }
};

export default resendInvitation;
